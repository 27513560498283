import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { addRequestReply } from "../../../redux/actions/studentsRequestsActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

export default function StudentRequestsRePlies() {
  const dispatch = useDispatch();
  const [body, setBody] = useState("");
  const [attachment, setAttachment] = useState(null);
  const request = useSelector((state) => state.studentRequests.request);
  if (!request) {
    return (
      <div className=" justify-content-center">
        <p>Could not load request</p>
      </div>
    );
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-8">
          <h1 className="my-4">{request.subjectId}</h1>
          <div className=" mb-3">
            <small className="text-mute">
              Question from {request?.userId?.fullName}
            </small>
            <br />
            <small className="text-muted">
              Submitted on{" "}
              {moment(request.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
            </small>
          </div>

          <p>{request.question}</p>
          <hr />
          <div className="pl-5">
            <h5>Replies</h5>
            {request.replies.length === 0 && (
              <div>
                <p>No Replies yet</p>
              </div>
            )}
            {request.replies.map((reply) => (
              <div>
                <small className="text-muted">
                  {moment(reply.createdAt).fromNow()}
                </small>
                <br />
                <p>{reply.body}</p>
                {reply.attachment && (
                  <a href={reply.attachment} download>
                    <small className="badge bg-secondary">
                      Attachment <FontAwesomeIcon icon={faDownload} size="sm" />{" "}
                    </small>
                  </a>
                )}
                {/* <p>{reply.attachment}</p> */}
              </div>
            ))}
          </div>
          <hr />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              const formData = new FormData();
              formData.append("body", body);
              if (attachment) {
                formData.append("file", attachment);
              }
              dispatch(addRequestReply(request._id, formData));
            }}
            className="mb-4"
          >
            <div class="mb-3">
              <label for="reply" class="form-label">
                Reply
              </label>
              <textarea
                class="form-control"
                id="reply"
                rows="6"
                required
                onChange={(e) => setBody(e.target.value)}
              ></textarea>
            </div>
            <div class="mb-3">
              <label for="attachment" class="form-label">
                Add Attachment
              </label>
              <div className="d-flex align-items-center">
                <input
                  type="file"
                  class="form-control w-auto"
                  id="attachment"
                  onChange={(e) => setAttachment(e.target.files[0])}
                />
                {attachment && (
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    color="red"
                    size="sm"
                    className="mx-3 cursor"
                    onClick={() => setAttachment(null)}
                  />
                )}
              </div>
            </div>
            <button type="submit" class="btn btn-primary">
              Send Reply
            </button>
          </form>
        </div>
        <div className="col-12 col-lg-4"></div>
      </div>
    </div>
  );
}
