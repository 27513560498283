import React, { useEffect, useState, useRef } from 'react'
import { useHistory, Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  fetchClassContents,
  deleteClassContent,
  deleteClassContents,
  inputChange
} from '../../../redux/actions/classContentsActions'
import { clearErrors } from '../../../redux/actions/errorActions'
import { clearSuccess } from '../../../redux/actions/successActions'
import { fetchCourses } from '../../../redux/actions/coursesActions'
import {
  fetchAssignedSubjects,
  inputChange as subjectsInputChange
} from '../../../redux/actions/assignSubjectActions'
import { fetchTerms } from '../../../redux/actions/termsActions'
import Table from '../../includes/table/table.component'
import { ReactComponent as Edit } from '../../../assets/icons/EditSquare.svg'
import Pagination from '../../includes/pagination/pagination.component'
import FilterSearch from '../../includes/filter-search/filterSearch.component'
import ActionButton from '../../includes/button/actionButton.component'
import DeleteDialog from '../../includes/confirm-dialog/confirmDialog.component'
import Alert from '../../includes/alert/alert.component'
import Snackbar from '../../includes/snackbar/snackbar.component'
import DateWrapper from '../../includes/date-wrapper/date-wrapper.component'
import { show, getAllContentMediaUrl } from '../../../assets/js/globalFunctions'
import Select from '../../includes/select-field/select.component'

import './css/style.css'
const Filter = FilterSearch(Pagination(Table))
const ActionLinkWrapper = props => {
  return (
    <Link to={`/class-contents/${props.item._id}/edit`}>{props.children}</Link>
  )
}

const ClassContents = props => {
  const {
    classContents,
    error,
    success,
    role,
    roles,
    courses,
    terms,
    assignedSubjects
  } = props
  const [course, setCourse] = useState({})
  const [subject, setSubject] = useState({})
  const [term, setTerm] = useState({})
  const [loading, setLoading] = useState(false)

  const mounted = useRef(false)

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    } else {
      if (success.id) {
        let id = success.id
        const snack = {
          message:
            typeof success.msg === 'object'
              ? success.msg.join('<br/>')
              : success.msg,
          type: 'success'
        }
        switch (id) {
          case 'DELETE_CLASS_CONTENT_SUCCESS':
            setSnackbar(snack)
            break
          case 'DELETE_CLASS_CONTENTS_SUCCESS':
            setSnackbar(snack)
            break
          case 'GET_CLASS_CONTENTS_SUCCESS':
            setLoading(false)
            break
        }
        props.clearSuccess()
      } else if (error.id) {
        let id = error.id
        const snack = {
          message:
            typeof error.msg === 'object' ? error.msg.join('<br/>') : error.msg,
          type: 'error'
        }
        switch (id) {
          case 'DELETE_CLASSCONTENT_FAILURE':
            setSnackbar(snack)
            break
          case 'DELETE_CLASSCONTENTS_FAILURE':
            setSnackbar(snack)
            break
        }
        props.clearErrors()
      }
    }
  })

  useEffect(() => {
    return () => {
      props.inputChange('classContents', [])
      props.subjectsInputChange('assignedSubjects', [])
    }
  }, [])

  useEffect(() => {
    if (role) {
      props.fetchCourses()
      props.fetchTerms()
    }
  }, [role])

  useEffect(() => {
    if (course._id) {
      setLoading(true)
      setSubject({})
      setTerm({})
      props.fetchAssignedSubjects(course)
      props.fetchClassContents({ courseId: course._id }, false)
    }
  }, [course])

  useEffect(() => {
    if (subject.assignedId) {
      setLoading(true)
      setTerm({})
      props.fetchClassContents(
        { courseId: course._id, subjectId: subject.assignedId },
        false
      )
    }
  }, [subject])

  useEffect(() => {
    if (term._id) {
      setLoading(true)
      props.fetchClassContents(
        {
          courseId: course._id,
          subjectId: subject.assignedId,
          termId: term._id
        },
        false
      )
    }
  }, [term])

  const selectCourse = e => {
    setCourse(e.target.value)
  }
  const selectSubject = e => {
    setSubject(e.target.value)
  }
  const selectTerm = e => {
    setTerm(e.target.value)
  }

  const history = useHistory()

  const [deleteDialog, setDeleteDialog] = useState(false)
  const [selectedContents, setSelectedContents] = useState([])

  const [successAlert, setSuccessAlert] = useState({
    show: false,
    message: 'Selected content(s) deleted successfully'
  })
  const [snackbar, setSnackbar] = useState({ message: '', type: 'error' })

  const deleteSelectedContents = () => {
    if (selectedContents.length < 1) {
      setSnackbar({ message: 'No class was selected', type: 'error' })
      return
    } else if (selectedContents.length === 1) {
      props.deleteClassContent(
        {
          _id: selectedContents[0]._id,
          deleteMedia: [
            ...selectedContents[0].videoUrls
              .filter(vid => vid.videoUrl)
              .map(vid => vid.videoUrl),
            ...getAllContentMediaUrl(selectedContents[0].content)
          ]
        },
        selectedContents[0].title
      )
    } else {
      const sendData = {
        lessonIds: [],
        deleteMedia: []
      }
      selectedContents.forEach(content => {
        sendData.lessonIds.push(content._id)
        let vidUrls = []
        content.videoUrls.forEach(vid => {
          vidUrls.push(vid.videoUrl)
        })
        sendData.deleteMedia = [
          ...sendData.deleteMedia,
          ...vidUrls,
          ...getAllContentMediaUrl(content.content)
        ]
      })
      props.deleteClassContents(sendData)
    }
  }

  const headers = [
    { value: '_index_', text: 'Id', align: 'left' },
    { value: 'title', text: 'Topic', align: 'left' },
    { value: 'subjectId.mainSubjectId.name', text: 'Subject' },
    { value: 'courseId.name', text: 'Class' },
    { value: 'termId.name', text: 'Term' },
    { value: 'createdAt', text: 'Date Created', wrapper: DateWrapper }
  ]

  return (
    <div id='contents-display' className='page-padding'>
      {role && (
        <div>
          {!show(0, role, roles) ? <Redirect to='/dashboard' /> : null}
          <DeleteDialog
            show={deleteDialog}
            onCancel={() => {
              setDeleteDialog(false)
            }}
            onContinue={() => {
              setDeleteDialog(false)
              deleteSelectedContents()
            }}
            onClose={() => {
              setDeleteDialog(false)
            }}
            title='Delete Class Content(s)'
            message={`Are you sure you want to delete the Class Content${
              selectedContents.length > 1 ? 's' : ''
            }?`}
          />
          <Snackbar
            snack={snackbar}
            reset={() => {
              setSnackbar({ message: '' })
            }}
          />
          <div className='d-flex justify-content-between align-items-center mb-4'>
            <h3 className='title-text' style={{ margin: 0 }}>
              Class Contents
            </h3>
            {show(1, role, roles) && (
              <ActionButton
                delete
                onClick={() => {
                  setDeleteDialog(true)
                }}
              />
            )}
          </div>

          <div className='select-grid mb-4'>
            <div>
              <label
                style={{
                  color: course._id ? 'var(--primary-color)' : 'inherit'
                }}
              >
                Class
              </label>
              <Select
                items={courses}
                value={course}
                textField='name'
                valueField='_id'
                onSelect={selectCourse}
                outlined
                color={course._id ? 'var(--primary-color)' : ''}
              />
            </div>
            <div>
              <label
                style={{
                  color: subject.assignedId ? 'var(--primary-color)' : 'inherit'
                }}
              >
                Subject
              </label>
              <Select
                items={assignedSubjects}
                value={subject}
                textField='name'
                valueField='_id'
                onSelect={selectSubject}
                outlined
                color={subject.assignedId ? 'var(--primary-color)' : ''}
              />
            </div>
            <div>
              <label
                style={{ color: term._id ? 'var(--primary-color)' : 'inherit' }}
              >
                Term
              </label>
              <Select
                items={terms}
                textField='name'
                valueField='_id'
                value={term}
                onSelect={selectTerm}
                outlined
                color={term._id ? 'var(--primary-color)' : ''}
              />
            </div>
          </div>

          <div className='d-flex justify-content-center mb-4'>
            {successAlert.show && (
              <Alert
                type='success'
                message={successAlert.message}
                style={{ maxWidth: 500, flexGrow: 1 }}
                className='mt-3'
                onClose={() => {
                  setSuccessAlert({
                    show: false,
                    message: 'Selected class(es) deleted successfully'
                  })
                }}
              />
            )}
          </div>

          <Filter
            filters={[
              { value: 'all', text: 'All' },
              {
                value: 'subject',
                text: 'Subject',
                searchValue: 'subjectId.mainSubjectId.name'
              },
              { value: 'term', text: 'Term', searchValue: 'termId.name' },
              { value: 'topic', text: 'Topic', searchValue: 'title' }
            ]}
            items={classContents}
            loading={loading}
            itemsPerPage={20}
            headers={headers}
            action={show(0, role, roles) && <Edit style={{ width: 20 }} />}
            selectable={show(1, role, roles)}
            onItemsSelected={val => {
              setSelectedContents(val)
            }}
            inactiveState={!course._id}
            inactiveMessage='Please select a course'
            onActionClicked={item => {
              props.inputChange('classContent', item)
            }}
            actionWrapper={ActionLinkWrapper}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  classContents: state.classContents.classContents,
  courses: state.courses.courses,
  assignedSubjects: state.assignSubject.assignedSubjects,
  terms: state.terms.terms,
  role: state.auth.user.role,
  roles: state.auth.roles,
  error: state.error,
  success: state.success
})
export default connect(mapStateToProps, {
  fetchClassContents,
  deleteClassContent,
  deleteClassContents,
  fetchCourses,
  fetchAssignedSubjects,
  fetchTerms,
  subjectsInputChange,
  clearErrors,
  clearSuccess,
  inputChange
})(ClassContents)
