import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  removeUserFeedback,
  updateAfrilearnUser,
} from "../../../redux/actions/afrilearnUsersActions";
import "./css/style.css";

const UserDetail = (props) => {
  const { user } = props;
  const dispatch = useDispatch();
  let enrolledCourses = "";

  const [newFeedBack, setNewFeedBack] = useState("");
  const [showfeedBack, setShowfeedBack] = useState(false);
  function printCourses() {
    for (let i = 0; i < user.enrolledCourses.length; i++) {
      const status = user.enrolledCourses[i].paymentIsActive
        ? '<p class="subscribed">Subscribed</p>'
        : '<p class="not-subscribed">Not Subscribed</p>';
      enrolledCourses += `${user.enrolledCourses[i].courseId.name} ${status}`;
    }
    return enrolledCourses;
  }

  function getRoleName(roleId) {
    if (roleId == "602f3ce39b146b3201c2dc1d") return "Teacher";
    else if (roleId == "5fd08fba50964811309722d5") return "Student";
    else if (roleId == "602f3cf79b146b3201c2dc1e") return "Administrator";
    else if (roleId == "606ed82e70f40e18e029165e") return "Parent";
    else if (roleId == "607ededa2712163504210684") return "School";
    else return "";
  }

  return (
    <div className="detail afrilearn-users">
      <h3 style={{ marginBottom: 30 }}>Afrilearn User</h3>
      {user.schoolId && user.schoolId.name && (
        <p>
          <label>School Name:</label>
          {user.schoolId && user.schoolId.name}
        </p>
      )}
      <p>
        <label>Full Name:</label>
        {user.fullName}
      </p>
      <p>
        <label>Role:</label>
        {getRoleName(user.role)}
      </p>
      <p>
        <label>Email:</label>
        {user.email}
      </p>
      <div>
        {user.enrolledCourses &&
        getRoleName(user.role) !== "Parent" &&
        getRoleName(user.role) !== "School" ? (
          <div>
            <label>Enrolled Courses: </label>
            {user.enrolledCourses.length > 0 ? (
              <p
                style={{ marginLeft: 150, marginTop: -32 }}
                dangerouslySetInnerHTML={{ __html: printCourses() }}
              ></p>
            ) : (
              <p style={{ marginLeft: 150, marginTop: -32 }}>None</p>
            )}
          </div>
        ) : (
          ""
        )}
      </div>

      <label for="exampleFormControlTextarea1" class="form-label">
        Feedback:
      </label>
      <p>{user.feedBack}</p>
      {showfeedBack && (
        <form
          onSubmit={(e) => {
            // setFeedback(newFeedBack);
            e.preventDefault();
            dispatch(updateAfrilearnUser(user._id, { feedBack: newFeedBack }));
          }}
        >
          <div className="row">
            <div className="col-8 d-flex justify-content-start align-items-end">
              <div class="w-100">
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="5"
                  defaultValue={user.feedBack}
                  onChange={(e) => setNewFeedBack(e.target.value)}
                  required
                ></textarea>
              </div>
            </div>
            <div className="col-4 d-flex justify-content-start align-items-end">
              <button className="btn btn-success btn-sm" type="submit">
                Update
              </button>
            </div>
          </div>
        </form>
      )}
      {!user.feedBack && (
        <button
          className="btn btn-success btn-sm mt-2"
          onClick={() => setShowfeedBack(!showfeedBack)}
        >
          {!showfeedBack ? "Add A Feedback" : "Cancel"}
        </button>
      )}
      {user.feedBack && (
        <div class="d-flex align-items-center mt-2">
          <button
            className="btn btn-secondary  btn-sm"
            onClick={() => setShowfeedBack(!showfeedBack)}
          >
            {!showfeedBack ? "Edit feedback" : "Close"}
          </button>
          <a
            href="#"
            className="link-danger ml-4 "
            onClick={(e) => {
              e.preventDefault();
              dispatch(removeUserFeedback(user._id));
            }}
          >
            <u>Delete</u>
          </a>
        </div>
      )}
    </div>
  );
};

export default UserDetail;
