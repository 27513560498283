import API from "../../assets/js/api";
import { returnErrors } from "./errorActions";
import { returnSuccess } from "./successActions";
import { getKey } from "../../assets/js/globalFunctions";

import {
  ADD_ASSIGNED_PAST_QUESTION_SUCCESS,
  ADD_ASSIGNED_PAST_QUESTION_FAILURE,
  GET_ASSIGNED_PAST_QUESTIONS_SUCCESS,
  GET_ASSIGNED_PAST_QUESTIONS_FAILURE,
  DELETE_ASSIGNED_PAST_QUESTION_SUCCESS,
  DELETE_ASSIGNED_PAST_QUESTION_FAILURE,
  INPUT_CHANGE,
  INITIALIZE_STATE,
} from "./types";

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  );
};
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id));
};

export const inputChange = (name, value) => (dispatch) => {
  dispatch({ type: INPUT_CHANGE, payload: { name, value } });
};

export const initializeState = (val) => (dispatch) => {
  dispatch({ type: INITIALIZE_STATE, payload: val });
};

export const assignPastQuestionType = (course, pastQuestionType) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.assignPastQuestionToCourse(
      { pastQuestionId : pastQuestionType._id }, course._id
    );

    dispatch({ type: ADD_ASSIGNED_PAST_QUESTION_SUCCESS, payload: result.data.data });
    dispatchSuccess(
      dispatch,
      `Past Question Type "${pastQuestionType.name}" successfully assigned to course "${course.name}"`,
      ADD_ASSIGNED_PAST_QUESTION_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, ADD_ASSIGNED_PAST_QUESTION_FAILURE);
  }
};

export const fetchAssignedPastQuestionTypes = (course) => async (dispatch) => {
  try {
    const result = await API.getCourseAssignedPastQuestions(course._id);

    dispatch({
      type: GET_ASSIGNED_PAST_QUESTIONS_SUCCESS,
      payload: result.data.data,
    });
  } catch (err) {
    dispatchError(dispatch, err, GET_ASSIGNED_PAST_QUESTIONS_FAILURE);
  }
};

export const deleteAssignedPastQuestionType = (course, pastQuestionType) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    await API.deleteCourseAssignedPastQuestion(
      course._id,
      pastQuestionType._id
    );

    document.body.classList.remove("loading-indicator");
    dispatch({
      type: DELETE_ASSIGNED_PAST_QUESTION_SUCCESS,
      payload: { pastQuestionTypeId: pastQuestionType._id },
    });
    dispatchSuccess(
      dispatch,
      `Past Question Type "${pastQuestionType.name}" successfully removed from course "${course.name}"`,
      DELETE_ASSIGNED_PAST_QUESTION_SUCCESS
    );
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_ASSIGNED_PAST_QUESTION_FAILURE);
  }
};
