import { GET_CLASSES_SUCCESS } from '../actions/types'

const initialState = {
  classes: []
}

const classesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CLASSES_SUCCESS:
      return {
        ...state,
        classes: action.payload.classes
      }
    default:
      return state
  }
}

export default classesReducer
