import {
  GET_PAYMENT_PLANS_SUCCESS,
  DELETE_PAYMENT_PLANS_SUCCESS,
  DELETE_PAYMENT_PLAN_SUCCESS,
  ADD_PAYMENT_PLAN_SUCCESS,
  EDIT_PAYMENT_PLAN_SUCCESS,
  INPUT_CHANGE,
} from "../actions/types";

const initialState = {
  paymentPlans: [],
  name: "",
  amount: "",
  duration: "",
  addCoinStatusMessage: "",
  addCoinStatus: "pending",
};

const paymentPlansReducer = (state = initialState, action) => {
  let arr;
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case GET_PAYMENT_PLANS_SUCCESS:
      return {
        ...state,
        paymentPlans: action.payload.paymentPlans,
      };
    case DELETE_PAYMENT_PLAN_SUCCESS:
      arr = state.paymentPlans.slice();
      arr = arr.filter((plan) => plan._id !== action.payload);
      return {
        ...state,
        paymentPlans: arr,
      };
    case DELETE_PAYMENT_PLANS_SUCCESS:
      return {
        ...state,
        paymentPlans: state.paymentPlans.filter(
          (plan) => !action.payload.planIds.includes(plan._id)
        ),
      };
    case ADD_PAYMENT_PLAN_SUCCESS:
      return {
        ...state,
        paymentPlans: [
          ...state.paymentPlans,
          {
            ...action.payload.paymentPlan,
            creatorId: {
              firstName: action.payload.user.firstName,
              _id: action.payload.user._id,
            },
          },
        ],
      };
    case EDIT_PAYMENT_PLAN_SUCCESS:
      arr = state.paymentPlans.slice();
      let ind = arr.findIndex(
        (plan) => plan._id === action.payload.paymentPlan._id
      );
      arr[ind] = {
        ...action.payload.paymentPlan,
        creatorId: {
          _id: action.payload.paymentPlan.creatorId,
          firstName: action.payload.user.firstName,
        },
      };
      return {
        ...state,
        paymentPlans: arr,
      };
    default:
      return state;
  }
};

export default paymentPlansReducer;
