import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import homepage from "../screens/homepage/homePage.component";
import NavMenu from "./nav-menu/navMenu.component";
import Toolbar from "./toolbar/toolbar.component";
import Login from "../screens/login/login.component";
import Classes from "../screens/classes/classes.component";
import AfrilearnUsers from "../screens/afrilearnUsers/listAfrilearnUsers.component";
import CmsUsers from "../screens/cmsusers/listCmsUsers.component";
import ManualActivation from "../screens/manual-activation/manualActivation";
import AssignSubject from "../screens/assign-subject/assignSubject.component";
import ClassContents from "../screens/class-contents/classContents.component";
import ClassContentCreation from "../screens/class-contents/classContentCreation.component";
import ClassContentEdition from "../screens/class-contents/classContentEdition.component";
import Quizzes from "../screens/quizzes/quizzes.component";
import QuizCreation from "../screens/quizzes/quizCreation.component";
import QuizEdition from "../screens/quizzes/quizEdition.component";
import Terms from "../screens/terms/term.component";
import Subjects from "../screens/subject/subject.component";
import PaymentTransactions from "../screens/payment-transactions/transactionsNav.component";
import StudentsPaymentPlans from "../screens/studentsPaymentPlans/paymentPlans.component";
import TeachersPaymentPlans from "../screens/teachersPaymentPlans/paymentPlans.component";
import EducationalCategory from "../screens/educational/educational.component";
import PastQuestion from "../screens/past-question/past-question.component.js";
import AssignPastQuestionType from "../screens/assign-past-question/assignPastQuestion.component";
import BlogArticleCreation from "../screens/blogArticles/blogArticleCreation.component";
import BlogArticleEdition from "../screens/blogArticles/blogArticleEdition.component";
import BlogArticles from "../screens/blogArticles/blogArticles.component";
import SendEmail from "../screens/sendEmail/sendEmail.component";
import AfrichallengeReport from "../screens/AfrichallengeReport/AfrichallengeReport.component";
import ImpactStories from "../screens/impactStories/impactStory.component";
import ImpactStoriesCreation from "../screens/impactStories/impactStoryCreation.component";
import ImpactStoriesEditing from "../screens/impactStories/impactStoryEdition.component";
import coinActivation from "../screens/coin-activation/coinActivation";
import StudentRequests from "../screens/studentRequests/studentRequests";
import studentRequestsRePlies from "../screens/studentRequests/studentRequestsRePlies";

const MyNav = (props) => {
  return (
    <Router>
      <div className="d-flex">
        <div className="hide-900">
          <NavMenu />
        </div>
        <div style={{ width: "100%" }}>
          <Toolbar />
          <Switch>
            <Route exact path="/" component={Login} />
            <Route
              exact
              path="/student-requests/:requestId"
              component={studentRequestsRePlies}
            />
            <Route exact path="/student-requests" component={StudentRequests} />
            <Route exact path="/coin-activation" component={coinActivation} />
            <Route exact path="/dashboard" component={homepage} />
            <Route exact path="/impact-stories" component={ImpactStories} />
            <Route
              exact
              path="/impact-stories/create"
              component={ImpactStoriesCreation}
            />
            <Route
              exact
              path="/impact-stories/:id/edit"
              component={ImpactStoriesEditing}
            />
            <Route exact path="/users/cms" component={CmsUsers} />
            <Route exact path="/classes" component={Classes} />
            <Route exact path="/users/afrilearn" component={AfrilearnUsers} />
            <Route exact path="/terms" component={Terms} />
            <Route
              exact
              path="/africhallenge-report"
              component={AfrichallengeReport}
            />
            <Route exact path="/subjects" component={Subjects} />
            <Route
              exact
              path="/manual-activation"
              component={ManualActivation}
            />
            <Route exact path="/assign-subject" component={AssignSubject} />
            <Route exact path="/class-contents" component={ClassContents} />
            <Route
              exact
              path="/class-contents/create"
              component={ClassContentCreation}
            />
            <Route
              exact
              path="/class-contents/:id/edit"
              component={ClassContentEdition}
            />
            <Route exact path="/quizzes" component={Quizzes} />
            <Route
              exact
              path="/payment-plans/students"
              component={StudentsPaymentPlans}
            />
            <Route
              exact
              path="/payment-plans/teachers"
              component={TeachersPaymentPlans}
            />
            <Route exact path="/quizzes/create" component={QuizCreation} />
            <Route exact path="/quizzes/:id/edit" component={QuizEdition} />
            <Route
              path="/payment-transactions"
              component={PaymentTransactions}
            />
            <Route path="/educational" component={EducationalCategory} />
            <Route path="/past-questions" component={PastQuestion} />
            <Route
              exact
              path="/assign-past-question"
              component={AssignPastQuestionType}
            />
            <Route
              exact
              path="/blog/articles/create"
              component={BlogArticleCreation}
            />
            <Route exact path="/blog/articles" component={BlogArticles} />
            <Route
              exact
              path="/blog/articles/:id/edit"
              component={BlogArticleEdition}
            />
            <Route exact path="/send-email" component={SendEmail} />
          </Switch>
        </div>
      </div>
    </Router>
  );
};

export default MyNav;
