import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../includes/button/button.component'
import SelectField from '../../includes/select-field/select.component'
import TextField from '../../includes/text-field/textField.component'
import SunEditor, { buttonList } from 'suneditor-react'
import { ReactComponent as Plus } from '../../../assets/icons/Plus.svg'
import Snackbar from '../../includes/snackbar/snackbar.component'
import { connect } from 'react-redux'
import {
  addQuiz,
  inputChange,
  initializeState,
  clearState
} from '../../../redux/actions/quizzesActions'
import { fetchCourses } from '../../../redux/actions/coursesActions'
import { fetchTerms } from '../../../redux/actions/termsActions'
import { fetchAssignedSubjects, inputChange as assignSubjectsInputChange } from '../../../redux/actions/assignSubjectActions'
import {
  fetchClassContents,
  inputChange as contentsInputChange
} from '../../../redux/actions/classContentsActions'
import { clearErrors } from '../../../redux/actions/errorActions'
import { clearSuccess } from '../../../redux/actions/successActions'

import './css/style.css'
import { getKey } from '../../../assets/js/globalFunctions'
import QuizQuestion from './quizQuestion.component'
import { getRandomString, deleteTags } from '../../../assets/js/globalFunctions'

const randomString = length => {
  var result = ''
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
const QuizCreation = props => {
  const { terms, courses, assignedSubjects, lessons, error, success } = props
  const [lesson, setLesson] = useState({})
  const [course, setCourse] = useState({})
  const [term, setTerm] = useState({})
  const [subject, setSubject] = useState({})
  const [snackbar, setSnackbar] = useState({ message: '', type: 'error' })

  const [key_, setKey] = useState(randomString(10))

  const history = useHistory()

  const quizzes = useRef([
    {
      question: 'question 1',
      options: [
        { val: '', _key: randomString(10), isMarkdown: false },
        { val: '', _key: randomString(10), isMarkdown: false },
        { val: '', _key: randomString(10), isMarkdown: false },
        { val: '', _key: randomString(10), isMarkdown: false }
      ],
      optionImages: [null, null, null, null],
      correct_option: '',
      explanation: '',
      tOrF: false,
      _key: getRandomString(10)
    }
  ])
  const mounted = useRef(false)
  const initialized = useRef(false)

  const clearState = () => {
    quizzes.current = [
      {
        question: 'question 1',
        options: ['', '', '', ''],
        optionImages: [null, null, null, null],
        correct_option: '',
        explanation: '',
        tOrF: false,
        _key: getRandomString(10)
      }
    ]
    setCourse({})
    setTerm({})
    setSubject({})
    setLesson({})
  }

  useEffect(() => {
    if (!mounted.current) {
      props.fetchCourses()
      props.fetchTerms()
      mounted.current = true
    } else {
      if (success.id) {
        const snack = {
          message:
            typeof success.msg === 'object'
              ? success.msg.join('<br/>')
              : success.msg,
          type: 'success'
        }

        switch (success.id) {
          case 'INITIALIZE_STATE_SUCCESS':
            initialized.current = true
            break
          case 'ADD_QUIZ_SUCCESS':
            clearState()
            setSnackbar({...snack})
            break
        }
        props.clearSuccess()
      } else if (error.id) {
        const snack = {
          message:
            typeof error.msg === 'object' ? error.msg.join('<br/>') : error.msg,
          type: 'error'
        }

        switch (error.id) {
          case 'ADD_QUIZ_FAILURE':
            setSnackbar(snack)
            break
        }
        props.clearErrors()
      }
    }
  })

  useEffect(() => {
    if (course._id) {
      props.fetchAssignedSubjects(course)
      setSubject({})
    }
  }, [course])
  useEffect(() => {
    setTerm({})
  }, [subject])
  useEffect(() => {
    if (term._id) {
      let message
      if (!course._id) message = 'Please select a class'
      else if (!subject.assignedId) message = 'Please select a subject'
      if (message) {
        setSnackbar({ message })
        return
      }
      props.fetchClassContents(
        {
          subjectId: subject.assignedId,
          courseId: course._id,
          termId: term._id
        },
        false
      )
      setLesson({})
    }
  }, [term])

  useEffect(() => {
    return () => {
      props.contentsInputChange('classContents', [])
      props.assignSubjectsInputChange('assignedSubjects', [])
    }
  }, [])

  const addQuiz = () => {
    let message
    quizzes.current.every((quiz, index) => {
      if (!deleteTags(quiz.question)) {
        message = `Question not provided for question ${index + 1}`
      } else if (!quiz.correct_option) {
        message = `Correct option not provided for question ${index + 1}`
      } else {
        quiz.options.every((option, ind) => {
          if (!deleteTags(option.val)) {
            message = `Option ${ind + 1} not provided for question ${index + 1}`
          }
          if (message) return false
          return true
        })
      }

      if (message) return false
      return true
    })
    if (!message) {
      if (!course._id) message = 'Please select a class'
      else if (!subject.assignedId) message = 'Please select a subject'
      else if (!term._id) message = 'Please select a term'
      else if (!lesson._id) message = 'Please select a lesson'

      if (message) {
        setSnackbar({ message, type: 'error' })
      } else {
        props.addQuiz({
          lesson,
          quizzes: quizzes.current,
          key: getKey(
            [course.name, subject.name, term.name, lesson.title],
            'quiz'
          )
        })
      }
    } else {
      setSnackbar({ message, type: 'error' })
    }
  }

  return (
    <div className='page-padding quiz-creation'>
      <div
        className='d-flex justify-content-between align-items-center'
        style={{
          borderLeft: '5px solid var(--primary-color)',
          paddingLeft: '8px'
        }}
      >
        <h3 style={{ margin: '0', flexGrow: 1 }} className='title-text'>
          Add Quiz
        </h3>
        <Button
          style={{
            fontSize: '120%',
            padding: '0.5em 2.5em',
            borderRadius: '.5em',
            fontFamily: 'font3'
          }}
          onClick={addQuiz}
        >
          Save
        </Button>
      </div>

      <div className='row mt-5'>
        <div className='col-12 col-lg-8'>
          <div>
            {quizzes.current.map((quiz, index) => (
              <QuizQuestion
                key={quiz._key}
                onChange={c => {
                  quizzes.current[index] = { ...quizzes.current[index], ...c }
                  setKey(randomString(10))
                }}
                onSetSnackbar={snack => {
                  setSnackbar(snack)
                }}
                index={index}
                quiz={false}
              />
            ))}
          </div>

          <Button
            style={{
              padding: '.3em 1em',
              marginTop: '40px',
              fontFamily: 'font3'
            }}
            color='var(--primary-color)'
            onClick={() => {
              quizzes.current = [
                ...quizzes.current,
                {
                  question: '',
                  options: ['', '', '', ''],
                  optionImages: [null, null, null, null],
                  correct_option: '',
                  tOrF: false,
                  explanation: '',
                  _key: getRandomString(10)
                }
              ]
              setKey(randomString(10))
              // setQuizzes(arr)
            }}
          >
            <Plus className='mr-2' />
            Question
          </Button>
        </div>
        <div className='col-12 col-lg-4 mt-5 mt-lg-0'>
          <div
            style={{
              backgroundColor: 'white',
              padding: '20px 0 60px 0',
              borderRadius: '10px'
            }}
          >
            <h4 className='pl-4'>Categories</h4>
            <hr />
            <div className='pl-4 pr-4'>
              <div className='mb-2'>Select Class</div>
              <SelectField
                value={course}
                onSelect={e => {
                  setCourse(e.target.value)
                }}
                items={courses}
                name='course'
                valueField='_id'
                textField='name'
                outlined
                placeholder='Select Class'
                color='#B6B6B6'
                inputStyle={{ borderRadius: 5, paddingLeft: 15 }}
                className='mb-4'
              ></SelectField>
              <div className='mb-2'>Select Subject</div>
              <SelectField
                value={subject}
                onSelect={e => {
                  setSubject(e.target.value)
                }}
                items={assignedSubjects}
                name='subject'
                valueField='_id'
                textField='name'
                outlined
                placeholder='Select Subject'
                color='#B6B6B6'
                inputStyle={{ borderRadius: 5, paddingLeft: 15 }}
                className='mb-4'
              ></SelectField>
              <div className='mb-2'>Select Term</div>
              <SelectField
                value={term}
                onSelect={e => {
                  setTerm(e.target.value)
                }}
                items={terms}
                name='term'
                valueField='_id'
                textField='name'
                outlined
                placeholder='Select Subject'
                color='#B6B6B6'
                selectStyle={{ borderRadius: 5, paddingLeft: 15 }}
                className='mb-4'
              ></SelectField>
              <div className='mb-2'>Select lesson</div>
              <SelectField
                value={lesson}
                onSelect={e => {
                  setLesson(e.target.value)
                }}
                items={lessons}
                name='lesson'
                valueField='_id'
                textField='title'
                outlined
                placeholder='Select Subject'
                color='#B6B6B6'
                selectStyle={{ borderRadius: 5, paddingLeft: 15 }}
                className='mb-4'
              ></SelectField>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        snack={snackbar}
        reset={() => {
          setSnackbar({ message: '' })
        }}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  term: state.quizzes.term,
  terms: state.terms.terms,
  course: state.quizzes.course,
  courses: state.courses.courses,
  subject: state.quizzes.subject,
  lesson: state.quizzes.lesson,
  lessons: state.classContents.classContents,
  assignedSubjects: state.assignSubject.assignedSubjects,
  quizzes: state.quizzes.quiz,
  error: state.error,
  success: state.success
})
export default connect(mapStateToProps, {
  addQuiz,
  fetchAssignedSubjects,
  fetchClassContents,
  inputChange,
  contentsInputChange,
  assignSubjectsInputChange,
  clearState,
  fetchCourses,
  fetchTerms,
  initializeState,
  clearSuccess,
  clearErrors
})(QuizCreation)
