import React, { useState, useEffect, useRef } from 'react'
import Button from '../../includes/button/button.component'
import SelectField from '../../includes/select-field/select.component'
import { ReactComponent as Plus } from '../../../assets/icons/Plus.svg'
import Snackbar from '../../includes/snackbar/snackbar.component'
import { connect } from 'react-redux'
import {
  fetchQuiz,
  updateQuiz,
  inputChange,
  initializeState
} from '../../../redux/actions/quizzesActions'
import { fetchCourses } from '../../../redux/actions/coursesActions'
import { fetchTerms } from '../../../redux/actions/termsActions'
import { fetchAssignedSubjects } from '../../../redux/actions/assignSubjectActions'
import {
  fetchClassContents,
  inputChange as lessonInputChange
} from '../../../redux/actions/classContentsActions'
import { clearErrors } from '../../../redux/actions/errorActions'
import { clearSuccess } from '../../../redux/actions/successActions'
import { useParams, useHistory } from 'react-router-dom'
import QuizQuestion from './quizQuestion.component'

import './css/style.css'
import { getRandomString, deleteTags } from '../../../assets/js/globalFunctions'

const randomString = length => {
  var result = ''
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const QuizCreation = props => {
  const {
    term,
    terms,
    course,
    courses,
    subject,
    assignedSubjects,
    lesson,
    lessons,
    error,
    success,
    editQuiz
  } = props
  const [snackbar, setSnackbar] = useState({ message: '', type: 'error' })
  const [key_, setKey] = useState(getRandomString(10))

  const mounted = useRef(false)
  const initialized = useRef(false)
  const { id } = useParams()
  const history = useHistory()

  const [optionKeys, setOptionKeys] = useState([
    randomString(10),
    randomString(10),
    randomString(10),
    randomString(10)
  ])

  useEffect(() => {
    if (initialized.current) {
      props.fetchAssignedSubjects(course)
      props.inputChange('subject', {})
    }
  }, [course])
  useEffect(() => {
    if (initialized.current) props.inputChange('term', {})
  }, [subject])
  useEffect(() => {
    if (initialized.current) {
      props.inputChange('lesson', {})
      props.lessonInputChange('classContents', [])
    }
    if (term._id) {
      let message
      if (!course || !subject) return
      if (!course._id) message = 'Please select a class'
      else if (!subject.assignedId) message = 'Please select a subject'
      if (message) {
        setSnackbar({ message })
        return
      }
      props.fetchClassContents(
        {
          subjectId: subject.assignedId,
          courseId: course._id,
          termId: term._id
        },
        false
      )
    }
  }, [term])

  useEffect(() => {
    if (!mounted.current) {
      props.fetchQuiz(id)
      props.fetchCourses()
      props.fetchTerms()
      mounted.current = true
    } else {
      if (success.id) {
        const snack = {
          message:
            typeof success.msg === 'object'
              ? success.msg.join('<br/>')
              : success.msg,
          type: 'success'
        }

        switch (success.id) {
          case 'INITIALIZE_STATE_SUCCESS':
            initialized.current = true
            break
          case 'EDIT_QUIZ_SUCCESS':
            setSnackbar({ ...snack })
            break
        }
        props.clearSuccess()
      } else if (error.id) {
        const snack = {
          message:
            typeof error.msg === 'object' ? error.msg.join('<br/>') : error.msg,
          type: 'error'
        }

        switch (error.id) {
          case 'EDIT_QUIZ_FAILURE':
            setSnackbar(snack)
            break
        }
        props.clearErrors()
      }
    }
  })

  const quizzes = useRef([
    {
      question: 'question 1',
      options: [
        { val: '', _key: randomString(10), isMarkdown: false },
        { val: '', _key: randomString(10), isMarkdown: false },
        { val: '', _key: randomString(10), isMarkdown: false },
        { val: '', _key: randomString(10), isMarkdown: false }
      ],
      markdown: [false, false, false, false],
      optionImages: [null, null, null, null],
      correct_option: '',
      explanation: '',
      tOrF: false,
      _key: getRandomString(10)
    }
  ])

  useEffect(() => {
    initialized.current = false
    if (editQuiz._id) {
      const init = {
        term: editQuiz.termId,
        course: editQuiz.courseId,
        subject: {
          assignedId: editQuiz.subjectId,
          ...editQuiz.subjectId.mainSubjectId
        },
        lesson: editQuiz
      }
      quizzes.current = editQuiz.questions.map(quiz => {
        return {
          question: quiz.question,
          options: quiz.options,
          optionImages: quiz.images,
          correct_option: quiz.correct_option,
          explanation: quiz.explanation,
          tOrF: false,
          ...quiz
        }
      })
      props.initializeState(init)
      props.fetchClassContents(
        {
          subjectId: editQuiz.subjectId && editQuiz.subjectId._id,
          courseId: editQuiz.courseId && editQuiz.courseId._id,
          termId: editQuiz.termId && editQuiz.termId._id
        },
        false
      )
      props.fetchAssignedSubjects(editQuiz.courseId)
    }
  }, [editQuiz])

  const handleInput = e => {
    props.inputChange(e.target.name, e.target.value)
  }

  const getHyphenated = string => {
    return string
      .toLowerCase()
      .trim()
      .replace(/ /g, '-')
  }

  const updateQuiz = () => {
    let message
    quizzes.current.every((quiz, index) => {
      if (!deleteTags(quiz.question)) {
        message = `Question not provided for question ${index + 1}`
      } else if (!quiz.correct_option) {
        message = `Correct option not provided for question ${index + 1}`
      } else {
        quiz.options.every((option, ind) => {
          if (!deleteTags(option.val)) {
            message = `Option ${ind + 1} not provided for question ${index + 1}`
          }
          if (message) return false
          return true
        })
      }

      if (message) return false
      return true
    })
    if (!message) {
      // if (!course._id) message = "Please select a class";
      // else if (!subject._id) message = "Please select a subject";
      // else if (!term._id) message = "Please select a term";

      if (message) {
        setSnackbar({ message, type: 'error' })
      } else {
        props.updateQuiz(
          {
            lesson,
            quizzes,
            key: `${getHyphenated(course.name)}/${getHyphenated(
              subject.name
            )}/${getHyphenated(term.name)}/${getHyphenated(
              editQuiz.title
            )}/quiz`
          },
          editQuiz._id
        )
      }
    } else {
      setSnackbar({ message, type: 'error' })
    }
  }

  return (
    <div className='page-padding quiz-creation'>
      <div
        className='d-flex justify-content-between align-items-center'
        style={{
          borderLeft: '5px solid var(--primary-color)',
          paddingLeft: '8px'
        }}
      >
        <h3 style={{ margin: '0', flexGrow: 1 }} className='title-text'>
          Edit Quiz
        </h3>
        <Button
          style={{
            fontSize: '120%',
            padding: '0.5em 2.5em',
            borderRadius: '.5em',
            fontFamily: 'font3'
          }}
          onClick={updateQuiz}
        >
          Save
        </Button>
      </div>

      <div className='row mt-5'>
        <div className='col-12 col-lg-8'>
          {initialized.current &&
            quizzes.current.map((quiz, index) => (
              <QuizQuestion
                key={quiz._key || index}
                onChange={c => {
                  quizzes.current[index] = c
                  setKey(randomString(10))
                }}
                onSetSnackbar={snack => {
                  setSnackbar(snack)
                }}
                index={index}
                quiz={editQuiz.questions[index]}
                edit
              />
            ))}

          <Button
            style={{
              padding: '.3em 1em',
              marginTop: '40px',
              fontFamily: 'font3'
            }}
            color='var(--primary-color)'
            onClick={() => {
              quizzes.current = [
                ...quizzes.current,
                {
                  question: '',
                  options: ['', '', '', ''],
                  optionImages: [null, null, null, null],
                  correct_option: '',
                  tOrF: false,
                  explanation: '',
                  _key: getRandomString(10)
                }
              ]
              setKey(randomString(10))
            }}
          >
            <Plus className='mr-2' />
            Question
          </Button>
        </div>
        <div className='col-12 col-lg-4 mt-5 mt-lg-0'>
          <div
            style={{
              backgroundColor: 'white',
              padding: '20px 0 60px 0',
              borderRadius: '10px'
            }}
          >
            <h4 className='pl-4'>Categories</h4>
            <hr />
            <div className='pl-4 pr-4'>
              <div className='mb-2'>Class</div>
              <SelectField
                value={course}
                onSelect={handleInput}
                items={courses}
                name='course'
                valueField='_id'
                textField='name'
                outlined
                readOnly
                placeholder='Select Class'
                color='#B6B6B6'
                inputStyle={{ borderRadius: 5, paddingLeft: 15 }}
                className='mb-4'
              ></SelectField>
              <div className='mb-2'>Subject</div>
              <SelectField
                value={subject}
                onSelect={handleInput}
                items={assignedSubjects}
                name='subject'
                valueField='_id'
                textField='name'
                readOnly
                outlined
                placeholder='Select Subject'
                color='#B6B6B6'
                inputStyle={{ borderRadius: 5, paddingLeft: 15 }}
                className='mb-4'
              ></SelectField>
              <div className='mb-2'>Term</div>
              <SelectField
                value={term}
                onSelect={handleInput}
                items={terms}
                name='term'
                valueField='_id'
                textField='name'
                readOnly
                outlined
                placeholder='Select Subject'
                color='#B6B6B6'
                selectStyle={{ borderRadius: 5, paddingLeft: 15 }}
                className='mb-4'
              ></SelectField>
              <div className='mb-2'>Lesson</div>
              <SelectField
                value={lesson}
                onSelect={handleInput}
                items={lessons}
                name='lesson'
                valueField='_id'
                readOnly
                textField='title'
                outlined
                placeholder='Select Subject'
                color='#B6B6B6'
                selectStyle={{ borderRadius: 5, paddingLeft: 15 }}
                className='mb-4'
              ></SelectField>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        snack={snackbar}
        reset={() => {
          setSnackbar({ message: '' })
        }}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  term: state.quizzes.term,
  terms: state.terms.terms,
  course: state.quizzes.course,
  courses: state.courses.courses,
  subject: state.quizzes.subject,
  lesson: state.quizzes.lesson,
  lessons: state.classContents.classContents,
  assignedSubjects: state.assignSubject.assignedSubjects,
  editQuiz: state.quizzes.editQuiz,
  error: state.error,
  success: state.success
})
export default connect(mapStateToProps, {
  fetchQuiz,
  updateQuiz,
  fetchAssignedSubjects,
  fetchClassContents,
  lessonInputChange,
  inputChange,
  fetchCourses,
  fetchTerms,
  initializeState,
  clearSuccess,
  clearErrors
})(QuizCreation)
