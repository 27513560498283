import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

import { ReactComponent as CloudUpload } from "../../../assets/icons/CloudUpload.svg";
import Button from "../button/button.component";
import { ReactComponent as Times } from "../../../assets/icons/Times.svg";
import Snackbar from "../snackbar/snackbar.component";
import "./css/style.css";

const CustomFileReader = forwardRef((props, ref) => {
  const [file, setFile] = useState([]);
  const [uploaderSnack, setUploaderSnack] = useState("");

  const onDragOver = (e) => {
    e.preventDefault();
  };

  useImperativeHandle(ref, () => ({
    clearFile() {
      setFile([]);
    },
  }));

  const onDragEnter = (e) => {
    e.preventDefault();
    e.target.className = "overlay drag-enter";
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    e.target.className = "overlay";
  };
  const onDrop = (e) => {
    e.preventDefault();
    e.target.className = "overlay";
    let files = e.dataTransfer.files;
    if (files.length < 1)
      setUploaderSnack({ message: "No file uploaded", type: "error" });
    else if (!props.multiple) {
      if (files.length > 1)
        setUploaderSnack({
          message: "You cannot upload multiple files",
          type: "error",
        });
      else if (!fileIsValid(files[0]))
        setUploaderSnack({ message: "File is invalid!", type: "error" });
      else {
        processSingleFile(files[0]);
      }
    } else {
      let selected = [];
      let invalids = 0;
      let equals = 0;
      if (props.maxFiles) {
        if (file.length + files.length > props.maxFiles) {
          setUploaderSnack({
            message: `Maximum limit exceeded. ${props.maxFiles} files allowed`,
            type: "error",
          });
          return;
        }
      }
      for (let i = 0; i < files.length; i++) {
        if (file.find((f) => f.file.name === files[i].name)) {
          equals++;
          continue;
        }
        if (
          !fileIsValid(files[i]) ||
          file.find((f) => f.name === files[i].name)
        ) {
          invalids++;
          continue;
        } else {
          selected.push(files[i]);
        }
      }

      if (selected.length === 0) {
        let message;
        if (files.length === invalids) message = "All files are invalid";
        else if (files.length === equals)
          message = "All files have already been selected";
        else
          message = `No permitted files: ${invalids} invalid file(s) and ${equals} existing file(s)`;
        setUploaderSnack({ message, type: "error" });
        return;
      } else {
        let sendData = [...file.map((f) => f.file), ...selected];
        if (selected.length < files.length) {
          let message;
          let removed = files.length - selected.length;
          if (invalids === removed)
            message = `Removed ${removed} invalid file(s)`;
          else if (equals === removed)
            message = `Removed ${removed} already existing files`;
          else
            message = `Removed ${invalids} invalid file(s) and ${equals} already existing file(s)`;
          setUploaderSnack({ message, type: "error" });
          props.onInput({ target: { value: sendData, name: props.name } });
          processMultipleFiles(selected);
        } else {
          props.onInput({ target: { value: sendData, name: props.name } });
          processMultipleFiles(selected);
        }
      }
    }
  };

  const processMultipleFiles = (files_, upload) => {
    let files;
    if (upload) {
      files = [];
      for (let i = 0; i < files_.length; i++) {
        files.push(files_[i]);
      }
    } else files = files_;
    let urlFiles = file.slice();
    files.forEach((file_) => {
      if (file_.type.includes("image")) {
        let reader = new FileReader();
        reader.onload = (e) => {
          urlFiles.push({ file: file_, dataUrl: e.target.result });
          if (urlFiles.length === files.length + file.length) {
            setFile(urlFiles);
          }
        };

        reader.readAsDataURL(file_);
      } else if (file_.type.includes("video")) {
        let video = document.createElement("VIDEO");
        let thumbnail = document.createElement("CANVAS");
        var ctx = thumbnail.getContext("2d");
        var duration = 0;

        video.src = URL.createObjectURL(file_);
        video.load();
        video.onloadedmetadata = function (e) {
          duration = video.duration;
          thumbnail.width = video.videoWidth;
          thumbnail.height = video.videoHeight;
          video.currentTime = Math.ceil(duration / 2);
          video.ontimeupdate = async function () {
            ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

            let url = thumbnail.toDataURL();

            urlFiles.push({ file: file_, dataUrl: url });
            if (urlFiles.length === files.length + file.length) {
              setFile(urlFiles);
            }
          };
        };
      }
    });
  };

  const processSingleFile = (file_) => {
    props.onInput({ target: { value: file_, name: props.name } });
    if (file_.type.includes("image")) {
      let reader = new FileReader();

      reader.onload = (e) => {
        setFile(e.target.result);
      };

      reader.readAsDataURL(file_);
    } else if (file_.type.includes("video")) {
      let video = document.createElement("VIDEO");
      let thumbnail = document.createElement("CANVAS");
      var ctx = thumbnail.getContext("2d");
      var duration = 0;

      video.src = URL.createObjectURL(file_);
      video.load();
      video.onloadedmetadata = function (e) {
        duration = video.duration;
        thumbnail.width = video.videoWidth;
        thumbnail.height = video.videoHeight;
        video.currentTime = Math.ceil(duration / 2);
        video.ontimeupdate = async function () {
          ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

          let url = thumbnail.toDataURL();

          setFile(url);
        };
      };
    }
  };

  const sizeIsValid = (size) => {
    if (props.maxSize && size > props.maxSize) return false;
    if (props.minSize && size < props.minSize) return false;
    return true;
  };

  const fileIsValid = (file) => {
    const validTypes = props.validTypes || [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/gif",
    ];
    let text = file.type.split("/")[0];
    if (validTypes.includes(text + "/*")) return true;
    else if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };
  const fileInput = useRef();
  const deleteFile = (name) => {
    let arr = file.slice();
    let newArr = arr.filter((f) => f.file.name !== name);
    props.onInput({
      target: { value: newArr.map((f) => f.file), name: props.name },
    });
    // props.onInput(newArr.map((f) => f.file));
    setFile(newArr);
  };

  return (
    <div id="file-uploader">
      <Snackbar
        snack={uploaderSnack}
        reset={() => {
          setUploaderSnack({ message: "" });
        }}
      />
      <div
        style={{ ...props.style }}
        className={`drop-container ${props.className}`}
      >
        {file.length == 0 && (
          <>
            {props.children || (
              <>
                <CloudUpload />
                <p>Drag and Drop your file here</p>
                <p>or</p>
              </>
            )}
            <span
              onClick={() => {
                fileInput.current.click();
              }}
              className="pointer"
            >
              {props.uploadButton || (
                <Button rounded color="rgba(38,170,118,1)">
                  Browse
                </Button>
              )}
            </span>
            <input
              type="file"
              ref={fileInput}
              accept={
                props.validTypes || "image/jpeg,image/jpg,image/png,image/gif"
              }
              multiple={props.multiple}
              onChange={(e) => {
                props.multiple
                  ? processMultipleFiles(e.target.files, true)
                  : processSingleFile(e.target.files[0]);
              }}
              style={{ opacity: 0, position: "absolute" }}
            />
          </>
        )}
        {file.length > 0 && !props.multiple && (
          <>
            <img
              src={file}
              style={{ maxWidth: "100%", objectFit: "contain" }}
            />
            <button
              style={{
                backgroundColor: "var(--error-color)",
                position: "absolute",
                width: 30,
                height: 30,
                borderRadius: "50%",
                top: -14,
                right: -14,
                border: "none",
              }}
              onClick={() => {
                setFile([]);
                props.onInput({ target: { value: null, name: props.name } });
              }}
            >
              <Times />
            </button>
          </>
        )}

        {file.length > 0 && props.multiple && (
          <div className="multiple-container">
            {file.map((file, index) => {
              return (
                <div
                  className="general-shadow"
                  style={{ position: "relative" }}
                  key={file.file.name}
                >
                  <img
                    src={file.dataUrl}
                    style={{ maxWidth: "100%", objectFit: "contain" }}
                  />
                  <div
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100%",
                      padding: "5px",
                      fontSize: "90%",
                    }}
                  >
                    {file.file.name}
                  </div>

                  <button
                    className="d-flex align-items-center close-btn-min"
                    onClick={() => {
                      deleteFile(file.file.name);
                    }}
                  >
                    <Times style={{ width: 10, stroke: "white" }} />
                  </button>
                </div>
              );
            })}
          </div>
        )}

        <div
          onDrop={onDrop}
          onDragOver={onDragOver}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          className="overlay"
        ></div>
      </div>
    </div>
  );
});
export default CustomFileReader;
