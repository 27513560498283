import API from "../../assets/js/api";
import { returnErrors } from "./errorActions";
import { returnSuccess } from "./successActions";

import { GET_CLASSES_FAILURE, GET_CLASSES_SUCCESS } from "./types";

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  );
};
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id));
};

export const fetchClasses = (loader = true) => async (dispatch) => {
  try {
    // if (loader) document.body.classList.add('loading-indicator')
    const result = await API.getClasses();

    dispatch({ type: GET_CLASSES_SUCCESS, payload: result.data.data });
    // if (loader) document.body.classList.remove('loading-indicator')
  } catch (err) {
    // if (loader) document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, GET_CLASSES_FAILURE);
  }
};
