import {
  GET_ALL_AFRILEARN_USERS_SUCCESS,
  DELETE_AFRILEARN_USER_SUCCESS,
  DELETE_AFRILEARN_USERS_SUCCESS,
  INPUT_CHANGE,
  UPDATE_AFRILEARN_USER_SUCCESS,
  REMOVE_USER_FEEDBACK_SUCCESS,
} from "../actions/types";

const initialState = {
  afrilearnUsers: [],
  count: "",
  fullName: "",
  email: "",
  isActivated: "",
};

const afrilearnUsersReducer = (state = initialState, action) => {
  let arr;
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case GET_ALL_AFRILEARN_USERS_SUCCESS:
      return {
        ...state,
        afrilearnUsers: action.payload.users,
        count: action.payload.count,
      };
    case DELETE_AFRILEARN_USER_SUCCESS:
      arr = state.afrilearnUsers.slice();
      arr = arr.filter((user) => user._id !== action.payload);
      return {
        ...state,
        afrilearnUsers: arr,
      };
    case REMOVE_USER_FEEDBACK_SUCCESS:
      const u = state.afrilearnUsers.find((u) => u._id === action.payload._id);
      u.feedBack = "";
      return {
        ...state,
        afrilearnUsers: [...state.afrilearnUsers],
      };
    case UPDATE_AFRILEARN_USER_SUCCESS:
      const user = state.afrilearnUsers.find(
        (u) => u._id === action.payload._id
      );
      user.feedBack = action.payload.feedBack;
      return {
        ...state,
        afrilearnUsers: [...state.afrilearnUsers],
      };
    case DELETE_AFRILEARN_USERS_SUCCESS:
      return {
        ...state,
        afrilearnUsers: state.afrilearnUsers.filter(
          (user) => !action.payload.userIds.includes(user._id)
        ),
      };
    default:
      return state;
  }
};

export default afrilearnUsersReducer;
