export const show = (permitted, role, roles) =>
  roles.findIndex(r => role === r._id) >= permitted

export const getKey = (arr, end = '') => {
  if (!arr[0])
    return end
      .toLowerCase()
      .trim()
      .replace(/ /g, '-')
  return (
    arr[0]
      .toLowerCase()
      .trim()
      .replace(/ /g, '-') +
    '/' +
    getKey(arr.slice(1), end)
  )
}

export const getRandomString = length => {
  var result = ''
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const deleteTags = str => {
  // if(!str) return ""
  if (str.includes('<') && str.includes('>')) {
    let openTag = str.indexOf('<')
    let closeTag = str.indexOf('>')
    let tagElem = str.substring(openTag, closeTag + 1)
    str = str.replace(tagElem, '')
    return deleteTags(str)
  } else return str
}

export const getAllContentMediaUrl = content => {
  let imgs = []

  const getImages = str => {
    let start = str.indexOf('src')
    if (start >= 0) {
      start = start + 5
      str = str.slice(start)
      let end = str.indexOf('"')
      imgs.push(str.slice(0, end))
      str = str.slice(end)
      getImages(str)
    } else {
      return
    }
  }

  getImages(content)

  return imgs
}
