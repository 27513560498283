import {
    GET_ALL_CMSUSERS_SUCCESS,
    DELETE_CMSUSERS_SUCCESS,
    DELETE_CMSUSER_SUCCESS,
    ADD_CMSUSER_SUCCESS,
    EDIT_CMSUSER_SUCCESS,
    INPUT_CHANGE,
} from "../actions/types";

const initialState = {
    cmsUsers: [],
    currentActionUser: {},
    firstName: "",
    lastName: "",
    name: "",
    email: "",
    role: "",
    password: ""
};

const cmsUsersReducer = (state = initialState, action) => {
    let arr, user;
    switch (action.type) {
        case INPUT_CHANGE:
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            };
        case GET_ALL_CMSUSERS_SUCCESS:
            return {
                ...state,
                cmsUsers: action.payload.users
            };
        case DELETE_CMSUSER_SUCCESS:
            arr = state.cmsUsers.slice();
            arr = arr.filter((user) => user._id !== action.payload);
            return {
                ...state,
                cmsUsers: arr,
            };
        case DELETE_CMSUSERS_SUCCESS:
            return {
                ...state,
                cmsUsers: state.cmsUsers.filter(
                    (user) => !action.payload.userIds.includes(user._id)
                ),
            };
        case ADD_CMSUSER_SUCCESS:
            arr = state.cmsUsers.slice();
            arr.push(action.payload.user);
            return {
                ...state,
                cmsUsers: arr,
                currentActionUser: action.payload.user
            }
        case EDIT_CMSUSER_SUCCESS:
            user = JSON.parse(localStorage.getItem("user"));
            arr = state.cmsUsers.slice();
            let ind = arr.findIndex(
                (user) => user._id === action.payload.user._id
            );
            arr[ind] = {
                ...action.payload.user,
                //   creatorId: {
                //     _id: action.payload.user.creatorId,
                //     firstName: action.payload.user.firstName,
                //   },
            };
            return {
                ...state,
                cmsUsers: arr,
                currentActionUser: action.payload.user
            };
        default:
            return state;
    }
};

export default cmsUsersReducer;
