import API from "../../assets/js/api";
import { returnErrors } from "./errorActions";
import { returnSuccess } from "./successActions";

import {
  GET_PAST_QUESTION_CATEGORIES_FAILURE,
  GET_PAST_QUESTION_CATEGORIES_SUCCESS,
  DELETE_PAST_QUESTION_CATEGORIES_FAILURE,
  DELETE_PAST_QUESTION_CATEGORIES_SUCCESS,
  DELETE_PAST_QUESTION_CATEGORY_FAILURE,
  DELETE_PAST_QUESTION_CATEGORY_SUCCESS,
  ADD_PAST_QUESTION_CATEGORY_SUCCESS,
  ADD_PAST_QUESTION_CATEGORY_FAILURE,
  EDIT_PAST_QUESTION_CATEGORY_SUCCESS,
  EDIT_PAST_QUESTION_CATEGORY_FAILURE,
  INPUT_CHANGE,
} from "./types";

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  );
};
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id));
};

export const inputChange = (name, value) => (dispatch) => {
  dispatch({ type: INPUT_CHANGE, payload: { name, value } });
};

export const fetchPastQuestionCategories = (loader = true) => async (
  dispatch
) => {
  try {
    if (loader) document.body.classList.add("loading-indicator");
    const result = await API.getPastQuestions();

    dispatch({
      type: GET_PAST_QUESTION_CATEGORIES_SUCCESS,
      payload: result.data.data,
    });
    if (loader) document.body.classList.remove("loading-indicator");
  } catch (err) {
    if (loader) document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_PAST_QUESTION_CATEGORIES_FAILURE);
  }
};

export const addPastQuestionCategory = (data) => async (dispatch, getState) => {
  try {
    document.body.classList.add("loading-indicator");
    const user = getState().auth.user;
    const result = await API.addPastQuestion({
      ...data,
      creatorId: user._id,
    });

    dispatch({
      type: ADD_PAST_QUESTION_CATEGORY_SUCCESS,
      payload: result.data.data,
    });
    dispatchSuccess(
      dispatch,
      `Past Question category "${data.name}" successfully created`,
      ADD_PAST_QUESTION_CATEGORY_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, ADD_PAST_QUESTION_CATEGORY_FAILURE);
  }
};
export const editPastQuestionCategory = (
  name,
  update,
  pastQuestionCategoryId
) => async (dispatch, getState) => {
  try {
    document.body.classList.add("loading-indicator");
    const user = getState().auth.user;
    const result = await API.editPastQuestion(
      update,
      pastQuestionCategoryId
    );

    dispatch({
      type: EDIT_PAST_QUESTION_CATEGORY_SUCCESS,
      payload: result.data.data,
    });
    dispatchSuccess(
      dispatch,
      `Past Question category successfully edited`,
      EDIT_PAST_QUESTION_CATEGORY_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, EDIT_PAST_QUESTION_CATEGORY_FAILURE);
  }
};

export const deletePastQuestionCategory = (pastQuestionCategoryId) => async (
  dispatch
) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deletePastQuestion(pastQuestionCategoryId);

    dispatch({
      type: DELETE_PAST_QUESTION_CATEGORY_SUCCESS,
      payload: pastQuestionCategoryId,
    });
    dispatchSuccess(
      dispatch,
      result.data.data.message,
      DELETE_PAST_QUESTION_CATEGORY_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_PAST_QUESTION_CATEGORY_FAILURE);
  }
};

export const deletePastQuestionCategories = (pastQuestionIds) => async (
  dispatch
) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deletePastQuestions({
      pastQuestionIds,
    });

    dispatch({
      type: DELETE_PAST_QUESTION_CATEGORIES_SUCCESS,
      payload: { pastQuestionIds },
    });
    dispatchSuccess(
      dispatch,
      result.data.data.message,
      DELETE_PAST_QUESTION_CATEGORIES_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_PAST_QUESTION_CATEGORIES_FAILURE);
  }
};
