import API from "../../assets/js/api";
import { returnErrors } from "./errorActions";
import { returnSuccess } from "./successActions";

import {
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_FAILURE,
    INPUT_CHANGE,
} from "./types";

const dispatchError = (dispatch, err, id) => {
    dispatch(
        returnErrors(
            err.response.data.errors
                ? err.response.data.errors
                : err.response.data.error,
            err.response.data.status,
            id
        )
    );
};
const dispatchSuccess = (dispatch, message, id) => {
    dispatch(returnSuccess(message, id));
};

export const inputChange = (name, value) => (dispatch) => {
    dispatch({ type: INPUT_CHANGE, payload: { name, value } });
};

export const sendEmail= (data) => async (dispatch) => {
    try {
        document.body.classList.add("loading-indicator");
        const result = await API.sendEmail(data);

        dispatch({ type: SEND_EMAIL_SUCCESS, payload: result.data.data });
        dispatchSuccess(dispatch, result.data.data.message, SEND_EMAIL_SUCCESS);
        document.body.classList.remove("loading-indicator");
    } catch (err) {
        document.body.classList.remove("loading-indicator");
        dispatchError(dispatch, err, SEND_EMAIL_FAILURE);
    }
};
