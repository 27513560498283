import React, { useEffect } from "react";
import {
  inputChange,
  addTerm,
  editTerm,
} from "../../../redux/actions/termsActions";
import { connect } from "react-redux";

import TextField from "../../includes/text-field/textField.component";
import Button from "../../includes/button/button.component";

const TermForm = (props) => {
  const { name } = props;

  useEffect(() => {
    if (props.type === "Edit" && props.term.name !== name) {
      props.inputChange("name", props.term.name);
    } else if (props.type !== "Edit") {
      props.inputChange("name", "");
    }
  }, [props.type, props.term]);

  const resetForm = () => {
    if (props.type === "Edit") {
      props.inputChange("name", props.term.name);
      return;
    }
    props.inputChange("name", "");
  };

  const addTerm = async() => {
    let message;
    if (!name) message = "Term Name is required";

    if (message) {
      props.onSetSnackbar({ message, type: "error" });
      return;
    }

    await props.addTerm({ name });
    resetForm();
    props.onComplete({ name });
  };

  const editTerm = async () => {
    let message;
    if (
      name === props.term.name
    )
      message = "No changes made";
    if (!name) message = "Field cannot be empty";

    if (message) {
      props.onSetSnackbar({ message, type: "error" });
      return;
    }

    await props.editTerm(
      props.term.name, { name }, props.term._id
    );
    resetForm();
    props.onComplete({ name });
  };

  const handleSubmit = () => {
    if (props.type === "Edit") {
      editTerm();
    } else {
      addTerm();
    }
  };

  const handleInput = (e) => {
    props.inputChange(e.target.name, e.target.value);
  };

  return (
    <div
      style={{
        padding: "0 30px",
      }}
    >
      <h3 className="mb-5">{props.type} Term Category</h3>

      <TextField
        onInput={handleInput}
        name="name"
        value={name}
        placeholder="Enter term name"
        outlined
        style={{ marginBottom: 40 }}
      ></TextField>
      <div className="center mt-5">
        <Button
          onClick={handleSubmit}
          style={{ fontSize: "120%" }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  name: state.terms.name
});
export default connect(mapStateToProps, { inputChange, addTerm, editTerm })(
  TermForm
);
