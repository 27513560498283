import API from "../../assets/js/api";
import { returnErrors } from "./errorActions";
import { returnSuccess } from "./successActions";

import {
  GET_EDUCATIONAL_CATEGORIES_FAILURE,
  GET_EDUCATIONAL_CATEGORIES_SUCCESS,
  DELETE_EDUCATIONAL_CATEGORIES_FAILURE,
  DELETE_EDUCATIONAL_CATEGORIES_SUCCESS,
  DELETE_EDUCATIONAL_CATEGORY_FAILURE,
  DELETE_EDUCATIONAL_CATEGORY_SUCCESS,
  ADD_EDUCATIONAL_CATEGORY_SUCCESS,
  ADD_EDUCATIONAL_CATEGORY_FAILURE,
  EDIT_EDUCATIONAL_CATEGORY_SUCCESS,
  EDIT_EDUCATIONAL_CATEGORY_FAILURE,
  INPUT_CHANGE,
} from "./types";

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  );
};
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id));
};

export const inputChange = (name, value) => (dispatch) => {
  dispatch({ type: INPUT_CHANGE, payload: { name, value } });
};

export const fetchEducationalCategories = (loader = true) => async (
  dispatch
) => {
  try {
    if (loader) document.body.classList.add("loading-indicator");
    const result = await API.getEducationalCategories();

    dispatch({
      type: GET_EDUCATIONAL_CATEGORIES_SUCCESS,
      payload: result.data.data,
    });
    if (loader) document.body.classList.remove("loading-indicator");
  } catch (err) {
    if (loader) document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_EDUCATIONAL_CATEGORIES_FAILURE);
  }
};

export const addEducationalCategory = (data) => async (dispatch, getState) => {
  try {
    document.body.classList.add("loading-indicator");
    const user = getState().auth.user;
    const result = await API.addEducationalCategory({
      ...data,
      creatorId: user._id,
    });

    dispatch({
      type: ADD_EDUCATIONAL_CATEGORY_SUCCESS,
      payload: result.data.data,
    });
    dispatchSuccess(
      dispatch,
      `Educational category "${data.name}" successfully created`,
      ADD_EDUCATIONAL_CATEGORY_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, ADD_EDUCATIONAL_CATEGORY_FAILURE);
  }
};
export const editEducationalCategory = (
  name,
  update,
  educationalCategoryId
) => async (dispatch, getState) => {
  try {
    document.body.classList.add("loading-indicator");
    const user = getState().auth.user;
    const result = await API.editEducationalCategory(
      update,
      educationalCategoryId
    );

    dispatch({
      type: EDIT_EDUCATIONAL_CATEGORY_SUCCESS,
      payload: result.data.data,
    });
    dispatchSuccess(
      dispatch,
      `Educational category "${name}" successfully edited`,
      EDIT_EDUCATIONAL_CATEGORY_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, EDIT_EDUCATIONAL_CATEGORY_FAILURE);
  }
};

export const deleteEducationalCategory = (educationalCategoryId) => async (
  dispatch
) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deleteEducationalCategory(educationalCategoryId);

    dispatch({
      type: DELETE_EDUCATIONAL_CATEGORY_SUCCESS,
      payload: educationalCategoryId,
    });
    dispatchSuccess(
      dispatch,
      result.data.data.message,
      DELETE_EDUCATIONAL_CATEGORY_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_EDUCATIONAL_CATEGORY_FAILURE);
  }
};

export const deleteEducationalCategories = (categoryIds) => async (
  dispatch
) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deleteEducationalCategories({
      categoryIds,
    });

    dispatch({
      type: DELETE_EDUCATIONAL_CATEGORIES_SUCCESS,
      payload: { categoryIds },
    });
    dispatchSuccess(
      dispatch,
      result.data.data.message,
      DELETE_EDUCATIONAL_CATEGORIES_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_EDUCATIONAL_CATEGORIES_FAILURE);
  }
};
