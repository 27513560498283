import React, { useState, useEffect, useRef } from "react";

import TextField from "../../includes/text-field/textField.component";
import SelectField from "../../includes/select-field/select.component";
import Button from "../../includes/button/button.component";
import { connect } from "react-redux";
import { clearErrors } from "../../../redux/actions/errorActions";
import { clearSuccess } from "../../../redux/actions/successActions";
import {
  inputChange,
  initializeState,
} from "../../../redux/actions/manualActivationActions";
import { fetchPaymentPlans } from "../../../redux/actions/paymentPlansActions";
import { fetchCourses } from "../../../redux/actions/coursesActions";
import { fetchClasses } from "../../../redux/actions/classesActions";
import { fetchAssignedSubjects } from "../../../redux/actions/assignSubjectActions";
import {
  fetchUser,
  activateUser,
  fetchUserEnrolledCourses,
  fetchSchoolEnrolledCourses,
} from "../../../redux/actions/manualActivationActions";

import { ReactComponent as SuccessTick } from "../../../assets/icons/SuccessTick.svg";
import Snackbar from "../../includes/snackbar/snackbar.component";
import { show } from "../../../assets/js/globalFunctions";
import { Redirect } from "react-router-dom";
import { ReactComponent as Plus } from "../../../assets/icons/Plus.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./css/style.css";
import {
  faChalkboardTeacher,
  faSchool,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";

const afriRoles = [
  "5fd08fba50964811309722d5",
  "602f3ce39b146b3201c2dc1d",
  "607ededa2712163504210684",
];

const Append = (props) => {
  const { item } = props;

  return (
    <span style={{ color: "rgba(0,0,0,.6)" }}>{" - " + item.courseName}</span>
  );
};

const Prepend = (props) => {
  const { item } = props;
  const isActive = () => {
    return new Date().getTime() < new Date(item.endDate).getTime();
  };

  return (
    <div
      style={{
        backgroundColor: isActive() ? "var(--success-bg)" : "var(--error-bg)",
        display: "inline-block",
        minWidth: 10,
        maxWidth: 10,
        minHeight: 10,
        maxHeight: 10,
        width: 10,
        height: 10,
        borderRadius: "50%",
      }}
      className="mr-2"
    ></div>
  );
};

const PaymentActivation = (props) => {
  const {
    user,
    paymentPlan,
    paymentPlans,
    courses,
    refNumber,
    roles,
    role,
    success,
    error,
    userEnrolledCourses,
  } = props;
  const [fetchingUser, setFetchingUser] = useState(false);
  const [snackbar, setSnackbar] = useState({ message: "" });

  const [email, setEmail] = useState("");
  const [course, setCourse] = useState({});
  const [class_, setClass] = useState({});
  const [newClassName, setNewClassName] = useState("");
  const [showAddClass, setShowAddClass] = useState(false);
  const mounted = useRef(false);

  useEffect(() => {
    console.log("user", user);
    if (user._id) {
      if (user.role === afriRoles[2]) {
        props.fetchSchoolEnrolledCourses(user.schoolId);
      } else {
        props.fetchUserEnrolledCourses(user._id);
      }
    }
  }, [user]);

  useEffect(() => {
    if (showAddClass) {
      props.fetchCourses(false);
      if (user.role === afriRoles[1] || user.role === afriRoles[2])
        props.fetchClasses();
    }
  }, [showAddClass]);

  useEffect(() => {
    if (email && mounted.current) {
      setFetchingUser(true);
      props.fetchUser(email.trim());
      setShowAddClass(false);
    }
    props.inputChange("user", {});
  }, [email]);

  // useEffect(() => {
  //   if (course._id) props.fetchAssignedSubjects(course);
  // }, [course]);

  useEffect(() => {
    return () => {
      props.initializeState();
    };
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      props.fetchPaymentPlans();
      // props.initializeState();
      mounted.current = true;
    } else {
      if (success.id) {
        const snack = { message: success.msg, type: "success" };
        switch (success.id) {
          case "GET_AFRILEARN_USER_SUCCESS":
            setFetchingUser(false);
            break;
          case "ACTIVATE_USER_SUCCESS":
            setSnackbar(snack);
            setEmail("");
            setCourse({});
            setClass({});
            setNewClassName("");
            setShowAddClass(false);
            props.initializeState();
            break;
        }
        props.clearSuccess();
      } else if (error.id) {
        const snack = { message: error.msg };
        switch (error.id) {
          case "GET_AFRILEARN_USER_FAILURE":
            setFetchingUser(false);
            setSnackbar(snack);
            break;
          case "ACTIVATE_USER_FAILURE":
            setFetchingUser(false);
            setSnackbar(snack);
            break;
        }
        props.clearErrors();
      }
    }
  });

  const submit = () => {
    let message;
    if (!user || !user._id) message = "No user selected";
    else if (!class_._id && !course._id) message = "No class selected";
    else if (user.role === afriRoles[1] && showAddClass && !newClassName)
      message = "No new class name entered";
    // else if (!subject._id) message = "No subject selected";
    else if (!paymentPlan._id) message = "No payment plan selected";

    if (message) {
      setSnackbar({ message });
      return;
    }

    let data = {};
    if (user.role === afriRoles[0]) {
      data = {
        email: user.email,
        user,
        courseId: course._id,
        transactionRef: refNumber,
        paymentPlan,
      };
    } else if (user.role === afriRoles[1]) {
      if (!newClassName) {
        data = {
          email: user.email,
          user,
          courseId: class_.courseId,
          transactionRef: refNumber,
          paymentPlan,
        };
      } else {
        data = {
          email: user.email,
          user,
          newClassName,
          courseId: course._id,
          transactionRef: refNumber,
          paymentPlan,
        };
      }
    } else if (user.role === afriRoles[2]) {
      data = {
        email: user.email,
        user,
        courseId: class_.courseId,
        transactionRef: refNumber,
        paymentPlan,
      };
    }
    props.activateUser(data, {
      name: user.fullName,
      courseName: class_.name || newClassName || course.name,
    });
  };

  const handleInput = (e) => {
    props.inputChange(e.target.name, e.target.value);
  };

  return (
    <div id="manual-activation" className="page-padding">
      {role && (
        <div>
          {!show(2, role, roles) ? <Redirect to="/dashboard" /> : null}
          <Snackbar
            snack={snackbar}
            reset={() => {
              setSnackbar({ message: "" });
            }}
          />
          <h3 className="title-text">Activate Account Manually</h3>
          <p className="mb-5">
            Manually input user details to activate their Afrilearn account
          </p>

          <label>Email</label>
          <div className="mb-4 d-flex align-items-center">
            <TextField
              value={email}
              onInput={(e) => {
                setEmail(e.target.value);
              }}
              style={{ maxWidth: 500 }}
              name="email"
              placeholder="Email"
            />
            <div className="ml-3">
              {fetchingUser && (
                <div>
                  <div className="loader-small loader-dark"></div>
                </div>
              )}
              <div style={{ fontSize: "85%" }}>
                {!fetchingUser && email && (!user || !user._id) && (
                  <span style={{ color: "var(--error-color)" }}>
                    This email is not registered
                  </span>
                )}
                {!fetchingUser && user && user._id && (
                  <div
                    style={{ color: "var(--primary-color)" }}
                    className="d-flex"
                  >
                    <SuccessTick style={{ width: 18 }} className="mr-3" />
                    <div>Great! This email is a registered user</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <label>Full Name</label>
          <div className="d-flex align-items-center">
            <TextField
              readOnly
              placeholder="Full name"
              style={{ maxWidth: 500 }}
              value={(user && user.fullName) || ""}
            />
            <span style={{ color: "var(--primary-color)" }} className="ml-3">
              {user.role === afriRoles[0] ? (
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faUserGraduate} />
                  <span style={{ fontSize: "85%" }} className="ml-3">
                    Student
                  </span>
                </div>
              ) : user.role === afriRoles[1] ? (
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faChalkboardTeacher} />
                  <span style={{ fontSize: "85%" }} className="ml-3">
                    Teacher
                  </span>
                </div>
              ) : user.role === afriRoles[2] ? (
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon={faSchool} />
                  <span style={{ fontSize: "85%" }} className="ml-3">
                    School
                  </span>
                </div>
              ) : (
                ""
              )}
            </span>
          </div>
          {showAddClass && (
            <div className="mt-4">
              {user.role === afriRoles[1] && (
                <div>
                  <label style={{ whiteSpace: "nowrap" }}>
                    Enter new class name
                  </label>
                  <TextField
                    value={newClassName}
                    style={{ maxWidth: 500 }}
                    className="mb-3"
                    onInput={(e) => {
                      setNewClassName(e.target.value);
                    }}
                  />
                </div>
              )}
              <label style={{ whiteSpace: "nowrap" }} className="">
                Select New Class
              </label>
              <SelectField
                value={course}
                items={courses.filter(
                  (c) =>
                    !userEnrolledCourses.find((nc) => c._id === nc.courseId._id)
                )}
                style={{ maxWidth: 500 }}
                name="course"
                valueField="_id"
                textField="name"
                className="mb-4"
                onSelect={(e) => {
                  setCourse(e.target.value);
                }}
              />
            </div>
          )}
          {!showAddClass && (
            <div className="mt-4">
              {user.role === afriRoles[0] && (
                <div>
                  <label>Class</label>
                  <SelectField
                    items={userEnrolledCourses
                      .filter((c) => c.courseId)
                      .map((course) => ({
                        _id: course.courseId._id,
                        name: course.courseId.name,
                        endDate: course.endDate,
                      }))}
                    prepend={Prepend}
                    onSelect={(e) => {
                      setCourse(e.target.value);
                    }}
                    placeholder="Select Class"
                    name="course"
                    value={course}
                    valueField="_id"
                    textField="name"
                    className="mb-4"
                    style={{ maxWidth: 500 }}
                    action={
                      <div className="pt-3 pb-3 pr-3 pl-3 right">
                        <Button
                          onClick={() => setShowAddClass(true)}
                          outlined
                          style={{ height: 40 }}
                        >
                          <Plus className="add-class-plus" />
                          New class
                        </Button>
                      </div>
                    }
                  />
                </div>
              )}
              {user.role === afriRoles[1] && (
                <div>
                  <label>Class</label>
                  <SelectField
                    items={userEnrolledCourses
                      .filter((c) => c.classId)
                      .map((course) => ({
                        _id: course.classId._id,
                        name: course.classId.name,
                        courseId: course.courseId._id,
                        courseName: course.courseId.name,
                        endDate: course.endDate,
                      }))}
                    append={Append}
                    prepend={Prepend}
                    onSelect={(e) => {
                      setClass(e.target.value);
                    }}
                    placeholder="Select Class"
                    value={class_}
                    valueField="_id"
                    textField="name"
                    className="mb-4"
                    style={{ maxWidth: 500 }}
                    action={
                      <div className="pt-3 pb-3 pr-3 pl-3 right">
                        <Button
                          onClick={() => setShowAddClass(true)}
                          outlined
                          style={{ height: 40 }}
                        >
                          <Plus className="add-class-plus" />
                          New class
                        </Button>
                      </div>
                    }
                  />
                </div>
              )}
              {user.role === afriRoles[2] && (
                <div>
                  <label>Class</label>
                  <SelectField
                    items={userEnrolledCourses
                      .filter((c) => c.classId)
                      .map((course) => ({
                        _id: course.classId._id,
                        name: course.classId.name,
                        courseId: course.courseId._id,
                        courseName: course.courseId.name,
                        endDate: course.endDate,
                      }))}
                    append={Append}
                    prepend={Prepend}
                    onSelect={(e) => {
                      setClass(e.target.value);
                    }}
                    placeholder="Select Class"
                    value={class_}
                    valueField="_id"
                    textField="name"
                    className="mb-4"
                    style={{ maxWidth: 500 }}
                    // action={
                    //   <div className="pt-3 pb-3 pr-3 pl-3 right">
                    //     <Button
                    //       onClick={() => setShowAddClass(true)}
                    //       outlined
                    //       style={{ height: 40 }}
                    //     >
                    //       <Plus className="add-class-plus" />
                    //       New class
                    //     </Button>
                    //   </div>
                    // }
                  />
                </div>
              )}
            </div>
          )}
          {/* <label>Subject</label> */}
          {/* <SelectField
        items={assignedSubjects}
        onSelect={handleInput}
        placeholder="Select Class"
        name="subject"
        value={subject}
        valueField="_id"
        textField="name"
        className="mb-4"
        style={{ maxWidth: 500 }}
      /> */}
          <label>Payment Plan</label>
          <SelectField
            onSelect={handleInput}
            name="paymentPlan"
            value={paymentPlan}
            items={paymentPlans}
            valueField="_id"
            textField="name"
            placeholder="Select Plan"
            className="mb-5"
            style={{ maxWidth: 500 }}
          />
          <TextField
            value={paymentPlan.amount || ""}
            readOnly
            className="mb-4"
            placeholder="Plan Amount"
            style={{ maxWidth: 500 }}
          />
          <p>Reference Number</p>
          <TextField
            value={refNumber}
            onInput={handleInput}
            name="refNumber"
            style={{ maxWidth: 500, marginBottom: 80 }}
          />
          <div className="right">
            <Button
              onClick={submit}
              style={{ fontSize: 18, padding: ".6em 1.5em" }}
            >
              Activate Account
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  paymentPlan: state.manualActivation.paymentPlan,
  paymentPlans: state.paymentPlans.paymentPlans,
  refNumber: state.manualActivation.refNumber,
  courses: state.courses.courses,
  classes: state.classes.classes,
  userEnrolledCourses: state.manualActivation.userCourses,
  assignedSubjects: state.assignSubject.assignedSubjects,
  subject: state.manualActivation.subject,
  user: state.manualActivation.user,
  roles: state.auth.roles,
  role: state.auth.user.role,
  error: state.error,
  success: state.success,
});
export default connect(mapStateToProps, {
  fetchPaymentPlans,
  fetchCourses,
  fetchClasses,
  fetchAssignedSubjects,
  fetchSchoolEnrolledCourses,
  fetchUserEnrolledCourses,
  fetchUser,
  activateUser,
  inputChange,
  clearErrors,
  clearSuccess,
  initializeState,
})(PaymentActivation);
