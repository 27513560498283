import {
  GET_BLOG_ARTICLE_CATEGORIES_SUCCESS,
  DELETE_BLOG_ARTICLE_CATEGORY_SUCCESS,
  DELETE_BLOG_ARTICLE_CATEGORIES_SUCCESS,
  ADD_BLOG_ARTICLE_CATEGORY_SUCCESS,
  EDIT_BLOG_ARTICLE_CATEGORY_SUCCESS,
  INPUT_CHANGE
} from '../actions/types'

const initialState = {
  blogArticleCategories: [],
  blogArticleCategory: {},
  name: ''
}

const blogArticleCategoriesReducer = (state = initialState, action) => {
  let arr
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        [action.payload.name]: action.payload.value
      }
    case GET_BLOG_ARTICLE_CATEGORIES_SUCCESS:
      return {
        ...state,
        blogArticleCategories: action.payload.articleCategories
      }
    case DELETE_BLOG_ARTICLE_CATEGORY_SUCCESS:
      arr = state.blogArticleCategories.slice()
      arr = arr.filter(
        blogArticleCategory => blogArticleCategory._id !== action.payload
      )
      return {
        ...state,
        blogArticleCategories: arr
      }
    case DELETE_BLOG_ARTICLE_CATEGORIES_SUCCESS:
      return {
        ...state,
        blogArticleCategories: state.blogArticleCategories.filter(
          blogArticleCategory =>
            !action.payload.categoryIds.includes(blogArticleCategory._id)
        )
      }
    case ADD_BLOG_ARTICLE_CATEGORY_SUCCESS:
      return {
        ...state,
        blogArticleCategories: [
          ...state.blogArticleCategories,
          action.payload.articleCategory
        ]
      }
    case EDIT_BLOG_ARTICLE_CATEGORY_SUCCESS:
      arr = state.blogArticleCategories.slice()
      let ind = arr.findIndex(
        blogArticleCategory =>
          blogArticleCategory._id === action.payload.articleCategory._id
      )
      arr[ind] = {
        ...action.payload.articleCategory
      }
      return {
        ...state,
        blogArticleCategories: arr
      }
    default:
      return state
  }
}

export default blogArticleCategoriesReducer
