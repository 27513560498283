import API from "../../assets/js/api";
import { returnErrors } from "./errorActions";
import { returnSuccess } from "./successActions";

import {
  ADD_IMPACT_STORY_SUCCESS,
  ADD_IMPACT_STORY_FAILURE,
  GET_IMPACT_STORIES_SUCCESS,
  GET_IMPACT_STORIES_FAILURE,
  IMPACT_STORIES_INPUT_CHANGE,
  GET_IMPACT_STORIES_CATEGORIES_FAILURE,
  GET_IMPACT_STORIES_CATEGORIES_SUCCESS,
  DELETE_IMPACT_STORIES_SUCCESS,
  DELETE_IMPACT_STORIES_FAILURE,
  DELETE_IMPACT_STORY_SUCCESS,
  DELETE_IMPACT_STORY_FAILURE,
  GET_IMPACT_STORY_SUCCESS,
  GET_IMPACT_STORY_FAILURE,
  UPDATE_IMPACT_STORY_SUCCESS,
  UPDATE_IMPACT_STORY_FAILURE,
} from "./types";

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  );
};
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id));
};

export const inputChange = (name, value) => (dispatch) => {
  dispatch({ type: IMPACT_STORIES_INPUT_CHANGE, payload: { name, value } });
};

export const addImpactStory = (data) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const formData = new FormData();
    if (data.title) {
      formData.set("title", data.title);
    }
    if (data.description) {
      formData.set("description", data.description);
    }
    if (data.category) {
      formData.set("categoryId", data.category._id);
    }
    if (data.fileURL) {
      formData.set("fileURL", data.fileURL);
    }
    if (data.description && data.contentImages.length) {
      formData.set("contentUrls", JSON.stringify(data.contentUrls));
      data.contentImages.forEach((image) => {
        formData.append("contentImages", image);
      });
    }
    await API.createImpactStories(data);
    dispatch({
      type: ADD_IMPACT_STORY_SUCCESS,
    });

    dispatchSuccess(
      dispatch,
      `User story successfully created`,
      ADD_IMPACT_STORY_SUCCESS
    );

    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, ADD_IMPACT_STORY_FAILURE);
  }
};
export const updateUserStory = (data, id) => async (dispatch, getState) => {
  try {
    document.body.classList.add("loading-indicator");
    const formData = new FormData();
    if (data.title) {
      formData.set("title", data.title);
    }
    if (data.description) {
      formData.set("description", data.description);
    }
    if (data.category) {
      formData.set("categoryId", data.category._id);
    }
    if (data.fileURL) {
      formData.set("fileURL", data.fileURL);
    }
    if (data.description && data.contentImages.length) {
      formData.set("contentUrls", JSON.stringify(data.contentUrls));
      data.contentImages.forEach((image) => {
        formData.append("contentImages", image);
      });
    }

    const result = await API.updateImpactStory(id, formData);

    dispatch({
      type: UPDATE_IMPACT_STORY_SUCCESS,
      payload: result.data.data?.customerStory,
    });
    dispatchSuccess(
      dispatch,
      `Story successfully updated`,
      UPDATE_IMPACT_STORY_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, UPDATE_IMPACT_STORY_FAILURE);
  }
};
export const getImpactStory = (id) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");

    const result = await API.getImpactStory(id);
    dispatch({
      type: GET_IMPACT_STORY_SUCCESS,
      payload: result.data.data?.customerStory,
    });

    dispatchSuccess(
      dispatch,
      `User story fetched successfully`,
      GET_IMPACT_STORY_SUCCESS
    );

    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_IMPACT_STORY_FAILURE);
  }
};
export const getImpactStories = (data) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");

    const result = await API.getImpactStories(data);
    dispatch({
      type: GET_IMPACT_STORIES_SUCCESS,
      payload: result.data.data,
    });

    dispatchSuccess(
      dispatch,
      `User stories fetched successfully`,
      GET_IMPACT_STORIES_SUCCESS
    );

    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_IMPACT_STORIES_FAILURE);
  }
};
export const getImpactStoryCategories = () => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.getImpactStoryCategories();
    dispatch({
      type: GET_IMPACT_STORIES_CATEGORIES_SUCCESS,
      payload: result.data.data,
    });

    dispatchSuccess(
      dispatch,
      `User story successfully created`,
      GET_IMPACT_STORIES_CATEGORIES_SUCCESS
    );

    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_IMPACT_STORIES_CATEGORIES_FAILURE);
  }
};
export const deleteImpactStories = (ids) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    await API.deleteImpactStories(ids);
    dispatch({
      type: DELETE_IMPACT_STORIES_SUCCESS,
      payload: ids,
    });

    dispatchSuccess(
      dispatch,
      `Stories successfully deleted`,
      DELETE_IMPACT_STORIES_SUCCESS
    );

    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_IMPACT_STORIES_FAILURE);
  }
};
export const deleteImpactStory = (id) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    await API.deleteImpactStory(id);
    dispatch({
      type: DELETE_IMPACT_STORY_SUCCESS,
      payload: id,
    });

    dispatchSuccess(
      dispatch,
      `Story successfully deleted`,
      DELETE_IMPACT_STORY_SUCCESS
    );

    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_IMPACT_STORY_FAILURE);
  }
};
