import React, { useEffect, useState } from "react";
import {
    inputChange,
    addPaymentPlan,
    editPaymentPlan,
} from "../../../redux/actions/paymentPlansActions";
import { connect } from "react-redux";
import TextField from "../../includes/text-field/textField.component";
import Button from "../../includes/button/button.component";

const PlanForm = (props) => {
    const { name, amount, duration } = props;
    const category = "5fd08fba50964811309722d5";

    useEffect(() => {
        if (props.type === "Edit" && props.plan.name !== name) {
            props.inputChange("name", props.plan.name);
            props.inputChange("amount", props.plan.amount);
            props.inputChange("duration", props.plan.duration);
        } else if (props.type !== "Edit") {
            props.inputChange("name", "");
            props.inputChange("amount", "");
            props.inputChange("duration", "");
        }
    }, [props.type, props.plan]);

    const resetForm = () => {
        if (props.type === "Edit") {
            props.inputChange("name", props.plan.name);
            props.inputChange("amount", props.plan.amount);
            props.inputChange("duration", props.plan.duration);
            return;
        }
        props.inputChange("name", "");
        props.inputChange("amount", "");
        props.inputChange("duration", "");
    };

    const addPlan = async () => {
        let message;
        let priceRegex = /^[0-9]+$/;
        if (!name) message = "Payment plan is required";
        else if (!amount) message = "Price is required";
        else if (!duration) message = "Duration is required";
        else if (!amount.match(priceRegex))
            message = "Price is invalid";

        if (message) {
            props.onSetSnackbar({ message, type: "error" });
            return;
        }

        await props.addPaymentPlan({ name, amount, duration, category });
        resetForm();
        props.onComplete({ name });
    };

    const editPlan = async () => {
        let message;
        if (
            name === props.plan.name &&
            amount === props.plan.amount &&
            duration === props.plan.duration
        )
            message = "No changes made";
        if (!name || !amount || !duration) message = "Field cannot be empty";

        if (message) {
            props.onSetSnackbar({ message, type: "error" });
            return;
        }

        await props.editPaymentPlan(
            props.plan.name,
            { name, amount, duration, category },
            props.plan._id
        );
        resetForm();
        props.onComplete({ name });
    };

    const handleSubmit = () => {
        if (props.type === "Edit") {
            editPlan();
        } else {
            addPlan();
        }
    };

    const handleInput = (e) => {
        props.inputChange(e.target.name, e.target.value);
    };

    return (
        <div
            style={{
                padding: "0 30px",
            }}
        >
            <h3 className="mb-3">
                {props.type} {props.type == "Create" ? " New " : " "}
                 Plan
            </h3>

            <label htmlFor="plan-name">Plan Name</label>
            <TextField
                onInput={handleInput}
                value={name}
                name="name"
                style={{ marginBottom: 15 }}
            ></TextField>

<label htmlFor="plan-name">Duration</label>
<TextField
                onInput={handleInput}
                value={duration}
                name="duration"
                style={{ marginBottom: 15 }}
            ></TextField>

            <label htmlFor="plan-name">Price</label>
            <div style={{ position: "relative" }}>
                <span
                    style={{
                        position: "absolute",
                        top: "8px",
                        left: "18px",
                        zIndex: 1,
                        fontSize: "larger"
                    }}
                >
                    ₦
                </span>
                <TextField
                    onInput={handleInput}
                    value={amount}
                    name="amount"
                    style={{ marginBottom: 40 }}
                    inputStyle={{ paddingLeft: "38px" }}
                ></TextField>
            </div>
            <div className="center mt-5">
                <Button
                    onClick={handleSubmit}
                    style={{ fontSize: "120%" }}
                >
                    {props.type}
                </Button>
            </div>
        </div >
    );
};
const mapStateToProps = (state) => ({
    name: state.paymentPlans.name,
    amount: state.paymentPlans.amount,
    duration: state.paymentPlans.duration
});
export default connect(mapStateToProps, { inputChange, addPaymentPlan, editPaymentPlan })(
    PlanForm
);
