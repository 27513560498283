import { returnErrors } from "./errorActions";
import { returnSuccess } from "./successActions";
import API from "../../assets/js/api";
import {
  GET_COUNTS_SUCCESS,
  GET_COUNTS_FAILURE,
  GET_SIGNUPS_BY_DATE_SUCCESS,
  GET_SIGNUPS_BY_DATE_FAILURE,
  GET_AFRICHALLENGE_DATA_BY_DATE_SUCCESS,
  GET_AFRICHALLENGE_DATA_BY_DATE_FAILURE,
  GET_AFRICHALLENGE_WINNER_THIS_WEEK_SUCCESS,
  GET_AFRICHALLENGE_WINNER_THIS_WEEK_FAILURE,
  GET_AFRICHALLENGE_WEEKLY_DATA_SUCCESS,
  GET_AFRICHALLENGE_WEEKLY_DATA_FAILURE,
} from "./types";

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response && err.response.data && err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  );
};

export const fetchCounts = () => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.getCounts();

    dispatch({ type: GET_COUNTS_SUCCESS, payload: result.data.data });
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_COUNTS_FAILURE);
  }
};
export const getSignUpsByDate = (date, endDate) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.getSignUpsByDate(date, endDate);

    dispatch({ type: GET_SIGNUPS_BY_DATE_SUCCESS, payload: result.data.data });
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_SIGNUPS_BY_DATE_FAILURE);
  }
};
export const getAfriChallengeReportByDate = (startDate, endDate) => async (
  dispatch
) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.getAfriChallengeReportByDate(startDate, endDate);

    dispatch({
      type: GET_AFRICHALLENGE_DATA_BY_DATE_SUCCESS,
      payload: result.data.data,
    });
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_AFRICHALLENGE_DATA_BY_DATE_FAILURE);
  }
};
export const getThisWeekAfrichallengesWinners = (startDate, endDate) => async (
  dispatch
) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.getThisWeekAfrichallengesWinners(
      startDate,
      endDate
    );

    dispatch({
      type: GET_AFRICHALLENGE_WINNER_THIS_WEEK_SUCCESS,
      payload: result.data.data,
    });
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_AFRICHALLENGE_WINNER_THIS_WEEK_FAILURE);
  }
};
export const getAfrichallengesWeeklyData = (startDate, endDate) => async (
  dispatch
) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.getAfrichallengesWeeklyData(startDate, endDate);

    dispatch({
      type: GET_AFRICHALLENGE_WEEKLY_DATA_SUCCESS,
      payload: result.data,
    });
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_AFRICHALLENGE_WEEKLY_DATA_FAILURE);
  }
};
