import React from "react";

import Modal from "../modal/modal.component";
import Button from "../button/button.component";

const ConfirmDialog = (props) => {
  return (
    <Modal
      show={props.show}
      onClose={() => {
        props.onClose();
      }}
    >
      <div>
        <h5 className="bold-text">{props.title || "Continue"}</h5>
        <hr />
        <div className="mt-4 mb-4">
          {props.message || "Do you want to continue?"}
        </div>
        <hr />
        <div className="d-flex justify-content-end">
          <Button
            outlined
            className="mr-2"
            onClick={() => {
              props.onCancel();
            }}
            style={{ fontSize: "90%", padding: ".5em 1em" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              props.onContinue();
            }}
            style={{ fontSize: "90%", padding: ".5em 1em" }}
          >
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default ConfirmDialog;
