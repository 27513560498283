import API from "../../assets/js/api";
import { returnErrors } from "./errorActions";
import { returnSuccess } from "./successActions";

import {
  GET_COURSES_FAILURE,
  GET_COURSES_SUCCESS,
  DELETE_COURSES_FAILURE,
  DELETE_COURSES_SUCCESS,
  DELETE_COURSE_FAILURE,
  DELETE_COURSE_SUCCESS,
  ADD_COURSE_SUCCESS,
  ADD_COURSE_FAILURE,
  EDIT_COURSE_SUCCESS,
  EDIT_COURSE_FAILURE,
  INPUT_CHANGE,
} from "./types";

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  );
};
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id));
};

export const inputChange = (name, value) => (dispatch) => {
  dispatch({ type: INPUT_CHANGE, payload: { name, value } });
};

export const fetchCourses = (loader = true) => async (dispatch) => {
  try {
    if (loader) document.body.classList.add("loading-indicator");
    const result = await API.getCourses();

    dispatch({ type: GET_COURSES_SUCCESS, payload: result.data.data });
    if (loader) document.body.classList.remove("loading-indicator");
  } catch (err) {
    if (loader) document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_COURSES_FAILURE);
  }
};

export const addCourse = (data) => async (dispatch, getState) => {
  try {
    document.body.classList.add("loading-indicator");
    const user = getState().auth.user;
    const result = await API.addCourse({
      ...data,
      creatorId: user._id,
    });

    dispatch({
      type: ADD_COURSE_SUCCESS,
      payload: { user, course: result.data.data.course },
    });
    dispatchSuccess(
      dispatch,
      `Class "${data.name}" successfully created`,
      ADD_COURSE_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, ADD_COURSE_FAILURE);
  }
};
export const editCourse = (name, update, courseId) => async (
  dispatch,
  getState
) => {
  try {
    document.body.classList.add("loading-indicator");
    const user = getState().auth.user;
    const result = await API.editCourse(update, courseId);

    dispatch({
      type: EDIT_COURSE_SUCCESS,
      payload: { user, course: result.data.data.course },
    });
    dispatchSuccess(
      dispatch,
      `Class "${name}" successfully edited`,
      EDIT_COURSE_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, EDIT_COURSE_FAILURE);
  }
};

export const deleteCourse = (courseId) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deleteCourse(courseId);

    dispatch({ type: DELETE_COURSE_SUCCESS, payload: courseId });
    dispatchSuccess(dispatch, result.data.data.message, DELETE_COURSE_SUCCESS);
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_COURSE_FAILURE);
  }
};

export const deleteCourses = (courseIds) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deleteCourses({ courseIds });

    dispatch({ type: DELETE_COURSES_SUCCESS, payload: { courseIds } });
    dispatchSuccess(dispatch, result.data.data.message, DELETE_COURSES_SUCCESS);
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_COURSES_FAILURE);
  }
};
