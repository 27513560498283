import React, { useState, useRef, useEffect } from "react";
import "./css/style.css";

const Menu = (props) => {
  const [show, setShow] = useState(false);

  const menuRef = useRef();

  const toggleMenu = (e) => {
    if (show) window.removeEventListener("click", evalClick);
    setShow(!show);
  };
  const evalClick = (e) => {
    if (!show) return;
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      hideShow();
    }
  };
  
  useEffect(() => {
    if (show) window.addEventListener("click", evalClick);
  });

  const hideShow = () => {
    setShow(false);
    window.removeEventListener("click", evalClick);
  };

  return (
    <div ref={menuRef} id="menu">
      <div style={{ display: "inline" }} onClick={toggleMenu}>
        {props.activator}
      </div>
      <div onClick={hideShow}>
        <div className={`popup ${!show ? "p-hide" : ""}`}>{props.children}</div>
      </div>
    </div>
  );
};

export default Menu;
