import {
    ADD_ASSIGNED_PAST_QUESTION_SUCCESS,
    GET_ASSIGNED_PAST_QUESTIONS_SUCCESS,
    DELETE_ASSIGNED_PAST_QUESTION_SUCCESS,
    INPUT_CHANGE,
    INITIALIZE_STATE,
  } from "../actions/types";
  
  const initialState = {
    course: {},
    pastQuestionType: {},
    assignedPastQuestionTypes: []
  };
  
  const assignPastQuestionTypeReducer = (state = initialState, action) => {
    switch (action.type) {
      case INPUT_CHANGE:
        return {
          ...state,
          [action.payload.name]: action.payload.value,
        };
      case INITIALIZE_STATE:
        return {
          course: {},
          pastQuestionType: {},
          assignedPastQuestionTypes: [],
        };
      case ADD_ASSIGNED_PAST_QUESTION_SUCCESS:
      return {
          ...state,
          pastQuestionType: {},
          assignedPastQuestionTypes: [
            ...state.assignedPastQuestionTypes,
            {
              _id: action.payload.pastQuestionType._id,
              categoryId: action.payload.pastQuestionType.pastQuestionTypeId.categoryId,
              name: action.payload.pastQuestionType.pastQuestionTypeId.name,
            },
          ],
        };
      case GET_ASSIGNED_PAST_QUESTIONS_SUCCESS:
        return {
          ...state,
          assignedPastQuestionTypes: action.payload.pastQuestionCategories.map((pq) => ({
            ...pq,
            name: pq.pastQuestionTypeId? pq.pastQuestionTypeId.name : "",
            categoryId: pq.pastQuestionTypeId? pq.pastQuestionTypeId.categoryId: "",
          })),
        };
      case DELETE_ASSIGNED_PAST_QUESTION_SUCCESS:
        return {
          ...state,
          assignedPastQuestionTypes: state.assignedPastQuestionTypes.filter(
            (sub) => sub._id !== action.payload.pastQuestionTypeId
          ),
        };
      default:
        return state;
    }
  };
  
  export default assignPastQuestionTypeReducer;
  