import {
    GET_PAST_QUESTION_CATEGORIES_SUCCESS,
    DELETE_PAST_QUESTION_CATEGORY_SUCCESS,
    DELETE_PAST_QUESTION_CATEGORIES_SUCCESS,
    ADD_PAST_QUESTION_CATEGORY_SUCCESS,
    EDIT_PAST_QUESTION_CATEGORY_SUCCESS,
    INPUT_CHANGE,
  } from "../actions/types";
  
  const initialState = {
    pastQuestionCategories: [],
    name: "",
    categoryId: ""
  };
  
  const pastQuestionsReducer = (state = initialState, action) => {
    let arr;
    switch (action.type) {
      case INPUT_CHANGE:
        return {
          ...state,
          [action.payload.name]: action.payload.value,
        };
      case GET_PAST_QUESTION_CATEGORIES_SUCCESS:
        return {
          ...state,
          pastQuestionCategories: action.payload.pastQuestionCategories,
        };
      case DELETE_PAST_QUESTION_CATEGORY_SUCCESS:
        arr = state.pastQuestionCategories.slice();
        arr = arr.filter(
          (pastQuestionCategory) => pastQuestionCategory._id !== action.payload
        );
        return {
          ...state,
          pastQuestionCategories: arr,
        };
      case DELETE_PAST_QUESTION_CATEGORIES_SUCCESS:
        return {
          ...state,
          pastQuestionCategories: state.pastQuestionCategories.filter(
            (pastQuestionCategory) =>
              !action.payload.pastQuestionIds.includes(
                pastQuestionCategory._id
              )
          ),
        };
      case ADD_PAST_QUESTION_CATEGORY_SUCCESS:
        return {
          ...state,
          pastQuestionCategories: [
            ...state.pastQuestionCategories,
            action.payload.pastQuestionCategory,
          ],
        };
      case EDIT_PAST_QUESTION_CATEGORY_SUCCESS:
        arr = state.pastQuestionCategories.slice();
        let ind = arr.findIndex(
          (pastQuestionCategory) =>
            pastQuestionCategory._id === action.payload.pastQuestionCategory._id
        );
        arr[ind] = {
          ...action.payload.pastQuestionCategory,
        };
        return {
          ...state,
          pastQuestionCategories: arr,
        };
      default:
        return state;
    }
  };
  
  export default pastQuestionsReducer;
  