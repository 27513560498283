import API from "../../assets/js/api";
import { returnErrors } from "./errorActions";
import { returnSuccess } from "./successActions";

import {
GET_TERMS_SUCCESS,
GET_TERMS_FAILURE,
ADD_TERM_SUCCESS,
ADD_TERM_FAILURE,
EDIT_TERM_SUCCESS,
EDIT_TERM_FAILURE,
DELETE_TERM_SUCCESS,
DELETE_TERM_FAILURE,
DELETE_TERMS_SUCCESS,
DELETE_TERMS_FAILURE,
INPUT_CHANGE,
} from "./types";

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  );
};
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id));
};

export const inputChange = (name, value) => (dispatch) => {
  dispatch({ type: INPUT_CHANGE, payload: { name, value } });
};

export const fetchTerms = () => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.fetchTerms();

    dispatch({ type: GET_TERMS_SUCCESS, payload: result.data.data });
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_TERMS_FAILURE);
  }
};

export const addTerm = (data) => async (dispatch, getState) => {
  try {
    document.body.classList.add("loading-indicator");
    const user = getState().auth.user;
    const result = await API.addTerm({
      ...data,
      creatorId: user._id,
    });

    dispatch({
      type: ADD_TERM_SUCCESS,
      payload: { user, term: result.data.data.term },
    });
    dispatchSuccess(
      dispatch,
      `Term "${data.name}" successfully created`,
      ADD_TERM_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, ADD_TERM_FAILURE);
  }
};
export const editTerm = (name, update, termId) => async (
  dispatch,
  getState
) => {
  try {
    document.body.classList.add("loading-indicator");
    const user = getState().auth.user;
    const result = await API.editTerm(update, termId);

    dispatch({
      type: EDIT_TERM_SUCCESS,
      payload: { user, term: result.data.data.term },
    });
    dispatchSuccess(
      dispatch,
      `Term "${name}" successfully edited`,
      EDIT_TERM_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, EDIT_TERM_FAILURE);
  }
};

export const deleteTerm = (termId) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deleteTerm(termId);

    dispatch({ type: DELETE_TERM_SUCCESS, payload: termId });
    dispatchSuccess(dispatch, result.data.data.message, DELETE_TERM_SUCCESS);
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_TERM_FAILURE);
  }
};

export const deleteTerms= (termIds) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deleteTerms({ termIds });

    dispatch({ type: DELETE_TERMS_SUCCESS, payload: { termIds } });
    dispatchSuccess(dispatch, result.data.data.message, DELETE_TERMS_SUCCESS);
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_TERMS_FAILURE);
  }
};
