import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as Edit } from "../../../assets/icons/EditSquare.svg";
import Pagination from "../../includes/pagination/pagination.component";
import Table from "../../includes/table/table.component";
import Alert from "../../includes/alert/alert.component";
import Modal from "../../includes/modal/modal.component";
import SubjectForm from "./subjectForm.component";
import FilterSearch from "../../includes/filter-search/filterSearch.component";
import { connect } from "react-redux";
import {
  fetchSubjects,
  deleteSubject,
  deleteSubjects,
} from "../../../redux/actions/subjectsActions";
import { clearErrors } from "../../../redux/actions/errorActions";
import { clearSuccess } from "../../../redux/actions/successActions";
import "./css/style.css";
import DeleteDialog from "../../includes/confirm-dialog/confirmDialog.component";
import ActionButton from "../../includes/button/actionButton.component";
import Snackbar from "../../includes/snackbar/snackbar.component";
import DateWrapper from "../../includes/date-wrapper/date-wrapper.component";
import NameWrapper from "../../includes/name-wrapper/name-wrapper.component"
import { show } from "../../../assets/js/globalFunctions";
const Filter = FilterSearch(Pagination(Table));

const Subjects = (props) => {
  const headers = [
    { value: "name", text: "Subject", align: "left" },
    { value: "creatorId", text: "Created By", wrapper: NameWrapper },
    { value: "createdAt", text: "Date Created", wrapper: DateWrapper },
  ];

  const { subjects, error, success, role, roles } = props;
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [currentActionSubject, setCurrentActionSubject] = useState({});
  const [loading, setLoading] = useState(false);
  const [subjectsAlert, setSubjectsAlert] = useState({
    show: false,
    message: "Selected subject(s) deleted successfully",
  });
  const [subjectForm, setSubjectForm] = useState({
    show: false,
    type: "Create",
  });
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ message: "", type: "error" });

  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (error.id) {
        const message =
          typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
        let alert_ = { type: "error", show: true, message };
        if (error.id === "DELETE_SUBJECT_FAILURE") {
          setSubjectsAlert({ ...alert_ });
          props.clearErrors();
        } else if (error.id === "DELETE_SUBJECTS_FAILURE") {
          const message =
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
          setSubjectsAlert({ ...alert_ });
          props.clearErrors();
        } else if (error.id === "ADD_SUBJECT_FAILURE") {
          const message =
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
          setSubjectsAlert({ ...alert_ });
          props.clearErrors();
        } else if (error.id === "EDIT_SUBJECT_FAILURE") {
          const message =
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
          setSubjectsAlert({ ...alert_ });
          props.clearErrors();
        }
        props.clearErrors();
      } else if (success.id) {
        let alert_ = { type: "success", show: true, message: success.msg };
        if (success.id === "DELETE_SUBJECT_SUCCESS") {
          setSubjectsAlert({ ...alert_ });
          props.clearSuccess();
        } else if (success.id === "DELETE_SUBJECTS_SUCCESS") {
          setSubjectsAlert({
            ...alert_,
          });
          props.clearSuccess();
        } else if (success.id === "ADD_SUBJECT_SUCCESS") {
          setSubjectsAlert({
            ...alert_,
          });
          props.clearSuccess();
        } else if (success.id === "EDIT_SUBJECT_SUCCESS") {
          setSubjectsAlert({
            ...alert_,
          });
          props.clearSuccess();
        }
        props.clearSuccess();
      }
    }
  });

  useEffect(() => {
    if (role) {

      props.fetchSubjects();
    }
  }, [role])

  const deleteSelectedSubjects = () => {
    if (selectedSubjects.length < 1) {
      setSnackbar({ message: "No subject was selected", type: "error" });
      return;
    } else if (selectedSubjects.length == 1) {
      let subject = selectedSubjects[0];
      let deleteMedia = [subject.imageUrl];
      props.deleteSubject({ _id: selectedSubjects[0]._id, deleteMedia });

    } else {
      let sendData = {
        deleteMedia: [],
        subjectIds: [],
      };
      selectedSubjects.forEach((subject) => {
        sendData.subjectIds.push(subject._id);
        sendData.deleteMedia.push(subject.imageUrl);
      });
      props.deleteSubjects(sendData);
    }
  };

  return (
    <div className="page-padding">
      <DeleteDialog
        show={deleteDialog}
        onCancel={() => {
          setDeleteDialog(false);
        }}
        onContinue={() => {
          setDeleteDialog(false);
          deleteSelectedSubjects();
        }}
        onClose={() => {
          setDeleteDialog(false);
        }}
        title="Delete Subject"
        message={`Are you sure you want to delete the subject${selectedSubjects.length > 1 ? "s" : ""
          }?`}
      />
      <Snackbar
        snack={snackbar}
        reset={() => {
          setSnackbar({ message: "" });
        }}
      />
      <div id="subjects" className="d-flex justify-content-between">
        <h3 className="title-text">Subjects</h3>
        <div>
          <Modal
            maxWidth="950px"
            show={subjectForm.show}
            onClose={() => {
              setSubjectForm({ ...subjectForm, show: false });
            }}
          >
            <SubjectForm
              onComplete={(val) => {
                setSubjectForm({ ...subjectForm, show: false });
                // let arr = subjects.slice();
                // arr.push({
                //   subject: val.subjectName,
                //   createdAt: "25/02/2020",
                //   creator: { name: "Lois Adex" },
                // });
                // setSubjects(arr);
              }}
              onSetSnackbar={(val) => {
                setSnackbar(val);
              }}
              type={subjectForm.type}
              subject={currentActionSubject}
            />
          </Modal>

          {show(1, role, roles) && <ActionButton
            onClick={() => {
              setSubjectForm({ type: "Create", show: true });
            }}
            className="mr-4"
          />}
          {show(1, role, roles) && <ActionButton
            delete
            onClick={() => {
              setDeleteDialog(true);
            }}
            className="mr-3"
          />}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        {subjectsAlert.show && (
          <Alert
            type={subjectsAlert.type}
            message={subjectsAlert.message}
            style={{ maxWidth: 500, flexGrow: 1 }}
            className="mt-3 mb-3"
            onClose={() => {
              setSubjectsAlert({
                show: false,
                message: "",
              });
            }}
          />
        )}
      </div>
      <Filter
        filters={[
          { value: "all", text: "All" },
          { value: "name", text: "Subject", searchValue: "name" },
          {
            value: "firstName",
            text: "First Name",
            searchValue: "creatorId.firstName",
          },
          {
            value: "lastName",
            text: "Lasst Name",
            searchValue: "creatorId.lastName",
          }
        ]}
        items={subjects}
        itemsPerPage={10}
        headers={headers}
        selectable={show(1, role, roles)}
        action={show(1, role, roles) && <Edit style={{ width: 20 }} />}
        onItemsSelected={(val) => {
          setSelectedSubjects(val);
        }}
        onActionClicked={(item) => {
          setCurrentActionSubject(item);
          setSubjectForm({ type: "Edit", show: true });
        }}
        loading={loading}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  subjects: state.subjects.subjects,
  role: state.auth.user.role,
  roles: state.auth.roles,
  error: state.error,
  success: state.success,
});
export default connect(mapStateToProps, {
  fetchSubjects,
  deleteSubject,
  deleteSubjects,
  clearErrors,
  clearSuccess,
})(Subjects);
