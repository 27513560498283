import {
    GET_BLOG_ARTICLES_SUCCESS,
    GET_BLOG_ARTICLE_SUCCESS,
    DELETE_BLOG_ARTICLE_SUCCESS,
    DELETE_BLOG_ARTICLES_SUCCESS,
    ADD_BLOG_ARTICLE_SUCCESS,
    EDIT_BLOG_ARTICLE_SUCCESS,
    BLOG_ARTICLES_INPUT_CHANGE,
    OBJECT_PROP_INPUT_CHANGE,
    INITIALIZE_BLOG_ARTICLE_STATE,
    ARRAY_INPUT_CHANGE
  } from "../actions/types";
  
  const initialState = {
    blogArticles: [],
    blogArticle: {},
  };
  
  const blogArticlesReducer = (state = initialState, action) => {
    switch (action.type) {
      case BLOG_ARTICLES_INPUT_CHANGE:
        return {
          ...state,
          [action.payload.name]: action.payload.value,
        };
      case INITIALIZE_BLOG_ARTICLE_STATE:
        return { ...state, ...action.payload };
      case OBJECT_PROP_INPUT_CHANGE:
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            [action.payload.propName]: action.payload.value,
          },
        };
      case ARRAY_INPUT_CHANGE:
        let arr = state[action.payload.name].slice();
        arr[action.payload.index] = action.payload.value;
        return {
          ...state,
          [action.payload.name]: arr,
        };
      case GET_BLOG_ARTICLES_SUCCESS:
        return {
          ...state,
          blogArticles: action.payload.articles,
        };
      case GET_BLOG_ARTICLE_SUCCESS:
        return {
          ...state,
          blogArticle: action.payload.article,
        };
      case DELETE_BLOG_ARTICLE_SUCCESS:
        return {
          ...state,
          blogArticles: state.blogArticles.filter(
            (blogArticle) => blogArticle._id !== action.payload
          ),
        };
      case DELETE_BLOG_ARTICLES_SUCCESS:
        return {
          ...state,
          blogArticles: state.blogArticles.filter(
            (blogArticle) => !action.payload.articleIds.includes(blogArticle._id)
          ),
        };
      case ADD_BLOG_ARTICLE_SUCCESS:
      case EDIT_BLOG_ARTICLE_SUCCESS:
        return {
          ...initialState,
          videoarticles: [{ transcript: "", video: null, audio: null }],
        };
      default:
        return state;
    }
  };
  
  export default blogArticlesReducer;
  