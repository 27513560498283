import React, { useState, useEffect, useRef } from 'react'
import Button from '../../includes/button/button.component'
import SelectField from '../../includes/select-field/select.component'
import TextField from '../../includes/text-field/textField.component'
import SunEditor, { buttonList } from 'suneditor-react'
import CheckBox from '../../includes/checkbox/checkbox.component'
import { ReactComponent as Times } from '../../../assets/icons/Times.svg'
import { ReactComponent as Plus } from '../../../assets/icons/Plus.svg'
import { getRandomString, deleteTags } from '../../../assets/js/globalFunctions'

const randomString = length => {
  var result = ''
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

const btnList = [
  ['undo', 'redo'],
  ['font', 'fontSize', 'formatBlock'],
  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
  ['removeFormat'],
  '/',
  ['fontColor', 'hiliteColor'],
  ['outdent', 'indent'],
  ['align', 'horizontalRule', 'list', 'table'],
  ['link', 'image'],
  ['fullScreen', 'showBlocks', 'codeView']
]

const QuizQuestion = props => {
  const quizRef = useRef({
    question: '',
    options: [
      { val: '', _key: randomString(10), isMarkdown: false },
      { val: '', _key: randomString(10), isMarkdown: false },
      { val: '', _key: randomString(10), isMarkdown: false },
      { val: '', _key: randomString(10), isMarkdown: false }
    ],
    optionImages: [null, null, null, null],
    correct_option: '',
    explanation: '',
    tOrF: false
  })

  const setQuizRef = val => {
    quizRef.current = val
    props.onChange(quizRef.current)
  }

  useEffect(() => {
    if (props.quiz) {     
      quizRef.current = {
        ...props.quiz,
        question: props.quiz.question,
        options: props.quiz.options.map(opt => ({
          val: opt,
          _key: getRandomString(10),
          isMarkdown: opt.includes('<') && opt.includes('>')
        })),
        optionImages: props.quiz.images,
        correct_option: props.quiz.correct_option,
        explanation: props.quiz.explanation,
        tOrF: false
      }
    }
  }, [props.quiz])

  const processOptionImage = (file, qId, oId) => {
    let reader = new FileReader()

    reader.onload = e => {
      const q = quizRef.current
      q.optionImages[oId] = { file, dataUrl: e.target.result }
      setQuizRef(q)
    }

    reader.readAsDataURL(file)
  }

  return (
    <div
      style={{
        backgroundColor: 'white',
        padding: '25px',
        borderRadius: '10px',
        borderLeft: '5px solid var(--primary-color)'
      }}
      className='mb-4'
    >
      <div>
        <h4 className='title-text'>Question {props.index + 1}</h4>
      </div>
      <div className='question-markdown mt-4 mb-4'>
        <SunEditor
          setOptions={{
            height: 300,
            buttonList: btnList,
            toolbarContainer: '#toolbar-container',
            stickyToolbar: false
          }}
          onChange={content => {
            let q = quizRef.current
            q.question = content
            setQuizRef(q)
          }}
          setContents={quizRef.current.question}
        />
      </div>
      <h4 className='mt-5 title-text'>Options</h4>
      {false && (
        <div className='d-flex mt-4'>
          <div className='d-flex align-items-center'>
            <CheckBox
              key={props.index}
              checked={!quizRef.current.tOrF}
              onChange={val => {
                const q = quizRef.current
                if (val) {
                  q.options = [
                    { val: '', _key: getRandomString(10) },
                    { val: '', _key: getRandomString(10) },
                    { val: '', _key: getRandomString(10) },
                    { val: '', _key: getRandomString(10) }
                  ]
                  q.optionImages = [null, null, null, null]
                }
                q.tOrF = !q.tOrF
                q.correct_option = ''
                setQuizRef(q)
              }}
            />
            <span className='ml-2'>[A/B/C/D]</span>
          </div>
          <div className='d-flex align-items-center ml-5'>
            <CheckBox
              checked={quizRef.current.tOrF}
              onChange={val => {
                const q = quizRef.current
                if (val) {
                  q.options = [
                    { val: 'true', _key: getRandomString(10) },
                    { val: 'false', _key: getRandomString(10) }
                  ]
                  q.optionImages = [null, null]
                }
                q.tOrF = !q.tOrF
                q.correct_option = ''
                setQuizRef(q)
              }}
            />
            <span className='ml-2'>[true/false]</span>
          </div>
        </div>
      )}

      <div className='mt-4'>
        {quizRef.current.options.map((option, ind) => (
          <div
            className={`d-flex align-items-center flex-wrap mb-3 option`}
            key={option._key}
          >
            <span className='mr-2'>Option {ind + 1}:</span>
            <div
              style={{ position: 'relative' }}
              className={`d-flex ${
                option.isMarkdown ? 'align-items-start' : 'align-items-center'
              }`}
            >
              {!option.isMarkdown && (
                <TextField
                  color='rgba(86,86,86,1)'
                  style={{ maxWidth: 280 }}
                  outlined
                  value={option.val}
                  onInput={e => {
                    const q = quizRef.current
                    q.options[ind] = { ...q.options[ind], val: e.target.value }
                    setQuizRef(q)
                  }}
                />
              )}
              {option.isMarkdown && (
                <SunEditor
                  setOptions={{
                    height: 150,
                    buttonList: [
                      ['undo', 'redo'],
                      ['subscript', 'superscript'],
                      ['table'],
                      [
                        'bold',
                        'italic',
                        'underline',
                        'fontColor',
                        'hiliteColor'
                      ],
                      ['removeFormat'],
                      ['formatBlock', 'font', 'fontSize']
                    ],
                    stickyToolbar: false
                  }}
                  onChange={content => {
                    const q = quizRef.current
                    q.options[ind] = { ...q.options[ind], val: content }
                    setQuizRef(q)
                  }}
                  setContents={option.val}
                />
              )}
              {quizRef.current.options.length > 2 && (
                <button
                  className='d-flex align-items-center justify-content-center option-remove'
                  style={{
                    border: 'none',
                    backgroundColor: 'var(--error-color)',
                    width: 20,
                    height: 20,
                    borderRadius: 5,
                    position: 'absolute',
                    zIndex: '10',
                    right: 10,
                    marginTop: option.isMarkdown ? "10px" : "0"
                  }}
                  onClick={() => {
                    const q = quizRef.current
                    q.options.splice(ind, 1)
                    q.optionImages.splice(ind, 1)
                    q.correct_option = null
                    setQuizRef(q)
                  }}
                >
                  <Times />
                </button>
              )}
            </div>
            <div
              className='mb-2 mt-2 d-flex justify-content-between align-items-center'
              style={{ flexGrow: '1' }}
            >
              <div className='d-flex align-items-center'>
                <CheckBox
                  className='ml-1 mr-2'
                  checked={option.isMarkdown}
                  onChange={() => {
                    const q = quizRef.current
                    q.options[ind].val = ''
                    q.options[ind].isMarkdown = !q.options[ind].isMarkdown
                    setQuizRef(q)
                  }}
                />
                <span style={{ fontSize: '85%', color: 'rgba(0,0,0,0.57)' }}>
                  Markdown
                </span>
              </div>
              {(!quizRef.current.optionImages[ind] && (
                <Button
                  color='var(--primary-color)'
                  style={{ padding: '.2em 1.5em' }}
                  depressed
                  disabled={
                    quizRef.current.options[ind].val === '' ||
                    deleteTags(quizRef.current.options[ind].val) === ''
                  }
                  onClick={() => {
                    document
                      .getElementById(`option${props.index}${ind}`)
                      .click()
                  }}
                >
                  Upload Image
                </Button>
              )) || (
                <div>
                  <img
                    src={
                      quizRef.current.optionImages[ind].dataUrl ||
                      quizRef.current.optionImages[ind]
                    }
                    style={{ maxWidth: '150px' }}
                    className='general-shadow'
                  />
                  <div className='d-flex justify-content-between'>
                    <span
                      onClick={() => {
                        document
                          .getElementById(`option${props.index}${ind}`)
                          .click()
                      }}
                      className='pointer'
                      style={{
                        color: 'var(--primary-color)',
                        textDecoration: 'underline',
                        fontSize: '90%'
                      }}
                    >
                      Change
                    </span>
                    <span
                      onClick={() => {
                        const q = quizRef.current
                        q.optionImages[ind] = null
                        setQuizRef(q)
                      }}
                      className='pointer'
                      style={{
                        color: 'red',
                        textDecoration: 'underline',
                        fontSize: '90%'
                      }}
                    >
                      Remove
                    </span>
                  </div>
                </div>
              )}
              <input
                id={`option${props.index}${ind}`}
                onChange={e => {
                  processOptionImage(e.target.files[0], props.index, ind)
                }}
                type='file'
                accept='image/*'
                style={{ display: 'none' }}
              />
            </div>
          </div>
        ))}

        {!quizRef.current.tOrF && (
          <Button
            style={{
              padding: '.3em 1em',
              marginTop: '20px',
              fontFamily: 'font3'
            }}
            color='rgba(74,80,94,1)'
            onClick={() => {
              const q = quizRef.current
              q.options = [...q.options, { val: '', _key: getRandomString(10) }]
              setQuizRef(q)
            }}
          >
            <Plus className='mr-2' />
            Option
          </Button>
        )}
      </div>

      <div className='mt-5'>
        <h4 className='title-text'>Correct Option</h4>
        <div className='d-flex align-items-center mt-4 flex-wrap'>
          <span className='mr-2'>Select correct option:</span>
          <SelectField
            outlined
            items={quizRef.current.options.map((option, i) => {
              return {
                value: i,
                text: quizRef.current.tOrF ? option : `Option ${i + 1}`
              }
            })}
            value={quizRef.current.correct_option}
            onSelect={e => {
              const q = quizRef.current
              q.correct_option = e.target.value
              if (deleteTags(q.options[e.target.value.value].val) === '') {
                props.onSetSnackbar({
                  message: 'The selected option has not been provided',
                  type: 'error'
                })
              }
              setQuizRef(q)
            }}
            style={{ maxWidth: 250 }}
          />
        </div>
      </div>

      <div className='mt-5 mb-5'>
        <h4 className='title-text'>Answer Explanation</h4>
        <div className='explanation-markdown mt-4'>
          <SunEditor
            setOptions={{
              height: 200,
              buttonList: [
                ['undo', 'redo'],
                ['formatBlock', 'font', 'fontSize'],
                ['bold', 'italic', 'underline', 'fontColor', 'hiliteColor'],
                ['link', 'image']
              ],
              stickyToolbar: false
            }}
            onChange={content => {
              const q = quizRef.current
              q.explanation = content
              setQuizRef(q)
            }}
            setContents={quizRef.current.explanation}
          />
        </div>
      </div>
    </div>
  )
}

export default QuizQuestion
