import React, { useState } from "react";
import { ResponsiveLine } from "@nivo/line";

import { ReactComponent as ArrowPaid } from "../../../assets/icons/ArrowPaid.svg";
import { ReactComponent as ArrowPend } from "../../../assets/icons/ArrowPend.svg";
import Table from "../../includes/table/table.component";
import Pagination from "../../includes/pagination/pagination.component";
import FilterSearch from "../../includes/filter-search/filterSearch.component";
import DeleteDialog from "../../includes/confirm-dialog/confirmDialog.component";
import ActionButton from "../../includes/button/actionButton.component";
import Snackbar from "../../includes/snackbar/snackbar.component";
import Alert from "../../includes/alert/alert.component";

import Button from "../../includes/button/button.component";
const Filter = FilterSearch(Pagination(Table));

const TeacherChart = (props) => {
  const [transactions, setTransactions] = useState(
    Array(80)
      .fill("")
      .map((a, index) => {
        let id = index + 1;
        return {
          name: `Name${id}`,
          email: `Email${id}`,
          class: `Class${id}`,
          plan: `Plan${id}`,
          price: `${index}`,
          startDate: `12/04/20`,
          endDate: `12/04/20`,
        };
      })
  );
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [currentActionTransaction, setCurrentActionTransaction] = useState(
    false
  );
  const [loading, setLoading] = useState(false);
  const [curentRevenue, setCurentRevenue] = useState(0);
  const [successAlert, setSuccessAlert] = useState({
    show: false,
    message: "Selected class(es) deleted successfully",
  });
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ message: "", type: "error" });
  const data = [
    {
      id: "japan",
      color: "rgba(38, 170, 118, 1)",
      data: [
        {
          x: "Jan",
          y: 241,
        },
        {
          x: "Feb",
          y: 275,
        },
        {
          x: "Mar",
          y: 155,
        },
        {
          x: "Apr",
          y: 142,
        },
        {
          x: "May",
          y: 52,
        },
        {
          x: "Jun",
          y: 255,
        },
        {
          x: "Jul",
          y: 66,
        },
        {
          x: "Aug",
          y: 289,
        },
        {
          x: "Sep",
          y: 200,
        },
        {
          x: "Oct",
          y: 192,
        },
        {
          x: "Nov",
          y: 20,
        },
        {
          x: "Dec",
          y: 295,
        },
      ],
    },
  ];

  const headers = [
    { value: "name", text: "Name" },
    { value: "email", text: "Email" },
    { value: "class", text: "Class" },
    { value: "plan", text: "Plan" },
    { value: "price", text: "Price" },
    { value: "startDate", text: "Start Date" },
    { value: "endDate", text: "End Date" },
  ];

  const deleteSelectedTransactions = () => {
    if (selectedTransactions.length == 0) {
      setSnackbar({ message: "No transaction was selected", type: "error" });
      return;
    }
    setLoading(true);
    setTimeout(() => {
      let deleted = [];
      let arr = transactions.filter((item) => {
        let isSelected = selectedTransactions.includes(item);
        if (isSelected) deleted.push(item.name);
        return !isSelected;
      });
      setTransactions(arr);
      setLoading(false);
      setSelectedTransactions([]);
      setSuccessAlert({
        ...successAlert,
        show: true,
        message:
          deleted.length == 1
            ? `Transaction "${deleted[0]}" was successfully deleted`
            : deleted.length < 6
            ? `Transactions "${deleted.join(", ")}" deleted successfully`
            : `All selected transactions were deleted successfully`,
      });
    }, 5000);
  };

  return (
    <div>
      <DeleteDialog
        show={deleteDialog}
        onCancel={() => {
          setDeleteDialog(false);
        }}
        onContinue={() => {
          setDeleteDialog(false);
          deleteSelectedTransactions();
        }}
        onClose={() => {
          setDeleteDialog(false);
        }}
        title="Delete Teacher Transaction(s)"
        message={`Are you sure you want to delete the teacher transaction${
          selectedTransactions.length > 1 ? "s" : ""
        }`}
      />
      <Snackbar
        snack={snackbar}
        reset={() => {
          setSnackbar({ message: "" });
        }}
      />
      <div className="d-flex align-items-center justify-content-between">
        <h3 className="title-text">Payment Chart</h3>
        <div>
          <span className="bold-text mr-2">Total Revenue</span>
          <Button
            color="var(--primary-color)"
            style={{
              borderRadius: 10,
              padding: ".8em 1.2em",
              fontSize: "120%",
            }}
          >
            4,687,380
          </Button>
        </div>
      </div>

      <div style={{ height: 400 }}>
        <ResponsiveLine
          data={data}
          margin={{ top: 50, right: 10, bottom: 50, left: 40 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: true,
            reverse: false,
          }}
          colors={["var(--primary-color)"]}
          yFormat=" >-.2f"
          curve="natural"
          enableArea={true}
          enableGridX={false}
          areaOpacity={0.02}
          axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Month",
            legendOffset: 46,
            legendPosition: "middle",
          }}
          axisLeft={null}
          lineWidth={3}
          pointSize={15}
          pointColor={["var(--primary-color)"]}
          pointBorderWidth={4}
          pointBorderColor={["white"]}
          pointLabelYOffset={-12}
          useMesh={true}
        />
      </div>

      <div className="mt-5">
        <h3 className="title-text">Payment Status</h3>
        <div className="displays mt-4">
          <div className="paid">
            <p style={{ color: "white" }}>Total Successful Payment</p>
            <h4 style={{ color: "white" }}>
              349
              <ArrowPaid
                style={{
                  width: 20,
                  marginLeft: 10,
                  transform: "translateY(-1px)",
                }}
              />
            </h4>
            <div className="extra"></div>
          </div>
          <div className="pending">
            <p style={{ color: "white" }}>Total Pending Payment</p>
            <h4 style={{ color: "white" }}>
              28
              <ArrowPend
                style={{
                  width: 20,
                  marginLeft: 10,
                  transform: "translateY(-1px)",
                }}
              />
            </h4>
            <div className="extra"></div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: 80 }}>
        <h3 className="title-text">Payment log</h3>
        <div className="right">
          <ActionButton
            delete
            onClick={() => {
              setDeleteDialog(true);
            }}
          />
        </div>

        <div className="d-flex justify-content-center">
          {successAlert.show && (
            <Alert
              type="success"
              message={successAlert.message}
              style={{ maxWidth: 500, flexGrow: 1 }}
              className="mt-3 mb-4"
              onClose={() => {
                setSuccessAlert({
                  show: false,
                  message: "Selected class(es) deleted successfully",
                });
              }}
            />
          )}
        </div>

        <Filter
          filters={[
            { value: "all", text: "All" },
            { value: "name", text: "Name", searchValue: "name" },
            { value: "email", text: "Email", searchValue: "email" },
            { value: "class", text: "Class", searchValue: "class" },
            { value: "plan", text: "Plan", searchValue: "plan" },
            { value: "price", text: "Price", searchValue: "price" },
            {
              value: "startDate",
              text: "Start Date",
              searchValue: "startDate",
            },
            { value: "endDate", text: "End Date", searchValue: "endDate" },
          ]}
          items={transactions}
          loading={loading}
          itemsPerPage={20}
          headers={headers}
          selectable
          onItemsSelected={(val) => {
            setSelectedTransactions(val);
          }}
        />
      </div>
    </div>
  );
};
export default TeacherChart;
