import {
  GET_EDUCATIONAL_CATEGORIES_SUCCESS,
  DELETE_EDUCATIONAL_CATEGORY_SUCCESS,
  DELETE_EDUCATIONAL_CATEGORIES_SUCCESS,
  ADD_EDUCATIONAL_CATEGORY_SUCCESS,
  EDIT_EDUCATIONAL_CATEGORY_SUCCESS,
  INPUT_CHANGE,
} from "../actions/types";

const initialState = {
  educationalCategories: [],
  name: "",
};

const educationalCategoriesReducer = (state = initialState, action) => {
  let arr;
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case GET_EDUCATIONAL_CATEGORIES_SUCCESS:
      return {
        ...state,
        educationalCategories: action.payload.courseCategories,
      };
    case DELETE_EDUCATIONAL_CATEGORY_SUCCESS:
      arr = state.educationalCategories.slice();
      arr = arr.filter(
        (educationalCategory) => educationalCategory._id !== action.payload
      );
      return {
        ...state,
        educationalCategories: arr,
      };
    case DELETE_EDUCATIONAL_CATEGORIES_SUCCESS:
      return {
        ...state,
        educationalCategories: state.educationalCategories.filter(
          (educationalCategory) =>
            !action.payload.categoryIds.includes(
              educationalCategory._id
            )
        ),
      };
    case ADD_EDUCATIONAL_CATEGORY_SUCCESS:
      return {
        ...state,
        educationalCategories: [
          ...state.educationalCategories,
          action.payload.courseCategory,
        ],
      };
    case EDIT_EDUCATIONAL_CATEGORY_SUCCESS:
      arr = state.educationalCategories.slice();
      let ind = arr.findIndex(
        (educationalCategory) =>
          educationalCategory._id === action.payload.courseCategory._id
      );
      arr[ind] = {
        ...action.payload.courseCategory,
      };
      return {
        ...state,
        educationalCategories: arr,
      };
    default:
      return state;
  }
};

export default educationalCategoriesReducer;
