import {
  GET_TERMS_SUCCESS,
  DELETE_TERM_SUCCESS,
  DELETE_TERMS_SUCCESS,
  ADD_TERM_SUCCESS,
  EDIT_TERM_SUCCESS,
  INPUT_CHANGE,
} from "../actions/types";

const initialState = {
  terms: [],
  name: "",
  // creatorId: ""
};

const termsReducer = (state = initialState, action) => {
  let arr;
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case GET_TERMS_SUCCESS:
      return {
        ...state,
        terms: action.payload.terms,
      };
    case DELETE_TERM_SUCCESS:
      arr = state.terms.slice();
      arr = arr.filter((term) => term._id !== action.payload);
      return {
        ...state,
        terms: arr,
      };
    case DELETE_TERMS_SUCCESS:
      return {
        ...state,
        terms: state.terms.filter(
          (term) => !action.payload.termIds.includes(term._id)
        ),
      };
    case ADD_TERM_SUCCESS:
      return {
        ...state,
        terms: [
          ...state.terms,
          {
            ...action.payload.term,
            creatorId: {
              firstName: action.payload.user.firstName, 
              lastName: action.payload.user.lastName
            }
          },
        ],
      };
    case EDIT_TERM_SUCCESS:
      arr = state.terms.slice();
      let ind = arr.findIndex(
        (term) => term._id === action.payload.term._id
      );
      arr[ind] = {
        ...action.payload.term,
        creatorId: {
          _id: action.payload.term.creatorId,
          firstName: action.payload.user.firstName,
          lastName: action.payload.user.lastName
        },
      };
      return {
        ...state,
        terms: arr,
      };
    default:
      return state;
  }
};

export default termsReducer;
