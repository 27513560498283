import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as Edit } from "../../../assets/icons/EditSquare.svg";

import Table from "../../includes/table/table.component";
import Modal from "../../includes/modal/modal.component";
import Alert from "../../includes/alert/alert.component";
import TermForm from "./termForm.component";
import { connect } from "react-redux";
import {
  fetchTerms,
  deleteTerm,
  deleteTerms,
} from "../../../redux/actions/termsActions";
import { clearErrors } from "../../../redux/actions/errorActions";
import { clearSuccess } from "../../../redux/actions/successActions";
import Snackbar from "../../includes/snackbar/snackbar.component";
import DeleteDialog from "../../includes/confirm-dialog/confirmDialog.component";
import ActionButton from "../../includes/button/actionButton.component";
import DateWrapper from "../../includes/date-wrapper/date-wrapper.component";
import NameWrapper from "../../includes/name-wrapper/name-wrapper.component";
import "./css/style.css";
import { show } from "../../../assets/js/globalFunctions";

const Terms = (props) => {
  const headers = [
    { value: "name", text: "Term", align: "left" },
    { value: "creatorId", text: "Created By", wrapper: NameWrapper },
    { value: "createdAt", text: "Date Created", wrapper: DateWrapper },
  ];

  const { terms, error, success, role, roles } = props;
  const [selectedTerms, setSelectedTerms] = useState([]);
  const [currentActionTerm, setCurrentActionTerm] = useState();
  const [loading, setLoading] = useState(false);
  const [termsAlert, setTermsAlert] = useState({
    show: false,
    message: "Selected term(s) deleted successfully",
  });
  const [termForm, setTermForm] = useState({ show: false, type: "Create" });
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ message: "", type: "error" });

  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (error.id) {
        const message =
          typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
        let alert_ = { type: "error", show: true, message };
        if (error.id === "DELETE_TERM_FAILURE") {
          setTermsAlert({ ...alert_ });
          props.clearErrors();
        } else if (error.id === "DELETE_TERMS_FAILURE") {
          const message =
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
          setTermsAlert({ ...alert_ });
          props.clearErrors();
        } else if (error.id === "ADD_TERM_FAILURE") {
          const message =
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
          setTermsAlert({ ...alert_ });
          props.clearErrors();
        } else if (error.id === "EDIT_TERM_FAILURE") {
          const message =
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
          setTermsAlert({ ...alert_ });
          props.clearErrors();
        }
        props.clearErrors();
      } else if (success.id) {
        let alert_ = { type: "success", show: true, message: success.msg };
        if (success.id === "DELETE_TERM_SUCCESS") {
          setTermsAlert({ ...alert_ });
          props.clearSuccess();
        } else if (success.id === "DELETE_TERMS_SUCCESS") {
          setTermsAlert({
            ...alert_,
          });
          props.clearSuccess();
        } else if (success.id === "ADD_TERM_SUCCESS") {
          setTermsAlert({
            ...alert_,
          });
          props.clearSuccess();
        } else if (success.id === "EDIT_TERM_SUCCESS") {
          setTermsAlert({
            ...alert_,
          });
          props.clearSuccess();
        }
        props.clearSuccess();
      }
    }
  });

  useEffect(() => {
    if (role) {
      props.fetchTerms();
    }
  }, [role]);

  const deleteSelectedTerms = () => {
    if (selectedTerms.length < 1) {
      setSnackbar({
        message: "No term was selected",
        type: "error",
      });
      return;
    } else if (selectedTerms.length == 1) {
      props.deleteTerm(selectedTerms[0]._id);
    } else {
      props.deleteTerms(selectedTerms.map((term) => term._id));
    }
  };

  return (
    <div className="page-padding">
      <DeleteDialog
        show={deleteDialog}
        onCancel={() => {
          setDeleteDialog(false);
        }}
        onContinue={() => {
          setDeleteDialog(false);
          deleteSelectedTerms();
        }}
        onClose={() => {
          setDeleteDialog(false);
        }}
        title="Delete Term"
        message={`Are you sure you want to delete the term${
          selectedTerms.length > 1 ? "s" : ""
        }`}
      />
      <Snackbar
        snack={snackbar}
        reset={() => {
          setSnackbar({ message: "" });
        }}
      />
      <div className="d-flex justify-content-between">
        <h3>Terms</h3>
        <div>
          <Modal
            maxWidth="550px"
            show={termForm.show}
            onClose={() => {
              setTermForm({ ...termForm, show: false });
            }}
          >
            <TermForm
              onComplete={(val) => {
                setTermForm({ ...termForm, show: false });
              }}
              onSetSnackbar={(val) => {
                setSnackbar(val);
              }}
              type={termForm.type}
              term={currentActionTerm}
            />
          </Modal>

          {show(1, role, roles) && (
            <ActionButton
              onClick={() => {
                setTermForm({ type: "Create", show: true });
              }}
              className="mr-4"
            />
          )}
          {show(1, role, roles) && (
            <ActionButton
              delete
              onClick={() => {
                setDeleteDialog(true);
              }}
              className="mr-3"
            />
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        {termsAlert.show && (
          <Alert
            type={termsAlert.type}
            message={termsAlert.message}
            style={{ maxWidth: 500, flexGrow: 1 }}
            className="mt-3"
            onClose={() => {
              setTermsAlert({
                show: false,
                message: "",
              });
            }}
          />
        )}
      </div>

      <Table
        headers={headers}
        items={terms}
        selectable={show(1, role, roles)}
        action={show(1, role, roles) && <Edit style={{ width: 20 }} />}
        onItemsSelected={(val) => {
          setSelectedTerms(val);
        }}
        onActionClicked={(item) => {
          setCurrentActionTerm(item);
          setTermForm({ type: "Edit", show: true });
        }}
        loading={loading}
        className="mt-4"
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  terms: state.terms.terms,
  role: state.auth.user.role,
  roles: state.auth.roles,
  error: state.error,
  success: state.success,
});
export default connect(mapStateToProps, {
  fetchTerms,
  deleteTerm,
  deleteTerms,
  clearErrors,
  clearSuccess,
})(Terms);
