import {
  REQUESTS_INPUT_CHANGE,
  GET_REQUESTS_SUCCESS,
  GET_REQUESTS_FAILURE,
  ADD_REQUEST_REPLY_SUCCESS,
} from "../actions/types";

const initialState = {
  request: null,
  requestsCount: 0,
  requests: [],
  addingRequestReply: false,
  loadingRequests: false,
  addingRequestReplyStatus: "pending",
  loadingRequestsStatus: "pending",
};

const studentRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUESTS_INPUT_CHANGE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };

    case GET_REQUESTS_SUCCESS:
      return {
        ...state,
        requests: [...state.requests, ...action.payload.requests],
        requestsCount: action.payload.requestsCount,
      };
    case ADD_REQUEST_REPLY_SUCCESS:
      return {
        ...state,
        request: {
          ...state.request,
          replies: [...state.request.replies, action.payload.request],
        },
      };
    default:
      return state;
  }
};

export default studentRequestReducer;
