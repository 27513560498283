import React, { useEffect, useRef } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./App.css";
import { loadUser } from "./redux/actions/authActions";
import Navigation from "./components/includes/nav.component";

const App = (props) => {
  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      store.dispatch(loadUser());
      mounted.current = true;
    }
  });

  return (
    <Provider store={store}>
      <Navigation />
    </Provider>
  );
};
export default App;
