import React, { useEffect, useState, useRef } from "react";
import TextField from "../../includes/text-field/textField.component";
import TextArea from "../../includes/text-area/textArea.component";
import SunEditor, { buttonList } from "suneditor-react";
import {
    inputChange,
    fetchEducationalCategories
} from "../../../redux/actions/educationalCategoriesActions";
import { fetchCourses } from "../../../redux/actions/coursesActions"
import { sendEmail } from "../../../redux/actions/sendEmailActions"
import { connect } from "react-redux";
import { clearErrors } from '../../../redux/actions/errorActions'
import { clearSuccess } from '../../../redux/actions/successActions'
import SelectField from '../../includes/select-field/select.component'
import Snackbar from '../../includes/snackbar/snackbar.component'
import Alert from "../../includes/alert/alert.component";
import Button from "../../includes/button/button.component";
import "./css/style.css";

const btnList = [
    ['undo', 'redo'],
    ['font', 'fontSize', 'formatBlock'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['removeFormat'],
    ['fontColor', 'hiliteColor'],
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list', 'table'],
    ['link', 'image'],
    ['fullScreen', 'showBlocks', 'codeView']
]

const SendEmailForm = (props) => {
    const {
        subject,
        educationalCategory,
        class_,
        message,
        role,
        emailTo,
        courses,
        error,
        success,
        mainCategory,
        educationalCategories
    } = props;
    const [snackbar, setSnackbar] = useState({ message: '' })
    const [emailAlert, setEmailAlert] = useState({
        show: false,
        message: "",
    });

    const mounted = useRef(false);

    useEffect(() => {
        if (!mounted.current) {
            props.fetchEducationalCategories();
            props.fetchCourses();
            mounted.current = true;
        } else {
            if (success.id) {
                switch (success.id) {
                    case 'SEND_EMAIL_SUCCESS':
                        const message =
                            typeof success.msg === "object" ? success.msg.join("<br/>") : success.msg;
                        let alert_ = { type: "success", show: true, message };
                        setEmailAlert({ ...alert_ });
                }
                props.clearSuccess()
            } else if (error.id) {
                switch (error.id) {
                    case 'SEND_EMAIL_FAILURE':
                        const message =
                            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
                        let alert_ = { type: "error", show: true, message };
                        setEmailAlert({ ...alert_ });
                }
                props.clearErrors()
            }
        }
    });

    const handleSubmit = () => {
        let category, alertMessage;
        if (mainCategory === 'Class' && class_)
            category = class_.name;
        if (mainCategory === 'Educational Category' && educationalCategory)
            category = educationalCategory.name;
        if (mainCategory === 'All Users')
            category = 'Afrilearn Users';
        if (mainCategory === 'Blog Subscribers')
            category = 'Subscribers';
        if (mainCategory === 'Others')
            category = 'Custom';
        if (mainCategory === 'Classnote Users')
            category = 'Classnote Users';          

        if (!subject) alertMessage = "Message Subject is Required";
        if (!message) alertMessage = "Message Body is Required";
        if (!category) alertMessage = "Please, select a category";
        if (mainCategory === "Others") {
            if (!emailTo) {
                alertMessage = "Email Address(es) is required for custom message";
            }

            const emails = emailTo.split(" ");
            // let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
            // let count = 0;

            // let lastIndex, firstIndex = 0;

            // for (let i = 0; i < emails.length; i++) {
            //     if (!emails[i].match(emailRegex)) {
            //         firstIndex = i;
            //         break;
            //     }
            // }

            // for (let i = 0; i < emails.length; i++) {
            //     if (!emails[i].match(emailRegex)) {
            //         count += 1;
            //         lastIndex = i;
            //     }
            // }


            // if (count != 0) {
            //     alertMessage = count > 1 ? "Email Addresses " : "Email Address "

            //     for (let i = 0; i < emails.length; i++) {
            //         if (!emails[i].match(emailRegex)) {
            //             if (count == 1) {
            //                 alertMessage += `${emails[i]} is invalid`
            //                 break;
            //             }

            //             else if (count > 1 && i == firstIndex) {
            //                 alertMessage += `${emails[i]}`
            //             }

            //             else if (count > 1 && i != lastIndex) {
            //                 alertMessage += `, ${emails[i]}`
            //             }

            //             if (count > 1 && i == lastIndex) {
            //                 alertMessage += `, ${emails[i]} are invalid`
            //             }
            //         }
            //     }
            // }

        }

        setSnackbar({ message: alertMessage, type: "error" });

        // if(mainCategory === 'Subscribers')
        // message += `<p>If you want to stop receiving emails from afrilearn, `
        // +`You can unsubscribe <a>here</a></p>`

        // console.log(message);

        if (!alertMessage) {
            props.sendEmail({ role, subject, message, emailTo, category });
        }
    };

    const handleInput = (e) => {
        props.inputChange(e.target.name, e.target.value);
    };

    return (
        <div id="add-subject" className="page-padding">
            <Snackbar
                snack={snackbar}
                reset={() => {
                    setSnackbar({ message: "" });
                }}
            />
            <div className="d-flex justify-content-center">
                {emailAlert.show && (
                    <Alert
                        type={emailAlert.type}
                        message={emailAlert.message}
                        style={{ maxWidth: 500, flexGrow: 1 }}
                        className="mt-3"
                        onClose={() => {
                            setEmailAlert({
                                show: false,
                                message: "",
                            });
                        }}
                    />
                )}
            </div>

            <h3 style={{ marginBottom: 30 }} className="title-text">{props.type} Send Email</h3>

            <div className="row">
                <div className="col-sm-7">
                    <label htmlFor="subject-name">Subject</label><br />
                    <TextField
                        value={subject}
                        onInput={handleInput}
                        name="subject"
                        inputStyle={{ borderRadius: 0, paddingLeft: 15 }}
                    />
                    <label htmlFor="subject-name">Body</label><br />
                    <div>
                        <SunEditor
                            setOptions={{
                                height: 200,
                                buttonList: btnList,
                                stickyToolbar: false,
                            }}

                            onChange={(content) => {
                                handleInput({ target: { name: "message", value: content } });
                            }}

                            setContents={message}
                        />
                    </div>

                    <div style={{ marginTop: 20, float: "right" }}>
                        <Button onClick={handleSubmit}>
                            Send Email
                        </Button>
                    </div>
                </div>
                <div className="col-sm-5">
                    <h4 className='pl-4'>Select Category</h4>
                    <hr />
                    <div className='pl-4 pr-4'>

                        <div><SelectField
                            value={mainCategory}
                            onSelect={handleInput}
                            name='mainCategory'
                            items={[
                                'All Users',
                                'Blog Subscribers',
                                'Class',
                                'Educational Category',
                                'Classnote Users',
                                'Others'
                            ]}
                            placeholder='Select Users By'
                            inputStyle={{ borderRadius: 5, paddingLeft: 15 }}
                            className='mb-4'
                        ></SelectField></div>

                        <div style={mainCategory == 'Class' || mainCategory == 'Educational Category' || mainCategory == 'All Users' ? { display: "block" } : { display: 'none' }}>
                            <div className='mb-2'>Select Role</div>
                            <SelectField
                                value={role}
                                onSelect={handleInput}
                                name='role'
                                items={[
                                    'All',
                                    'Students',
                                    'Teachers'
                                ]}
                                outlined
                                placeholder='Select Role'
                                color='#B6B6B6'
                                inputStyle={{ borderRadius: 5, paddingLeft: 15 }}
                                className='mb-4'
                            ></SelectField>
                        </div>
                        <div style={mainCategory == 'Class' ? { display: "block" } : { display: 'none' }}>
                            <div className='mb-2'>Select Class</div>
                            <SelectField
                                value={class_}
                                onSelect={handleInput}
                                name='class_'
                                items={courses}
                                outlined
                                valueField='_id'
                                textField='name'
                                placeholder='Select Class'
                                color='#B6B6B6'
                                inputStyle={{ borderRadius: 5, paddingLeft: 15 }}
                                className='mb-4'
                            ></SelectField>
                        </div>
                        <div style={mainCategory == 'Educational Category' ? { display: "block" } : { display: 'none' }}>
                            <div className='mb-2'>Select Category</div>
                            <SelectField
                                value={educationalCategory}
                                onSelect={handleInput}
                                items={educationalCategories}
                                name='educationalCategory'
                                outlined
                                valueField='_id'
                                textField='name'
                                placeholder='Select Educational Category'
                                color='#B6B6B6'
                                inputStyle={{ borderRadius: 5, paddingLeft: 15 }}
                                className='mb-4'
                            ></SelectField>
                        </div>
                        <div style={mainCategory == 'Others' ? { display: "block" } : { display: 'none' }}>
                            <TextArea
                                value={emailTo}
                                onInput={handleInput}
                                name='emailTo'
                                outlined
                                placeholder='Enter Email Address(es) each separated with a single space'
                                color='#B6B6B6'
                                height='180px'
                                inputStyle={{
                                    borderRadius: 5,
                                    paddingLeft: 15
                                }}
                                className='mb-4'
                            ></TextArea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    courses: state.courses.courses,
    educationalCategories: state.educationalCategories.educationalCategories,
    educationalCategory: state.sendEmail.educationalCategory,
    class_: state.sendEmail.class_,
    mainCategory: state.sendEmail.mainCategory,
    subject: state.sendEmail.subject,
    emailTo: state.sendEmail.emailTo,
    message: state.sendEmail.message,
    role: state.sendEmail.role,
    error: state.error,
    success: state.success,
});
export default connect(mapStateToProps,
    {
        inputChange,
        fetchEducationalCategories,
        fetchCourses,
        sendEmail,
        clearErrors,
        clearSuccess
    })(
        SendEmailForm
    );
