import React from "react";
import "./css/style.css";
import { useLocation, useHistory } from "react-router-dom";
import MenuItem from "./navMenuLink.component";
import MenuDropdown from "./navMenuDropdown.component";
import { connect } from "react-redux";
import { ReactComponent as Home } from "../../../assets/icons/Home.svg";
import { ReactComponent as Categories } from "../../../assets/icons/Categories.svg";
import { ReactComponent as BookStack } from "../../../assets/icons/BookStack.svg";
import { ReactComponent as QandA } from "../../../assets/icons/QandA.svg";
import { ReactComponent as Users } from "../../../assets/icons/Users.svg";
import { ReactComponent as Trophy } from "../../../assets/icons/Trophy.svg";
import { ReactComponent as Wallet } from "../../../assets/icons/Wallet.svg";
import { ReactComponent as Document } from "../../../assets/icons/Document.svg";
import { ReactComponent as Send } from "../../../assets/icons/send-message-svgrepo-com.svg";
import { ReactComponent as EditSquare } from "../../../assets/icons/EditSquare1.svg";
// import { ReactComponent as Setting } from "../../../assets/icons/Setting.svg";
import { ReactComponent as Login } from "../../../assets/icons/Login.svg";
import { show } from "../../../assets/js/globalFunctions";
import { logout } from "../../../redux/actions/authActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faInbox } from "@fortawesome/free-solid-svg-icons";

const NavMenu = (props) => {
  const location = useLocation();
  const { role, roles, isAuthenticated } = props;

  const history = useHistory();

  return (
    location.pathname !== "/" && (
      <div id="navbar">
        <h2 className="title font2 hide-900">Afrilearn</h2>
        <div className="mb-3">
          <MenuItem
            name="Dashboard"
            show={show(0, role, roles)}
            exact
            icon={<Home />}
            path="/dashboard"
          />
          <MenuDropdown
            name="Categories"
            icon={<Categories />}
            show={show(0, role, roles)}
            links={[
              {
                name: "Educational",
                path: "/educational",
                show: show(0, role, roles),
              },
              {
                name: "Classes",
                path: "/classes",
                show: show(0, role, roles),
              },
              {
                name: "Subjects",
                path: "/subjects",
                show: show(0, role, roles),
              },
              {
                name: "Assign Subject",
                path: "/assign-subject",
                show: show(1, role, roles),
              },
              {
                name: "Terms",
                path: "/terms",
                show: show(0, role, roles),
              },
              {
                name: "Past Questions",
                path: "/past-questions",
                show: show(0, role, roles),
              },
              {
                name: "Assign Past Question",
                path: "/assign-past-question",
                show: show(1, role, roles),
              },
            ]}
          />
          <MenuDropdown
            name="Class Content"
            icon={<BookStack />}
            show={show(0, role, roles)}
            links={[
              {
                name: "Contents",
                path: "/class-contents",
                show: show(0, role, roles),
              },
              {
                name: "Add New",
                path: "/class-contents/create",
                show: show(0, role, roles),
              },
            ]}
          />
          <MenuDropdown
            name="Quiz"
            icon={<QandA />}
            show={show(0, role, roles)}
            links={[
              {
                name: "Quizzes",
                path: "/quizzes",
                show: show(0, role, roles),
              },
              {
                name: "Add New",
                path: "/quizzes/create",
                show: show(0, role, roles),
              },
            ]}
          />
          <MenuDropdown
            name="Users"
            icon={<Users />}
            show={show(1, role, roles)}
            links={[
              {
                name: "CMS",
                path: "/users/cms",
                show: show(1, role, roles),
              },
              {
                name: "Afrilearn",
                path: "/users/afrilearn",
                show: show(1, role, roles),
              },
            ]}
          />
          <MenuDropdown
            name="Blog"
            icon={<Document />}
            show={show(1, role, roles)}
            links={[
              {
                name: "Articles",
                path: "/blog/articles",
                show: show(1, role, roles),
              },
              {
                name: "Add Article",
                path: "/blog/articles/create",
                show: show(1, role, roles),
              },
            ]}
          />
          <MenuDropdown
            name="Impact Stories"
            icon={<Users />}
            show={show(1, role, roles)}
            links={[
              {
                name: "Impact Stories",
                path: "/impact-stories",
                show: show(1, role, roles),
              },
              {
                name: "Add Impact Story",
                path: "/impact-stories/create",
                show: show(1, role, roles),
              },
            ]}
          />
          <MenuDropdown
            name="Payment Plans"
            icon={<Wallet />}
            show={show(0, role, roles)}
            links={[
              {
                name: "Students",
                path: "/payment-plans/students",
                show: show(0, role, roles),
              },
              {
                name: "Teachers",
                path: "/payment-plans/teachers",
                show: show(0, role, roles),
              },
            ]}
          />
          <MenuItem
            name="Payment Transactions"
            icon={<Document />}
            path="/payment-transactions"
            exact={false}
            show={show(2, role, roles)}
          />
          <MenuItem
            name="Manual Activation"
            icon={<EditSquare />}
            path="/manual-activation"
            show={show(2, role, roles)}
          />
          <MenuItem
            name="Coin Activation"
            icon={<FontAwesomeIcon icon={faCoins} color="white" size="lg" />}
            path="/coin-activation"
            show={show(2, role, roles)}
          />
          <MenuItem
            name="Students' Homework"
            icon={<FontAwesomeIcon icon={faInbox} color="white" size="lg" />}
            path="/student-requests"
            show={show(2, role, roles)}
          />
          <MenuItem
            name="Africhallenge"
            icon={<Trophy />}
            path="/africhallenge-report"
            show={show(2, role, roles)}
          />
          <MenuItem
            name="Email"
            icon={<Send />}
            path="/send-email"
            show={show(2, role, roles)}
          />
        </div>

        <div style={{ paddingLeft: 15 }} className="mt-auto pt-3">
          {/* <div>
            <span className="icon-container">
              <Setting />
            </span>
            <NavLink
              to="/settings"
              style={{ fontSize: "85%", color: "var(--primary-text-alt)" }}
            >
              Settings
            </NavLink>
          </div> */}
          {isAuthenticated && (
            <div className="mt-3 pointer">
              <span className="icon-container">
                <Login />
              </span>
              <span
                style={{ fontSize: "85%", color: "var(--primary-text-alt)" }}
                onClick={() => {
                  props.logout();
                  history.push("/");
                }}
              >
                Logout
              </span>
            </div>
          )}
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  role: state.auth.user.role,
  roles: state.auth.roles,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { logout })(NavMenu);
