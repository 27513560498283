import API from "../../assets/js/api";
import { returnErrors } from "./errorActions";
import { returnSuccess } from "./successActions";

import {
  GET_ALL_AFRILEARN_USERS_SUCCESS,
  GET_ALL_AFRILEARN_USERS_FAILURE,
  DELETE_AFRILEARN_USER_SUCCESS,
  DELETE_AFRILEARN_USER_FAILURE,
  DELETE_AFRILEARN_USERS_SUCCESS,
  DELETE_AFRILEARN_USERS_FAILURE,
  INPUT_CHANGE,
  UPDATE_AFRILEARN_USER_SUCCESS,
  UPDATE_AFRILEARN_USER_FAILURE,
  REMOVE_USER_FEEDBACK_SUCCESS,
} from "./types";

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  );
};
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id));
};

export const inputChange = (name, value) => (dispatch) => {
  dispatch({ type: INPUT_CHANGE, payload: { name, value } });
};

export const fetchAfrilearnUsers = (data) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.getAfrilearnUsers(data);

    dispatch({
      type: GET_ALL_AFRILEARN_USERS_SUCCESS,
      payload: result.data.data,
    });
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_ALL_AFRILEARN_USERS_FAILURE);
  }
};

export const deleteAfrilearnUser = (userId) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deleteAfrilearnUser(userId);

    dispatch({ type: DELETE_AFRILEARN_USER_SUCCESS, payload: userId });
    dispatchSuccess(
      dispatch,
      result.data.data.message,
      DELETE_AFRILEARN_USER_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_AFRILEARN_USER_FAILURE);
  }
};

export const deleteAfrilearnUsers = (userIds) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deleteAfrilearnUsers({ userIds });

    dispatch({ type: DELETE_AFRILEARN_USERS_SUCCESS, payload: { userIds } });
    dispatchSuccess(
      dispatch,
      result.data.data.message,
      DELETE_AFRILEARN_USERS_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_AFRILEARN_USERS_FAILURE);
  }
};
export const updateAfrilearnUser = (userId, data) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.updateAfrilearnUser(userId, data);
    dispatch({
      type: UPDATE_AFRILEARN_USER_SUCCESS,
      payload: result.data.data.user,
    });
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    // dispatchError(dispatch, err, UPDATE_AFRILEARN_USER_FAILURE);
  }
};
export const removeUserFeedback = (userId) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.removeUserfeedback(userId);
    dispatch({
      type: REMOVE_USER_FEEDBACK_SUCCESS,
      payload: result.data.data.user,
    });
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    // dispatchError(dispatch, err, UPDATE_AFRILEARN_USER_FAILURE);
  }
};
