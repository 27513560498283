import React, { useState, useRef, useEffect } from 'react'
import { Redirect, useHistory, Link } from 'react-router-dom'

import Table from '../../includes/table/table.component'
import { ReactComponent as Edit } from '../../../assets/icons/EditSquare.svg'
import Pagination from '../../includes/pagination/pagination.component'
import FilterSearch from '../../includes/filter-search/filterSearch.component'
import ActionButton from '../../includes/button/actionButton.component'
import DeleteDialog from '../../includes/confirm-dialog/confirmDialog.component'
import Alert from '../../includes/alert/alert.component'
import Snackbar from '../../includes/snackbar/snackbar.component'
import { connect } from 'react-redux'
import {
  fetchQuizzes,
  deleteQuiz,
  deleteQuizzes,
  inputChange
} from '../../../redux/actions/quizzesActions'
import { clearErrors } from '../../../redux/actions/errorActions'
import { clearSuccess } from '../../../redux/actions/successActions'
import { fetchCourses } from '../../../redux/actions/coursesActions'
import {
  fetchAssignedSubjects,
  inputChange as subjectsInputChange
} from '../../../redux/actions/assignSubjectActions'
import { fetchTerms } from '../../../redux/actions/termsActions'
import './css/style.css'
import DateWrapper from '../../includes/date-wrapper/date-wrapper.component'
import { show } from '../../../assets/js/globalFunctions'
import { getKey } from '../../../assets/js/globalFunctions'
import Select from '../../includes/select-field/select.component'

import './css/style.css'
const Filter = FilterSearch(Pagination(Table))

const ActionLinkWrapper = props => {
  return <Link to={`/quizzes/${props.item._id}/edit`}>{props.children}</Link>
}

const Quizzes = props => {
  const {
    quizzes,
    success,
    error,
    role,
    roles,
    courses,
    terms,
    assignedSubjects
  } = props

  const [deleteDialog, setDeleteDialog] = useState(false)
  const [selectedQuizzes, setSelectedquizzes] = useState([])
  const [quizzesAlert, setQuizzesAlert] = useState({
    show: false,
    message: 'Selected quiz(zes) deleted successfully'
  })
  const [snackbar, setSnackbar] = useState({ message: '', type: 'error' })
  const [course, setCourse] = useState({})
  const [subject, setSubject] = useState({})
  const [term, setTerm] = useState({})
  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const deleteSelectedQuizzes = () => {
    if (selectedQuizzes.length < 1) {
      setSnackbar({ message: 'No quiz was selected', type: 'error' })
      return
    } else {
      if (selectedQuizzes.length === 1) {
        let lesson = selectedQuizzes[0]
        let deleteMedia = []
        lesson.questions.forEach(question => {
          question.images
            .filter(img => img)
            .forEach(img => {
              deleteMedia = [...deleteMedia, img]
            })
        })
        props.deleteQuiz(
          {
            _id: lesson._id,
            deleteMedia
          },
          lesson.title
        )
      } else {
        let sendData = {
          deleteMedia: [],
          lessonIds: []
        }
        selectedQuizzes.forEach(lesson => {
          sendData.lessonIds.push(lesson._id)
          lesson.questions.forEach(question => {
            question.images
              .filter(img => img)
              .forEach(img => {
                sendData.deleteMedia.push(img)
              })
          })
        })
        props.deleteQuizzes(sendData)
      }
    }
  }

  const headers = [
    { value: '_index_', text: 'Id', align: 'left' },
    { value: 'title', text: 'Topic', align: 'left' },
    { value: 'subjectId.mainSubjectId.name', text: 'Subject' },
    { value: 'courseId.name', text: 'Class' },
    { value: 'termId.name', text: 'Term' },
    { value: 'createdAt', text: 'Date Created', wrapper: DateWrapper }
  ]

  const mounted = useRef(false)
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    } else {
      if (error.id) {
        const message =
          typeof error.msg === 'object' ? error.msg.join('<br/>') : error.msg
        let alert_ = { type: 'error', show: true, message }
        if (error.id === 'DELETE_QUIZ_FAILURE') {
          setQuizzesAlert({ ...alert_ })
          props.clearErrors()
        } else if (error.id === 'DELETE_QUIZZES_FAILURE') {
          const message =
            typeof error.msg === 'object' ? error.msg.join('<br/>') : error.msg
          setQuizzesAlert({ ...alert_ })
          props.clearErrors()
        } else if (error.id === 'GET_QUIZ_FAILURE') {
          const message =
            typeof error.msg === 'object' ? error.msg.join('<br/>') : error.msg
          setQuizzesAlert({ ...alert_ })
          props.clearErrors()
        }
        props.clearErrors()
      } else if (success.id) {
        let alert_ = { type: 'success', show: true, message: success.msg }
        if (success.id === 'DELETE_QUIZ_SUCCESS') {
          setQuizzesAlert({ ...alert_ })
          props.clearSuccess()
        } else if (success.id === 'DELETE_QUIZZES_SUCCESS') {
          setQuizzesAlert({
            ...alert_
          })
          props.clearSuccess()
        } else if (success.id === 'ADD_QUIZ_SUCCESS') {
          setQuizzesAlert({
            ...alert_
          })
          props.clearSuccess()
        } else if (success.id === 'EDIT_QUIZ_SUCCESS') {
          setQuizzesAlert({
            ...alert_
          })
          props.clearSuccess()
        } else if (success.id === 'GET_QUIZZES_SUCCESS') {
          setLoading(false)
        }
        props.clearSuccess()
      }
    }
  })

  useEffect(() => {
    return () => {
      props.inputChange('quizzes', [])
      props.subjectsInputChange('assignedSubjects', [])
    }
  }, [])

  useEffect(() => {
    if (role) {
      props.fetchCourses()
      props.fetchTerms()
    }
  }, [role])

  useEffect(() => {
    if (course._id) {
      setLoading(true)
      setSubject({})
      setTerm({})
      props.fetchAssignedSubjects(course)
      props.fetchQuizzes({ courseId: course._id }, false)
    }
  }, [course])

  useEffect(() => {
    if (subject.assignedId) {
      setLoading(true)
      setTerm({})
      props.fetchQuizzes(
        { courseId: course._id, subjectId: subject.assignedId },
        false
      )
    }
  }, [subject])

  useEffect(() => {
    if (term._id) {
      setLoading(true)
      props.fetchQuizzes(
        {
          courseId: course._id,
          subjectId: subject.assignedId,
          termId: term._id
        },
        false
      )
    }
  }, [term])

  const selectCourse = e => {
    setCourse(e.target.value)
  }
  const selectSubject = e => {
    setSubject(e.target.value)
  }
  const selectTerm = e => {
    setTerm(e.target.value)
  }

  return (
    <div id='quizzes-display' className='page-padding'>
      {role && (
        <div>
          {!show(0, role, roles) && <Redirect to='/dashboard' />}
          <DeleteDialog
            show={deleteDialog}
            onCancel={() => {
              setDeleteDialog(false)
            }}
            onContinue={() => {
              setDeleteDialog(false)
              deleteSelectedQuizzes()
            }}
            onClose={() => {
              setDeleteDialog(false)
            }}
            title='Delete Quiz(zes)'
            message='Are you sure you want t o delete the quiz(zes)'
          />
          <Snackbar
            snack={snackbar}
            reset={() => {
              setSnackbar({ message: '' })
            }}
          />
          <div className='d-flex justify-content-between align-items-center mb-4'>
            <h3 className='title-text' style={{ margin: 0 }}>
              Quizzes
            </h3>
            {show(1, role, roles) && (
              <ActionButton
                delete
                onClick={() => {
                  setDeleteDialog(true)
                }}
              />
            )}
          </div>

          <div className='select-grid mb-4'>
            <div>
              <label
                style={{
                  color: course._id ? 'var(--primary-color)' : 'inherit'
                }}
              >
                Class
              </label>
              <Select
                items={courses}
                value={course}
                textField='name'
                valueField='_id'
                onSelect={selectCourse}
                outlined
                color={course._id ? 'var(--primary-color)' : ''}
              />
            </div>
            <div>
              <label
                style={{
                  color: subject.assignedId ? 'var(--primary-color)' : 'inherit'
                }}
              >
                Subject
              </label>
              <Select
                items={assignedSubjects}
                value={subject}
                textField='name'
                valueField='_id'
                onSelect={selectSubject}
                outlined
                color={subject.assignedId ? 'var(--primary-color)' : ''}
              />
            </div>
            <div>
              <label
                style={{ color: term._id ? 'var(--primary-color)' : 'inherit' }}
              >
                Term
              </label>
              <Select
                items={terms}
                textField='name'
                valueField='_id'
                value={term}
                onSelect={selectTerm}
                outlined
                color={term._id ? 'var(--primary-color)' : ''}
              />
            </div>
          </div>

          <div className='d-flex justify-content-center mb-4'>
            {quizzesAlert.show && (
              <Alert
                type='success'
                message={quizzesAlert.message}
                style={{ maxWidth: 500, flexGrow: 1 }}
                className='mt-3'
                onClose={() => {
                  setQuizzesAlert({
                    show: false,
                    message: 'Selected quizz(es) deleted successfully'
                  })
                }}
              />
            )}
          </div>

          <Filter
            filters={[
              { value: 'all', text: 'All' },
              {
                value: 'class',
                text: 'Class',
                searchValue: 'lessonId.courseId.name'
              },
              {
                value: 'subject',
                text: 'Subject',
                searchValue: 'lessonId.subjectId.mainSubjectId.name'
              },
              {
                value: 'term',
                text: 'Term',
                searchValue: 'lessonId.termId.name'
              },
              { value: 'topic', text: 'Topic', searchValue: 'lessonId.title' }
            ]}
            items={quizzes}
            loading={loading}
            itemsPerPage={20}
            headers={headers}
            action={<Edit style={{ width: 20 }} />}
            selectable
            inactiveState={!course._id && !subject.assignedId && !term._id}
            inactiveMessage='Please select a course, subject, or term'
            onItemsSelected={val => {
              setSelectedquizzes(val)
            }}
            onActionClicked={item => {}}
            actionWrapper={ActionLinkWrapper}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  quizzes: state.quizzes.quizzes,
  courses: state.courses.courses,
  assignedSubjects: state.assignSubject.assignedSubjects,
  terms: state.terms.terms,
  role: state.auth.user.role,
  roles: state.auth.roles,
  error: state.error,
  success: state.success
})
export default connect(mapStateToProps, {
  fetchQuizzes,
  deleteQuiz,
  deleteQuizzes,
  fetchCourses,
  fetchAssignedSubjects,
  fetchTerms,
  inputChange,
  subjectsInputChange,
  clearErrors,
  clearSuccess
})(Quizzes)
