import {
  GET_CLASS_CONTENTS_SUCCESS,
  GET_CLASS_CONTENT_SUCCESS,
  DELETE_CLASS_CONTENT_SUCCESS,
  DELETE_CLASS_CONTENTS_SUCCESS,
  ADD_CLASS_CONTENT_SUCCESS,
  EDIT_CLASS_CONTENT_SUCCESS,
  CLASS_CONTENTS_INPUT_CHANGE,
  OBJECT_PROP_INPUT_CHANGE,
  INITIALIZE_STATE,
  ARRAY_INPUT_CHANGE,
  UPDATE_CLASS_TEXTS_AND_THUMBNAIL_SUCCESS,
  DELETE_VIDEO_LESSON_SUCCESS,
  UPDATE_VIDEO_LESSON_SUCCESS,
  ADD_VIDEO_LESSON_SUCCESS,
  GET_CONTENT_RELATED_CONTENTS_SUCCESS,
} from "../actions/types";

const initialState = {
  classContents: [],
  classContent: { videoUrls: [] },
  contentImages: [],
  title: "",
  content: "",
  course: {},
  subject: {},
  term: {},
  videoLessons: [{ transcript: "", video: null, audio: null }],
  selectedVideo: {},
  gotos: [],
};

const classContentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLASS_CONTENTS_INPUT_CHANGE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case INITIALIZE_STATE:
      return { ...state, ...action.payload };
    case OBJECT_PROP_INPUT_CHANGE:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          [action.payload.propName]: action.payload.value,
        },
      };
    case ARRAY_INPUT_CHANGE:
      let arr = state[action.payload.name].slice();
      arr[action.payload.index] = action.payload.value;
      return {
        ...state,
        [action.payload.name]: arr,
      };
    case GET_CONTENT_RELATED_CONTENTS_SUCCESS:
      return {
        ...state,
        gotos: [...action.payload.lessons],
      };
    case GET_CLASS_CONTENTS_SUCCESS:
      return {
        ...state,
        classContents: [...action.payload.lessons],
      };
    case GET_CLASS_CONTENT_SUCCESS:
      return {
        ...state,
        classContent: action.payload.lesson,
      };
    case DELETE_CLASS_CONTENT_SUCCESS:
      return {
        ...state,
        classContents: state.classContents.filter(
          (classContent) => classContent._id !== action.payload
        ),
      };
    case DELETE_CLASS_CONTENTS_SUCCESS:
      return {
        ...state,
        classContents: state.classContents.filter(
          (classContent) => !action.payload.lessonIds.includes(classContent._id)
        ),
      };
    case ADD_CLASS_CONTENT_SUCCESS:
    case EDIT_CLASS_CONTENT_SUCCESS:
      return {
        ...initialState,
        videoLessons: [{ transcript: "", video: null, audio: null }],
      };
    case UPDATE_CLASS_TEXTS_AND_THUMBNAIL_SUCCESS:
      return {
        ...state,
        classContent: action.payload,
      };
    case DELETE_VIDEO_LESSON_SUCCESS:
      return {
        ...state,
        classContent: {
          ...state.classContent,
          videoUrls: [...action.payload.videoUrls],
        },
      };
    case ADD_VIDEO_LESSON_SUCCESS:
      return {
        ...state,
        classContent: {
          ...state.classContent,
          videoUrls: [...action.payload.videoUrls],
        },
      };
    case UPDATE_VIDEO_LESSON_SUCCESS:
      const updatedVideo = state.classContent.videoUrls.find(
        (item) => item._id == action.payload._id
      );
      updatedVideo.videoUrl = action.payload.videoUrl;
      updatedVideo.transcript = action.payload.transcript;
      return {
        ...state,
        classContent: {
          ...state.classContent,
        },
      };
    default:
      return state;
  }
};

export default classContentsReducer;
