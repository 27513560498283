import React, { useState, useRef, useEffect } from "react";
import { ResponsiveLine } from "@nivo/line";

import { ReactComponent as ArrowPaid } from "../../../assets/icons/ArrowPaid.svg";
import { ReactComponent as ArrowPend } from "../../../assets/icons/ArrowPend.svg";
import Table from "../../includes/table/table.component";
import Pagination from "../../includes/pagination/pagination.component";
import FilterSearch from "../../includes/filter-search/filterSearch.component";
import DeleteDialog from "../../includes/confirm-dialog/confirmDialog.component";
import Snackbar from "../../includes/snackbar/snackbar.component";
import Alert from "../../includes/alert/alert.component";

import { connect, useDispatch, useSelector } from "react-redux";
import {
  fetchPaymentTransactions,
  deletePaymentTransaction,
  deletePaymentTransactions,
  fetchAllCoinTransactions,
} from "../../../redux/actions/transactionsActions";
import { clearErrors } from "../../../redux/actions/errorActions";
import { clearSuccess } from "../../../redux/actions/successActions";

import Button from "../../includes/button/button.component";
import DateWrapper from "../../includes/date-wrapper/date-wrapper.component";
import SelectField from "../../includes/select-field/select.component";
const currentDate = new Date();
const months = [
  {
    value: 0,
    name: "January",
    noOfDays: 31,
  },
  {
    value: 1,
    name: "February",
    noOfDays: 28,
  },
  {
    value: 2,
    name: "March",
    noOfDays: 31,
  },
  {
    value: 3,
    name: "April",
    noOfDays: 30,
  },
  {
    value: 4,
    name: "May",
    noOfDays: 31,
  },
  {
    value: 5,
    name: "June",
    noOfDays: 30,
  },
  {
    value: 6,
    name: "July",
    noOfDays: 31,
  },
  {
    value: 7,
    name: "August",
    noOfDays: 31,
  },
  {
    value: 8,
    name: "Sepetember",
    noOfDays: 30,
  },
  {
    value: 9,
    name: "October",
    noOfDays: 31,
  },
  {
    value: 10,
    name: "November",
    noOfDays: 31,
  },
  {
    value: 11,
    name: "December",
    noOfDays: 31,
  },
];

const currentMonth = months.find((m) => m.value === currentDate.getMonth());
const currentYear = currentDate.getFullYear();

const Filter = FilterSearch(Pagination(Table));
const numWithComma = (num) => {
  return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.$gtOrEqual = function (date) {
  let me = new Date(this.valueOf());
  let it = new Date(date);

  return (
    me.getFullYear() >= it.getFullYear() &&
    me.getMonth() >= it.getMonth() &&
    me.getDay() >= it.getDay()
  );
};
Date.prototype.$lt = function (date) {
  let me = new Date(this.valueOf());
  let it = new Date(date);

  return (
    // me.getFullYear() < it.getFullYear() &&
    // me.getMonth() < it.getMonth() &&
    me.getDay() < it.getDay()
  );
};

const PriceWrapper = (props) => {
  return <div>₦{numWithComma(props.value)}</div>;
};
const CoinTypeWrapper = (props) => {
  return <div>{props.value === "add" ? "Bought" : "Spent"}</div>;
};

const getValues = (transactions, { value, noOfDays }, year, interval = 2) => {
  if (!transactions.length) return;
  let vals = [];
  let current = 1;
  for (let i = 1; i <= noOfDays; i += interval) {
    let start = new Date(year, value, i);
    let a;
    if (i + interval > noOfDays) a = i + interval - noOfDays;
    else a = interval;
    let end = start.addDays(a);
    let revenue;

    let arr = transactions
      .filter((t) => {
        if (!t.createdAt || !t.amount) return false;
        let tDate = new Date(t.createdAt);

        return tDate >= start && tDate < end;
      })
      .map((t) => t.amount);
    if (arr.length)
      revenue = arr.reduce((a, b) => {
        if (typeof b === "number") return a + b;
        return a + 0;
      });
    else revenue = 0;

    vals.push({
      x:
        interval === 1 || i === noOfDays
          ? `${i}`
          : `${i} - ${i + interval > noOfDays ? noOfDays : i + interval - 1}`,
      y: revenue,
    });
  }
  if (interval !== 1) vals.unshift({ x: "", y: 0 });
  return vals;
};

const StudentChart = (props) => {
  const { transactions, error, success } = props;
  const dispatch = useDispatch();
  const allCoinTransactions = useSelector(
    (state) => state.transactions.allCoinTransactions
  );
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successAlert, setSuccessAlert] = useState({
    show: false,
    message: "Selected class(es) deleted successfully",
  });
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ message: "", type: "error" });
  const [graphData, setGraphData] = useState([]);
  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [interval, setInterval] = useState(7);
  const [curentRevenue, setCurentRevenue] = useState(0);

  useEffect(() => {
    dispatch(fetchAllCoinTransactions());
  }, []);

  useEffect(() => {
    if (transactions.length) {
      let d = getValues(transactions, month, year, interval);

      let result = d.map((a) => a.y);

      var total = 0;
      for (var i in result) {
        total += result[i];
      }

      setCurentRevenue(total);
      setGraphData([
        {
          id: "japan",
          color: "rgba(38, 170, 118, 1)",
          data: d,
        },
      ]);
    }
  }, [month, transactions, year, interval]);

  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      props.fetchPaymentTransactions();
      mounted.current = true;
    } else {
      if (error.id) {
        const snack = { message: error.msg };
        switch (error.id) {
          case "GET_PAYMENT_TRANSACTIONS_FAILURE":
            setSnackbar(snack);
            break;
        }
        props.clearErrors();
      } else if (success.id) {
        const snack = { message: success.msg, type: "success" };
        switch (success.id) {
          case "DELETE_PAYMENT_TRANSACTION_SUCCESS":
            setSnackbar(snack);
            break;
          case "DELETE_PAYMENT_TRANSACTIONS_SUCCESS":
            setSnackbar(snack);
            break;
        }
        props.clearSuccess();
      }
    }
  });

  const headers = [
    { value: "userId.fullName", text: "Name", align: "left" },
    { value: "userId.email", text: "Email", align: "left" },
    {
      value: [
        "enrolledCourseId.classId.name",
        "enrolledCourseId.courseId.name",
      ],
      text: "Class",
      align: "left",
    },
    { value: "paymentPlanId.name", text: "Plan", align: "left" },
    { value: "amount", text: "Price", align: "left", wrapper: PriceWrapper },
    {
      value: "enrolledCourseId.startDate",
      text: "Start Date",
      wrapper: DateWrapper,
      align: "left",
    },
    {
      value: "enrolledCourseId.endDate",
      text: "End Date",
      wrapper: DateWrapper,
      align: "left",
    },
  ];
  const coinTableHeaders = [
    { value: "userId.fullName", text: "Name", align: "left" },
    { value: "userId.email", text: "Email", align: "left" },
    { value: "type", text: "Type", align: "left" },
    { value: "amount", text: "Amount", align: "left", wrapper: PriceWrapper },
  ];

  const deleteSelectedTransactions = () => {
    if (selectedTransactions.length == 0) {
      setSnackbar({ message: "No transaction was selected", type: "error" });
      return;
    } else if (selectedTransactions.length === 1) {
      props.deletePaymentTransaction(selectedTransactions[0]);
    } else {
      props.deletePaymentTransactions(
        selectedTransactions.map((trans) => trans._id)
      );
    }
  };

  return (
    <div>
      <div></div>
      <DeleteDialog
        show={deleteDialog}
        onCancel={() => {
          setDeleteDialog(false);
        }}
        onContinue={() => {
          setDeleteDialog(false);
          deleteSelectedTransactions();
        }}
        onClose={() => {
          setDeleteDialog(false);
        }}
        title="Delete Student Transaction(s)"
        message={`Are you sure you want to delete the student transaction${
          selectedTransactions.length > 1 ? "s" : ""
        }`}
      />
      <Snackbar
        snack={snackbar}
        reset={() => {
          setSnackbar({ message: "" });
        }}
      />
      <div className="d-flex align-items-center justify-content-between flex-wrap mt-5 mt-md-0">
        <h3 className="title-text">Revenue Growth Rate (Money)</h3>
        <div>
          <span className="bold-text mr-2">Total Revenue</span>
          <Button
            color="var(--primary-color)"
            style={{
              borderRadius: 10,
              padding: ".8em 1.2em",
              fontSize: "120%",
            }}
          >
            ₦ {numWithComma(curentRevenue)}
          </Button>
        </div>
      </div>

      <div className="mt-4 d-flex align-items-start">
        <SelectField
          items={months}
          value={month}
          textField="name"
          onSelect={(e) => {
            setMonth(e.target.value);
          }}
          style={{ maxWidth: 200 }}
          className="mr-2"
        />
        <SelectField
          items={[2020, 2021, 2022, 2023, 2024]}
          value={year}
          onSelect={(e) => {
            setYear(e.target.value);
          }}
          style={{ maxWidth: 120 }}
          className="mr-2"
        />
        <div className="ml-auto">
          <SelectField
            items={[1, 2, 5, 7, 10, 15]}
            value={interval}
            onSelect={(e) => {
              setInterval(e.target.value);
            }}
            style={{ maxWidth: 120 }}
          />
          <div style={{ fontSize: "85%" }}>Interval</div>
        </div>
      </div>

      {transactions.length > 0 && (
        <div style={{ height: 400 }}>
          {/* {graphData.length > 0 && ( */}
          <ResponsiveLine
            data={graphData}
            margin={{ top: 50, right: 10, bottom: 50, left: 40 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
            colors={["var(--primary-color)"]}
            yFormat=" >-.2f"
            curve="natural"
            enableArea={true}
            enableGridX={false}
            enableSlices="x"
            areaOpacity={0.02}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: -20,
              legend: "Month",
              legendOffset: 46,
              legendPosition: "middle",
            }}
            axisLeft={null}
            lineWidth={3}
            pointSize={15}
            pointColor={["var(--primary-color)"]}
            pointBorderWidth={4}
            pointBorderColor={["white"]}
            pointLabelYOffset={-12}
            useMesh={true}
            sliceTooltip={({ slice }) => {
              return (
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "15px 20px",
                    borderRadius: 10,
                  }}
                  className="general-shadow"
                >
                  <div style={{ fontSize: "1.2em" }}>
                    ₦ {numWithComma(parseInt(slice.points[0].data.yFormatted))}
                  </div>
                  <div
                    style={{ fontSize: "0.8em", color: "rgba(0,0,0,0.5)" }}
                  >{`${slice.points[0].data.xFormatted}, ${month.name} ${year}`}</div>
                </div>
              );
            }}
          />
          {/* )} */}
        </div>
      )}

      {transactions.length < 1 && (
        <div style={{ padding: "40px 20px" }} className="center">
          No Transactions carried out yet
        </div>
      )}

      <div className="mt-5">
        <h3 className="title-text">Payment Status</h3>
        <div className="displays mt-4">
          <div className="paid">
            <p style={{ color: "white" }}>Total Successful Payment</p>
            <h4 style={{ color: "white" }}>
              {transactions.filter((t) => t.status === "successful").length}
              <ArrowPaid
                style={{
                  width: 20,
                  marginLeft: 10,
                  transform: "translateY(-1px)",
                }}
              />
            </h4>
            <div className="extra"></div>
          </div>
          <div className="pending">
            <p style={{ color: "white" }}>Total Pending Payment</p>
            <h4 style={{ color: "white" }}>
              {transactions.filter((t) => t.status === "pending").length}
              <ArrowPend
                style={{
                  width: 20,
                  marginLeft: 10,
                  transform: "translateY(-1px)",
                }}
              />
            </h4>
            <div className="extra"></div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: 80 }}>
        <h3 className="title-text mb-4">Payment Log</h3>
        {/* <div className='right'>
          <ActionButton
            delete
            onClick={() => {
              setDeleteDialog(true)
            }}
          />
        </div> */}

        <div className="d-flex justify-content-center">
          {successAlert.show && (
            <Alert
              type="success"
              message={successAlert.message}
              style={{ maxWidth: 500, flexGrow: 1 }}
              className="mt-3 mb-4"
              onClose={() => {
                setSuccessAlert({
                  show: false,
                  message: "Selected class(es) deleted successfully",
                });
              }}
            />
          )}
        </div>

        <Filter
          filters={[
            { value: "all", text: "All" },
            { value: "name", text: "Name", searchValue: "userId.fullName" },
            { value: "email", text: "Email", searchValue: "userId.email" },
            {
              value: "class",
              text: "Class",
              searchValue: "enrolledCourseId.courseId.name",
            },
            { value: "plan", text: "Plan", searchValue: "paymentPlanId.name" },
            { value: "price", text: "Price", searchValue: "amount" },
          ]}
          for="transactions"
          items={transactions}
          loading={loading}
          itemsPerPage={20}
          headers={headers}
          onItemsSelected={(val) => {
            setSelectedTransactions(val);
          }}
        />
        <div className="my-5"></div>
        <h3 className="title-text mb-4">Coin Transaction Log</h3>
        <Filter
          filters={[
            { value: "all", text: "All" },
            { value: "name", text: "Name", searchValue: "userId.fullName" },
            { value: "email", text: "Email", searchValue: "userId.email" },
            { value: "price", text: "Amount", searchValue: "amount" },
            { value: "type", text: "Type", searchValue: "type" },
          ]}
          for="transactions"
          items={allCoinTransactions}
          loading={loading}
          itemsPerPage={20}
          headers={coinTableHeaders}
          onItemsSelected={(val) => {
            setSelectedTransactions(val);
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  transactions: state.transactions.transactions,
  error: state.error,
  success: state.success,
});

export default connect(mapStateToProps, {
  fetchPaymentTransactions,
  clearErrors,
  clearSuccess,
  deletePaymentTransaction,
  deletePaymentTransactions,
})(StudentChart);
