import API from "../../assets/js/api";
import { returnErrors } from "./errorActions";
import { returnSuccess } from "./successActions";

import {
  GET_CLASS_CONTENTS_FAILURE,
  GET_CLASS_CONTENTS_SUCCESS,
  GET_CLASS_CONTENT_FAILURE,
  GET_CLASS_CONTENT_SUCCESS,
  DELETE_CLASS_CONTENTS_FAILURE,
  DELETE_CLASS_CONTENTS_SUCCESS,
  DELETE_CLASS_CONTENT_FAILURE,
  DELETE_CLASS_CONTENT_SUCCESS,
  ADD_CLASS_CONTENT_SUCCESS,
  ADD_CLASS_CONTENT_FAILURE,
  EDIT_CLASS_CONTENT_SUCCESS,
  EDIT_CLASS_CONTENT_FAILURE,
  CLASS_CONTENTS_INPUT_CHANGE,
  OBJECT_PROP_INPUT_CHANGE,
  INITIALIZE_STATE,
  INITIALIZE_STATE_SUCCESS,
  ARRAY_INPUT_CHANGE,
  UPDATE_CLASS_TEXTS_AND_THUMBNAIL_SUCCESS,
  UPDATE_CLASS_TEXTS_AND_THUMBNAIL_FAILURE,
  DELETE_VIDEO_LESSON_SUCCESS,
  DELETE_VIDEO_LESSON_FAILURE,
  UPDATE_VIDEO_LESSON_SUCCESS,
  UPDATE_VIDEO_LESSON_FAILURE,
  ADD_VIDEO_LESSON_SUCCESS,
  ADD_VIDEO_LESSON_FAILURE,
  GET_CONTENT_RELATED_CONTENTS_SUCCESS,
  GET_CONTENT_RELATED_CONTENTS_FAILURE,
} from "./types";

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  );
};
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id));
};

export const inputChange = (name, value) => (dispatch) => {
  dispatch({ type: CLASS_CONTENTS_INPUT_CHANGE, payload: { name, value } });
};

export const objectPropInputChange = (name, propName, value) => (dispatch) => {
  dispatch({
    type: OBJECT_PROP_INPUT_CHANGE,
    payload: { name, propName, value },
  });
};

export const arrayInputChange = (name, value, index) => (dispatch) => {
  dispatch({ type: ARRAY_INPUT_CHANGE, payload: { name, value, index } });
};

export const initializeState = (value) => (dispatch) => {
  dispatch({ type: INITIALIZE_STATE, payload: value });
  dispatchSuccess(dispatch, "", INITIALIZE_STATE_SUCCESS);
};

export const fetchClassContents = (params, loader = true) => async (
  dispatch
) => {
  try {
    if (loader) document.body.classList.add("loading-indicator");
    const result = await API.getClassContents(params);

    dispatch({ type: GET_CLASS_CONTENTS_SUCCESS, payload: result.data.data });
    dispatchSuccess(dispatch, ``, GET_CLASS_CONTENTS_SUCCESS);
    if (loader) document.body.classList.remove("loading-indicator");
  } catch (err) {
    if (loader) document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_CLASS_CONTENTS_FAILURE);
  }
};
export const fetchRelatedContents = (lessonId, loader = true) => async (
  dispatch
) => {
  try {
    if (loader) document.body.classList.add("loading-indicator");
    const result = await API.getRelatedContents(lessonId);

    dispatch({
      type: GET_CONTENT_RELATED_CONTENTS_SUCCESS,
      payload: result.data.data,
    });
    dispatchSuccess(dispatch, ``, GET_CONTENT_RELATED_CONTENTS_SUCCESS);
    if (loader) document.body.classList.remove("loading-indicator");
  } catch (err) {
    if (loader) document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_CONTENT_RELATED_CONTENTS_FAILURE);
  }
};

export const fetchClassContent = (id) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.getClassContent(id);

    dispatch({ type: GET_CLASS_CONTENT_SUCCESS, payload: result.data.data });
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_CLASS_CONTENT_FAILURE);
  }
};

export const addClassContent = (data) => async (dispatch, getState) => {
  try {
    document.body.classList.add("loading-indicator");
    const user = getState().auth.user;
    const formData = new FormData();
    formData.set("title", data.title);
    formData.set("key", data.key);
    formData.set("content", data.content);
    formData.set("termId", data.termId);
    formData.set("courseId", data.courseId);
    formData.set("subjectId", data.subjectId);
    formData.set("creatorId", user._id);
    if (data.contentImages.length) {
      formData.set("contentUrls", JSON.stringify(data.contentUrls));
      data.contentImages.forEach((image) => {
        formData.append("contentImages", image);
      });
    }
    formData.append("videoUrls", JSON.stringify(data.videoLessons));
    // if (data.videoLessons.length)
    //   data.videoLessons.forEach((vLesson) => {
    //     formData.append("transcripts", vLesson.transcript);
    //   });

    const result = await API.addClassContent(formData);

    dispatch({
      type: ADD_CLASS_CONTENT_SUCCESS,
      payload: { user, classContent: result.data.data.lesson },
    });
    dispatchSuccess(
      dispatch,
      `Lesson "${data.title}" successfully created`,
      ADD_CLASS_CONTENT_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, ADD_CLASS_CONTENT_FAILURE);
  }
};
export const editClassContent = (data, classContentId) => async (
  dispatch,
  getState
) => {
  try {
    document.body.classList.add("loading-indicator");
    const formData = new FormData();
    formData.set("title", data.title);
    formData.set("key", data.key);
    formData.set("content", data.content);
    formData.set("termId", data.termId);
    formData.set("courseId", data.courseId);
    formData.set("subjectId", data.subjectId);
    if (data.contentImages.length) {
      formData.set("contentUrls", JSON.stringify(data.contentUrls));
      data.contentImages.forEach((image) => {
        formData.append("contentImages", image);
      });
    }

    const videoLessons = data.videoLessons;
    const length = videoLessons.filter((v) => v.video.file).length;
    data.deleteMedia.forEach((url) => {
      formData.append("deleteMedia", url);
    });
    videoLessons.forEach((vLesson, index) => {
      if (length) {
        if (vLesson.video.file) {
          formData.append("videos", vLesson.video.file);
        }
      }
      formData.append(
        "videoData",
        videoLessons[index].video.file
          ? videoLessons[index].video.file.name
          : videoLessons[index].video.url
      );
      formData.append("transcripts", vLesson.transcript);
    });

    const result = await API.editClassContent(formData, classContentId);

    dispatchSuccess(
      dispatch,
      `Lesson "${data.title}" edited successfully`,
      EDIT_CLASS_CONTENT_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, EDIT_CLASS_CONTENT_FAILURE);
  }
};

export const deleteClassContent = (data, title) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deleteClassContent(data);

    dispatch({ type: DELETE_CLASS_CONTENT_SUCCESS, payload: data._id });
    dispatchSuccess(
      dispatch,
      `Lesson "${title}" deleted successfully`,
      DELETE_CLASS_CONTENT_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_CLASS_CONTENT_FAILURE);
  }
};

export const deleteClassContents = (data) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deleteClassContents(data);

    dispatch({
      type: DELETE_CLASS_CONTENTS_SUCCESS,
      payload: { lessonIds: data.lessonIds },
    });
    dispatchSuccess(
      dispatch,
      result.data.data.message,
      DELETE_CLASS_CONTENTS_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_CLASS_CONTENTS_FAILURE);
  }
};

//used
export const updateClassTextsAndThumbNAil = (lessonId, data) => async (
  dispatch
) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.updateClassTextsAndThumbNAil(lessonId, data);

    dispatch({
      type: UPDATE_CLASS_TEXTS_AND_THUMBNAIL_SUCCESS,
      payload: result.data.data.lesson,
    });
    dispatchSuccess(
      dispatch,
      result.data.data.lesson,
      UPDATE_CLASS_TEXTS_AND_THUMBNAIL_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, UPDATE_CLASS_TEXTS_AND_THUMBNAIL_FAILURE);
  }
};

//used
export const removeThumbNAil = (lessonId) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.removeThumbNAil(lessonId);

    dispatch({
      type: UPDATE_CLASS_TEXTS_AND_THUMBNAIL_SUCCESS,
      payload: result.data.data.lesson,
    });
    dispatchSuccess(
      dispatch,
      result.data.data.lesson,
      UPDATE_CLASS_TEXTS_AND_THUMBNAIL_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, UPDATE_CLASS_TEXTS_AND_THUMBNAIL_FAILURE);
  }
};

//used
export const deleteLessonVideo = (lessonId, videoId) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deleteLessonVideo(lessonId, videoId);

    dispatch({
      type: DELETE_VIDEO_LESSON_SUCCESS,
      payload: result.data.data.lesson,
    });
    dispatchSuccess(
      dispatch,
      result.data.data.lesson,
      DELETE_VIDEO_LESSON_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_VIDEO_LESSON_FAILURE);
  }
};

export const updateVideoLesson = (lessonId, videoId, data) => async (
  dispatch
) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.updateVideoLesson(lessonId, videoId, data);

    dispatch({
      type: UPDATE_VIDEO_LESSON_SUCCESS,
      payload: result.data.data.video,
    });
    dispatchSuccess(
      dispatch,
      result.data.data.video,
      UPDATE_VIDEO_LESSON_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, UPDATE_VIDEO_LESSON_FAILURE);
  }
};
export const addVideoLesson = (lessonId, data) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.addVideoLesson(lessonId, data);

    dispatch({
      type: ADD_VIDEO_LESSON_SUCCESS,
      payload: result.data.data.lesson,
    });
    dispatchSuccess(
      dispatch,
      result.data.data.lesson,
      ADD_VIDEO_LESSON_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, ADD_VIDEO_LESSON_FAILURE);
  }
};
