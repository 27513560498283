import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const NavMenuLink = (props) => {
  if (!props.sub)
    return (
      <>
        {props.show && (
          <NavLink
            exact={props.exact === undefined ? true : props.exact}
            to={props.path}
            className="link"
          >
            <span className="icon-container">{props.icon}</span>
            <span>{props.name}</span>
          </NavLink>
        )}
      </>
    );
  else
    return (
      <>
        {props.show && (
          <NavLink
            exact={props.exact === undefined ? true : props.exact}
            to={props.path}
            className="link sub"
          >
            <span>{props.name}</span>
          </NavLink>
        )}
      </>
    );
};

export default NavMenuLink;
