import React, { useState, useEffect, useRef } from "react";
import SunEditor from "suneditor-react";
import { useHistory, Redirect } from "react-router-dom";
import "suneditor/dist/css/suneditor.min.css";
import Button from "../../includes/button/button.component";
import SelectField from "../../includes/select-field/select.component";
import TextField from "../../includes/text-field/textField.component";
import { ReactComponent as Play } from "../../../assets/icons/Play.svg";
import FileUploader from "../../includes/file-uploader/fileUploader.component";
import { ReactComponent as Plus } from "../../../assets/icons/Plus.svg";
import {
  inputChange,
  addImpactStory,
  getImpactStoryCategories,
} from "../../../redux/actions/impactStoryActions";
import { connect, useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../../redux/actions/errorActions";
import { clearSuccess } from "../../../redux/actions/successActions";
import Snackbar from "../../includes/snackbar/snackbar.component";
import { deleteTags, show } from "../../../assets/js/globalFunctions";
import Select from "../../includes/select-field/select.component";

import "./css/style.css";

const btnList = [
  ["undo", "redo"],
  ["font", "fontSize", "formatBlock"],
  ["bold", "underline", "italic", "strike", "subscript", "superscript"],
  ["removeFormat"],
  "/",
  ["fontColor", "hiliteColor"],
  ["outdent", "indent"],
  ["align", "horizontalRule", "list", "table"],
  ["link", "image"],
  ["fullScreen", "showBlocks", "codeView"],
];

const ImpactStoryCreation = (props) => {
  const { articleCategories, role, roles, success, error } = props;
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [media, setMedia] = useState(null);
  const [category, setCategory] = useState({});
  const [fileURL, setfileURL] = useState("");
  const [snackbar, setSnackbar] = useState({ message: "" });
  const [addingCategory, setAddingCategory] = useState(false);
  const [isHeadline, setIsHeadline] = useState(false);

  const customerStoryCategories = useSelector(
    (state) => state.customerStories.customerStoryCategories
  );

  const contentImagesRef = useRef([]);
  const fileUploaderRef = useRef();
  const mounted = useRef(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      dispatch(getImpactStoryCategories());
    } else {
      if (success.id === "ADD_IMPACT_STORY_SUCCESS") {
        const snack = {
          message:
            typeof success.msg === "object"
              ? success.msg.join("<br/>")
              : success.msg,
          type: "success",
        };
        setSnackbar({ ...snack });
        // switch (success.id) {
        //   case 'ADD_IMPACT_STORY_SUCCESS':
        //     setSnackbar({ ...snack, timeout: 1500 })
        //     setTimeout(() => {
        //       history.push('/blog/articles')
        //     }, 2000)
        //     break
        //   case 'ADD_BLOG_ARTICLE_CATEGORY_SUCCESS':
        //     setSnackbar({ ...snack })
        //     setAddingCategory(false)
        //     setfileURL('')
        //     break
        // }
        setSnackbar({ ...snack });
        props.clearSuccess();
      } else if (error.id === "ADD_IMPACT_STORY_SUCCESS") {
        const snack = {
          message:
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg,
          type: "error",
        };

        // switch (error.id) {
        //   case 'ADD_BLOG_ARTICLE_FAILURE':
        //     setSnackbar(snack)
        //     break
        //   case 'ADD_BLOG_ARTICLE_CATEGORY_FAILURE':
        //     setSnackbar({ ...snack })
        //     setAddingCategory(false)
        //     break
        // }
        props.clearErrors();
      }
    }
  });

  const handleImageUpload = (targetImgElement, index, state, imageInfo) => {
    if (state === "create") {
      if (
        imageInfo.src.includes("http://") ||
        imageInfo.src.includes("https://")
      )
        return;
      var blobBin = atob(imageInfo.src.split(",")[1]);
      var array = [];
      for (var i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      var file = new Blob([new Uint8Array(array)], { type: "image/jpg" });
      contentImagesRef.current = [
        ...contentImagesRef.current,
        { file: file, src: imageInfo.src },
      ];
    }
  };

  const submit = () => {
    let message = "";
    if (!title) message = "Title cannot be empty";
    else if (!deleteTags(content)) message = "Content cannot be empty";
    else if (!(category && category._id))
      message = "You have to select a category";
    else if (!fileURL) message = "You have to put a video or image url";
    if (message) {
      setSnackbar({ message });
      return;
    }
    const data = {
      title,
      description: content,
      fileURL,
      categoryId: category._id,
    };

    data.contentImages = contentImagesRef.current.map((cnt) => cnt.file);
    data.contentUrls = contentImagesRef.current.map((cnt) => cnt.src);
    props.addImpactStory(data);
  };
  const setTitle_ = (e) => {
    setTitle(e.target.value);
  };
  const setfileURL_ = (e) => {
    setfileURL(e.target.value);
  };
  const selectCategory = (e) => {
    setCategory(e.target.value);
  };

  return (
    <div id="content-creation" className="page-padding">
      {role && (
        <>
          {!show(1, role, roles) ? <Redirect to="/dashboard" /> : null}
          <Snackbar
            snack={snackbar}
            reset={() => {
              setSnackbar({ message: "" });
            }}
          />
          <div
            className="d-flex justify-content-between align-items-center"
            style={{
              borderLeft: "5px solid var(--primary-color)",
              paddingLeft: "8px",
            }}
          >
            <h4 style={{ margin: "0", flexGrow: 1 }} className="title-text">
              Impact Story
            </h4>
            <Button onClick={submit}>Save</Button>
          </div>
          <div className="row mt-5">
            <div className="col-12 col-lg-8">
              <TextField
                placeholder="Title"
                name="title"
                value={title}
                onInput={setTitle_}
                plain
                className="mb-4"
              />
              <div className="note-markup">
                {/* {initialized.current && ( */}
                <SunEditor
                  setOptions={{
                    height: 515,
                    buttonList: btnList,
                    toolbarContainer: "#toolbar-container",
                  }}
                  onChange={(cont) => {
                    setContent(cont);
                  }}
                  onImageUpload={handleImageUpload}
                  setContents={content}
                />
                {/* )} */}
              </div>
            </div>
            <div className="col-12 col-lg-4 mt-4 mt-lg-0">
              <div
                style={{
                  backgroundColor: "white",
                  padding: "20px 0 60px 0",
                  borderRadius: "10px",
                }}
              >
                <h4 className="pl-4">File Section</h4>
                <hr />
                <div className="pl-4 pr-4 mb-2">
                  <label
                    style={{
                      color: category._id ? "var(--primary-color)" : "inherit",
                    }}
                  >
                    Category
                  </label>
                  <Select
                    items={customerStoryCategories}
                    value={category}
                    textField="name"
                    valueField="_id"
                    onSelect={selectCategory}
                    outlined
                    color={category._id ? "var(--primary-color)" : "#B6B6B6"}
                  />
                </div>

                <div className="pl-4 pr-4">
                  <div className="mb-2">Upload image/video url</div>
                  <TextField
                    value={fileURL}
                    onInput={setfileURL_}
                    name="new category"
                    outlined
                    placeholder="File URL"
                    color="#B6B6B6"
                    inputStyle={{ borderRadius: 5, paddingLeft: 15 }}
                    className="mb-2"
                  ></TextField>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  role: state.auth.user.role,
  roles: state.auth.roles,
  error: state.error,
  success: state.success,
});
export default connect(mapStateToProps, {
  inputChange,
  addImpactStory,
  clearSuccess,
  clearErrors,
})(ImpactStoryCreation);
