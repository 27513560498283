import React from "react";

import { ReactComponent as Plus } from "../../../assets/icons/Plus.svg";
import { ReactComponent as Delete } from "../../../assets/icons/Delete.svg";

const ActionButton = (props) => {
  return (
    <button
      id="action-button"
      className={`${props.delete ? "delete" : "add"} ${props.className}`}
      style={{...props.style}}
      onClick={() => {
        props.onClick();
      }}
    >
      {!props.delete && <Plus style={{ width: 25 }} />}
      {props.delete && <Delete style={{ width: 25 }} />}
    </button>
  );
};

export default ActionButton;
