import {
  GET_AFRICHALLENGE_DATA_BY_DATE_SUCCESS,
  GET_AFRICHALLENGE_WEEKLY_DATA_SUCCESS,
  GET_AFRICHALLENGE_WINNER_THIS_WEEK_SUCCESS,
  GET_COUNTS_SUCCESS,
  GET_SIGNUPS_BY_DATE_SUCCESS,
} from "../actions/types";

const initialState = {
  counts: {
    courses: 0,
    lessons: 0,
    videos: 0,
    afrilearnUsers: {
      students: 0,
      teachers: 0,
      parents: 0,
      schools: 0,
      admins: 0,
      total: 0,
    },
    subjects: 0,
    quizzes: 0,
    subscribedUsers: 0,
    unsubscribedUsers: 0,
  },
  signUps: {
    students: 0,
    teachers: 0,
    parents: 0,
    schools: 0,
  },
  winners: [],
  usersPerState: [],
  nigeria: {},
  afriChallengeWeeklyData: {
    results: [],
    nigeria: {},
    usersPerState: [],
  },
  afriChallengeData: [],
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_AFRICHALLENGE_WEEKLY_DATA_SUCCESS:
      return {
        ...state,
        afriChallengeWeeklyData: action.payload,
      };
    case GET_AFRICHALLENGE_WINNER_THIS_WEEK_SUCCESS:
      return {
        ...state,
        usersPerState: action.payload.usersPerState,
        nigeria: action.payload.nigeria,
        winners: action.payload.winners,
      };
    case GET_AFRICHALLENGE_DATA_BY_DATE_SUCCESS:
      return {
        ...state,
        afriChallengeData: action.payload,
      };
    case GET_COUNTS_SUCCESS:
      return {
        ...state,
        counts: action.payload.counts,
      };
    case GET_SIGNUPS_BY_DATE_SUCCESS:
      return {
        ...state,
        signUps: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
