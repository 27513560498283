import React, { useEffect, useState } from 'react'
import { ReactComponent as Times } from '../../../assets/icons/Times.svg'

import './css/style.css'

let timeout
const Snackbar = props => {
  useEffect(() => {
    processSnackbar()
  })

  const processSnackbar = () => {
    if (props.snack.message) {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        reset()
      }, props.snack.timeout || 3500)
    }
  }

  processSnackbar()

  const reset = () => {
    props.reset()
  }
  const getColor = () => {
    let type = props.snack.type
    if (type === 'success') return 'var(--success-bg)'
    else if (type === 'error') return 'var(--error-bg)'
    else return 'var(--error-bg)'
  }
  return (
    <>
      {props.snack.message && (
        <div
          id='snackbar'
          className={`general-shadow ${
            !props.color ? props.snack.type || 'error' : ''
          }`}
        >
          <div style={{ fontFamily: 'font2' }} className='title'>
            {props.snack.type || 'Error'}!
          </div>
          <div className='d-flex align-items-center'>
            <div dangerouslySetInnerHTML={{ __html: props.snack.message }} />
            <Times
              onClick={() => {
                clearTimeout(timeout)
                reset()
              }}
              style={{
                width: 24,
                position: 'absolute',
                top: '15px',
                right: '10px'
              }}
              className='pointer ml-5 close'
            />
          </div>
        </div>
      )}
    </>
  )
}
export default Snackbar
