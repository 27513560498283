import React, { useEffect, useState, useRef } from 'react'
import { useHistory, Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  fetchBlogArticles,
  deleteBlogArticle,
  deleteBlogArticles,
  inputChange
} from '../../../redux/actions/blogArticlesActions'
import { fetchBlogArticleCategories } from '../../../redux/actions/blogArticleCategoriesActions'
import { clearErrors } from '../../../redux/actions/errorActions'
import { clearSuccess } from '../../../redux/actions/successActions'
import Table from '../../includes/table/table.component'
import { ReactComponent as Edit } from '../../../assets/icons/EditSquare.svg'
import Pagination from '../../includes/pagination/pagination.component'
import FilterSearch from '../../includes/filter-search/filterSearch.component'
import ActionButton from '../../includes/button/actionButton.component'
import DeleteDialog from '../../includes/confirm-dialog/confirmDialog.component'
import Alert from '../../includes/alert/alert.component'
import Snackbar from '../../includes/snackbar/snackbar.component'
import DateWrapper from '../../includes/date-wrapper/date-wrapper.component'
import { show, getAllContentMediaUrl } from '../../../assets/js/globalFunctions'
import Select from '../../includes/select-field/select.component'

import './css/style.css'
const Filter = FilterSearch(Pagination(Table))
const ActionLinkWrapper = props => {
  return (
    <Link to={`/blog/articles/${props.item._id}/edit`}>{props.children}</Link>
  )
}

const headers = [
  { value: '_index_', text: 'Id', align: 'left' },
  { value: 'title', text: 'Topic', align: 'left' },
  // { value: 'categoryId.name', text: 'Category' },
  { value: 'creatorId.firstName', text: 'By' },
  { value: 'createdAt', text: 'Date Created', wrapper: DateWrapper }
]

const BlogArticles = props => {
  const { blogArticles, articleCategories, error, success, role, roles } = props
  const [category, setCategory] = useState({})
  const [loading, setLoading] = useState(false)

  const mounted = useRef(false)

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    } else {
      if (success.id) {
        let id = success.id
        const snack = {
          message:
            typeof success.msg === 'object'
              ? success.msg.join('<br/>')
              : success.msg,
          type: 'success'
        }
        switch (id) {
          case 'DELETE_BLOG_ARTICLE_SUCCESS':
            setSnackbar(snack)
            break
          case 'DELETE_BLOG_ARTICLES_SUCCESS':
            setSnackbar(snack)
            break
          case 'GET_BLOG_ARTICLES_SUCCESS':
            setLoading(false)
            break
        }
        props.clearSuccess()
      } else if (error.id) {
        let id = error.id
        const snack = {
          message:
            typeof error.msg === 'object' ? error.msg.join('<br/>') : error.msg,
          type: 'error'
        }
        switch (id) {
          case 'DELETE_BLOG_ARTICLE_FAILURE':
            setSnackbar(snack)
            break
          case 'DELETE_BLOG_ARTICLES_FAILURE':
            setSnackbar(snack)
            break
        }
        props.clearErrors()
      }
    }
  })

  useEffect(() => {
    return () => {
      props.inputChange('blogArticles', [])
    }
  }, [])

  useEffect(() => {
    if (role) {
      props.fetchBlogArticleCategories()
    }
  }, [role])

  useEffect(() => {
    if (category._id) {
      setLoading(true)
      props.fetchBlogArticles({ categoryId: category._id }, false)
    }
  }, [category])

  const selectCategory = e => {
    setCategory(e.target.value)
  }

  const [deleteDialog, setDeleteDialog] = useState(false)
  const [selectedArticles, setSelectedArticles] = useState([])

  const [successAlert, setSuccessAlert] = useState({
    show: false,
    message: 'Selected content(s) deleted successfully'
  })
  const [snackbar, setSnackbar] = useState({ message: '', type: 'error' })

  const deleteSelectedArticles = () => {
    if (selectedArticles.length < 1) {
      setSnackbar({ message: 'No article was selected', type: 'error' })
      return
    } else if (selectedArticles.length === 1) {
      props.deleteBlogArticle(
        {
          _id: selectedArticles[0]._id,
          deleteMedia: [
            selectedArticles[0].mediaUrl,
            ...getAllContentMediaUrl(selectedArticles[0].content)
          ]
        },
        selectedArticles[0].title
      )
    } else {
      const sendData = {
        articleIds: [],
        deleteMedia: []
      }
      selectedArticles.forEach(article => {
        sendData.articleIds.push(article._id)
        let med = [article.mediaUrl, ...getAllContentMediaUrl(article.content)]
        sendData.deleteMedia = [...sendData.deleteMedia, ...med]
      })
      props.deleteBlogArticles(sendData)
    }
  }

  return (
    <div id='contents-display' className='page-padding'>
      {role && (
        <div>
          {!show(1, role, roles) ? <Redirect to='/dashboard' /> : null}
          <DeleteDialog
            show={deleteDialog}
            onCancel={() => {
              setDeleteDialog(false)
            }}
            onContinue={() => {
              setDeleteDialog(false)
              deleteSelectedArticles()
            }}
            onClose={() => {
              setDeleteDialog(false)
            }}
            title='Delete Blog Post(s)'
            message={`Are you sure you want to delete the blog post${
              selectedArticles.length > 1 ? 's' : ''
            }?`}
          />
          <Snackbar
            snack={snackbar}
            reset={() => {
              setSnackbar({ message: '' })
            }}
          />
          <div className='d-flex justify-content-between align-items-center mb-4'>
            <h3 className='title-text' style={{ margin: 0 }}>
              Blog Posts
            </h3>
            {show(1, role, roles) && (
              <ActionButton
                delete
                onClick={() => {
                  setDeleteDialog(true)
                }}
              />
            )}
          </div>

          <div className='select-grid mb-4'>
            <div style={{ maxWidth: 300 }}>
              <label
                style={{
                  color: category._id ? 'var(--primary-color)' : 'inherit'
                }}
              >
                Category
              </label>
              <Select
                items={articleCategories}
                value={category}
                textField='name'
                valueField='_id'
                onSelect={selectCategory}
                outlined
                color={category._id ? 'var(--primary-color)' : ''}
              />
            </div>
          </div>

          <div className='d-flex justify-content-center mb-4'>
            {successAlert.show && (
              <Alert
                type='success'
                message={successAlert.message}
                style={{ maxWidth: 500, flexGrow: 1 }}
                className='mt-3'
                onClose={() => {
                  setSuccessAlert({
                    show: false,
                    message: 'Selected class(es) deleted successfully'
                  })
                }}
              />
            )}
          </div>

          <Filter
            filters={[
              { value: 'all', text: 'All' },
              { value: 'title', text: 'Title', searchValue: 'title' }
            ]}
            items={blogArticles}
            loading={loading}
            itemsPerPage={20}
            headers={headers}
            action={show(0, role, roles) && <Edit style={{ width: 20 }} />}
            selectable={show(1, role, roles)}
            onItemsSelected={val => {
              setSelectedArticles(val)
            }}
            inactiveState={!category._id}
            inactiveMessage='Please select a category'
            onActionClicked={item => {
              props.inputChange('blogArticle', item)
            }}
            actionWrapper={ActionLinkWrapper}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  blogArticles: state.blogArticles.blogArticles,
  articleCategories: state.blogArticleCategories.blogArticleCategories,
  role: state.auth.user.role,
  roles: state.auth.roles,
  error: state.error,
  success: state.success
})
export default connect(mapStateToProps, {
  fetchBlogArticles,
  deleteBlogArticle,
  deleteBlogArticles,
  fetchBlogArticleCategories,
  clearErrors,
  clearSuccess,
  inputChange
})(BlogArticles)
