import {
  GET_AFRILEARN_USER_SUCCESS,
  ACTIVATE_USER_SUCCESS,
  ACTIVATION_INPUT_CHANGE,
  INITIALIZE_STATE,
  FETCH_USER_ENROLLED_COURSES_SUCCESS
} from '../actions/types'

const initialState = {
  email: '',
  paymentPlan: {},
  course: {},
  refNumber: '',
  user: {},
  subject: {},
  userCourses: [],
  newClassName: ''
}

const manualActivationReducer = (state = initialState, action) => {
  let arr
  switch (action.type) {
    case ACTIVATION_INPUT_CHANGE:
      return {
        ...state,
        [action.payload.name]: action.payload.value
      }
    case INITIALIZE_STATE:
      return action.payload
        ? { ...state, ...action.payload }
        : {
            email: '',
            paymentPlan: {},
            course: {},
            refNumber: '',
            user: {},
            subject: {},
            userCourses: []
          }
    case GET_AFRILEARN_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user || {}
      }
    case ACTIVATE_USER_SUCCESS:
      return {
        ...state,
        terms: [
          ...state.terms,
          {
            ...action.payload.term,
            creatorId: {
              firstName: action.payload.user.firstName,
              _id: action.payload.user._id
            }
          }
        ]
      }
    case FETCH_USER_ENROLLED_COURSES_SUCCESS:
      return {
        ...state,
        userCourses: action.payload.courses
      }
    default:
      return state
  }
}

export default manualActivationReducer
