import API from "../../assets/js/api";
import { returnErrors } from "./errorActions";
import { returnSuccess } from "./successActions";

import {
  GET_PAYMENT_PLANS_FAILURE,
  GET_PAYMENT_PLANS_SUCCESS,
  // GET_TEACHERS_PAYMENT_PLANS_FAILURE,
  // GET_TEACHERS_PAYMENT_PLANS_SUCCESS,
  DELETE_PAYMENT_PLANS_FAILURE,
  DELETE_PAYMENT_PLANS_SUCCESS,
  DELETE_PAYMENT_PLAN_FAILURE,
  DELETE_PAYMENT_PLAN_SUCCESS,
  ADD_PAYMENT_PLAN_SUCCESS,
  ADD_PAYMENT_PLAN_FAILURE,
  EDIT_PAYMENT_PLAN_SUCCESS,
  EDIT_PAYMENT_PLAN_FAILURE,
  INPUT_CHANGE,
  ADD_OR_REMOVE_COINS_SUCCESS,
  ADD_OR_REMOVE_COINS_FAILURE,
} from "./types";

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  );
};
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id));
};

export const inputChange = (name, value) => (dispatch) => {
  dispatch({ type: INPUT_CHANGE, payload: { name, value } });
};

export const addOrRemoveCoins = (data) => async (dispatch, getState) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.addOrRemoveCoins(data);

    dispatch({
      type: INPUT_CHANGE,
      payload: { name: "addCoinStatus", value: "success" },
    });
    // dispatch({ type: ADD_OR_REMOVE_COINS_SUCCESS, payload: result.data.data });
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    // dispatchError(dispatch, err, ADD_OR_REMOVE_COINS_FAILURE);
    dispatch({
      type: INPUT_CHANGE,
      payload: { name: "addCoinStatus", value: "failed" },
    });
    if (err?.response?.data?.error) {
      dispatch({
        type: INPUT_CHANGE,
        payload: {
          name: "addCoinStatusMessage",
          value: err?.response?.data?.error,
        },
      });
    }
  }
};
export const fetchPaymentPlans = () => async (dispatch, getState) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.getPaymentPlans();

    dispatch({ type: GET_PAYMENT_PLANS_SUCCESS, payload: result.data.data });
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_PAYMENT_PLANS_FAILURE);
  }
};

// export const fetchTeachersPaymentPlans = () => async (dispatch) => {
//   try {
//     document.body.classList.add("loading-indicator");
//     const result = await API.getPaymentPlans();

//     dispatch({ type: GET_TEACHERS_PAYMENT_PLANS_SUCCESS, payload: result.data.data });
//     document.body.classList.remove("loading-indicator");
//   } catch (err) {
//     document.body.classList.remove("loading-indicator");
//     dispatchError(dispatch, err, GET_TEACHERS_PAYMENT_PLANS_FAILURE);
//   }
// };

export const addPaymentPlan = (data) => async (dispatch, getState) => {
  try {
    document.body.classList.add("loading-indicator");
    const user = getState().auth.user;
    const result = await API.addPaymentPlan({
      ...data,
      creatorId: user._id,
    });

    dispatch({
      type: ADD_PAYMENT_PLAN_SUCCESS,
      payload: { user, paymentPlan: result.data.data.paymentPlan },
    });
    dispatchSuccess(
      dispatch,
      `Payment plan "${data.name}" successfully created`,
      ADD_PAYMENT_PLAN_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, ADD_PAYMENT_PLAN_FAILURE);
  }
};
export const editPaymentPlan = (name, update, planId) => async (
  dispatch,
  getState
) => {
  try {
    document.body.classList.add("loading-indicator");
    const user = getState().auth.user;
    const result = await API.editPaymentPlan(update, planId);

    dispatch({
      type: EDIT_PAYMENT_PLAN_SUCCESS,
      payload: { user, paymentPlan: result.data.data.paymentPlan },
    });
    dispatchSuccess(
      dispatch,
      `Payment plan "${name}" successfully edited`,
      EDIT_PAYMENT_PLAN_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, EDIT_PAYMENT_PLAN_FAILURE);
  }
};

export const deletePaymentPlan = (planId) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deletePaymentPlan(planId);

    dispatch({ type: DELETE_PAYMENT_PLAN_SUCCESS, payload: planId });
    dispatchSuccess(
      dispatch,
      result.data.data.message,
      DELETE_PAYMENT_PLAN_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_PAYMENT_PLAN_FAILURE);
  }
};

export const deletePaymentPlans = (planIds) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deletePaymentPlans({ planIds });

    dispatch({ type: DELETE_PAYMENT_PLANS_SUCCESS, payload: { planIds } });
    dispatchSuccess(
      dispatch,
      result.data.data.message,
      DELETE_PAYMENT_PLANS_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_PAYMENT_PLANS_FAILURE);
  }
};
