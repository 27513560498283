import {
  GET_PAYMENT_TRANSACTIONS_SUCCESS,
  DELETE_PAYMENT_TRANSACTIONS_SUCCESS,
  DELETE_PAYMENT_TRANSACTION_SUCCESS,
  GET_COINS_TRANSACTIONS_SUCCESS,
  GET_COINS_TRANSACTIONS_BY_YEAR_SUCCESS,
  GET_ALL_COINS_TRANSACTIONS_SUCCESS,
} from "../actions/types";

const initialState = {
  allCoinTransactions: [],
  transactions: [],
  coinTransactions: { added: [], removed: [] },
  coinTransactionsByYear: [],
};

const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COINS_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        allCoinTransactions: action.payload.coinTransactions,
      };
    case GET_PAYMENT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: action.payload.paymentTransactions,
      };
    case GET_COINS_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        coinTransactions: action.payload,
      };
    case GET_COINS_TRANSACTIONS_BY_YEAR_SUCCESS:
      return {
        ...state,
        coinTransactionsByYear: action.payload,
      };
    case DELETE_PAYMENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: state.transactions.filter(
          (trans) => trans._id !== action.payload
        ),
      };
    case DELETE_PAYMENT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: state.transactions.filter(
          (trans) => !action.payload.includes(trans._id)
        ),
      };
    default:
      return state;
  }
};

export default transactionsReducer;
