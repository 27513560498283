import axios from "axios";

const URL = "https://afrilearn-cms.herokuapp.com/api/v1/";
const LocalURL = "http://localhost:5000/api/v1/";
const PastQuestionURL = "https://api.exambly.com/adminpanel/v2/";

export default {
  url: URL,
  url2: PastQuestionURL,
  headers(fileupload = false) {
    const token = localStorage.getItem("token");

    let header = {};
    if (fileupload) {
      header["Content-type"] = "multipart/form-data";
    } else {
      header["Content-type"] = "application/json";
      header["Accept"] = "*/*";
      header["Access-Control-Allow-Origin"] = "*";
    }
    if (token && token !== undefined) {
      header["token"] = token;
    }
    return header;
  },

  headers2() {
    let header = {};
    header["Content-type"] = "application/json";
    header["authorization"] =
      "F0c7ljTmi25e7LMIF0Wz01lZlkHX9b57DFTqUHFyWeVOlKAsKR0E5JdBOvdunpqv";
    return header;
  },

  updateProfilePic(profilePhotoUrl) {
    return axios({
      method: "patch",
      url: `${this.url}auth/update-profile-pic`,
      headers: this.headers(),
      data: profilePhotoUrl,
    });
  },
  acceptRejectClassmember(classId, userId, status) {
    return axios({
      method: "patch",
      url: `${this.url}classes/accept-reject-class-request`,
      headers: this.headers(),
      data: { classId, userId, status },
    });
  },

  checkUserExistJoinClass(email, classId) {
    return axios({
      method: "post",
      url: `${this.url}auth/check-join-class`,
      data: { email, classId },
    });
  },
  getRoles() {
    return axios({
      method: "get",
      url: `${this.url}auth/roles`,
      headers: this.headers(),
    });
  },

  sendClassInvite(email, link) {
    return axios({
      method: "post",
      url: `${this.url}classes/send-class-invite`,
      headers: this.headers(),
      data: { email, link },
    });
  },

  joinApproved(classId, email, fullName, password) {
    return axios({
      method: "post",
      url: `${this.url}classes/${classId}/join-class`,
      data: { email, fullName, password },
    });
  },

  registerUser(data) {
    return axios({
      method: "post",
      url: `${this.url}auth/signup`,
      headers: this.headers(),
      data,
    });
  },

  login(data) {
    return axios({
      method: "post",
      url: `${this.url}auth/signin`,
      headers: this.headers(),
      data,
    });
  },

  resetPassword(data) {
    return axios({
      method: "get",
      url: `${this.url}auth/${data}/reset_password`,
      headers: this.headers(),
    });
  },

  updateProfile(data) {
    return axios({
      method: "patch",
      url: `${this.url}auth/profile-update`,
      headers: this.headers(),
      data,
    });
  },

  addRecentActivity(lessonId, type) {
    return axios({
      method: "post",
      url: `${this.url}recents/add-recent-activity`,
      headers: this.headers(),
      data: {
        lessonId,
        type,
      },
    });
  },

  addClass(courseId, name) {
    return axios({
      method: "post",
      url: `${this.url}classes/add-class`,
      headers: this.headers(),
      data: {
        courseId,
        name,
      },
    });
  },

  addSubjectProgress(
    classId,
    lessonId,
    subjectId,
    courseId,
    recommended,
    reason,
    type
  ) {
    return axios({
      method: "post",
      url: `${this.url}courses/subject-progress`,
      headers: this.headers(),
      data: {
        classId,
        lessonId,
        subjectId,
        courseId,
        recommended,
        reason,
        type,
      },
    });
  },

  changePassword(data) {
    return axios({
      method: "post",
      url: `${this.url}auth/change_password`,
      headers: this.headers(),
      data,
    });
  },

  changePasswordDirectly(data) {
    return axios({
      method: "post",
      url: `${this.url}auth/change-password`,
      headers: this.headers(),
      data,
    });
  },

  socialLoginGoogle(data) {
    return axios({
      method: "post",
      url: `${this.url}auth/social_login/google`,
      headers: this.headers(),
      data,
    });
  },

  socialLoginFacebook(data) {
    return axios({
      method: "post",
      url: `${this.url}auth/social_login/facebook`,
      headers: this.headers(),
      data,
    });
  },

  socialLoginUpdate(data) {
    return axios({
      method: "patch",
      url: `${this.url}auth/profile-update`,
      headers: this.headers(),
      data,
    });
  },

  courseEnrolment(data) {
    return axios({
      method: "post",
      url: `${this.url}courses/enroll`,
      headers: this.headers(),
      data,
    });
  },

  getSearchResults(searchQuery) {
    return axios({
      method: "get",
      url: `${this.url}lessons?searchQuery=${searchQuery}`,
    });
  },

  //Courses

  getCourses() {
    return axios({
      method: "get",
      url: `${this.url}courses`,
      headers: this.headers(),
    });
  },

  addCourse(data) {
    return axios({
      method: "post",
      url: `${this.url}courses`,
      headers: this.headers(),
      data,
    });
  },

  editCourse(data, courseId) {
    return axios({
      method: "patch",
      url: `${this.url}courses/${courseId}`,
      headers: this.headers(),
      data,
    });
  },

  deleteCourse(courseId) {
    return axios({
      method: "delete",
      url: `${this.url}courses/${courseId}`,
      headers: this.headers(),
    });
  },
  deleteCourses(data) {
    return axios({
      method: "delete",
      url: `${this.url}courses`,
      headers: this.headers(),
      data,
    });
  },

  getCourse(data) {
    return axios({
      method: "get",
      url: `${this.url}courses/${data}`,
      headers: this.headers(),
    });
  },

  fetchTerms() {
    return axios({
      method: "get",
      url: `${this.url}terms`,
      headers: this.headers(),
    });
  },

  addTerm(data) {
    return axios({
      method: "post",
      url: `${this.url}terms`,
      headers: this.headers(),
      data,
    });
  },

  editTerm(data, termId) {
    return axios({
      method: "put",
      url: `${this.url}terms/${termId}`,
      headers: this.headers(),
      data,
    });
  },

  deleteTerm(termId) {
    return axios({
      method: "delete",
      url: `${this.url}terms/${termId}`,
      headers: this.headers(),
    });
  },

  deleteTerms(data) {
    return axios({
      method: "delete",
      url: `${this.url}terms`,
      headers: this.headers(),
      data,
    });
  },

  //Educational Categories

  addEducationalCategory(data) {
    return axios({
      method: "post",
      url: `${this.url}course-categories`,
      headers: this.headers(),
      data,
    });
  },

  editEducationalCategory(data, id) {
    return axios({
      method: "patch",
      url: `${this.url}course-categories/${id}`,
      headers: this.headers(),
      data,
    });
  },

  deleteEducationalCategory(id) {
    return axios({
      method: "delete",
      url: `${this.url}course-categories/${id}`,
      headers: this.headers(),
    });
  },
  deleteEducationalCategories(data) {
    return axios({
      method: "delete",
      url: `${this.url}course-categories`,
      headers: this.headers(),
      data,
    });
  },

  getEducationalCategory(data) {
    return axios({
      method: "get",
      url: `${this.url}course-categories/${data}`,
      headers: this.headers(),
    });
  },
  getEducationalCategories() {
    return axios({
      method: "get",
      url: `${this.url}course-categories/`,
      headers: this.headers(),
    });
  },

  //Blog Article Categories

  addBlogArticleCategory(data) {
    return axios({
      method: "post",
      url: `${this.url}blog/categories`,
      headers: this.headers(),
      data,
    });
  },

  editBlogArticleCategory(data, id) {
    return axios({
      method: "patch",
      url: `${this.url}blog/categories/${id}`,
      headers: this.headers(),
      data,
    });
  },

  deleteBlogArticleCategory(id) {
    return axios({
      method: "delete",
      url: `${this.url}blog/categories/${id}`,
      headers: this.headers(),
    });
  },
  deleteBlogArticleCategories(data) {
    return axios({
      method: "delete",
      url: `${this.url}blog/categories`,
      headers: this.headers(),
      data,
    });
  },

  getBlogArticleCategory(data) {
    return axios({
      method: "get",
      url: `${this.url}blog/categories/${data}`,
      headers: this.headers(),
    });
  },
  getBlogArticleCategories() {
    return axios({
      method: "get",
      url: `${this.url}blog/categories/`,
      headers: this.headers(),
    });
  },

  //Blog Articles

  getBlogArticles(params) {
    if (params)
      return axios({
        method: "get",
        url: `${this.url}blog/articles`,
        headers: this.headers(),
        params,
      });
    else
      return axios({
        method: "get",
        url: `${this.url}blog/articles`,
        headers: this.headers(),
      });
  },

  addBlogArticle(data) {
    return axios({
      method: "post",
      url: `${this.url}blog/articles`,
      headers: this.headers(true),
      data,
    });
  },

  editBlogArticle(data, id) {
    return axios({
      method: "put",
      url: `${this.url}blog/articles/${id}`,
      headers: this.headers(),
      data,
    });
  },

  deleteBlogArticle(data) {
    return axios({
      method: "delete",
      url: `${this.url}blog/articles/${data._id}`,
      headers: this.headers(),
      data,
    });
  },
  deleteBlogArticles(data) {
    return axios({
      method: "delete",
      url: `${this.url}blog/articles`,
      headers: this.headers(),
      data,
    });
  },

  getBlogArticle(id) {
    return axios({
      method: "get",
      url: `${this.url}blog/articles/${id}`,
      headers: this.headers(),
    });
  },

  //Class Contents

  getClassContents(params) {
    if (params)
      return axios({
        method: "get",
        url: `${this.url}lessons`,
        headers: this.headers(),
        params,
      });
    else
      return axios({
        method: "get",
        url: `${this.url}lessons`,
        headers: this.headers(),
      });
  },

  getRelatedContents(lessonId) {
    return axios({
      method: "get",
      url: `${this.url}lessons/related-lessons/${lessonId}`,
    });
  },
  addClassContent(data) {
    return axios({
      method: "post",
      url: `${this.url}lessons`,
      headers: this.headers(true),
      data,
    });
  },

  editClassContent(data, id) {
    return axios({
      method: "put",
      url: `${this.url}lessons/${id}`,
      headers: this.headers(),
      data,
    });
  },

  deleteClassContent(data) {
    return axios({
      method: "delete",
      url: `${this.url}lessons/${data._id}`,
      headers: this.headers(),
      data,
    });
  },
  deleteClassContents(data) {
    return axios({
      method: "delete",
      url: `${this.url}lessons`,
      headers: this.headers(),
      data,
    });
  },
  addVideoLesson(lessonId, data) {
    return axios({
      method: "post",
      url: `${this.url}lessons/${lessonId}/add-video`,
      data,
    });
  },
  updateVideoLesson(lessonId, videoId, data) {
    return axios({
      method: "patch",
      url: `${this.url}lessons/${lessonId}/${videoId}/update-video`,
      data,
    });
  },
  deleteLessonVideo(lessonId, videoId) {
    return axios({
      method: "delete",
      url: `${this.url}lessons/${lessonId}/${videoId}/delete-video`,
    });
  },
  updateClassTextsAndThumbNAil(lessonId, data) {
    return axios({
      method: "patch",
      url: `${this.url}lessons/${lessonId}`,
      data,
    });
  },
  removeThumbNAil(lessonId) {
    return axios({
      method: "patch",
      url: `${this.url}lessons/${lessonId}/remove-thumbnail`,
    });
  },

  getClassContent(id) {
    return axios({
      method: "get",
      url: `${this.url}lessons/${id}`,
      headers: this.headers(),
    });
  },

  //Quizzes

  getQuizzes(params) {
    return axios({
      method: "get",
      url: `${this.url}quizzes`,
      headers: this.headers(),
      params,
    });
  },

  addQuiz(data, lessonId) {
    return axios({
      method: "post",
      url: `${this.url}quizzes/`,
      headers: this.headers(true),
      data,
    });
  },

  editQuiz(data, id) {
    return axios({
      method: "put",
      url: `${this.url}quizzes/${id}`,
      headers: this.headers(),
      data,
    });
  },

  deleteQuiz(data) {
    return axios({
      method: "delete",
      url: `${this.url}quizzes/${data._id}`,
      headers: this.headers(),
      data,
    });
  },
  deleteQuizzes(data) {
    return axios({
      method: "delete",
      url: `${this.url}quizzes`,
      headers: this.headers(),
      data,
    });
  },

  getQuiz(id) {
    return axios({
      method: "get",
      url: `${this.url}quizzes/${id}`,
      headers: this.headers(),
    });
  },

  //Classes

  getClasses() {
    return axios({
      method: "get",
      url: `${this.url}classes`,
      headers: this.headers(),
    });
  },

  getClass(classId) {
    return axios({
      method: "get",
      url: `${this.url}classes/${classId}`,
    });
  },

  getMainSubjects() {
    return axios({
      method: "get",
      url: `${this.url}main-subjects`,
      headers: this.headers(),
    });
  },
  getMainStudentRequests(skip, limit) {
    return axios({
      method: "get",
      url: `${this.url}requests?skip=${skip}&limit=${limit}`,
      headers: this.headers(),
    });
  },
  addStudentRequestReply(requestId, data) {
    return axios({
      method: "post",
      url: `${this.url}requests/reply-request/${requestId}`,
      data,
      headers: this.headers(),
    });
  },

  // Counts

  getCounts() {
    return axios({
      method: "get",
      url: `${this.url}counts`,
      headers: this.headers(),
    });
  },

  getSignUpsByDate(date, endDate) {
    return axios({
      method: "post",
      url: `${this.url}counts/users-by-date`,
      headers: this.headers(),
      data: { date, endDate },
    });
  },
  getAfriChallengeReportByDate(startDate, endDate) {
    return axios({
      method: "post",
      url: `${this.url}counts/challenges-by-date`,
      data: { startDate, endDate },
    });
  },
  getThisWeekAfrichallengesWinners(startDate, endDate) {
    return axios({
      method: "post",
      url: `${this.url}counts/challenge-winners`,
      data: { startDate, endDate },
    });
  },

  getAfrichallengesWeeklyData(startDate, endDate) {
    return axios({
      method: "post",
      url: `${this.url}counts/challenge-week`,
      data: { startDate, endDate },
    });
  },
  //Terms
  addMainSubject(data) {
    return axios({
      method: "post",
      url: `${this.url}main-subjects`,
      headers: this.headers(),
      data,
    });
  },

  editMainSubject(data, subjectId) {
    return axios({
      method: "put",
      url: `${this.url}main-subjects/${subjectId}`,
      headers: this.headers(),
      data,
    });
  },

  deleteMainSubject(data) {
    return axios({
      method: "delete",
      url: `${this.url}main-subjects/${data._id}`,
      headers: this.headers(),
      data,
    });
  },

  deleteMainSubjects(data) {
    return axios({
      method: "delete",
      url: `${this.url}main-subjects`,
      headers: this.headers(),
      data,
    });
  },

  getTerms() {
    return axios({
      method: "get",
      url: `${this.url}terms`,
      headers: this.headers(),
    });
  },

  assignSubjectToCourse(data, courseId) {
    return axios({
      method: "post",
      url: `${this.url}courses/${courseId}/subjects`,
      headers: this.headers(true),
      data,
    });
  },

  getCourseAssignedSubjects(courseId) {
    return axios({
      method: "get",
      url: `${this.url}courses/${courseId}/subjects`,
      headers: this.headers(),
    });
  },

  deleteCourseAssignedSubject(courseId, subjectId, data) {
    return axios({
      method: "delete",
      url: `${this.url}courses/${courseId}/subjects/${subjectId}`,
      headers: this.headers(),
      data,
    });
  },

  assignPastQuestionToCourse(data, courseId) {
    return axios({
      method: "post",
      url: `${this.url}courses/${courseId}/past-questions`,
      headers: this.headers(),
      data,
    });
  },

  getCourseAssignedPastQuestions(courseId) {
    return axios({
      method: "get",
      url: `${this.url}courses/${courseId}/past-questions`,
      headers: this.headers(),
    });
  },

  deleteCourseAssignedPastQuestion(courseId, pastQuestionId, data) {
    return axios({
      method: "delete",
      url: `${this.url}courses/${courseId}/past-questions/${pastQuestionId}`,
      headers: this.headers(),
      data,
    });
  },

  loadUser() {
    return axios({
      method: "get",
      url: `${this.url}auth/load-user`,
      headers: this.headers(),
    });
  },

  getPaymentPlans() {
    return axios({
      method: "get",
      url: `${this.url}payments/plans`,
      headers: this.headers(),
    });
  },
  addOrRemoveCoins(data) {
    return axios({
      method: "post",
      url: `${this.url}coins`,
      data,
    });
  },

  addPaymentPlan(data) {
    return axios({
      method: "post",
      url: `${this.url}payments/plans`,
      headers: this.headers(),
      data,
    });
  },

  editPaymentPlan(data, planId) {
    return axios({
      method: "patch",
      url: `${this.url}payments/plans/${planId}`,
      headers: this.headers(),
      data,
    });
  },

  deletePaymentPlan(planId) {
    return axios({
      method: "delete",
      url: `${this.url}payments/plans/${planId}`,
      headers: this.headers(),
    });
  },

  deletePaymentPlans(data) {
    return axios({
      method: "delete",
      url: `${this.url}payments/plans`,
      headers: this.headers(),
      data,
    });
  },

  getPaymentTransactions() {
    return axios({
      method: "get",
      url: `${this.url}payments/transactions`,
      headers: this.headers(),
    });
  },
  getAllCoinTransactions() {
    return axios({
      method: "get",
      url: `${this.url}payments/coin-transactions`,
    });
  },
  getCoinTransactions(date, nextMonth) {
    return axios({
      method: "post",
      url: `${this.url}counts/coins-by-date`,
      headers: this.headers(),
      data: { date, nextMonth },
    });
  },
  getCoinTransactionsByYear() {
    return axios({
      method: "post",
      url: `${this.url}counts/coins-by-year`,
    });
  },
  deletePaymentTransaction(id) {
    return axios({
      method: "delete",
      url: `${this.url}payments/transactions/${id}`,
      headers: this.headers(),
    });
  },
  deletePaymentTransactions(data) {
    return axios({
      method: "delete",
      url: `${this.url}payments/transactions`,
      headers: this.headers(),
      data,
    });
  },

  createPaymentTransaction(data) {
    return axios({
      method: "post",
      url: `${this.url}payments/add-transaction`,
      headers: this.headers(),
      data,
    });
  },

  getUserEnrolledCourses(userId) {
    return axios({
      method: "get",
      url: `${this.url}enrolled-courses/${userId}`,
      headers: this.headers(),
    });
  },
  getSchoolEnrolledCourses(schoolId) {
    return axios({
      method: "get",
      url: `${this.url}enrolled-courses/school/${schoolId}`,
      headers: this.headers(),
    });
  },

  makeAnnouncement(classId, text) {
    return axios({
      method: "post",
      url: `${this.url}classes/${classId}/announce`,
      headers: this.headers(),
      data: { text },
    });
  },

  addCommentToAnnouncement(announcementId, text) {
    return axios({
      method: "post",
      url: `${this.url}classes/${announcementId}/comment`,
      headers: this.headers(),
      data: { text },
    });
  },

  addCommentToAssignedContent(assignedContentId, text, student) {
    return axios({
      method: "post",
      url: `${this.url}classes/${assignedContentId}/comment-on-content`,
      headers: this.headers(),
      data: { text, student },
    });
  },

  sendClassRequest(classCode) {
    return axios({
      method: "post",
      url: `${this.url}classes/send-class-request`,
      headers: this.headers(),
      data: { classCode },
    });
  },

  assignContentToStudent(description, lessonId, classId, dueDate, userId) {
    return axios({
      method: "post",
      url: `${this.url}classes/${classId}/assign-content`,
      headers: this.headers(),
      data: { description, lessonId, classId, dueDate, userId },
    });
  },

  populateDashboard(data) {
    return axios({
      method: "post",
      url: `${this.url}dashboard`,
      headers: this.headers(),
      data,
    });
  },

  getSubjectAndRelatedLessons(courseId, subjectId) {
    return axios({
      method: "post",
      url: `${this.url}lessons/${courseId}/${subjectId}/subject-lessons`,
    });
  },

  loadSubjects(examId) {
    return axios({
      method: "get",
      url: `${this.url2}getMySubjects/${examId}`,
      headers: this.headers2(),
    });
  },

  loadSchools() {
    return axios({
      method: "get",
      url: `${this.url2}getMySchools`,
      headers: this.headers2(),
    });
  },

  loadQuestions(id) {
    return axios({
      method: "get",
      url: `${this.url2}getQuestions/${id}`,
      headers: this.headers2(),
    });
  },

  flagQuestion(data) {
    return axios({
      method: "post",
      url: `${this.url2}reportQuestion`,
      headers: this.headers2(),
      data,
    });
  },

  submitPastQuestionResult(data) {
    return axios({
      method: "post",
      url: `${this.url}past-questions/save-past-question-result`,
      headers: this.headers(),
      data,
    });
  },

  submitPastQuestionProgress(data) {
    return axios({
      method: "post",
      url: `${this.url}past-questions/add-progress`,
      headers: this.headers(),
      data,
    });
  },

  submitLessonQuizResult(lessonId, data) {
    return axios({
      method: "post",
      url: `${this.url}lessons/${lessonId}/save-test-results`,
      headers: this.headers(),
      data,
    });
  },

  getPerformance(courseId) {
    return axios({
      method: "post",
      url: `${this.url}courses/${courseId}/progress-and-performance`,
      headers: this.headers(),
    });
  },

  getPerformanceInClass(courseId, data) {
    return axios({
      method: "post",
      url: `${this.url}courses/${courseId}/progress-and-performance`,
      headers: this.headers(),
      data,
    });
  },

  getAfrilearnUsers(data) {
    return axios({
      method: "post",
      url: `${this.url}users/afrilearn`,
      headers: this.headers(),
      data,
    });
  },

  getAfrilearnUser(email) {
    return axios({
      method: "get",
      url: `${this.url}users/afrilearn/${email}`,
      headers: this.headers(),
    });
  },

  sendEmail(data) {
    return axios({
      method: "post",
      url: `${this.url}users/send-email`,
      headers: this.headers(),
      data,
    });
  },

  manuallyActivateUser(data) {
    return axios({
      method: "post",
      url: `${this.url}payments/manual-activation`,
      headers: this.headers(),
      data,
    });
  },

  deleteAfrilearnUser(userId) {
    return axios({
      method: "delete",
      url: `${this.url}users/afrilearn/${userId}`,
      headers: this.headers(),
    });
  },
  updateAfrilearnUser(userId, data) {
    return axios({
      method: "patch",
      url: `${this.url}users/afrilearn/${userId}`,
      headers: this.headers(),
      data,
    });
  },
  removeUserfeedback(userId) {
    return axios({
      method: "post",
      url: `${this.url}users/afrilearn/${userId}/delete-feedback`,
      headers: this.headers(),
    });
  },

  deleteAfrilearnUsers(data) {
    return axios({
      method: "delete",
      url: `${this.url}users/afrilearnusers`,
      headers: this.headers(),
    });
  },

  getCmsUsers() {
    return axios({
      method: "get",
      url: `${this.url}users`,
      headers: this.headers(),
    });
  },

  addCmsUser(data) {
    return axios({
      method: "post",
      url: `${this.url}auth/signup`,
      headers: this.headers(),
      data,
    });
  },

  deleteCmsUser(userId) {
    return axios({
      method: "delete",
      url: `${this.url}users/cms/${userId}`,
      headers: this.headers(),
    });
  },

  deleteCmsUsers(data) {
    return axios({
      method: "delete",
      url: `${this.url}users`,
      headers: this.headers(),
      data,
    });
  },

  editCmsUser(data, userId) {
    return axios({
      method: "patch",
      url: `${this.url}users/${userId}`,
      headers: this.headers(),
      data,
    });
  },

  getPastQuestions() {
    return axios({
      method: "get",
      url: `${this.url}past-questions`,
      headers: this.headers(),
    });
  },

  addPastQuestion(data) {
    return axios({
      method: "post",
      url: `${this.url}past-questions`,
      headers: this.headers(),
      data,
    });
  },

  deletePastQuestion(pastQuestionId) {
    return axios({
      method: "delete",
      url: `${this.url}past-questions/${pastQuestionId}`,
      headers: this.headers(),
    });
  },

  deletePastQuestions(data) {
    return axios({
      method: "delete",
      url: `${this.url}past-questions`,
      headers: this.headers(),
      data,
    });
  },

  editPastQuestion(data, pastQuestionId) {
    return axios({
      method: "put",
      url: `${this.url}past-questions/${pastQuestionId}`,
      headers: this.headers(),
      data,
    });
  },
  createImpactStories(data) {
    return axios({
      method: "post",
      url: `${this.url}/user-story`,
      headers: this.headers(),
      data,
    });
  },
  getImpactStories(data) {
    return axios({
      method: "post",
      url: `${this.url}user-story/get`,
      data,
    });
  },
  getImpactStory(id) {
    return axios({
      method: "get",
      url: `${this.url}user-story/get/${id}`,
    });
  },
  updateImpactStory(id, data) {
    return axios({
      method: "patch",
      url: `${this.url}user-story/${id}`,
      headers: this.headers(true),
      data,
    });
  },
  getImpactStoryCategories() {
    return axios({
      method: "get",
      url: `${this.url}user-story/categories`,
    });
  },
  deleteImpactStory(id) {
    return axios({
      method: "delete",
      url: `${this.url}user-story/story/${id}`,
    });
  },
  deleteImpactStories(ids) {
    return axios({
      method: "delete",
      url: `${this.url}user-story/stories`,
      data: { storyIds: ids },
    });
  },
};
