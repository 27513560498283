import API from "../../assets/js/api";

import {
  ADD_REQUEST_REPLY_SUCCESS,
  GET_REQUESTS_SUCCESS,
  REQUESTS_INPUT_CHANGE,
} from "./types";

export const requestInputChange = (name, value) => (dispatch) => {
  dispatch({ type: REQUESTS_INPUT_CHANGE, payload: { name, value } });
};

export const fetchRequests = (skip, limit) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    dispatch({
      type: REQUESTS_INPUT_CHANGE,
      payload: { name: "loadingRequests", value: true },
    });
    dispatch({
      type: REQUESTS_INPUT_CHANGE,
      payload: { name: "loadingRequestsStatus", value: "pending" },
    });
    const result = await API.getMainStudentRequests(skip, limit);
    dispatch({
      type: REQUESTS_INPUT_CHANGE,
      payload: { name: "loadingRequests", value: false },
    });
    dispatch({
      type: REQUESTS_INPUT_CHANGE,
      payload: { name: "loadingRequestsStatus", value: "success" },
    });
    dispatch({ type: GET_REQUESTS_SUCCESS, payload: result.data.data });
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    dispatch({
      type: REQUESTS_INPUT_CHANGE,
      payload: { name: "loadingRequests", value: false },
    });
    dispatch({
      type: REQUESTS_INPUT_CHANGE,
      payload: { name: "loadingRequestsStatus", value: "failed" },
    });
    document.body.classList.remove("loading-indicator");
  }
};
export const addRequestReply = (requestId, data) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    dispatch({
      type: REQUESTS_INPUT_CHANGE,
      payload: { name: "addingRequestReply", value: true },
    });
    dispatch({
      type: REQUESTS_INPUT_CHANGE,
      payload: { name: "addingRequestReplyStatus", value: "pending" },
    });
    const result = await API.addStudentRequestReply(requestId, data);
    dispatch({
      type: REQUESTS_INPUT_CHANGE,
      payload: { name: "addingRequestReply", value: false },
    });
    dispatch({
      type: REQUESTS_INPUT_CHANGE,
      payload: { name: "addingRequestReplyStatus", value: "success" },
    });
    dispatch({ type: ADD_REQUEST_REPLY_SUCCESS, payload: result.data.data });
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    dispatch({
      type: REQUESTS_INPUT_CHANGE,
      payload: { name: "addingRequestReply", value: false },
    });
    dispatch({
      type: REQUESTS_INPUT_CHANGE,
      payload: { name: "addingRequestReplyStatus", value: "failed" },
    });
    document.body.classList.remove("loading-indicator");
  }
};
