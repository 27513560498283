import {
  DELETE_IMPACT_STORIES_SUCCESS,
  DELETE_IMPACT_STORY_SUCCESS,
  GET_IMPACT_STORIES_CATEGORIES_SUCCESS,
  GET_IMPACT_STORIES_SUCCESS,
  GET_IMPACT_STORY_SUCCESS,
  IMPACT_STORIES_INPUT_CHANGE,
  UPDATE_IMPACT_STORY_SUCCESS,
} from "../actions/types";

const initialState = {
  customerStoryCategories: [],
  customerStories: [],
  customerStoryCategory: {},
  customerStory: {},
};

const impactStoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case IMPACT_STORIES_INPUT_CHANGE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case GET_IMPACT_STORY_SUCCESS:
    case UPDATE_IMPACT_STORY_SUCCESS:
      return {
        ...state,
        customerStory: action.payload,
      };
    case GET_IMPACT_STORIES_SUCCESS:
      return {
        ...state,
        customerStories: action.payload.customerStories,
      };
    case GET_IMPACT_STORIES_CATEGORIES_SUCCESS:
      return {
        ...state,
        customerStoryCategories: action.payload.impactStoryCategories,
      };
    case DELETE_IMPACT_STORY_SUCCESS:
      return {
        ...state,
        customerStories: state.customerStories.filter(
          (story) => story._id !== action.payload
        ),
      };

    case DELETE_IMPACT_STORIES_SUCCESS:
      return {
        ...state,
        customerStories: state.customerStories.filter(
          (story) => !action.payload.includes(story._id)
        ),
      };
    default:
      return state;
  }
};

export default impactStoriesReducer;
