import React from "react";

const AssignedSubject = (props) => {
  return (
    <div
      style={{ width: "100%" }}
      className="d-flex justify-content-between assigned-subject"
    >
      <div className="d-flex flex-column justify-content-between">
        <span>{props.subject.name}</span>
        <span className="gif-button">
          {props.subject.name + "-image"}
        </span>
      </div>
      <button
        className="remove-button"
        onClick={() => {
          props.onDeleteAssigned(props.subject);
        }}
      >
        Remove
      </button>
    </div>
  );
};
export default AssignedSubject;
