import {
  ADD_ASSIGNED_SUBJECT_SUCCESS,
  GET_ASSIGNED_SUBJECTS_SUCCESS,
  DELETE_ASSIGNED_SUBJECT_SUCCESS,
  ASSIGN_SUBJECTS_INPUT_CHANGE,
  INITIALIZE_STATE,
} from "../actions/types";

const initialState = {
  course: {},
  subject: {},
  assignedSubjects: [],
  media: null,
};

const assignSubjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASSIGN_SUBJECTS_INPUT_CHANGE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case INITIALIZE_STATE:
      return {
        course: {},
        subject: {},
        assignedSubjects: [],
        media: null,
      };
    case ADD_ASSIGNED_SUBJECT_SUCCESS:
      return {
        ...state,
        media: null,
        subject: {},
        assignedSubjects: [
          ...state.assignedSubjects,
          {
            ...action.payload.subject.mainSubjectId,
            image: action.payload.subject.imageUrl,
          },
        ],
      };
    case GET_ASSIGNED_SUBJECTS_SUCCESS:
      return {
        ...state,
        assignedSubjects: action.payload.courseSubjects.map((sub) => ({
          image: sub.imageUrl,
          assignedId: sub._id,
          ...sub.mainSubjectId,
        })),
      };
    case DELETE_ASSIGNED_SUBJECT_SUCCESS:
      return {
        ...state,
        assignedSubjects: state.assignedSubjects.filter(
          (sub) => sub._id !== action.payload.subjectId
        ),
      };
    default:
      return state;
  }
};

export default assignSubjectReducer;
