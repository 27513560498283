import API from "../../assets/js/api";
import { returnErrors } from "./errorActions";
import { returnSuccess } from "./successActions";

import {
  GET_AFRILEARN_USER_FAILURE,
  GET_AFRILEARN_USER_SUCCESS,
  ACTIVATE_USER_SUCCESS,
  ACTIVATE_USER_FAILURE,
  ACTIVATION_INPUT_CHANGE,
  INITIALIZE_STATE,
  FETCH_USER_ENROLLED_COURSES_SUCCESS,
} from "./types";

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  );
};
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id));
};

export const initializeState = (val) => (dispatch) => {
  dispatch({ type: INITIALIZE_STATE, payload: val });
};
export const inputChange = (name, value) => (dispatch) => {
  dispatch({ type: ACTIVATION_INPUT_CHANGE, payload: { name, value } });
};

export const fetchUser = (email) => async (dispatch) => {
  try {
    // document.body.classList.add("loading-indicator");
    const result = await API.getAfrilearnUser(email);

    dispatch({ type: GET_AFRILEARN_USER_SUCCESS, payload: result.data.data });
    dispatchSuccess(dispatch, "", GET_AFRILEARN_USER_SUCCESS);
    // document.body.classList.remove("loading-indicator");
  } catch (err) {
    // document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_AFRILEARN_USER_FAILURE);
  }
};

export const activateUser = (data, info) => async (dispatch, getState) => {
  try {
    document.body.classList.add("loading-indicator");
    const creatorId = getState().auth.user._id;
    await API.manuallyActivateUser({ ...data, creatorId });

    dispatchSuccess(
      dispatch,
      `User "${info.name}" has been successfully subscribed to "${info.courseName}"`,
      ACTIVATE_USER_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, ACTIVATE_USER_FAILURE);
  }
};

export const fetchUserEnrolledCourses = (userId) => async (dispatch) => {
  try {
    const result = await API.getUserEnrolledCourses(userId);

    dispatch({
      type: FETCH_USER_ENROLLED_COURSES_SUCCESS,
      payload: result.data.data,
    });
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, ACTIVATE_USER_FAILURE);
  }
};
export const fetchSchoolEnrolledCourses = (schoolId) => async (dispatch) => {
  try {
    const result = await API.getSchoolEnrolledCourses(schoolId);

    dispatch({
      type: FETCH_USER_ENROLLED_COURSES_SUCCESS,
      payload: result.data.data,
    });
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, ACTIVATE_USER_FAILURE);
  }
};
