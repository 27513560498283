import React, { useState, useRef, useEffect } from 'react'
import { ReactComponent as Edit } from '../../../assets/icons/EditSquare.svg'
import Table from '../../includes/table/table.component'
import Alert from '../../includes/alert/alert.component'
import Modal from '../../includes/modal/modal.component'
import PlanForm from './planForm.component'
import { connect } from 'react-redux'
import {
  fetchPaymentPlans,
  deletePaymentPlan,
  deletePaymentPlans
} from '../../../redux/actions/paymentPlansActions'
import { clearErrors } from '../../../redux/actions/errorActions'
import { clearSuccess } from '../../../redux/actions/successActions'
import FilterSearch from '../../includes/filter-search/filterSearch.component'
import DeleteDialog from '../../includes/confirm-dialog/confirmDialog.component'
import ActionButton from '../../includes/button/actionButton.component'
import Snackbar from '../../includes/snackbar/snackbar.component'
import { show } from '../../../assets/js/globalFunctions'
import './css/style.css'
const Filter = FilterSearch(Table)
const PriceWrapper = props => {
  return <div>₦{props.value}</div>
}

const headers = [
  { value: 'name', text: 'Plan', align: 'left' },
  { value: 'duration', text: 'Duration' },
  { value: 'amount', text: 'Price', wrapper: PriceWrapper }
]

const PaymentPlans = props => {
  const { paymentPlans, error, success, role, roles } = props
  const [selectedPlans, setSelectedPlans] = useState([])
  const [currentActionPlan, setCurrentActionPlan] = useState()
  const [loading, setLoading] = useState(false)
  const [plansAlert, setPlansAlert] = useState({
    show: false,
    message: 'Selected plan(s) deleted successfully'
  })
  const [planForm, setPlanForm] = useState({ show: false, type: 'Create' })
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [snackbar, setSnackbar] = useState({ message: '', type: 'error' })

  const mounted = useRef(false)
  useEffect(() => {
    if (!mounted.current) {
      props.fetchPaymentPlans()
      mounted.current = true
    } else {
      if (error.id) {
        const message =
          typeof error.msg === 'object' ? error.msg.join('<br/>') : error.msg
        let alert_ = { type: 'error', show: true, message }
        if (error.id === 'DELETE_PAYMENT_PLAN_FAILURE') {
          setPlansAlert({ ...alert_ })
          props.clearErrors()
        } else if (error.id === 'DELETE_PAYMENT_PLANS_FAILURE') {
          const message =
            typeof error.msg === 'object' ? error.msg.join('<br/>') : error.msg
          setPlansAlert({ ...alert_ })
          props.clearErrors()
        } else if (error.id === 'ADD_PAYMENT_PLAN_FAILURE') {
          const message =
            typeof error.msg === 'object' ? error.msg.join('<br/>') : error.msg
          setPlansAlert({ ...alert_ })
          props.clearErrors()
        } else if (error.id === 'EDIT_PAYMENT_PLAN_FAILURE') {
          const message =
            typeof error.msg === 'object' ? error.msg.join('<br/>') : error.msg
          setPlansAlert({ ...alert_ })
          props.clearErrors()
        }
        props.clearErrors()
      } else if (success.id) {
        let alert_ = { type: 'success', show: true, message: success.msg }
        if (success.id === 'DELETE_PAYMENT_PLAN_SUCCESS') {
          setPlansAlert({ ...alert_ })
          props.clearSuccess()
        } else if (success.id === 'DELETE_PAYMENT_PLANS_SUCCESS') {
          setPlansAlert({
            ...alert_
          })
          props.clearSuccess()
        } else if (success.id === 'ADD_PAYMENT_PLAN_SUCCESS') {
          setPlansAlert({
            ...alert_
          })
          props.clearSuccess()
        } else if (success.id === 'EDIT_PAYMENT_PLAN_SUCCESS') {
          setPlansAlert({
            ...alert_
          })
          props.clearSuccess()
        }
        props.clearSuccess()
      }
    }
  })

  const deleteSelectedPlans = () => {
    if (selectedPlans.length < 1) {
      setSnackbar({ message: 'No plan was selected', type: 'error' })
      return
    } else if (selectedPlans.length == 1) {
      props.deletePaymentPlan(selectedPlans[0]._id)
    } else {
      props.deletePaymentPlans(selectedPlans.map(plan_ => plan_._id))
    }
  }

  return (
    <div className='page-padding'>
      <DeleteDialog
        show={deleteDialog}
        onCancel={() => {
          setDeleteDialog(false)
        }}
        onContinue={() => {
          setDeleteDialog(false)
          deleteSelectedPlans()
        }}
        onClose={() => {
          setDeleteDialog(false)
        }}
        title='Delete Class'
        message={`Are you sure you want to delete the payment plan${
          selectedPlans.length > 1 ? 's' : ''
        }?`}
      />
      <Snackbar
        snack={snackbar}
        reset={() => {
          setSnackbar({ message: '' })
        }}
      />
      <div id='plans' className='d-flex justify-content-between'>
        <h3 className='mb-3 title-text'>Payment Plans: Student</h3>
        <div>
          <Modal
            maxWidth='550px'
            show={planForm.show}
            onClose={() => {
              setPlanForm({ ...planForm, show: false })
            }}
          >
            <PlanForm
              onComplete={val => {
                setPlanForm({ ...planForm, show: false })
              }}
              onSetSnackbar={val => {
                setSnackbar(val)
              }}
              type={planForm.type}
              plan={currentActionPlan}
            />
          </Modal>

          {show(2, role, roles) && (
            <ActionButton
              onClick={() => {
                setPlanForm({ type: 'Create', show: true })
              }}
              className='mr-4 mb-2'
            />
          )}
          {show(2, role, roles) && (
            <ActionButton
              delete
              onClick={() => {
                setDeleteDialog(true)
              }}
              className='mr-3'
            />
          )}
        </div>
      </div>
      <div className='d-flex justify-content-center'>
        {plansAlert.show && (
          <Alert
            type={plansAlert.type}
            message={plansAlert.message}
            style={{ maxWidth: 500, flexGrow: 1 }}
            className='mt-3 mb-3'
            onClose={() => {
              setPlansAlert({
                show: false,
                message: ''
              })
            }}
          />
        )}
      </div>

      <Filter
        filters={[
          { value: 'all', text: 'All' },
          { value: 'name', text: 'Name', searchValue: 'name' }
        ]}
        items={paymentPlans}
        loading={loading}
        headers={headers}
        selectable={show(2,role,roles)}
        action={<Edit style={{ width: 20 }} />}
        onItemsSelected={val => {
          setSelectedPlans(val)
        }}
        onActionClicked={item => {
          setCurrentActionPlan(item)
          setPlanForm({ type: 'Edit', show: true, plan_: item })
        }}
        hideFilter
      />
    </div>
  )
}

const mapStateToProps = state => ({
  paymentPlans: state.paymentPlans.paymentPlans.filter(
    plan => plan.category == '5fd08fba50964811309722d5'
  ),
  role: state.auth.user.role,
  roles: state.auth.roles,
  error: state.error,
  success: state.success
})
export default connect(mapStateToProps, {
  fetchPaymentPlans,
  deletePaymentPlan,
  deletePaymentPlans,
  clearErrors,
  clearSuccess
})(PaymentPlans)
