import API from '../../assets/js/api'
import { returnErrors } from './errorActions'
import { returnSuccess } from './successActions'

import {
  GET_BLOG_ARTICLE_CATEGORIES_FAILURE,
  GET_BLOG_ARTICLE_CATEGORIES_SUCCESS,
  DELETE_BLOG_ARTICLE_CATEGORIES_FAILURE,
  DELETE_BLOG_ARTICLE_CATEGORIES_SUCCESS,
  DELETE_BLOG_ARTICLE_CATEGORY_FAILURE,
  DELETE_BLOG_ARTICLE_CATEGORY_SUCCESS,
  ADD_BLOG_ARTICLE_CATEGORY_SUCCESS,
  ADD_BLOG_ARTICLE_CATEGORY_FAILURE,
  EDIT_BLOG_ARTICLE_CATEGORY_SUCCESS,
  EDIT_BLOG_ARTICLE_CATEGORY_FAILURE,
  INPUT_CHANGE
} from './types'

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  )
}
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id))
}

export const inputChange = (name, value) => dispatch => {
  dispatch({ type: INPUT_CHANGE, payload: { name, value } })
}

export const fetchBlogArticleCategories = (loader = true) => async dispatch => {
  try {
    if (loader) document.body.classList.add('loading-indicator')
    const result = await API.getBlogArticleCategories()

    dispatch({
      type: GET_BLOG_ARTICLE_CATEGORIES_SUCCESS,
      payload: result.data.data
    })
    if (loader) document.body.classList.remove('loading-indicator')
  } catch (err) {
    if (loader) document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, GET_BLOG_ARTICLE_CATEGORIES_FAILURE)
  }
}

export const addBlogArticleCategory = (data, loader = true) => async (
  dispatch,
  getState
) => {
  try {
    if (loader) document.body.classList.add('loading-indicator')
    const user = getState().auth.user
    const result = await API.addBlogArticleCategory({
      ...data,
      creatorId: user._id
    })

    dispatch({
      type: ADD_BLOG_ARTICLE_CATEGORY_SUCCESS,
      payload: result.data.data
    })
    dispatchSuccess(
      dispatch,
      `BlogArticle category "${data.name}" successfully created`,
      ADD_BLOG_ARTICLE_CATEGORY_SUCCESS
    )
    if (loader) document.body.classList.remove('loading-indicator')
  } catch (err) {
    if (loader) document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, ADD_BLOG_ARTICLE_CATEGORY_FAILURE)
  }
}
export const editBlogArticleCategory = (
  name,
  update,
  blogArticleCategoryId
) => async (dispatch, getState) => {
  try {
    document.body.classList.add('loading-indicator')
    const user = getState().auth.user
    const result = await API.editBlogArticleCategory(
      update,
      blogArticleCategoryId
    )

    dispatch({
      type: EDIT_BLOG_ARTICLE_CATEGORY_SUCCESS,
      payload: result.data.data
    })
    dispatchSuccess(
      dispatch,
      `BlogArticle category "${name}" successfully edited`,
      EDIT_BLOG_ARTICLE_CATEGORY_SUCCESS
    )
    document.body.classList.remove('loading-indicator')
  } catch (err) {
    document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, EDIT_BLOG_ARTICLE_CATEGORY_FAILURE)
  }
}

export const deleteBlogArticleCategory = blogArticleCategoryId => async dispatch => {
  try {
    document.body.classList.add('loading-indicator')
    const result = await API.deleteBlogArticleCategory(blogArticleCategoryId)

    dispatch({
      type: DELETE_BLOG_ARTICLE_CATEGORY_SUCCESS,
      payload: blogArticleCategoryId
    })
    dispatchSuccess(
      dispatch,
      result.data.data.message,
      DELETE_BLOG_ARTICLE_CATEGORY_SUCCESS
    )
    document.body.classList.remove('loading-indicator')
  } catch (err) {
    document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, DELETE_BLOG_ARTICLE_CATEGORY_FAILURE)
  }
}

export const deleteBlogArticleCategories = categoryIds => async dispatch => {
  try {
    document.body.classList.add('loading-indicator')
    const result = await API.deleteBlogArticleCategories({
      categoryIds
    })

    dispatch({
      type: DELETE_BLOG_ARTICLE_CATEGORIES_SUCCESS,
      payload: { categoryIds }
    })
    dispatchSuccess(
      dispatch,
      result.data.data.message,
      DELETE_BLOG_ARTICLE_CATEGORIES_SUCCESS
    )
    document.body.classList.remove('loading-indicator')
  } catch (err) {
    document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, DELETE_BLOG_ARTICLE_CATEGORIES_FAILURE)
  }
}
