import {
  GET_SUBJECTS_SUCCESS,
  DELETE_SUBJECTS_SUCCESS,
  DELETE_SUBJECT_SUCCESS,
  ADD_SUBJECT_SUCCESS,
  EDIT_SUBJECT_SUCCESS,
  INPUT_CHANGE,
  OBJECT_PROP_INPUT_CHANGE,
  INITIALIZE_STATE,
} from "../actions/types";

const initialState = {
  subjects: [],
  name: "",
  introText: "",
  media: "",
  classification: "",
  creatorName: "",
};

const subjectsReducer = (state = initialState, action) => {
  let arr;
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case INITIALIZE_STATE:
      return { ...state, ...action.payload };
    case OBJECT_PROP_INPUT_CHANGE:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          [action.payload.propName]: action.payload.value,
        },
      };
    case GET_SUBJECTS_SUCCESS:
      return {
        ...state,
        subjects: action.payload.subjects,
      };
    case DELETE_SUBJECT_SUCCESS:
      arr = state.subjects.slice();
      arr = arr.filter((subject) => subject._id !== action.payload);
      return {
        ...state,
        subjects: arr,
      };
    case DELETE_SUBJECTS_SUCCESS:
      return {
        ...state,
        subjects: state.subjects.filter(
          (subject) => !action.payload.subjectIds.includes(subject._id)
        ),
      };
    case ADD_SUBJECT_SUCCESS:
      return {
        ...state,
        subjects: [
          ...state.subjects,
          {
            ...action.payload.subject,
            creatorId: {
              firstName: action.payload.user.firstName,
              lastName: action.payload.user.lastName,
              _id: action.payload.user._id,
            },
            creatorName: `${action.payload.user.firstName} ${action.payload.user.lastName}`,
          },
        ],
      };
    case EDIT_SUBJECT_SUCCESS:
      arr = state.subjects.slice();
      let ind = arr.findIndex(
        (subject) => subject._id === action.payload.subject._id
      );
      arr[ind] = {
        ...action.payload.subject,
        creatorId: {
          _id: action.payload.subject.creatorId,
          firstName: action.payload.user.firstName,
        },
      };
      return {
        ...state,
        subjects: arr,
      };
    default:
      return state;
  }
};

export default subjectsReducer;
