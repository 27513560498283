import React, { useState, useEffect, useRef } from "react";
import SunEditor from "suneditor-react";
import { useHistory } from "react-router-dom";
import "suneditor/dist/css/suneditor.min.css";
import Button from "../../includes/button/button.component";
import SelectField from "../../includes/select-field/select.component";
import TextField from "../../includes/text-field/textField.component";
import { ReactComponent as Play } from "../../../assets/icons/Play.svg";
import Modal from "../../includes/modal/modal.component";
import FileUploader from "../../includes/file-uploader/fileUploader.component";
import { ReactComponent as Plus } from "../../../assets/icons/Plus.svg";
import { connect } from "react-redux";
import {
  addClassContent,
  inputChange,
  initializeState,
} from "../../../redux/actions/classContentsActions";
import { fetchCourses } from "../../../redux/actions/coursesActions";
import { fetchTerms } from "../../../redux/actions/termsActions";
import { fetchAssignedSubjects } from "../../../redux/actions/assignSubjectActions";
import { clearErrors } from "../../../redux/actions/errorActions";
import { clearSuccess } from "../../../redux/actions/successActions";
import Snackbar from "../../includes/snackbar/snackbar.component";
import { ReactComponent as Times } from "../../../assets/icons/Times.svg";
import { deleteTags } from "../../../assets/js/globalFunctions";
import parse from "html-react-parser";
import ReactPlayer from "react-player/lazy";

import "./css/style.css";

const btnList = [
  ["undo", "redo"],
  ["font", "fontSize", "formatBlock"],
  ["bold", "underline", "italic", "strike", "subscript", "superscript"],
  ["removeFormat"],
  "/",
  ["fontColor", "hiliteColor"],
  ["outdent", "indent"],
  ["align", "horizontalRule", "list", "table"],
  ["link", "image"],
  ["fullScreen", "showBlocks", "codeView"],
];

const ClassContentCreation = (props) => {
  const {
    success,
    error,
    terms,
    term,
    courses,
    course,
    assignedSubjects,
    subject,
    videoLessons,
    title,
  } = props;
  const [addVideoModal, setAddVideoModal] = useState(false);
  const [isUpload, setIsUpload] = useState(true);
  const [snackbar, setSnackbar] = useState({ message: "" });
  const [content, setContent] = useState("");
  const [fetchingSubjects, setFetchingSubjects] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [newContent, setNewContent] = useState("");
  const [newCourse, setNewCourse] = useState("");
  const [newSubject, setNewSubject] = useState("");
  const [newTerm, setNewTerm] = useState("");
  const [newVideoUrls, setNewVideoUrls] = useState([]);

  const [newVideoFile, setNewVideoFile] = useState("");
  const [newVideoTranscript, setNewVideoTranscript] = useState("");

  const mounted = useRef(false);
  const initialized = useRef(false);
  const contentImagesRef = useRef([]);
  const history = useHistory();

  useEffect(() => {
    if (!mounted.current) {
      const init = {
        term: {},
        course: {},
        subject: {},
        title: "",
        content: "",
        videoLessons: [{ transcript: "", video: null, audio: null }],
      };
      props.initializeState(init);
      props.fetchCourses();
      props.fetchTerms();
      mounted.current = true;
    } else {
      if (success.id) {
        const snack = {
          message:
            typeof success.msg === "object"
              ? success.msg.join("<br/>")
              : success.msg,
          type: "success",
        };

        switch (success.id) {
          case "INITIALIZE_STATE_SUCCESS":
            // initialized.current = true;
            break;
          case "ADD_CLASS_CONTENT_SUCCESS":
            setContent("");
            setSnackbar({ ...snack });
            break;
          case "GET_ASSIGNED_SUBJECTS_SUCCESS":
            setFetchingSubjects(false);
        }
        props.clearSuccess();
      } else if (error.id) {
        const snack = {
          message:
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg,
          type: "error",
        };

        switch (error.id) {
          case "ADD_CLASS_CONTENT_FAILURE":
            setSnackbar(snack);
            break;
        }
        props.clearErrors();
      }
    }
  });

  useEffect(async () => {
    if (newCourse._id) {
      setFetchingSubjects(true);
      props.fetchAssignedSubjects(newCourse);
      props.inputChange("subject", {});
    }
  }, [newCourse]);

  const processVideo = (file, index) => {
    let video = document.createElement("VIDEO");
    let thumbnail = document.createElement("CANVAS");
    var ctx = thumbnail.getContext("2d");
    var duration = 0;

    video.src = URL.createObjectURL(file);
    video.load();
    video.onloadedmetadata = function (e) {
      duration = video.duration;
      thumbnail.width = video.videoWidth;
      thumbnail.height = video.videoHeight;
      video.currentTime = Math.ceil(duration / 2);
      video.ontimeupdate = async function () {
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        let url = thumbnail.toDataURL();
        let arr = videoLessons.slice();
        arr[index].video = { preview: url, file };

        handleInput({ target: { name: "videoLessons", value: arr } });
      };
    };
  };

  const handleInput = (e) => {
    props.inputChange(e.target.name, e.target.value);
  };

  const submit = () => {
    let message;
    if (!newTitle) message = "Topic cannot be empty";
    else if (!deleteTags(newContent)) message = "Note cannot be empty";
    else if (!newCourse._id) message = "Please select a class";
    else if (!newSubject.assignedId) message = "Please select a subject";
    else if (!newTerm._id) message = "Please select a term";
    else if (newVideoUrls.filter((l) => !l.videoUrl).length)
      message = "Cannot submit video lesson without video";

    if (message) {
      setSnackbar({ message, type: "error" });
      return;
    }

    props.addClassContent({
      courseId: newCourse._id,
      termId: newTerm._id,
      subjectId: newSubject.assignedId,
      title: newTitle,
      content: newContent,
      videoLessons: newVideoUrls,
      key: `${getHyphenated(newCourse.name)}/${getHyphenated(
        newSubject.name
      )}/${getHyphenated(newTerm.name)}/${getHyphenated(
        newTitle
      )}/video-lessons`,
      contentImages: contentImagesRef.current.map((cnt) => cnt.file),
      contentUrls: contentImagesRef.current.map((cnt) => cnt.src),
    });
  };

  const randomString = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const getHyphenated = (string) => {
    return string?.toLowerCase().trim().replace(/ /g, "-");
  };

  const handleImageUpload = (targetImgElement, index, state, imageInfo) => {
    if (imageInfo?.src?.includes("http://")) return;
    if (!imageInfo?.src) return;
    var blobBin = imageInfo?.src && atob(imageInfo?.src?.split(",")[1]);
    var array = [];
    for (var i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)], { type: "image/jpg" });
    contentImagesRef.current = [
      ...contentImagesRef.current,
      { file: file, src: imageInfo.src },
    ];
  };

  return (
    <div id="content-creation" className="page-padding">
      <Snackbar
        snack={snackbar}
        reset={() => {
          setSnackbar({ message: "" });
        }}
      />
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          borderLeft: "5px solid var(--primary-color)",
          paddingLeft: "8px",
        }}
      >
        <h4 style={{ margin: "0", flexGrow: 1 }} className="title-text">
          Class Note
        </h4>
        <Button onClick={submit}>Save</Button>
      </div>
      <div className="row mt-5">
        <div className="col-12 col-lg-8">
          <TextField
            placeholder="Topic"
            name="title"
            value={newTitle}
            onInput={(e) => setNewTitle(e.target.value)}
            plain
            className="mb-4"
          />
          <div className="note-markup">
            {/* {initialized.current && ( */}
            <SunEditor
              setOptions={{
                height: 400,
                buttonList: btnList,
                toolbarContainer: "#toolbar-container",
              }}
              onChange={(cont) => {
                setNewContent(cont);
              }}
              onImageUpload={handleImageUpload}
              // setContents={newContent}
            />
            {/* )} */}
          </div>
        </div>
        <div className="col-12 col-lg-4 mt-4 mt-lg-0">
          <div
            style={{
              backgroundColor: "white",
              padding: "20px 0 60px 0",
              borderRadius: "10px",
            }}
          >
            <h4 className="pl-4">Categories</h4>
            <hr />
            <div className="pl-4 pr-4">
              <div className="mb-2">Select Class</div>
              <SelectField
                value={newCourse}
                onSelect={(e) => setNewCourse(e.target.value)}
                name="course"
                items={courses}
                outlined
                valueField="_id"
                textField="name"
                placeholder="Select Class"
                color="#B6B6B6"
                inputStyle={{ borderRadius: 5, paddingLeft: 15 }}
                className="mb-4"
              ></SelectField>
              <div className="mb-2">Select Subject</div>
              <SelectField
                value={newSubject}
                onSelect={(e) => setNewSubject(e.target.value)}
                items={assignedSubjects}
                name="subject"
                outlined
                valueField="_id"
                textField="name"
                placeholder="Select Subject"
                color="#B6B6B6"
                inputStyle={{ borderRadius: 5, paddingLeft: 15 }}
                className="mb-4"
                loading={fetchingSubjects}
              ></SelectField>
              <div className="mb-2">Select Term</div>
              <SelectField
                value={newTerm}
                onSelect={(e) => {
                  setNewTerm(e.target.value);
                }}
                name="term"
                items={terms}
                outlined
                placeholder="Select Term"
                color="#B6B6B6"
                textField="name"
                valueField="_id"
                selectStyle={{ borderRadius: 5, paddingLeft: 15 }}
                className="mb-4"
              ></SelectField>
            </div>
          </div>

          <Button
            color="var(--primary-color)"
            className="mt-4"
            style={{ padding: ".7em 1.4em", borderRadius: "10px" }}
            onClick={() => {
              document.getElementById("video-lessons").scrollIntoView();
            }}
          >
            <Play className="mr-2" />
            Attach Video Lessons
          </Button>
        </div>
      </div>

      <div className="pt-5" id="video-lessons">
        <h4
          style={{
            padding: "4px 0 4px 8px",
            borderLeft: "5px solid var(--primary-color)",
          }}
          className="title-text"
        >
          Video Lesson
        </h4>
        {newVideoUrls.map((lesson, index) => (
          <div
            key={randomString(10)}
            style={{ borderBottom: "1px solid #B6B6B6", paddingBottom: 35 }}
          >
            <p
              style={{
                color: "rgba(74,80,94,1)",
                fontSize: "110%",
                marginTop: 16,
                paddingLeft: 14,
              }}
              className="d-flex"
            >
              Video {index + 1}
              <button
                className="ml-auto d-flex align-items-center justify-content-center"
                style={{
                  border: "none",
                  backgroundColor: "var(--error-color)",
                  width: 30,
                  height: 30,
                  borderRadius: 5,
                }}
                onClick={() => {
                  let arr = newVideoUrls;
                  arr.splice(index, 1);
                  setNewVideoUrls((b) => [...arr]);
                }}
              >
                <Times />
              </button>
            </p>
            <p style={{ color: "rgba(74,80,94,1)", paddingLeft: 14 }}>
              Transcript
            </p>
            <div className="video-markup">
              {/* <div className="w-100 border ">{parse(lesson.transcript)}</div> */}
              <div className="transcript-preview px-3 py-2">
                {lesson.transcript && parse(lesson.transcript)}
              </div>
            </div>
            <div className="mt-3 d-flex align-items-start">
              {(!lesson.videoUrl && (
                <Button
                  color="var(--primary-color)"
                  style={{
                    fontSize: "90%",
                    fontFamily: "font3",
                    marginLeft: 25,
                  }}
                  rounded
                  onClick={(e) => {
                    // let elem = document.getElementById(`video${index + 1}`);
                    // elem.value = "";
                    // elem.click();
                    const existing = newVideoUrls[index];
                    // let arr = newVideoUrls;

                    // arr[index].transcript = content;
                    setNewVideoUrls((arr) =>
                      arr.splice(index, 1, {
                        ...existing,
                        video: e.target.files[0],
                      })
                    );
                  }}
                >
                  Upload Video
                </Button>
              )) || (
                <div style={{ maxWidth: 150, display: "inline-block" }}>
                  {/* <img
                    style={{ maxWidth: "100%", borderRadius: 5 }}
                    src={lesson.video.preview}
                  /> */}
                  {/* <iframe
                    width="100%"
                    height="140"
                    src={lesson.videoUrl}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe> */}
                  <ReactPlayer
                    // className="react-player"

                    url={lesson.videoUrl}
                    controls="true"
                    width="100%"
                    height="100px"
                    // muted={false}
                    // playing={true}
                    // ref={ref}
                  />
                  <div className="d-flex justify-content-between">
                    {/* <span
                      onClick={() => {
                        let elem = document.getElementById(`video${index + 1}`);
                        elem.click();
                      }}
                      className="pointer"
                      style={{
                        color: "var(--primary-color)",
                        textDecoration: "underline",
                        fontSize: "90%",
                      }}
                    >
                      Change
                    </span> */}
                    {/* <span
                      onClick={() => {
                        let arr = newVideoUrls.slice();
                        arr[index].video = null;
                        // handleInput({
                        //   target: { name: "newVideoUrls", value: arr },
                        // });
                        setNewVideoUrls((b) => [...arr]);
                      }}
                      className="pointer"
                      style={{
                        color: "red",
                        textDecoration: "underline",
                        fontSize: "90%",
                      }}
                    >
                      Remove
                    </span> */}
                  </div>
                </div>
              )}
              <input
                onInput={(e) => {
                  processVideo(e.target.files[0], index);
                }}
                id={`video${index + 1}`}
                style={{ display: "none" }}
                type="file"
                accept="video/*"
              />
              {/* <Button
                color="#4F4E4E"
                style={{ fontSize: "90%", fontFamily: "font3", marginLeft: 80 }}
                rounded
                outlined
              >
                Upload Audio
              </Button> */}
            </div>
          </div>
        ))}

        <button
          className="border-0 bg-transparent m-2"
          type="button"
          data-toggle="modal"
          data-target="#addNewVideo"
        >
          <Plus className="c-plus" />
          <span className="text-muted">Add Lesson</span>
        </button>
      </div>
      <Modal
        show={addVideoModal}
        onClose={() => {
          setAddVideoModal(false);
        }}
        maxWidth="80vw"
      >
        <h4>Upload or Select Media</h4>
        <div className="nav-container">
          <span
            className={`pointer ${isUpload ? "active" : ""}`}
            onClick={() => {
              setIsUpload(true);
            }}
          >
            Upload
          </span>
          <span
            className={`pointer ${!isUpload ? "active" : ""}`}
            onClick={() => {
              setIsUpload(false);
            }}
          >
            Media
          </span>
        </div>
        <FileUploader
          multiple
          onInput={(files) => {}}
          style={{ marginTop: 30 }}
          maxFiles={10}
          validTypes="video/mp4,video/mov"
        />
      </Modal>
      {/* Add new video */}
      <div
        class="modal fade"
        id="addNewVideo"
        tabindex="-1"
        role="dialog"
        aria-labelledby="addNewVideoLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Add New Lesson Video and Transcript
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const data = {};
                if (newVideoFile) {
                  data.videoUrl = newVideoFile;
                }
                if (newVideoTranscript) {
                  data.transcript = newVideoTranscript;
                }
                // arr.push(data);
                setNewVideoUrls((b) => [...b, data]);
                // setNewVideoTranscript(null);
                // setNewVideoFile(null);
              }}
            >
              <div class="modal-body">
                <div className="row">
                  <div className="col-5">
                    <div class="mb-3">
                      <label for="newVideo" class="form-label">
                        Upload Video
                      </label>
                      <input
                        class="form-control"
                        type="url"
                        id="newVideo"
                        // accept="video/*"
                        required
                        placeholder="Video link"
                        onChange={(e) => {
                          // const data = new FormData();
                          // data.append("thumbnailUrl", e.target.value);
                          setNewVideoFile(e.target.value);
                        }}
                      />
                    </div>
                    {newVideoFile && (
                      <button
                        className="btn btn-primary my-2"
                        onClick={() => setNewVideoFile(null)}
                      >
                        Delete Video
                      </button>
                    )}
                  </div>
                  {newVideoFile && (
                    <div className="col-7">
                      {/* <video
                        width="100%"
                        height="140"
                        controls
                        className="rounded rounded-4"
                      >
                        <source src={newVideoFile} type="video/mp4" />
                        <source src={newVideoFile} type="video/ogg" />
                        Your browser does not support the video tag.
                      </video> */}
                      <ReactPlayer
                        // className="react-player"

                        url={newVideoFile}
                        controls="true"
                        width="100%"
                        height="140px"
                        // muted={false}
                        // playing={true}
                        // ref={ref}
                      />
                      {/* <iframe
                        width="100%"
                        height="140"
                        src={newVideoFile}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe> */}
                    </div>
                  )}
                </div>
                <p>Transcript</p>
                <div className="video-markup">
                  {/* {initialized.current && ( */}
                  <SunEditor
                    setOptions={{
                      height: 300,
                      buttonList: [
                        ["undo", "redo"],
                        ["formatBlock", "font", "fontSize"],
                        [
                          "bold",
                          "italic",
                          "underline",
                          "fontColor",
                          "hiliteColor",
                        ],
                      ],
                      stickyToolbar: false,
                    }}
                    onChange={(text) => {
                      setNewVideoTranscript(text);
                    }}
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Add new Video
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Add new video */}
    </div>
  );
};
const mapStateToProps = (state) => ({
  term: state.classContents.term,
  terms: state.terms.terms,
  course: state.classContents.course,
  courses: state.courses.courses,
  subject: state.classContents.subject,
  assignedSubjects: state.assignSubject.assignedSubjects,
  content: state.classContents.content,
  videoLessons: state.classContents.videoLessons,
  title: state.classContents.title,
  contentImages: state.classContents.contentImages,
  error: state.error,
  success: state.success,
});
export default connect(mapStateToProps, {
  addClassContent,
  fetchAssignedSubjects,
  inputChange,
  fetchCourses,
  fetchTerms,
  initializeState,
  clearSuccess,
  clearErrors,
})(ClassContentCreation);
