import API from '../../assets/js/api'
import { returnErrors } from './errorActions'
import { returnSuccess } from './successActions'

import {
  GET_QUIZZES_FAILURE,
  GET_QUIZZES_SUCCESS,
  GET_QUIZ_FAILURE,
  GET_QUIZ_SUCCESS,
  DELETE_QUIZZES_FAILURE,
  DELETE_QUIZZES_SUCCESS,
  DELETE_QUIZ_FAILURE,
  DELETE_QUIZ_SUCCESS,
  ADD_QUIZ_SUCCESS,
  ADD_QUIZ_FAILURE,
  EDIT_QUIZ_SUCCESS,
  EDIT_QUIZ_FAILURE,
  QUIZ_INPUT_CHANGE,
  INITIALIZE_STATE,
  INITIALIZE_STATE_SUCCESS,
  CLEAR_QUIZ_STATE
} from './types'

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  )
}
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id))
}

export const inputChange = (name, value) => dispatch => {
  dispatch({ type: QUIZ_INPUT_CHANGE, payload: { name, value } })
}

export const clearState = () => dispatch => {
  dispatch({ type: CLEAR_QUIZ_STATE, payload: null })
}

export const initializeState = value => dispatch => {
  dispatch({ type: INITIALIZE_STATE, payload: value })
  dispatchSuccess(dispatch, '', INITIALIZE_STATE_SUCCESS)
}

export const fetchQuizzes = (params, loader = true) => async dispatch => {
  try {
    if (loader) document.body.classList.add('loading-indicator')
    const result = await API.getQuizzes(params)

    dispatch({ type: GET_QUIZZES_SUCCESS, payload: result.data.data })
    dispatchSuccess(dispatch, "", GET_QUIZZES_SUCCESS)
    if (loader) document.body.classList.remove('loading-indicator')
  } catch (err) {
    if (loader) document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, GET_QUIZZES_FAILURE)
  }
}

export const fetchQuiz = id => async dispatch => {
  try {
    document.body.classList.add('loading-indicator')
    const result = await API.getQuiz(id)

    dispatch({ type: GET_QUIZ_SUCCESS, payload: result.data.data })
    document.body.classList.remove('loading-indicator')
  } catch (err) {
    document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, GET_QUIZ_FAILURE)
  }
}

export const addQuiz = data => async (dispatch, getState) => {
  try {
    document.body.classList.add('loading-indicator')
    const user = getState().auth.user
    const formData = new FormData()
    formData.set('lessonId', data.lesson._id)
    formData.set('creatorId', user._id)
    formData.set('key', data.key)
    console.log(data.quizzes)
    let quizzes = data.quizzes.slice()
    quizzes.forEach((quiz, index) => {
      quiz.optionImages.forEach((img, ind) => {
        let newImg
        if (img) {
          newImg = new File(
            [img.file],
            `question${index + 1}-option${ind + 1}`,
            {
              type: img.file.type,
              lastModified: img.file.lastModified
            }
          )
          formData.append('images', newImg)
          img = { ...img, file: newImg }
        }
      })
      quiz.options = quiz.options.map(opt => opt.val)
    })
    formData.append(
      'questionsArray',
      JSON.stringify(
        quizzes.map(quiz => {
          return {
            ...quiz,
            optionImages: undefined,
            correct_option: quiz.correct_option.value
          }
        })
      )
    )

    const result = await API.addQuiz(formData)

    dispatchSuccess(dispatch, `Quiz successfully created`, ADD_QUIZ_SUCCESS)
    document.body.classList.remove('loading-indicator')
  } catch (err) {
    document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, ADD_QUIZ_FAILURE)
  }
}
export const updateQuiz = (data, quizId) => async (dispatch, getState) => {
  try {
    console.log('am here')
    document.body.classList.add('loading-indicator')
    const formData = new FormData()
    formData.set('lessonId', data.lesson._id)
    formData.set('key', data.key)
    console.log(data.quizzes) 
    const controlQuiz = getState().quizzes.controlQuiz

    let quizzes = data.quizzes.current.slice()
    quizzes.forEach((quiz, index) => {
      quiz.optionImages.forEach((img, ind) => {
        let newImg
        if (img && typeof img !== 'string') {
          newImg = new File(
            [img.file],
            `question${index + 1}-option${ind + 1}`,
            {
              type: img.file.type,
              lastModified: img.file.lastModified
            }
          )
          formData.append('images', newImg)
          img = { ...img, file: newImg }
        }
        if (
          controlQuiz.questions[index] &&
          controlQuiz.questions[index].images[ind] &&
          (!img || typeof img !== 'string')
        )
          formData.append(
            'deleteMedia',
            controlQuiz.questions[index].images[ind]
          )
      })
      // quiz.options = quiz.options.map(opt => opt.val)
    })
    formData.append(
      'questionsArray',
      JSON.stringify(
        quizzes.map((quiz, index) => {
          return {
            ...quiz,
            optionImages: quiz.optionImages.map((i, ind) => {
              if (typeof i === 'string' || !i) return i
              return `question${index}-option${ind}`
            }),
            correct_option: quiz.correct_option.value ===0? 0: (quiz.correct_option.value || quiz.correct_option)
          }
        })
      )
    )

    const result = await API.editQuiz(formData, quizId)

    dispatchSuccess(dispatch, `Quiz edited successfully`, EDIT_QUIZ_SUCCESS)
    document.body.classList.remove('loading-indicator')
  } catch (err) {
    document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, EDIT_QUIZ_FAILURE)
  }
}

export const deleteQuiz = (data, title) => async dispatch => {
  try {
    document.body.classList.add('loading-indicator')
    const result = await API.deleteQuiz(data)

    dispatch({ type: DELETE_QUIZ_SUCCESS, payload: data._id })
    dispatchSuccess(
      dispatch,
      `Quiz for lesson "${title}" deleted successfully`,
      DELETE_QUIZ_SUCCESS
    )
    document.body.classList.remove('loading-indicator')
  } catch (err) {
    document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, DELETE_QUIZ_FAILURE)
  }
}

export const deleteQuizzes = data => async dispatch => {
  try {
    document.body.classList.add('loading-indicator')
    const result = await API.deleteQuizzes(data)

    dispatch({
      type: DELETE_QUIZZES_SUCCESS,
      payload: { lessonIds: data.lessonIds }
    })
    dispatchSuccess(dispatch, result.data.data.message, DELETE_QUIZZES_SUCCESS)
    document.body.classList.remove('loading-indicator')
  } catch (err) {
    document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, DELETE_QUIZZES_FAILURE)
  }
}
