import { getByText } from '@testing-library/react'
import React, { useState, useEffect } from 'react'
import { ReactComponent as SelectArrow } from '../../../assets/icons/SelectArrow.svg'

import './css/style.css'

const SelectField = props => {
  const [showOptions, setShowOptions] = useState(false)

  const optionsRef = React.createRef()
  const inputRef = React.createRef()

  const toggleShowOptions = e => {
    if (props.readOnly) return
    if (showOptions) window.removeEventListener('click', evalClick)
    else {
      let ref = optionsRef.current
      ref.style.maxHeight = '0'
      let clientRect = e.target.getBoundingClientRect()
      let top = clientRect.top
      let left = clientRect.left
      setTimeout(() => {
        let height = ref.scrollHeight
        let width = ref.clientWidth
        let verticalSpace = window.innerHeight - top
        let horizontalSpace = window.innerWidth - left
        if (window.innerHeight - top < height + 20) {
          ref.style.top = `-${
            height < 300 ? height - verticalSpace + 20 : 320 - verticalSpace
          }px`
        }
        if (window.innerWidth - left < width + 35) {
          ref.style.left = `-${width - horizontalSpace + 35}px`
        }
        ref.style.maxHeight = '300px'
      }, 0)
    }
    // setTimeout(() => {
    setShowOptions(!showOptions)
    // }, 20)
  }
  const evalClick = e => {
    if (!showOptions) return
    if (
      optionsRef.current &&
      !optionsRef.current.contains(e.target) &&
      !inputRef.current.contains(e.target)
    ) {
      hideShowOptions()
    }
  }
  const hideShowOptions = () => {
    optionsRef.current.style.top = '0'
    optionsRef.current.style.left = '0'
    setShowOptions(false)
    window.removeEventListener('click', evalClick)
  }

  const handleBlur = () => {
    if (props.validator)
      props.validator.showMessageFor(props.validationData.for)
  }

  const checkSelected = item => {    
    let val = props.value
    if (!props.value) return false
    if (props.value.value) {
      return props.value.value === item.value
    } else {
      return props.value === item
    }
  }

  useEffect(() => {
    if (showOptions) window.addEventListener('click', evalClick)
  })

  const getText = item => {
    if ((!item && item !==0) || (typeof item === 'object' && !item[props.textField || 'text']))
      return ''
    if (typeof props.items[0] === 'object' && props.items[0] !== null) {
      const item_ = props.items.find(
        i =>
          i === item ||
          i[props.valueField || 'value'] ===
            item[props.valueField || 'value'] ||
          i[props.valueField || 'value'] === item
      )
      if (item_) {
        return item_[props.textField || 'text']
      } else return item.text || item
    } else return props.textField ? item[props.textField] : item.text || item
  }

  return (
    <div
      id='select'
      className={`${props.className}`}
      style={{ ...props.style }}
    >
      <input
        ref={inputRef}
        type='multiple'
        readOnly
        value={props.value? getText(props.value) : props.value === 0? getText(0):''}       
        style={{
          width: props.width || '100%',
          borderColor: props.outlined ? props.color || 'black' : '',
          backgroundColor: !props.outlined ? props.color || 'white' : 'inherit',
          height: props.height || 45
        }}
        onClick={toggleShowOptions}
        onBlur={handleBlur}
        placeholder={props.placeholder}
        className={`${
          props.outlined ? 'outlined' : !props.depressed ? 'general-shadow' : ''
        }`}
      />
      {!props.readOnly && (
        <SelectArrow
          onClick={toggleShowOptions}
          className='arrow'
          style={{ width: 10 }}
        />
      )}
      {
        <div
          className={`options ${!showOptions ? 'o-hide' : ''}`}
          ref={optionsRef}
        >
          {props.items.length > 0 &&
            !props.loading &&
            props.items.map((item, index) => (
              <button
                key={
                  item[props.valueField || 'value'] || typeof item === 'object'
                    ? index
                    : item
                }
                style={{ padding: 0, display: '', width: '100%' }}
                onClick={e => {
                  if (props.onSelect)
                    props.onSelect({
                      target: { value: item, name: props.name }
                    })
                  else {
                    alert('Missing prop: onSelect')
                    e.target.checked = false
                  }
                  hideShowOptions(false)
                }}
                className={`${checkSelected(item) ? 'selected' : ''}`}
              >
                {props.prepend && <props.prepend item={item}></props.prepend>}
                <span>{getText(item)}</span>
                {props.append && <props.append item={item}></props.append>}
              </button>
            ))}
          {props.items.length < 1 && !props.loading && (
            <div
              className='center'
              onClick={() => {
                hideShowOptions(false)
              }}
            >
              No data
            </div>
          )}
          {props.action && !props.loading && (
            <div
              onClick={() => {
                if (props.onActionClicked) props.onActionClicked()
                hideShowOptions(false)
              }}
            >
              {props.action}
            </div>
          )}
          {props.loading && (
            <div className='px-3 py-2 center'>
              <div className='loader-medium'></div>
            </div>
          )}
        </div>
      }
    </div>
  )
}
export default SelectField
