import React, { useEffect, useState, useRef } from "react";
import TextField from "../../includes/text-field/textField.component";
import SunEditor, { buttonList } from "suneditor-react";
import {
    inputChange,
    addSubject,
    editSubject,
} from "../../../redux/actions/subjectsActions";
import { connect } from "react-redux";
import Button from "../../includes/button/button.component";
import FileUploader from "../../includes/file-uploader/fileUploader.component";
import "./css/style.css";
import { nodeName } from "jquery";

const SubjectForm = (props) => {
    const { name, introText, media, creatorName } = props;
    const [loading, setLoading] = useState(true);
    const [imageStyle, setImageStyle] = useState("block");
    const [uploaderStyle, setUploaderStyle] = useState("none");

    useEffect(() => {
        if (props.type === "Edit" && props.subject.name !== name) {
            setLoading(true);
            props.inputChange("name", props.subject.name);
        } else if (props.type !== "Edit") {
            props.inputChange("name", "");
            props.inputChange("introText", "");
        }
    }, [props.type, props.subject]);

    const resetForm = () => {
        if (props.type === "Edit") {
            props.inputChange("name", props.subject.name);
            props.inputChange("introText", props.subject.introText);
            return;
        }
        props.inputChange("name", "");
        props.inputChange("introText", "")
    };

    const addSubject = async () => {
        let message;
        if (!name) message = "Subject Name is required";
        else if (!introText) message = "Summary is required";
        else if (!media) message = "Image is required";

        if (message) {
            props.onSetSnackbar({ message, type: "error" });
            return;
        }
        await props.addSubject({ name, introText, media, creatorName });
        fileUploaderRef.current.clearFile();
        resetForm();
        props.onComplete({ name });
    };

    const editSubject = async () => {
        let message;
        // if (
        //     name === props.subject.name &&
        //     introText === props.subject.introText
        // )
        //     message = "No changes made";
        // if (!name || !introText || !media) message = "Field cannot be empty";
        if (message) {
            props.onSetSnackbar({ message, type: "error" });
            return;
        }

        await props.editSubject(
            props.subject.name,
            { name, introText, media },
            props.subject._id
        );
        fileUploaderRef.current.clearFile();
        resetForm();
        secondImageUpdate();
        props.onComplete({ name });
    };

    const handleSubmit = () => {
        if (props.type === "Edit") {
            editSubject();
        } else {
            addSubject();
        }
    };

    const handleInput = (e) => {
        props.inputChange(e.target.name, e.target.value);
    };

    const imageUpdate = () => {
        setUploaderStyle("block");
        setImageStyle("none");
    }

    const secondImageUpdate = () => {
        setUploaderStyle("none");
        setImageStyle("block");
    }

    const fileUploaderRef = useRef();

    return (
        <div id="add-subject" className="page-padding">
            <h3 className="mb-3">{props.type} Subject Category</h3>
            <label htmlFor="subject-name">Subject Name</label><br />
            <TextField
                value={name}
                onInput={handleInput}
                name="name"
                outlined
            />

            <div className="row mt-5">
                <div className="col-sm-7">
                    <p>Subject Summary</p>
                    <div>
                        {(props.type == "Create") ?
                            <SunEditor
                                setOptions={{
                                    height: 200,
                                    buttonList: [
                                        ["undo", "redo"],
                                        ["formatBlock", "font", "fontSize"],
                                        ["bold", "italic", "underline", "fontColor", "hiliteColor"],
                                    ],
                                    stickyToolbar: false,
                                }}

                                onChange={(content) => {
                                    handleInput({ target: { name: "introText", value: content } });
                                }}

                                setContents={introText}
                            /> : ""
                        }

                        {(props.type == "Edit" && props.subject) ?
                            <SunEditor
                                setOptions={{
                                    height: 200,
                                    buttonList: [
                                        ["undo", "redo"],
                                        ["formatBlock", "font", "fontSize"],
                                        ["bold", "italic", "underline", "fontColor", "hiliteColor"],
                                    ],
                                    stickyToolbar: false,
                                }}

                                onChange={(content) => {
                                    handleInput({ target: { name: "introText", value: content } });
                                }}

                                setContents={props.subject.introText}
                            /> : ""
                        }
                    </div>

                    <div style={{ marginTop: 50, float: "right" }}>
                        <Button onClick={handleSubmit}>
                            Save
                        </Button>
                    </div>
                </div>
                <div className="col-sm-5">
                    <p>Upload Thumbnail</p>
                    {props.type == "Edit" ?
                        <div>
                            <p className={loading ? "loader-medium" : ""} style={{
                                margin: "0 auto",
                                display: "block",
                                marginBottom: 12
                            }}></p>
                            <div style={{ display: imageStyle }}>
                                <img className="mb-3 text-center" width="100%" style=
                                    {{
                                        border: "1px solid black",
                                        borderRadius: 8,
                                        padding: 20,
                                        maxHeight: "350px"
                                    }} src={props.subject.imageUrl} onLoad={() => setLoading(false)} />
                                <br />
                                <button
                                    style={{ float: "right" }}
                                    id="img-link"
                                    onClick={imageUpdate}>
                                    Change Image
                                    </button>
                            </div>
                            <div style={{ display: uploaderStyle }}>
                                <FileUploader
                                    ref={fileUploaderRef} onInput={handleInput} name="media"
                                />
                                <br />
                                <button
                                    style={{ float: "right" }}
                                    id="img-link"
                                    onClick={secondImageUpdate}>
                                    Show Current Image
                                    </button>
                            </div>
                        </div> : ""
                    }
                    {props.type == "Create" ?
                        <FileUploader
                            ref={fileUploaderRef} onInput={handleInput} name="media"
                        /> : ""
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    name: state.subjects.name,
    introText: state.subjects.introText,
    media: state.subjects.media,
    classification: state.subjects.classification
});
export default connect(mapStateToProps, { inputChange, addSubject, editSubject })(
    SubjectForm
);
