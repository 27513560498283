import React, { useRef, useEffect } from "react";
import "./css/style.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ResponsivePie } from "@nivo/pie";

import { linearGradientDef } from "@nivo/core";

import { clearErrors } from "../../../redux/actions/errorActions";
import { clearSuccess } from "../../../redux/actions/successActions";
import { inputChange as authInputChange } from "../../../redux/actions/authActions";
import {
  fetchCounts,
  getSignUpsByDate,
} from "../../../redux/actions/dashboardActions";
import { fetchAfrilearnUsers } from "../../../redux/actions/afrilearnUsersActions";
import { ReactComponent as Group } from "../../../assets/icons/Group.svg";
import { ReactComponent as Language } from "../../../assets/icons/Language.svg";
import { ReactComponent as BookStack } from "../../../assets/icons/BookStack.svg";
import { ReactComponent as Video } from "../../../assets/icons/Video.svg";
import { ReactComponent as QandA } from "../../../assets/icons/QandA.svg";
import { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import moment from "moment";

const numWithComma = (num) => {
  return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
};

const getLast30DaysFormat = () => {
  var n = new Date().setDate(new Date().getDate() - 30);
  var d = new Date(n).toISOString().split("-")[2].split("T")[0];
  var m = new Date(n).toISOString().split("-")[1];
  var y = new Date(n).toISOString().split("-")[0];
  return `${y}-${m}-${d}`;
};
const getYesterdayFormat = () => {
  var n = new Date().setDate(new Date().getDate() - 1);
  var d = new Date(n).toISOString().split("-")[2].split("T")[0];
  var m = new Date(n).toISOString().split("-")[1];
  var y = new Date(n).toISOString().split("-")[0];
  return `${y}-${m}-${d}`;
};
const getCurrentDateFormat = () => {
  var d = new Date().toISOString().split("-")[2].split("T")[0];
  var m = new Date().toISOString().split("-")[1];
  var y = new Date().toISOString().split("-")[0];
  return `${y}-${m}-${d}`;
};

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.$gtOrEqual = function (date) {
  let me = new Date(this.valueOf());
  let it = new Date(date);

  return (
    me.getFullYear() >= it.getFullYear() &&
    me.getMonth() >= it.getMonth() &&
    me.getDay() >= it.getDay()
  );
};

Date.prototype.$lt = function (date) {
  let me = new Date(this.valueOf());
  let it = new Date(date);

  return (
    // me.getFullYear() < it.getFullYear() &&
    // me.getMonth() < it.getMonth() &&
    me.getDay() < it.getDay()
  );
};

const Homepage = (props) => {
  const dispatch = useDispatch();
  const { counts, role } = props;
  const [modalisOpen, setModalisOpen] = useState(false);

  const [startDate, setStartdate] = useState("");
  const [endDate, setEndDate] = useState("");
  const signUps = useSelector((state) => state.dashboard.signUps);

  const [sortSignUps, setSortSignUps] = useState(getLast30DaysFormat());
  console.log("sortSignUps", sortSignUps);

  const signUpsData = [
    {
      id: "parents",
      label: "Parents",
      value: signUps.parents,
      color: "hsl(253, 63%, 60%)",
    },
    {
      id: "Students",
      label: "Students",
      value: signUps.students,
      color: "hsl(156, 63%, 41%)",
    },
    {
      id: "Teachers",
      label: "Teachers",
      value: signUps.teachers,
      color: "hsl(48, 100%, 57%)",
    },
    {
      id: "Schools",
      label: "Schools",
      value: signUps.schools,
      color: "hsl(240, 3%, 94%)",
    },
  ];
  // add a day
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      props.clearErrors();
    }
  });

  useEffect(() => {
    if (role) {
      props.authInputChange("redirect", false);
      props.fetchCounts();
    }
  }, [role]);

  useEffect(() => {
    if (sortSignUps) {
      if (typeof sortSignUps === "string") {
        dispatch(getSignUpsByDate(sortSignUps));
      }
      if (Array.isArray(sortSignUps)) {
        dispatch(getSignUpsByDate(sortSignUps[0], sortSignUps[1]));
      }
    }
  }, [sortSignUps]);

  const data = [
    {
      id: "Subscribed Users",
      value: counts.subscribedUsers,
    },
    {
      id: "Unsubscribed Users",
      value: counts.unsubscribedUsers,
    },
  ];

  const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
    let total = 0;
    dataWithArc.forEach((datum) => {
      total += datum.value;
    });

    return (
      <>
        <text
          x={centerX}
          y={centerY - 20}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          {numWithComma(total)}
        </text>
        <text
          x={centerX}
          y={centerY + 20}
          textAnchor="middle"
          dominantBaseline="central"
          style={{
            fontSize: "12px",
            fontWeight: "600",
            color: "rgba(0, 0, 0, 0.3)",
          }}
        >
          Total Subscriptions
        </text>
      </>
    );
  };
  const studentsCount = counts.afrilearnUsers.students;
  const teachersCount = counts.afrilearnUsers.teachers;
  const parentsCount = counts.afrilearnUsers.parents;
  const schoolsCount = counts.afrilearnUsers.schools;
  const totalCount =
    studentsCount + teachersCount + parentsCount + schoolsCount;
  const userTotal = counts.afrilearnUsers.total;

  const numWithComma = (num) => {
    return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  };

  const getStudentsWidth = () => {
    return `${(studentsCount / totalCount) * 100}%`;
  };

  const studentsWidth = getStudentsWidth();

  const getTeachersWidth = () => {
    return `${((studentsCount + teachersCount) / totalCount) * 100}%`;
  };

  const teachersWidth = getTeachersWidth();

  const getParentsWidth = () => {
    return `${
      ((studentsCount + teachersCount + parentsCount) / totalCount) * 100
    }%`;
  };

  const parentsWidth = getParentsWidth();

  return (
    <div id="dashboard" className="page-padding">
      {role && (
        <div>
          <div className="displays">
            <div className="classes">
              <p style={{ color: "white" }}>Classes</p>
              <h4 style={{ color: "white" }}>
                {counts.courses}{" "}
                <Group
                  style={{
                    width: 20,
                    marginLeft: 10,
                    transform: "translateY(-1px)",
                  }}
                />
              </h4>
              <div className="extra"></div>
            </div>
            <div className="subjects">
              <p style={{ color: "white" }}>Subjects</p>
              <h4 style={{ color: "white" }}>
                {counts.subjects}{" "}
                <Language
                  style={{
                    width: 20,
                    marginLeft: 10,
                    transform: "translateY(-1px)",
                  }}
                />
              </h4>
              <div className="extra"></div>
            </div>
            <div className="class-notes">
              <p style={{ color: "white" }}>Class Notes</p>
              <h4 style={{ color: "white" }}>
                {counts.lessons}{" "}
                <BookStack
                  style={{
                    width: 20,
                    marginLeft: 10,
                    transform: "translateY(-1px)",
                  }}
                />
              </h4>
              <div className="extra"></div>
            </div>
            <div className="lessons">
              <p style={{ color: "white" }}>Video & Audio Lessons</p>
              <h4 style={{ color: "white" }}>
                {counts.videos}{" "}
                <Video
                  style={{
                    width: 20,
                    marginLeft: 10,
                    transform: "translateY(-1px)",
                  }}
                />
              </h4>
              <div className="extra"></div>
            </div>
            <div className="quizzes">
              <p style={{ color: "white" }}>Quizzes</p>
              <h4 style={{ color: "white" }}>
                {counts.quizzes}{" "}
                <QandA
                  style={{
                    width: 20,
                    marginLeft: 10,
                    transform: "translateY(-1px)",
                  }}
                />
              </h4>
              <div className="extra"></div>
            </div>
          </div>
          <div className="mt-80-20">
            <h4 style={{ marginLeft: 30 }}>Stats</h4>
            <div
              className="data-display mt-md-4 mt-2 p-md-4 p-3 "
              style={{
                backgroundColor: "white",
                borderRadius: 20,
              }}
            >
              <div>
                <h5>Subscriptions</h5>
                <div
                  className="d-flex mt-4 flex-wrap"
                  style={{ marginLeft: -20, marginRight: -20 }}
                >
                  <div style={{ height: 200, width: 200 }}>
                    <ResponsivePie
                      data={data}
                      margin={{ top: 0, right: 10, bottom: 10, left: 10 }}
                      innerRadius={0.85}
                      padAngle={9}
                      cornerRadius={16}
                      borderWidth={0}
                      enableRadialLabels={false}
                      enableSliceLabels={false}
                      layers={[
                        "slices",
                        "sliceLabels",
                        "radialLabels",
                        "legends",
                        CenteredMetric,
                      ]}
                      defs={[
                        linearGradientDef("gradientA", [
                          { offset: 0, color: "rgba(5, 7, 8, 0.55)" },
                          { offset: 100, color: "rgba(32, 49, 59, 1)" },
                        ]),
                        linearGradientDef("gradientB", [
                          { offset: 0, color: "rgba(255, 212, 34, 1)" },
                          { offset: 100, color: "rgba(255, 125, 5, 1)" },
                        ]),
                      ]}
                      fill={[
                        {
                          match: { id: "Subscribed Users" },
                          id: "gradientA",
                        },
                        {
                          match: (d) => d.id === "Unsubscribed Users",
                          id: "gradientB",
                        },
                      ]}
                    />
                  </div>
                  <div style={{ margin: 10 }}>
                    <div className="d-flex align-items-start">
                      <div
                        style={{
                          color: "rgba(32,49,59,1)",
                          width: 30,
                          borderTop: "2px solid rgba(32,49,59,1)",
                          borderBottom: "2px solid rgba(32,49,59,1)",
                          borderRadius: 2,
                          marginTop: 9,
                        }}
                      ></div>
                      <div className="pl-2">
                        <div
                          style={{
                            fontSize: "90%",
                            color: "rgba(121, 120, 120, 1)",
                          }}
                        >
                          Active Subscriptions
                        </div>
                        <div>{numWithComma(data[0].value)}</div>
                      </div>
                    </div>
                    <div className="d-flex align-items-start mt-5">
                      <div
                        style={{
                          color: "rgba(32,49,59,1)",
                          width: 30,
                          borderTop: "2px solid rgba(255, 212, 34, 1)",
                          borderBottom: "2px solid rgba(255, 212, 34, 1)",
                          borderRadius: 2,
                          marginTop: 9,
                        }}
                      ></div>
                      <div className="pl-2">
                        <div
                          style={{
                            fontSize: "90%",
                            color: "rgba(121, 120, 120, 1)",
                          }}
                        >
                          Inactive Subscriptions
                        </div>
                        <div>{numWithComma(data[1].value)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <h5>Users</h5>
                {/* <h5>User per roles</h5> */}
                <div style={{ marginTop: 60 }}>
                  <h4 className="mb-3">
                    {numWithComma(userTotal)}{" "}
                    <span
                      style={{
                        fontSize: 13,
                        color: "rgba(121, 120, 120, 1)",
                        fontFamily: "font3",
                      }}
                    >
                      Total
                    </span>
                  </h4>
                  <div
                    style={{
                      width: "100%",
                      height: 13,
                      backgroundColor: "rgb(32, 49, 59)",
                      borderRadius: "999px",
                      position: "relative",
                    }}
                    className="linear-chart"
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        height: "100%",
                        width: studentsWidth,
                        backgroundColor: "rgb(120, 140, 182)",
                        borderRadius: "999px",
                        zIndex: 2,
                      }}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        height: "100%",
                        width: teachersWidth,
                        backgroundColor: "#ca2424",
                        borderRadius: "999px",
                        zIndex: 1,
                      }}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        height: "100%",
                        width: parentsWidth,
                        backgroundColor: "#ffd422",
                        borderRadius: "999px",
                      }}
                    ></div>
                  </div>

                  <div
                    className="d-flex mt-4 flex-wrap"
                    style={{ marginLeft: -10, marginRight: -10 }}
                  >
                    <Link
                      to={{
                        pathname: "/users/afrilearn",
                        state: { filterBy: "role", searchValue: "student" },
                      }}
                      style={{ color: "#212529" }}
                    >
                      <div
                        className="d-flex align-items-start"
                        style={{ margin: 10 }}
                      >
                        <div
                          style={{
                            color: "rgba(32,49,59,1)",
                            width: 30,
                            borderTop: "2px solid rgba(120, 140, 182, 1)",
                            borderBottom: "2px solid rgba(120, 140, 182, 1)",
                            borderRadius: 2,
                            marginTop: 9,
                          }}
                        ></div>
                        <div className="pl-2">
                          <div
                            style={{
                              fontSize: "90%",
                              color: "rgba(121, 120, 120, 1)",
                            }}
                          >
                            Students
                          </div>
                          <div>{numWithComma(studentsCount)}</div>
                        </div>
                      </div>
                    </Link>
                    <Link
                      to={{
                        pathname: "/users/afrilearn",
                        state: { filterBy: "role", searchValue: "teacher" },
                      }}
                      style={{ color: "#212529" }}
                    >
                      <div
                        className="d-flex align-items-start"
                        style={{ margin: 10 }}
                      >
                        <div
                          style={{
                            color: "#ca2424",
                            width: 30,
                            borderTop: "2px solid #ca2424",
                            borderBottom: "2px solid #ca2424",
                            borderRadius: 2,
                            marginTop: 9,
                          }}
                        ></div>
                        <div className="pl-2">
                          <div
                            style={{
                              fontSize: "90%",
                              color: "rgba(121, 120, 120, 1)",
                            }}
                          >
                            Teachers
                          </div>
                          <div>{numWithComma(teachersCount)}</div>
                        </div>
                      </div>
                    </Link>
                    <Link
                      to={{
                        pathname: "/users/afrilearn",
                        state: { filterBy: "role", searchValue: "parent" },
                      }}
                      style={{ color: "#212529" }}
                    >
                      <div
                        className="d-flex align-items-start"
                        style={{ margin: 10 }}
                      >
                        <div
                          style={{
                            color: "#ffd422",
                            width: 30,
                            borderTop: "2px solid #ffd422",
                            borderBottom: "2px solid #ffd422",
                            borderRadius: 2,
                            marginTop: 9,
                          }}
                        ></div>
                        <div className="pl-2">
                          <div
                            style={{
                              fontSize: "90%",
                              color: "rgba(121, 120, 120, 1)",
                            }}
                          >
                            Parents
                          </div>
                          <div>{numWithComma(parentsCount)}</div>
                        </div>
                      </div>
                    </Link>
                    <Link
                      to={{
                        pathname: "/users/afrilearn",
                        state: { filterBy: "role", searchValue: "school" },
                      }}
                      style={{ color: "#212529" }}
                    >
                      <div
                        className="d-flex align-items-start"
                        style={{ margin: 10 }}
                      >
                        <div
                          style={{
                            color: "rgb(32, 49, 59)",
                            width: 30,
                            borderTop: "2px solid rgb(32, 49, 59)",
                            borderBottom: "2px solid rgb(32, 49, 59)",
                            borderRadius: 2,
                            marginTop: 9,
                          }}
                        ></div>
                        <div className="pl-2">
                          <div
                            style={{
                              fontSize: "90%",
                              color: "rgba(121, 120, 120, 1)",
                            }}
                          >
                            Schools
                          </div>
                          <div>{numWithComma(schoolsCount)}</div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-80-20">
            <h4 className="ml-30-900">Growth Rate</h4>
            <div className="row">
              <div className="col-12 col-xl-8">
                <div
                  className=" mt-md-4 mt-2 p-md-4 p-3"
                  style={{
                    padding: "10px 30px 50px",
                    backgroundColor: "white",
                    borderRadius: 20,
                    height: 502,
                  }}
                >
                  <h6 className="bold-text">
                    Growth rate per user role (
                    <strong>
                      {signUps.schools +
                        signUps.students +
                        signUps.parents +
                        signUps.teachers}{" "}
                      Total
                    </strong>
                    )
                  </h6>
                  <div className="d-flex justify-content-start mt-3">
                    <select
                      name="duration"
                      id="duration"
                      className="form-select w-auto bold-text"
                      onChange={(e) => {
                        if (e.target.value === "custom") {
                          setModalisOpen(true);
                        } else {
                          setSortSignUps(e.target.value);
                        }
                      }}
                    >
                      <option value={getCurrentDateFormat()}>Today</option>
                      <option value={getYesterdayFormat()}>Yesterday</option>
                      <option value={getLast30DaysFormat()} selected>
                        30 Days
                      </option>
                      <option value="2018-01-01">Lifetime</option>
                      <option value="custom">
                        {startDate && endDate
                          ? moment(startDate).format("MMM Do YY") +
                            " - " +
                            moment(endDate).format("MMM Do YY")
                          : "Custom Period"}
                      </option>
                      {/* <option value="-1">custom</option> */}
                    </select>
                  </div>
                  <div className="ResponsivePie">
                    <ResponsivePie
                      data={signUpsData}
                      margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                      innerRadius={0.5}
                      padAngle={0.7}
                      cornerRadius={3}
                      activeOuterRadiusOffset={8}
                      borderWidth={1}
                      borderColor={{
                        from: "color",
                        modifiers: [["darker", 0.2]],
                      }}
                      arcLinkLabelsSkipAngle={10}
                      arcLinkLabelsTextColor="#333333"
                      arcLinkLabelsThickness={2}
                      arcLinkLabelsColor={{ from: "color" }}
                      arcLabelsSkipAngle={10}
                      arcLabelsTextColor={{
                        from: "color",
                        modifiers: [["darker", 2]],
                      }}
                      colors={{ datum: "data.color" }}
                    />
                  </div>
                  <div className="container">
                    <div className="row justify-content-between flex-wrap">
                      <div className="col-md-6 col-12">
                        <div className="rate-per-user-item parents">
                          Parents <strong>{signUps.parents}</strong> (
                          {Math.round(
                            (signUps.parents * 100) /
                              (signUps.parents +
                                signUps.students +
                                signUps.teachers +
                                signUps.schools)
                          )}
                          %)
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="rate-per-user-item students">
                          Students <strong>{signUps.students}</strong> (
                          {Math.round(
                            (signUps.students * 100) /
                              (signUps.parents +
                                signUps.students +
                                signUps.teachers +
                                signUps.schools)
                          )}
                          %)
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-between flex-wrap">
                      <div className="col-md-6 col-12 ">
                        <div className="rate-per-user-item teachers">
                          Teachers <strong>{signUps.teachers}</strong> (
                          {Math.round(
                            (signUps.teachers * 100) /
                              (signUps.parents +
                                signUps.students +
                                signUps.teachers +
                                signUps.schools)
                          )}
                          %)
                        </div>
                      </div>
                      <div className="col-md-6 col-12  ">
                        <div className="rate-per-user-item schools">
                          Schools <strong>{signUps.schools}</strong> (
                          {Math.round(
                            (signUps.schools * 100) /
                              (signUps.parents +
                                signUps.students +
                                signUps.teachers +
                                signUps.schools)
                          )}
                          %)
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {modalisOpen && (
        <div class="modal show" tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                console.log({ startDate, endDate });
                setSortSignUps([startDate, endDate]);
              }}
              class="modal-content"
            >
              <div class="modal-header">
                <h5 class="modal-title">Select Date Range</h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setModalisOpen(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div className="my-3">
                  <label htmlFor="startDate">Start Date</label>
                  <input
                    type="date"
                    id="startDate"
                    className="form-control"
                    required
                    onChange={(e) => {
                      setStartdate(e.target.value);
                    }}
                    defaultValue={startDate}
                    max={getCurrentDateFormat()}
                  />
                </div>
                <div className="my-3">
                  <label htmlFor="endDate">End Date</label>
                  <input
                    type="date"
                    id="endDate"
                    className="form-control"
                    required
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                    defaultValue={endDate}
                    min={getCurrentDateFormat()}
                  />
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-primary">
                  Submit
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  onClick={() => setModalisOpen(false)}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  location: state.auth.location,
  counts: state.dashboard.counts,
  role: state.auth.user.role,
});

export default connect(mapStateToProps, {
  clearErrors,
  clearSuccess,
  authInputChange,
  fetchCounts,
  fetchAfrilearnUsers,
})(Homepage);
