import React, { useState } from "react";
import "./css/style.css";

const TextArea = (props) => {
  const [focused, setFocused] = useState(false);
  const handleInput = (e) => {
    if (props.onInput) props.onInput(e);
  };

  const handleBlur = () => {
    setFocused(false);
    if (props.validator)
      props.validator.showMessageFor(props.validationData.for);
  };

  return (
    <div
      id="text-field"
      className={`${props.className || ""}`}
      style={{ ...props.style }}
    >
      <textarea
        value={props.value}
        onChange={handleInput}
        style={{
          width: props.width || "100%",
          height: props.height || "50px",
          borderColor: props.outlined ? props.color || "black" : "",
          backgroundColor: !props.outlined ? props.color || "white" : "inherit",
          padding: !props.icon
            ? "10px 15px 10px 15px"
            : props.iconLeft
            ? "10px 15px 10px 62px"
            : "10px 62px 10px 15px",
          ...props.inputStyle,
          height: props.height || 200,
        }}
        onFocus={() => setFocused(true)}
        onBlur={handleBlur}
        placeholder={props.placeholder}
        className={`${
          props.outlined ? "outlined" : !props.depressed ? "general-shadow" : ""
        } ${props.plain ? "plain" : ""}`}
        readOnly={props.readOnly}
        name={props.name}
      ></textarea>
      {(props.icon && (
        <button
          style={{
            left: props.iconLeft ? "25px" : "",
            right: !props.iconLeft ? "25px" : "",
          }}
          className={`icon${focused ? " focused" : ""}`}
        >
          {props.icon}
        </button>
      ))}
      {props.validationData && props.validator && (
        <div className="error-message">
          {props.validator.message(
            props.validationData.for,
            props.value,
            props.validationData.rules
          )}
        </div>
      )}
    </div>
  );
};

export default TextArea;
