import React from "react";
import "../AfrichallengeReport/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { getAfrichallengesWeeklyData } from "../../../redux/actions/dashboardActions";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { VectorMap } from "@south-paw/react-vector-maps";
import styled from "styled-components";
const mapCourses = [
  { _id: "5fc8cfbb81a55b4c3c19737d", name: "J1 Combat", rank: "a" },
  { _id: "5fd12c70e74b15663c5f4c6e", name: "J2 Clash", rank: "b" },
  { _id: "5fff5a67de0bdb47f826fea8", name: "J3 Champ", rank: "c" },
  { _id: "5fff5a7ede0bdb47f826fea9", name: "S1 Sprint", rank: "d" },
  { _id: "5fff5aaede0bdb47f826feaa", name: "S2 Whiz", rank: "e" },
  { _id: "5fff5abede0bdb47f826feab", name: "S3 Hero", rank: "f" },
];

export default function AfrichallengeReport() {
  const Map = styled.div`
    svg {
      stroke: #fff;

      // All layers are just path elements
      path {
        fill: #b6b6b6;
        cursor: pointer;
        outline: none;

        // When a layer is hovered
        &:hover {
          fill: #26aa76;
        }

        // When a layer is focused.
        &:focus {
          fill: #26aa76;
        }

        // When a layer is 'checked' (via checkedLayers prop).
        &[aria-checked="true"] {
          fill: #26aa76;
        }

        // When a layer is 'selected' (via currentLayers prop).
        &[aria-current="true"] {
          fill: #26aa76;
        }
      }
    }
  `;

  var first = new Date().getDate() - new Date().getDay();
  var last = first + 6;

  var firstday = new Date(new Date().setDate(first));
  var lastday = new Date(new Date().setDate(last));

  const afriChallengeWeeklyData = useSelector(
    (state) => state.dashboard.afriChallengeWeeklyData
  );

  const above0 = [];
  afriChallengeWeeklyData.nigeria.layers &&
    afriChallengeWeeklyData.nigeria.layers.filter((s) => {
      if (s.count > 0) {
        above0.push(s.id);
      }
    });

  const [startDate, setStartDate] = useState(firstday);
  const [endDate, setEndDate] = useState(lastday);
  const [hovered, setHovered] = useState("");
  const [hoveredValue, setHoveredValue] = useState("");

  const layerProps = {
    onMouseEnter: ({ target }) => {
      setHovered(target.attributes.name.value);
      setHoveredValue(target.attributes.count.value);
    },
    onMouseLeave: ({ target }) => {
      setHovered("");
      setHoveredValue(0);
    },
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAfrichallengesWeeklyData(startDate, endDate));
  }, [startDate, endDate]);

  let totalCoinsUsed = 0;
  let totalParticipants = 0;
  afriChallengeWeeklyData &&
    afriChallengeWeeklyData.results.forEach((i) => {
      totalCoinsUsed += i.entryFee * i.participants;
      totalParticipants += i.participants;
    });

  Date.prototype.getWeek = function () {
    var target = new Date(this.valueOf());
    var dayNr = (this.getDay() + 6) % 7;
    target.setDate(target.getDate() - dayNr + 3);
    var firstThursday = target.valueOf();
    target.setMonth(0, 1);
    if (target.getDay() != 4) {
      target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
    }
    return 1 + Math.ceil((firstThursday - target) / 604800000);
  };

  const getDateRangeOfWeek = (weekNo) => {
    var d1 = new Date();
    const numOfdaysPastSinceLastMonday = eval(d1.getDay() - 1);
    d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
    var weekNoToday = d1.getWeek();
    var weeksInTheFuture = eval(weekNo - weekNoToday);
    d1.setDate(d1.getDate() + eval(7 * weeksInTheFuture));
    var rangeIsFrom =
      eval(d1.getMonth() + 1) + "/" + d1.getDate() + "/" + d1.getFullYear();
    d1.setDate(d1.getDate() + 6);
    var rangeIsTo =
      eval(d1.getMonth() + 1) + "/" + d1.getDate() + "/" + d1.getFullYear();
    return { rangeIsFrom, rangeIsTo };
  };
  const ClassReportItem = ({ label, number, percent }) => {
    return (
      <>
        <div className="d-flex justify-content-between">
          <p>
            <b>{label}</b>
          </p>
          <p className="text-muted">
            {number} Participant{number > 1 ? "s" : ""}
          </p>
        </div>
        <div class="progress rounded-pill" style={{ height: 20 }}>
          <div
            class="progress-bar rounded-pill"
            role="progressbar"
            style={{ width: `${percent}%`, backgroundColor: "#26AA76" }}
            aria-valuenow={`${percent}`}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      </>
    );
  };
  const StateItem = ({ name, value }) => (
    <div className="d-flex justify-content-between my-3">
      <span>{name}</span>
      <span>{value}</span>
    </div>
  );

  const TableRowItem = ({ data, name }) => {
    if (!data.userId) return null;
    return (
      <tr>
        <td className="p-3 text-muted">
          {data.userId && data.userId.fullName}
        </td>
        <td className="p-3 text-muted">{data.userId && data.userId.email}</td>
        <td className="p-3 text-muted">{name}</td>
        <td className="p-3 text-muted">{data.winRatio}</td>
      </tr>
    );
  };
  const TableHeadItem = ({
    data = ["Name", "Email", "Challenge", "Score"],
  }) => {
    return (
      <tr>
        {data.map((item) => (
          <th scope="col" className="p-3 bb-0 bold">
            {item}
          </th>
        ))}
      </tr>
    );
  };

  const thereIsAwinner = afriChallengeWeeklyData.results.findIndex(
    (result) => result.winner
  );
  return (
    <div className="p-4 p-md-5">
      <h1 className="mb-4">Africhallenge</h1>
      <div className="d-flex align-items-center mt-2 mb-5">
        <span>Stats for</span>
        <input
          type="week"
          name="week"
          id="camp-week"
          defaultValue={`${new Date().getFullYear()}-W${new Date().getWeek()}`}
          required
          className="form-control w-auto mx-3"
          onChange={(e) => {
            const range = getDateRangeOfWeek(e.target.value.split("W")[1]);
            const from = new Date(range.rangeIsFrom);
            const to = new Date(range.rangeIsTo);
            setStartDate(from.setDate(from.getDate() - 1));
            setEndDate(to.setDate(to.getDate() - 1));
          }}
        />
      </div>

      <h5 className="my-3">This Week</h5>
      <div className="row ">
        <div className="col-lg-8 col-12 bg-white box-1 p-4">
          <div className="row">
            <div className="col-xl-6 col-12">
              <h5 className="mb-5">Total Participants </h5>
              <div className="total-participants p-3">
                <h5 className="text-white">Total Africhallenge Participants</h5>
                <h3 className="mt-4 text-white ">{totalParticipants}</h3>
              </div>
              <h5 className="mt-5 mb-4">Duration</h5>
              <p>Start Date: {moment(startDate).format("llll")}</p>
              <p>End Date: {moment(endDate).format("llll")}</p>
            </div>
            <div className="col-xl-6 col-12 pr-5">
              <h5 className="mb-5">Participants per class</h5>
              {afriChallengeWeeklyData.results.map((i) => (
                <ClassReportItem
                  label={i.name}
                  number={i.participants}
                  percent={(i.participants * 100) / totalParticipants}
                />
              ))}
              {afriChallengeWeeklyData.results.length === 0 &&
                mapCourses.map((i) => (
                  <ClassReportItem label={i.name} number={0} percent={0} />
                ))}
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-12 bg-white box-2 mx-lg-4 mt-lg-0 mt-2 p-3">
          <p>Total Coins Used</p>
          <p>
            <small>This week</small>
          </p>
          <p>
            <b>Amount of Africoins used by participants</b>
          </p>
          <h4>
            <b>&#8358;{totalCoinsUsed}</b>
          </h4>
        </div>
      </div>

      <h5 className="mb-3 mt-5">Winners This Week</h5>
      <div className="row">
        <div className="box-1 w-100 bg-white table-responsive">
          <table class="table table-hover p-3">
            <thead className="border-bottom-0">
              <TableHeadItem />
            </thead>
            <tbody>
              {afriChallengeWeeklyData.results.map((item) => {
                if (!item.winner) return null;
                return <TableRowItem data={item.winner} name={item.name} />;
              })}
            </tbody>
          </table>
          {thereIsAwinner === -1 && (
            <div className="card text-center dashed my-5 p-5 w-100">
              <p>Africhallenge Winners for this week will be listed here</p>
            </div>
          )}
        </div>
      </div>

      <h5 className="mb-3 mt-5">Participant’s Location Analytics</h5>
      <div className="box-1 w-100 bg-white table-responsive p-3 p-md-5">
        <div className="row ">
          <div className="col-lg-7 col-12">
            <p>
              <b>Users By State</b>
            </p>
            <div>
              <Map>
                <VectorMap
                  {...afriChallengeWeeklyData.nigeria}
                  layerProps={layerProps}
                  checkedLayers={above0}
                />
              </Map>
              {hovered && (
                <p>
                  {hovered}: {hovered && <code>{hoveredValue}</code>}
                </p>
              )}
            </div>
          </div>
          <div className="col-lg-5 col-12">
            <div className="d-flex justify-content-between border-bottom text-muted mt-5">
              <span>States</span>
              <span>Users</span>
            </div>
            {afriChallengeWeeklyData.usersPerState.map((item) => (
              <StateItem name={item.state} value={item.value} />
            ))}
            {afriChallengeWeeklyData.usersPerState.length === 0 && (
              <div className="card text-center dashed my-5 p-5 w-100">
                <p>
                  Africhallenge Participants for this week will be listed here
                  according to Location
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
