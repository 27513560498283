import React from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import "./css/style.css";
import photo from "../../../assets/icons/MaskGroup.png";
import { ReactComponent as Notification } from "../../../assets/icons/Notification.svg";
import { ReactComponent as Moon } from "../../../assets/icons/Moon.svg";
import { ReactComponent as Search } from "../../../assets/icons/Search.svg";
import { connect } from "react-redux";
import { logout } from "../../../redux/actions/authActions";
import { ReactComponent as Logout } from "../../../assets/icons/Login.svg";

import NavMenu from "../nav-menu/navMenu.component";
function convertDate(inputFormat) {
  function pad(s) {
    return s < 10 ? "0" + s : s;
  }
  var d = new Date(inputFormat);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
}

const roleColors = ["var(--error-bg)", "orange", "var(--primary-color)"];

const Toolbar = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { auth } = props;
  let role = auth.roles && auth.roles.find((r) => r._id === auth.user.role);
  let roleIndex =
    auth.roles && auth.roles.findIndex((r) => r._id === auth.user.role);
  return (
    location.pathname !== "/" && (
      <div
        id="toolbar"
        className="horizontal-padding right d-flex justify-content-between align-items-center"
      >
        <button
          class="btn btn-light show-900"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          <i className="fa fa-bars"></i>
        </button>
        <div
          class="offcanvas offcanvas-end navDark"
          tabindex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div class="offcanvas-header">
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
          <div class="offcanvas-body scroll-y">
            <div className="scroll-offcanvas">
              <NavMenu />
            </div>
          </div>
        </div>

        <div className="ml-auto">
          {/* <Search style={{ width: 22 }} />
          <Moon style={{ width: 22 }} className="ml-4" /> */}
          {auth.isAuthenticated && (
            <span style={{ position: "relative" }}>
              {/* <Notification style={{ width: 22 }} className="ml-4" /> */}
              <div
                className="user-profile pt-2 pb-2"
                style={{ display: "inline-block" }}
              >
                <img src={photo} />
                <span className="ml-1">
                  {auth.user.firstName}
                  <i
                    className="fa fa-chevron-down ml-2"
                    style={{ fontSize: 12 }}
                  ></i>
                </span>
              </div>
              <div
                className="left user-menu"
                style={{
                  position: "absolute",
                  right: 0,
                  marginTop: -5,
                  zIndex: "300",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    padding: 15,
                    backgroundColor: "white",
                    borderRadius: 10,
                    border: "1px solid var(--primary-color)",
                  }}
                  className="general-shadow"
                >
                  <div
                    style={{ fontWeight: "500" }}
                  >{`${auth.user.firstName} ${auth.user.lastName}`}</div>
                  <div
                    style={{
                      fontFamily: "font3",
                      color: "rgba(0,0,0,0.6)",
                      borderBottom: "1px solid rgba(0,0,0,0.1)",
                    }}
                    className="deco"
                  >
                    {auth.user.email}
                  </div>
                  <div
                    className="deco"
                    style={{ color: roleColors[roleIndex] }}
                  >
                    {role.name}
                  </div>
                  <div
                    className="deco mt-n2"
                    style={{ color: "rgba(0,0,0,0.5)" }}
                  >
                    Joined: {convertDate(auth.user.createdAt)}
                  </div>
                  <div className="mt-4">
                    <span
                      className="pointer"
                      onClick={() => {
                        props.logout();
                        history.push("/");
                      }}
                    >
                      <Logout
                        className="logout-icon mr-1"
                        style={{ width: 20 }}
                      />
                      Logout
                    </span>
                  </div>
                </div>
              </div>
            </span>
          )}
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logout })(Toolbar);
