import {
  GET_QUIZZES_SUCCESS,
  GET_QUIZ_SUCCESS,
  DELETE_QUIZ_SUCCESS,
  DELETE_QUIZZES_SUCCESS,
  ADD_QUIZ_SUCCESS,
  EDIT_QUIZ_SUCCESS,
  QUIZ_INPUT_CHANGE,
  OBJECT_PROP_INPUT_CHANGE,
  INITIALIZE_STATE,
  ARRAY_INPUT_CHANGE,
  CLEAR_QUIZ_STATE
} from "../actions/types";

const initialState = {
  quizzes: [],
  quiz: [
    {
      question: "",
      options: ["", "", "", ""],
      markdown: [false,false,false,false],
      optionImages: [null, null, null, null],
      correct_option: "",
      explanation: "",
      tOrF: false,
    },
  ],
  editQuiz: {},
  controlQuiz: {},
  course: {},
  subject: {},
  term: {},
  topic: "",
};

const quizzesReducer = (state = initialState, action) => {
  switch (action.type) {
    case QUIZ_INPUT_CHANGE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case INITIALIZE_STATE:
      return { ...state, ...action.payload };
    case CLEAR_QUIZ_STATE:
      return {
        ...state,
        quiz: JSON.parse(JSON.stringify([
          {
            question: "",
            options: ["", "", "", ""],
            markdown: [false,false,false,false],
            optionImages: [null, null, null, null],
            correct_option: "",
            explanation: "",
            tOrF: false,
          },
        ]))
      }
    case OBJECT_PROP_INPUT_CHANGE:
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          [action.payload.propName]: action.payload.value,
        },
      };
    case ARRAY_INPUT_CHANGE:
      let arr = state[action.payload.name].slice();
      arr[action.payload.index] = action.payload.value;
      return {
        ...state,
        [action.payload.name]: arr,
      };
    case GET_QUIZZES_SUCCESS:
      return {
        ...state,
        quizzes: action.payload.quizzes,
      };
    case GET_QUIZ_SUCCESS:
      return {
        ...state,
        editQuiz: action.payload.quiz,
        controlQuiz: JSON.parse(JSON.stringify(action.payload.quiz)),
      };
    case DELETE_QUIZ_SUCCESS:
      return {
        ...state,
        quizzes: state.quizzes.filter((quiz) => quiz._id !== action.payload),
      };
    case DELETE_QUIZZES_SUCCESS:
      return {
        ...state,
        quizzes: state.quizzes.filter(
          (lesson) => !action.payload.lessonIds.includes(lesson._id)
        ),
      };
    default:
      return state;
  }
};

export default quizzesReducer;
