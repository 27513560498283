import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import cmsUsersReducer from "./cmsUsersReducer";
import successReducer from "./successReducer";
import coursesReducer from "./coursesReducer";
import subjectsReducer from "./subjectsReducer";
import assignSubjectReducer from "./assignSubjectReducer";
import classContentsReducer from "./classContentsReducer";
import termsReducer from "./termsReducer";
import quizzesReducer from "./quizzesReducer";
import afrilearnUsersReducer from "./afrilearnUsersReducer";
import educationalCategoriesReducer from "./educationalCategoriesReducer";
import paymentPlansReducer from "./paymentPlansReducer";
import dashboardReducer from "./dashboardReducers";
import manualActivationReducer from "./manualActivationReducers";
import transactionsReducer from "./transactionsReducer";
import classesReducer from "./classesReducer";
import pastQuestionCategoriesReducer from "./pastQuestionTypesReducer";
import assignpastQuestionTypesReducer from "./assignPastQuestionTypeReducer";
import blogArticlesReducer from "./blogArticleReducer";
import blogArticleCategoriesReducer from "./blogArticleCategoriesReducer";
import customerStoriesReducer from "./impactStoryReducer";
import studentRequestReducer from "./studentRequestReducer";
import sendEmailReducer from "./sendEmailReducer";

export default combineReducers({
  error: errorReducer,
  success: successReducer,
  auth: authReducer,
  error: errorReducer,
  cmsUsers: cmsUsersReducer,
  courses: coursesReducer,
  studentRequests: studentRequestReducer,
  subjects: subjectsReducer,
  assignSubject: assignSubjectReducer,
  classContents: classContentsReducer,
  terms: termsReducer,
  quizzes: quizzesReducer,
  afrilearnUsers: afrilearnUsersReducer,
  educationalCategories: educationalCategoriesReducer,
  paymentPlans: paymentPlansReducer,
  dashboard: dashboardReducer,
  paymentPlans: paymentPlansReducer,
  manualActivation: manualActivationReducer,
  transactions: transactionsReducer,
  classes: classesReducer,
  pastQuestionCategories: pastQuestionCategoriesReducer,
  assignPastQuestionType: assignpastQuestionTypesReducer,
  blogArticles: blogArticlesReducer,
  blogArticleCategories: blogArticleCategoriesReducer,
  customerStories: customerStoriesReducer,
  sendEmail: sendEmailReducer,
});
