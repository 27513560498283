import API from '../../assets/js/api'
import { returnErrors } from './errorActions'
import { returnSuccess } from './successActions'
import { getKey } from '../../assets/js/globalFunctions'

import {
  ADD_ASSIGNED_SUBJECT_SUCCESS,
  ADD_ASSIGNED_SUBJECT_FAILURE,
  GET_ASSIGNED_SUBJECTS_SUCCESS,
  GET_ASSIGNED_SUBJECTS_FAILURE,
  DELETE_ASSIGNED_SUBJECT_SUCCESS,
  DELETE_ASSIGNED_SUBJECT_FAILURE,
  ASSIGN_SUBJECTS_INPUT_CHANGE,
  INITIALIZE_STATE
} from './types'

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  )
}
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id))
}

export const inputChange = (name, value) => dispatch => {
  dispatch({ type: ASSIGN_SUBJECTS_INPUT_CHANGE, payload: { name, value } })
}

export const initializeState = val => dispatch => {
  dispatch({ type: INITIALIZE_STATE, payload: val })
}

export const assignSubject = (course, subject, media) => async dispatch => {
  try {
    document.body.classList.add('loading-indicator')
    const formData = new FormData()
    formData.set('mainSubjectId', subject._id)
    formData.set('media', media)
    formData.set('key', getKey([course.name], subject.name))
    const result = await API.assignSubjectToCourse(formData, course._id)

    dispatch({ type: ADD_ASSIGNED_SUBJECT_SUCCESS, payload: result.data.data })
    dispatchSuccess(
      dispatch,
      `Subject "${subject.name}" successfully assigned to course "${course.name}"`,
      ADD_ASSIGNED_SUBJECT_SUCCESS
    )
    document.body.classList.remove('loading-indicator')
  } catch (err) {
    document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, ADD_ASSIGNED_SUBJECT_FAILURE)
  }
}

export const fetchAssignedSubjects = course => async dispatch => {
  try {
    const result = await API.getCourseAssignedSubjects(course._id)

    dispatch({
      type: GET_ASSIGNED_SUBJECTS_SUCCESS,
      payload: result.data.data
    })
    dispatchSuccess(dispatch, '', GET_ASSIGNED_SUBJECTS_SUCCESS)
  } catch (err) {
    dispatchError(dispatch, err, GET_ASSIGNED_SUBJECTS_FAILURE)
  }
}

export const deleteAssignedSubject = (course, subject) => async dispatch => {
  try {
    document.body.classList.add('loading-indicator')
    await API.deleteCourseAssignedSubject(course._id, subject._id, {
      deleteMedia: [subject.image]
    })

    document.body.classList.remove('loading-indicator')
    dispatch({
      type: DELETE_ASSIGNED_SUBJECT_SUCCESS,
      payload: { subjectId: subject._id }
    })
    dispatchSuccess(
      dispatch,
      `Subject "${subject.name}" successfully removed from course "${course.name}"`,
      DELETE_ASSIGNED_SUBJECT_SUCCESS
    )
  } catch (err) {
    document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, DELETE_ASSIGNED_SUBJECT_FAILURE)
  }
}
