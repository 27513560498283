import React, { useState } from "react";

import NavMenuLink from "./navMenuLink.component";
import { ReactComponent as ChevronUp } from "../../../assets/icons/ChevronUp.svg";

const NavMenuDropdown = (props) => {
  const [isActive, setIsActive] = useState(false);

  const toggleIsActive = () => {
    setIsActive(!isActive);
  };

  const links = props.links.map((link) => {
    return (
      <NavMenuLink
        name={link.name}
        path={link.path}
        sub={true}
        show={link.show}
        key={link.name}
      />
    );
  });
  return (
    <>
      {props.show && (
        <div>
          <div
            onClick={toggleIsActive}
            className={`dropdown${isActive ? " active" : ""}`}
          >
            <span className="icon-container">{props.icon}</span>
            <span>{props.name}</span>
            <ChevronUp className={`chevron ${isActive ? "active" : ""}`} />
          </div>
          <div className={`dropdown-items ${isActive ? "active" : ""}`}>
            {links}
          </div>
        </div>
      )}
    </>
  );
};

export default NavMenuDropdown;
