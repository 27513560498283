import API from "../../assets/js/api";
import { returnErrors } from "./errorActions";
import { returnSuccess } from "./successActions";

import {
  GET_PAYMENT_TRANSACTIONS_SUCCESS,
  GET_PAYMENT_TRANSACTIONS_FAILURE,
  DELETE_PAYMENT_TRANSACTIONS_SUCCESS,
  DELETE_PAYMENT_TRANSACTIONS_FAILURE,
  DELETE_PAYMENT_TRANSACTION_SUCCESS,
  DELETE_PAYMENT_TRANSACTION_FAILURE,
  GET_COINS_TRANSACTIONS_SUCCESS,
  GET_COINS_TRANSACTIONS_FAILURE,
  GET_COINS_TRANSACTIONS_BY_YEAR_SUCCESS,
  GET_COINS_TRANSACTIONS_BY_YEAR_FAILURE,
  GET_ALL_COINS_TRANSACTIONS_SUCCESS,
  GET_ALL_COINS_TRANSACTIONS_FAILURE,
} from "./types";

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  );
};
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id));
};

export const fetchAllCoinTransactions = () => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.getAllCoinTransactions();

    dispatch({
      type: GET_ALL_COINS_TRANSACTIONS_SUCCESS,
      payload: result.data.data,
    });
    dispatchSuccess(dispatch, "", GET_ALL_COINS_TRANSACTIONS_SUCCESS);
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_ALL_COINS_TRANSACTIONS_FAILURE);
  }
};
export const fetchPaymentTransactions = (data, type = "student") => async (
  dispatch
) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.getPaymentTransactions(data);

    dispatch({
      type: GET_PAYMENT_TRANSACTIONS_SUCCESS,
      payload: result.data.data,
    });
    dispatchSuccess(dispatch, "", GET_PAYMENT_TRANSACTIONS_SUCCESS);
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_PAYMENT_TRANSACTIONS_FAILURE);
  }
};
export const fetchCoinTransactions = (date, nextMonth) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.getCoinTransactions(date, nextMonth);

    dispatch({
      type: GET_COINS_TRANSACTIONS_SUCCESS,
      payload: result.data.data,
    });
    dispatchSuccess(dispatch, "", GET_COINS_TRANSACTIONS_SUCCESS);
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_COINS_TRANSACTIONS_FAILURE);
  }
};
export const fetchCoinTransactionsByYear = () => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.getCoinTransactionsByYear();

    dispatch({
      type: GET_COINS_TRANSACTIONS_BY_YEAR_SUCCESS,
      payload: result.data.data,
    });
    dispatchSuccess(dispatch, "", GET_COINS_TRANSACTIONS_BY_YEAR_SUCCESS);
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_COINS_TRANSACTIONS_BY_YEAR_FAILURE);
  }
};

export const deletePaymentTransaction = (trans, type = "student") => async (
  dispatch
) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deletePaymentTransaction(trans._id);

    dispatch({
      type: DELETE_PAYMENT_TRANSACTION_SUCCESS,
      payload: trans._id,
    });
    dispatchSuccess(
      dispatch,
      `Transaction for ${type} ${trans.userId.fullName} deleted successfully`,
      DELETE_PAYMENT_TRANSACTION_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_PAYMENT_TRANSACTION_FAILURE);
  }
};

export const deletePaymentTransactions = (
  transactionIds,
  type = "student"
) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deletePaymentTransactions({ transactionIds });

    dispatch({
      type: DELETE_PAYMENT_TRANSACTIONS_SUCCESS,
      payload: transactionIds,
    });
    dispatchSuccess(
      dispatch,
      "Payment transactions deleted successfully",
      DELETE_PAYMENT_TRANSACTIONS_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_PAYMENT_TRANSACTIONS_FAILURE);
  }
};
