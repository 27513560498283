import React, { useEffect, useState, useRef, forwardRef } from 'react'
import SelectField from '../../includes/select-field/select.component'
import { ReactComponent as Plus } from '../../../assets/icons/PlusDark.svg'
import Button from '../../includes/button/button.component'
import { connect } from 'react-redux'
import {
  inputChange,
  assignSubject,
  fetchAssignedSubjects,
  deleteAssignedSubject,
  initializeState
} from '../../../redux/actions/assignSubjectActions'
import { fetchCourses } from '../../../redux/actions/coursesActions'
import { fetchSubjects } from '../../../redux/actions/subjectsActions'
import { clearErrors } from '../../../redux/actions/errorActions'
import { clearSuccess } from '../../../redux/actions/successActions'

import FileUploader from '../../includes/file-uploader/fileUploader.component'
import Snackbar from '../../includes/snackbar/snackbar.component'

import AssignedSubject from './assignedSubject'
import { Redirect } from 'react-router-dom'
import { show } from '../../../assets/js/globalFunctions'

import './css/style.css'

const AssignSubject = props => {
  const {
    courses,
    subjects,
    subject,
    media,
    role,
    roles,
    success,
    error,
    assignedSubjects
  } = props
  const [fetchingAssigned, setFetchingAssigned] = useState(false)
  const [snack, setSnack] = useState({ message: '', type: 'error' })
  const mounted = useRef(false)
  const [course, setCourse] = useState({})
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    } else {
      if (success.id) {
        let id = success.id
        const snack = {
          message:
            typeof success.msg === 'object'
              ? success.msg.join('<br/>')
              : success.msg,
          type: 'success'
        }
        switch (id) {
          case 'ADD_ASSIGNED_SUBJECT_SUCCESS':
            setSnack(snack)
            fileUploaderRef.current.clearFile()
            props.clearSuccess()
            break
          case 'DELETE_ASSIGNED_SUBJECT_SUCCESS':
            setSnack(snack)
            props.clearSuccess()
            break
        }
        props.clearSuccess()
      } else if (error.id) {
        let id = error.id
        const snack = {
          message:
            typeof error.msg === 'object' ? error.msg.join('<br/>') : error.msg,
          type: 'error'
        }
        if (id === 'ADD_ASSIGNED_SUBJECT_FAILURE') {
          setSnack(snack)
        }
        props.clearErrors()
      }
    }
  })

  useEffect(() => {
    return () => {
      props.inputChange("assignedSubjects", [])

    }
  }, [])

  useEffect(() => {
    if (role) {
      props.fetchCourses()
      props.fetchSubjects()
      props.initializeState()
    }
  }, [role])

  useEffect(() => {
    if (course._id) {
      fetchAssigned()
    }
  }, [course])

  const fetchAssigned = async () => {
    setFetchingAssigned(true)
    await props.fetchAssignedSubjects(course)
    setFetchingAssigned(false)
  }

  const assign = () => {
    let message
    if (!course) message = 'Please select a class'
    if (!subject) message = 'Please select a subject'
    if (assigned(subject)) message = 'Cannot override existing subject'
    if (!media) message = 'Please add an image'

    if (message) {
      setSnack({ message, type: 'error' })
      return
    }

    props.assignSubject(course, subject, media)
  }

  const assigned = sub => {
    return assignedSubjects.find(assigned => assigned.name == sub)
  }

  const selectSubject = e => {
    handleInput(e)
    if (assigned(e.target.value)) {
      setSnack({ message: 'Subject already assigned' })
    }
  }

  const deleteAssigned = subject => {
    props.deleteAssignedSubject(course, subject)
  }

  const handleInput = e => {
    props.inputChange(e.target.name, e.target.value)
  }

  const fileUploaderRef = useRef()

  return (
    <div id='assign-subject' className='page-padding'>
      {role && (
        <div>
          {!show(1, role, roles) ? <Redirect to='/' /> : null}
          <Snackbar
            snack={snack}
            reset={() => {
              setSnack({ message: '' })
            }}
          />
          <h3 className='mb-2 mb-md-5 title-text'>Assign Subject to Class</h3>
          <div className='d-flex align-items-center'>
            <span className='mr-4'>Classes</span>
            <SelectField
              items={courses}
              style={{ maxWidth: 250 }}
              depressed
              value={course}
              textField='name'
              valueField='_id'
              name='course'
              onSelect={e => {
                setCourse(e.target.value)
              }}
            />
          </div>

          <div
            style={{ borderBottom: '4px solid var(--primary-color)' }}
            className='mt-md-5 mt-3 mb-md-4 mb-2'
          >
            <h4 className=''>{course.name || 'Please Select A Class'}</h4>
          </div>
          <div className='row'>
            <div className='col-sm-6'>
              <button style={{ backgroundColor: 'inherit', border: 'none' }}>
                <div className='d-flex align-items-center'>
                  <Plus style={{ width: 18 }} />
                  <span className='ml-2' style={{ fontSize: '110%' }}>
                    Assign New
                  </span>
                </div>
              </button>

              <SelectField
                className='mt-4 mb-5'
                placeholder='Select Subject'
                value={subject}
                valueField='_id'
                textField='name'
                name='subject'
                items={subjects.filter(
                  sub =>
                    !assignedSubjects.find(aSub => aSub._id === sub._id)
                )}
                onSelect={selectSubject}
              />

              <p>Upload Preview Gif</p>
              <FileUploader
                ref={fileUploaderRef}
                onInput={handleInput}
                name='media'
              />
              <div className='center my-md-5 my-3'>
                <Button onClick={assign}>Assign</Button>
              </div>
            </div>
            <div className='col-sm-6'>
              <div className='assigned-display'>
                <div
                  style={{
                    padding: '20px 30px 10px 30px',
                    borderBottom: '1px solid rgba(0,0,0,0.3)'
                  }}
                >
                  <h5 className='font2'>Assigned Subjects</h5>
                </div>
                <div style={{ padding: '20px 30px 0px 30px' }}>
                  {!fetchingAssigned && (
                    <div style={{ marginTop: '-20px' }}>
                      {assignedSubjects.length > 0 &&
                        assignedSubjects.map((subject, index) => (
                          <AssignedSubject
                            subject={subject}
                            key={index}
                            onDeleteAssigned={deleteAssigned}
                          />
                        ))}
                      {!course._id && (
                        <div className='center pt-3 pb-3'>Select a class</div>
                      )}
                      {course._id && assignedSubjects.length < 1 && (
                        <div className='center pt-3 pb-3'>
                          No subjects have been assigned to this class
                        </div>
                      )}
                    </div>
                  )}
                  {fetchingAssigned && (
                    <div className='center pt-3 pb-3'>
                      <div className='loader-medium' style={{}}></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  subjects: state.subjects.subjects,
  subject: state.assignSubject.subject,
  courses: state.courses.courses,
  assignedSubjects: state.assignSubject.assignedSubjects,
  media: state.assignSubject.media,
  role: state.auth.user.role,
  roles: state.auth.roles,
  error: state.error,
  success: state.success
})

export default connect(mapStateToProps, {
  inputChange,
  fetchSubjects,
  fetchCourses,
  assignSubject,
  clearErrors,
  clearSuccess,
  initializeState,
  fetchAssignedSubjects,
  deleteAssignedSubject
})(AssignSubject)
