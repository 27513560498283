import React, { useEffect, useState } from "react";
import Workbook from "react-excel-workbook";

import SelectField from "../select-field/select.component";
import TextField from "../text-field/textField.component";
import { ReactComponent as Search } from "../../../assets/icons/Search.svg";
import moment from "moment";
import { useHistory } from "react-router-dom";

import "./css/style.css";
const FilterSearch = (Display) => {
  const F = (props) => {
    let history = useHistory();
    const [filter, setFilter] = useState({ value: "all", text: "All" });
    const [newFilter, setNewFilter] = useState({ value: "all", text: "All" });
    const [search, setSearch] = useState("");
    const [displayItems, setDisplayItems] = useState(props.items);

    const fetchFromObject = (obj, prop) => {
      if (typeof obj === "undefined") {
        return "";
      }

      let _index = prop.indexOf(".");
      if (_index > -1) {
        return fetchFromObject(
          obj[prop.substring(0, _index)],
          prop.substr(_index + 1)
        );
      }
      if (!obj || !obj[prop]) return "";
      return obj[prop].toString() || "";
    };
    useEffect(() => {
      let arr = props.items.slice();
      setDisplayItems(arr);
    }, [props.items]);

    // useEffect(() => {
    //   setDisplay(
    //     React.cloneElement(props.display, {
    //       items: displayItems,
    //       loading: props.loading,
    //     })
    //   );
    // }, [props.loading]);
    useEffect(() => {
      let arr;
      if (filter.searchValue) {
        arr = props.items.filter((item) =>
          fetchFromObject(item, filter.searchValue)
            .toLowerCase()
            .includes(search.toLowerCase())
        );
        setDisplayItems(arr);
      } else {
        arr = props.items.filter((item) => {
          for (let i = 0; i < props.filters.length; i++) {
            if (!props.filters[i].searchValue) continue;
            if (
              fetchFromObject(item, props.filters[i].searchValue)
                .toLowerCase()
                .includes(search.toLowerCase())
            )
              return true;
          }
        });
        setDisplayItems(arr);
      }
    }, [search]);

    const searchChange = (e) => {
      const search_value = e.target.value;
      setSearch(search_value);
      if (props.isDbQuery) {
        if (search_value.length > 2 && props.onSearch) {
          setTimeout(props.onSearch(filter.value, search_value), 3000);
        }
        if (search_value.length < 2 && props.onSearch) {
          props.onSearch();
          props.refreshSkip();
        }
      }
    };

    return (
      <div id="filter-search">
        <div
          style={{ ...props.style }}
          className={`d-flex align-items-center ${props.className} mb-3 flex-wrap`}
        >
          {!props.hideFilter && (
            <span className="mr-3 mb-2 filter-text">Filter By</span>
          )}
          {!props.hideFilter && (
            <div
              style={{ maxWidth: "300px" }}
              className="mr-3 mb-2 filter-input"
            >
              <SelectField
                items={props.filters}
                depressed
                value={filter}
                onSelect={(val) => {
                  setFilter(val.target.value);
                  if (props.onFilterChange)
                    props.onFilterChange(val.target.value);
                }}
              />
            </div>
          )}
          {props.showNewFilter && (
            <div
              style={{ maxWidth: "300px" }}
              className="mr-3 mb-2 filter-input"
            >
              <SelectField
                items={props.newFilters}
                depressed
                value={newFilter}
                onSelect={(val) => {
                  setNewFilter(val.target.value);
                  if (props.onNewFilterChange)
                    props.onNewFilterChange(val.target.value);
                }}
              />
            </div>
          )}
          {!props.showNewFilter && (
            <TextField
              outlined
              color="rgba(182,182,182,1)"
              className="stroke-icon mb-2"
              iconLeft
              placeholder="Search"
              style={{ maxWidth: "300px" }}
              icon={<Search />}
              value={search}
              onInput={searchChange}
            />
          )}
          {history.location.pathname === "/users/afrilearn" && (
            <div className="ms-md-auto mb-2">
              <Workbook
                filename="Users.xlsx"
                element={
                  <button type="button" class="btn btn-outline-secondary">
                    Export User
                  </button>
                }
              >
                <Workbook.Sheet data={displayItems} name="Users">
                  <Workbook.Column label="id" value="id" />
                  <Workbook.Column label="fullName" value="fullName" />
                  <Workbook.Column label="afriCoins" value="afriCoins" />
                  <Workbook.Column label="email" value="email" />
                  <Workbook.Column label="googleUserId" value="googleUserId" />
                  <Workbook.Column label="channel" value="channel" />
                  <Workbook.Column
                    label="createdAt"
                    value={(row) =>
                      moment(row.createdAt).format("MMMM Do YYYY, h:mm:ss a")
                    }
                  />
                  <Workbook.Column label="isActivated" value="isActivated" />
                  <Workbook.Column label="isAdmin" value="isAdmin" />
                  <Workbook.Column
                    label="Last Login"
                    value={(row) =>
                      moment(row.updatedAt).format("MMMM Do YYYY, h:mm:ss a")
                    }
                  />
                </Workbook.Sheet>
                <Workbook.Sheet data={[]} name="Yearly">
                  <Workbook.Column label="Year" value={(row) => row._id.year} />
                  <Workbook.Column
                    label="Month"
                    value={(row) => row._id.month}
                  />
                  <Workbook.Column label="Type" value={(row) => row._id.type} />
                  <Workbook.Column label="amount" value="totalAmount" />
                </Workbook.Sheet>
              </Workbook>
            </div>
          )}
        </div>
        <Display {...props} items={displayItems} />
      </div>
    );
  };

  return F;
};

export default FilterSearch;
