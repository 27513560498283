import React, { useEffect, useState, useRef } from "react";

import Table from "../../includes/table/table.component";
import Alert from "../../includes/alert/alert.component";
import Modal from "../../includes/modal/modal.component";
import { ReactComponent as Edit } from "../../../assets/icons/EditSquare.svg";
import { connect } from "react-redux";
import {
  fetchPastQuestionCategories,
  deletePastQuestionCategory,
  deletePastQuestionCategories,
} from "../../../redux/actions/pastQuestionTypesActions";
import { clearErrors } from "../../../redux/actions/errorActions";
import { clearSuccess } from "../../../redux/actions/successActions";
import PastQuestionForm from "./past-question-form.component";
import DeleteDialog from "../../includes/confirm-dialog/confirmDialog.component";
import ActionButton from "../../includes/button/actionButton.component";
import Snackbar from "../../includes/snackbar/snackbar.component";
import DateWrapper from "../../includes/date-wrapper/date-wrapper.component";
import { show } from "../../../assets/js/globalFunctions";

const PastQuestionCategories = (props) => {
  const headers = [
    { value: "name", text: "Name", align: "left" },
    { value: "categoryId", text: "Category Id"},
    { value: "createdAt", text: "Date created", wrapper: DateWrapper },
  ];

  const { pastQuestionCategories, error, success, role, roles } = props;
  const [items, setItems] = useState([]);
  const [
    selectedPastQuestionCategories,
    setSelectedPastQuestionCategories,
  ] = useState([]);
  const [currentActionPastQuestion, setCurrentActionPastQuestion] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pastQuestionsAlert, setPastQuestionsAlert] = useState({
    show: false,
    type: "success",
    message: "Selected past question(s) deleted successfully",
  });
  const [pastQuestionForm, setPastQuestionForm] = useState({
    show: false,
    type: "edit",
    category: null,
  });
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ message: "", type: "error" });

  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (error.id) {
        const message =
          typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
        let alert_ = { type: "error", show: true, message };
        if (error.id === "DELETE_PAST_QUESTION_CATEGORY_FAILURE") {
          setPastQuestionsAlert({ ...alert_ });
          props.clearErrors();
        } else if (error.id === "DELETE_PAST_QUESTION_CATEGORIES_FAILURE") {
          const message =
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
          setPastQuestionsAlert({ ...alert_ });
          props.clearErrors();
        } else if (error.id === "ADD_PAST_QUESTION_CATEGORY_FAILURE") {
          const message =
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
          setPastQuestionsAlert({ ...alert_ });
          props.clearErrors();
        } else if (error.id === "EDIT_PAST_QUESTION_CATEGORY_FAILURE") {
          const message =
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
          setPastQuestionsAlert({ ...alert_ });
          props.clearErrors();
        }
        props.clearErrors();
      } else if (success.id) {
        let alert_ = { type: "success", show: true, message: success.msg };
        if (success.id === "DELETE_PAST_QUESTION_CATEGORY_SUCCESS") {
          setPastQuestionsAlert({ ...alert_ });
          props.clearSuccess();
        } else if (success.id === "DELETE_PAST_QUESTION_CATEGORIES_SUCCESS") {
          setPastQuestionsAlert({
            ...alert_,
          });
          props.clearSuccess();
        } else if (success.id === "ADD_PAST_QUESTION_CATEGORY_SUCCESS") {
          setPastQuestionsAlert({
            ...alert_,
          });
          props.clearSuccess();
        } else if (success.id === "EDIT_PAST_QUESTION_CATEGORY_SUCCESS") {
          setPastQuestionsAlert({
            ...alert_,
          });
          props.clearSuccess();
        }
        props.clearSuccess();
      }
    }
  });

  useEffect(() => {
    if (role) {
      props.fetchPastQuestionCategories();
    }
  }, [role]);

  const deleteSelectedPastQuestionCategories = () => {
    if (selectedPastQuestionCategories.length < 1) {
      setSnackbar({ message: "No past question category was selected", type: "error" });
      return;
    } else if (selectedPastQuestionCategories.length == 1) {
      props.deletePastQuestionCategory(selectedPastQuestionCategories[0]._id);
    } else {
      props.deletePastQuestionCategories(
        selectedPastQuestionCategories.map((category) => category._id)
      );
    }
  };

  return (
    <div id="pastQuestionCategories" className="page-padding">
      {role && (
        <div>
          <DeleteDialog
            show={deleteDialog}
            onCancel={() => {
              setDeleteDialog(false);
            }}
            onContinue={() => {
              setDeleteDialog(false);
              deleteSelectedPastQuestionCategories();
            }}
            onClose={() => {
              setDeleteDialog(false);
            }}
            title="Delete Class"
            message={`Are you sure you want to delete the past question categor${
              selectedPastQuestionCategories.length > 1 ? "ies" : "y"
            }?`}
          />
          <Snackbar
            snack={snackbar}
            reset={() => {
              setSnackbar({ message: "" });
            }}
          />
          <div className="d-flex justify-content-between">
            <h3 className="title-text">Past Question Categories</h3>
            <div>
              <PastQuestionForm
                show={pastQuestionForm.show}
                onClose={() => {
                  setPastQuestionForm({ ...pastQuestionForm, show: false });
                }}
                onComplete={(val) => {
                  setPastQuestionForm({ ...pastQuestionForm, show: false });
                  // let arr = items.slice();
                  // arr.push({
                  //   ...val,
                  //   createdAt: "25/02/2020",
                  //   creator: { name: "Victor" },
                  // });
                  // setItems(arr);
                }}
                onSetSnackbar={(val) => {
                  setSnackbar(val);
                }}
                category={pastQuestionForm.category}
                type={pastQuestionForm.type}
              />

              {show(1, role, roles) && (
                <ActionButton
                  onClick={() => {
                    setPastQuestionForm({ type: "Create", show: true });
                  }}
                  className="mr-4 mb-2"
                />
              )}
              {show(1, role, roles) && (
                <ActionButton
                  delete
                  onClick={() => {
                    setDeleteDialog(true);
                  }}
                  className="mr-3"
                />
              )}
            </div>
          </div>
          <div className="d-flex justify-content-center">
            {pastQuestionsAlert.show && (
              <Alert
                type={pastQuestionsAlert.type}
                message={pastQuestionsAlert.message}
                style={{ maxWidth: 500, flexGrow: 1 }}
                className="mt-3"
                onClose={() => {
                  setPastQuestionsAlert({
                    show: false,
                    message: "",
                  });
                }}
              />
            )}
          </div>
          <Table
            headers={headers}
            items={pastQuestionCategories}
            selectable={show(1, role, roles)}
            action={show(1, role, roles) && <Edit style={{ width: 20 }} />}
            onItemsSelected={(val) => {
              setSelectedPastQuestionCategories(val);
            }}
            onActionClicked={(item) => {
              setCurrentActionPastQuestion(item);
              setPastQuestionForm({ type: "Edit", show: true, category: item });
            }}
            loading={loading}
            className="mt-4"
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  pastQuestionCategories: state.pastQuestionCategories.pastQuestionCategories,
  role: state.auth.user.role,
  roles: state.auth.roles,
  error: state.error,
  success: state.success,
});
export default connect(mapStateToProps, {
  fetchPastQuestionCategories,
  deletePastQuestionCategory,
  deletePastQuestionCategories,
  clearErrors,
  clearSuccess,
})(PastQuestionCategories);
