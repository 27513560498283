import React, { useState, useEffect, useRef } from "react";

import Table from "../../includes/table/table.component";
import Alert from "../../includes/alert/alert.component";
import { ReactComponent as Edit } from "../../../assets/icons/EditSquare.svg";
import { ReactComponent as View } from "../../../assets/icons/Show.svg";
import { connect } from "react-redux";
import {
  fetchCmsUsers,
  deleteCmsUser,
  deleteCmsUsers,
} from "../../../redux/actions/cmsUserActions";
import { clearErrors } from "../../../redux/actions/errorActions";
import { clearSuccess } from "../../../redux/actions/successActions";
import Modal from "../../includes/modal/modal.component";
import UserForm from "./cmsUserForm.component";
import Panel from "../../includes/menu/menu.component";
import Pagination from "../../includes/pagination/pagination.component";
import DeleteDialog from "../../includes/confirm-dialog/confirmDialog.component";
import ActionButton from "../../includes/button/actionButton.component";
import Snackbar from "../../includes/snackbar/snackbar.component";
import DateWrapper from "../../includes/date-wrapper/date-wrapper.component";
import RoleWrapper from "../../includes/role-wrapper/role-wrapper.component";
import NameWrapper from "../../includes/name-wrapper/name-wrapper.component";
import { show } from "../../../assets/js/globalFunctions";
import { Redirect } from "react-router-dom";

import "./css/style.css";
const Tab = Pagination(Table);
const CmsUsers = (props) => {
  const headers = [
    { value: "_this_", text: "Name", wrapper: NameWrapper, align: "left" },
    { value: "email", text: "Email", align: "left" },
    { value: "role", text: "Role", wrapper: RoleWrapper },
    { value: "createdAt", text: "Date created", wrapper: DateWrapper },
  ];

  const { cmsUsers, error, success, role, roles } = props;
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [currentActionUser, setCurrentActionUser] = useState({});
  const [userForm, setUserForm] = useState({ show: false, type: "Create" });
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ message: "", type: "error" });
  const [loading, setLoading] = useState(false);
  const [usersAlert, setUsersAlert] = useState({
    show: false,
    message: "",
  });

  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (error.id) {
        const message =
          typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
        let alert_ = { type: "error", show: true, message };
        if (error.id === "DELETE_CMSUSER_FAILURE") {
          setUsersAlert({ ...alert_});
          props.clearErrors();
        } else if (error.id === "DELETE_CMSUSERS_FAILURE") {
          const message =
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
          setUsersAlert({ ...alert_ });
          props.clearErrors();
        } else if (error.id === "ADD_CMSUSER_FAILURE") {
          const message =
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
          setUsersAlert({ ...alert_ });
          setUserForm({ ...userForm, show: false });
          props.clearErrors();
        } else if (error.id === "EDIT_CMSUSER_FAILURE") {
          const message =
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
          setUsersAlert({ ...alert_ });
          setUserForm({ ...userForm, show: false });
          props.clearErrors();
        }
        props.clearErrors();
      } else if (success.id) {
        let alert_ = { type: "success", show: true, message: success.msg };
        if (success.id === "DELETE_CMSUSER_SUCCESS") {
          setUsersAlert({ ...alert_ });
          props.clearSuccess();
        } else if (success.id === "DELETE_CMSUSERS_SUCCESS") {
          setUsersAlert({
            ...alert_,
          });
          props.clearSuccess();
        } else if (success.id === "ADD_CMSUSER_SUCCESS") {
          setUserForm({ type: "Detail New", show: true });
          setUsersAlert({
            ...alert_,
          });
          props.clearSuccess();
        } else if (success.id === "EDIT_CMSUSER_SUCCESS") {
          setUsersAlert({
            ...alert_,
          });
          setUserForm({ ...userForm, show: false });
          props.clearSuccess();
        }
        props.clearSuccess();
      }
    }
  });

  useEffect(() => {
    if (role) {
      props.fetchCmsUsers();
    }
  }, [role]);

  const deleteSelectedUsers = () => {
    if (selectedUsers.length == 0) {
      setSnackbar({ message: "No user was selected", type: "error" });
      return;
    } else if (selectedUsers.length == 1) {
      props.deleteCmsUser(selectedUsers[0]._id);
    } else {
      props.deleteCmsUsers(selectedUsers.map((user_) => user_.id));
    }
  };
  return (
    <div id="users" className="page-padding">
      {role && (
        <div>
          {!show(1, role, roles) ? <Redirect to="/dashboard" /> : null}
          <DeleteDialog
            show={deleteDialog}
            onCancel={() => {
              setDeleteDialog(false);
            }}
            onContinue={() => {
              setDeleteDialog(false);
              deleteSelectedUsers();
            }}
            onClose={() => {
              setDeleteDialog(false);
            }}
            title="Delete User"
            message={`Are you sure you want to delete the user${selectedUsers.length > 1 ? "s" : ""
              }?`}
          />
          <Snackbar
            snack={snackbar}
            reset={() => {
              setSnackbar({ message: "" });
            }}
          />
          <div className="d-flex justify-content-between">
            <h3 className="title-text">Users: CMS</h3>
            <div>
              <Modal
                maxWidth="550px"
                show={userForm.show}
                onClose={() => {
                  setUserForm({ ...userForm, show: false });
                }}
              >
                <UserForm
                  onSetSnackbar={(val) => {
                    setSnackbar(val);
                  }}
                  user={currentActionUser}
                  type={userForm.type}
                  setPassword={userForm.setPassword}
                  onEdit={() => {
                    setUserForm({ type: "Edit New", show: true });
                  }}
                />
              </Modal>

              {show(2, role, roles) && (
                <ActionButton
                  onClick={() => {
                    setUserForm({ type: "Create", show: true });
                  }}
                  className="mr-4"
                />
              )}
              {show(2, role, roles) && (
                <ActionButton
                  delete
                  onClick={() => {
                    setDeleteDialog(true);
                  }}
                  className="mr-3"
                />
              )}
            </div>
          </div>
          <div className="d-flex justify-content-center">
            {usersAlert.show && (
              <Alert
                type={usersAlert.type}
                message={usersAlert.message}
                style={{ maxWidth: 500, flexGrow: 1 }}
                className="mt-3"
                onClose={() => {
                  setUsersAlert({
                    show: false,
                    message: "",
                  });
                }}
              />
            )}
          </div>
          <Tab
            items={cmsUsers}
            headers={headers}
            selectable={show(2, role, roles)}
            loading={loading}
            action={
              show(2, role, roles) && (
                <Panel
                  activator={
                    <div>
                      <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
                    </div>
                  }
                >
                  <Edit
                    onClick={() => {
                      setUserForm({ type: "Edit", show: true });
                    }}
                  />
                  <View
                    onClick={() => {
                      setUserForm({ type: "Detail", show: true });
                    }}
                    style={{ marginLeft: 10 }}
                  />
                </Panel>
              )
            }
            onItemsSelected={(val) => {
              setSelectedUsers(val);
            }}
            onActionClicked={(item) => {
              setCurrentActionUser(item);
            }}
            className="mt-4"
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  cmsUsers: state.cmsUsers.cmsUsers,
  role: state.auth.user.role,
  roles: state.auth.roles,
  error: state.error,
  success: state.success,
});
export default connect(mapStateToProps, {
  fetchCmsUsers,
  deleteCmsUser,
  deleteCmsUsers,
  clearErrors,
  clearSuccess,
})(CmsUsers);
