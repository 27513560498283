import API from '../../assets/js/api'
import { returnErrors } from './errorActions'
import { returnSuccess } from './successActions'

import {
  GET_BLOG_ARTICLES_FAILURE,
  GET_BLOG_ARTICLES_SUCCESS,
  GET_BLOG_ARTICLE_FAILURE,
  GET_BLOG_ARTICLE_SUCCESS,
  DELETE_BLOG_ARTICLES_FAILURE,
  DELETE_BLOG_ARTICLES_SUCCESS,
  DELETE_BLOG_ARTICLE_FAILURE,
  DELETE_BLOG_ARTICLE_SUCCESS,
  ADD_BLOG_ARTICLE_SUCCESS,
  ADD_BLOG_ARTICLE_FAILURE,
  EDIT_BLOG_ARTICLE_SUCCESS,
  EDIT_BLOG_ARTICLE_FAILURE,
  BLOG_ARTICLES_INPUT_CHANGE,
  OBJECT_PROP_INPUT_CHANGE,
  INITIALIZE_BLOG_ARTICLE_STATE,
  ARRAY_INPUT_CHANGE
} from './types'

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  )
}
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id))
}

export const inputChange = (name, value) => dispatch => {
  dispatch({ type: BLOG_ARTICLES_INPUT_CHANGE, payload: { name, value } })
}

export const objectPropInputChange = (name, propName, value) => dispatch => {
  dispatch({
    type: OBJECT_PROP_INPUT_CHANGE,
    payload: { name, propName, value }
  })
}

export const arrayInputChange = (name, value, index) => dispatch => {
  dispatch({ type: ARRAY_INPUT_CHANGE, payload: { name, value, index } })
}

export const initializeState = value => dispatch => {
  dispatch({ type: INITIALIZE_BLOG_ARTICLE_STATE, payload: value })
}

export const fetchBlogArticles = (params, loader = true) => async dispatch => {
  try {
    if (loader) document.body.classList.add('loading-indicator')
    const result = await API.getBlogArticles(params)

    dispatch({ type: GET_BLOG_ARTICLES_SUCCESS, payload: result.data.data })
    dispatchSuccess(dispatch, ``, GET_BLOG_ARTICLES_SUCCESS)
    if (loader) document.body.classList.remove('loading-indicator')
  } catch (err) {
    if (loader) document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, GET_BLOG_ARTICLES_FAILURE)
  }
}

export const fetchBlogArticle = id => async dispatch => {
  try {
    document.body.classList.add('loading-indicator')
    const result = await API.getBlogArticle(id)

    dispatch({ type: GET_BLOG_ARTICLE_SUCCESS, payload: result.data.data })
    document.body.classList.remove('loading-indicator')
  } catch (err) {
    document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, GET_BLOG_ARTICLE_FAILURE)
  }
}

export const addBlogArticle = data => async (dispatch, getState) => {
  try {
    document.body.classList.add('loading-indicator')
    const user = getState().auth.user
    const formData = new FormData()
    formData.set('title', data.title)
    formData.set('content', data.content)
    formData.set('categoryId', data.categoryId)
    formData.set('creatorId', user._id)
    formData.set('isHeadline', data.isHeadline)

    if (data.contentImages.length) {
      formData.set('contentUrls', JSON.stringify(data.contentUrls))
      data.contentImages.forEach(image => {
        formData.append('contentImages', image)
      })
    }
    formData.append('media', data.media)

    const result = await API.addBlogArticle(formData)

    dispatch({
      type: ADD_BLOG_ARTICLE_SUCCESS,
      payload: { user, blogArticle: result.data.data.article }
    })
    dispatchSuccess(
      dispatch,
      `Article "${data.title}" successfully created`,
      ADD_BLOG_ARTICLE_SUCCESS
    )
    document.body.classList.remove('loading-indicator')
  } catch (err) {
    document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, ADD_BLOG_ARTICLE_FAILURE)
  }
}
export const editBlogArticle = (data, blogArticleId) => async dispatch => {
  try {
    document.body.classList.add('loading-indicator')
    const formData = new FormData()
    formData.set('title', data.title)
    formData.set('content', data.content)
    formData.set('categoryId', data.categoryId)
    formData.set('isHeadline', data.isHeadline)
    data.deleteMedia.forEach(media => {
      formData.append('deleteMedia', media)
    })
    if (data.contentImages.length) {
      formData.set('contentUrls', JSON.stringify(data.contentUrls))
      data.contentImages.forEach(image => {
        formData.append('contentImages', image)
      })
    }
    if (data.media) formData.append('media', data.media)

    await API.editBlogArticle(formData, blogArticleId)

    dispatchSuccess(
      dispatch,
      `Article "${data.title}" edited successfully`,
      EDIT_BLOG_ARTICLE_SUCCESS
    )
    document.body.classList.remove('loading-indicator')
  } catch (err) {
    document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, EDIT_BLOG_ARTICLE_FAILURE)
  }
}

export const deleteBlogArticle = (data, title) => async dispatch => {
  try {
    document.body.classList.add('loading-indicator')
    const result = await API.deleteBlogArticle(data)

    dispatch({ type: DELETE_BLOG_ARTICLE_SUCCESS, payload: data._id })
    dispatchSuccess(
      dispatch,
      `Article "${title}" deleted successfully`,
      DELETE_BLOG_ARTICLE_SUCCESS
    )
    document.body.classList.remove('loading-indicator')
  } catch (err) {
    document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, DELETE_BLOG_ARTICLE_FAILURE)
  }
}

export const deleteBlogArticles = data => async dispatch => {
  try {
    document.body.classList.add('loading-indicator')
    const result = await API.deleteBlogArticles(data)

    dispatch({
      type: DELETE_BLOG_ARTICLES_SUCCESS,
      payload: { articleIds: data.articleIds }
    })
    dispatchSuccess(
      dispatch,
      result.data.data.message,
      DELETE_BLOG_ARTICLES_SUCCESS
    )
    document.body.classList.remove('loading-indicator')
  } catch (err) {
    document.body.classList.remove('loading-indicator')
    dispatchError(dispatch, err, DELETE_BLOG_ARTICLES_FAILURE)
  }
}
