import React, { useState, useEffect, useRef } from "react";
import SunEditor from "suneditor-react";
import { useHistory, useParams } from "react-router-dom";
import "suneditor/dist/css/suneditor.min.css";
import Button from "../../includes/button/button.component";
import TextField from "../../includes/text-field/textField.component";
import { ReactComponent as Play } from "../../../assets/icons/Play.svg";
import Modal from "../../includes/modal/modal.component";
import FileUploader from "../../includes/file-uploader/fileUploader.component";
import { ReactComponent as Plus } from "../../../assets/icons/Plus.svg";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  editClassContent,
  inputChange,
  objectPropInputChange,
  initializeState,
  fetchClassContent,
  updateClassTextsAndThumbNAil,
  removeThumbNAil,
  deleteLessonVideo,
  updateVideoLesson,
  addVideoLesson,
  fetchRelatedContents,
} from "../../../redux/actions/classContentsActions";
import { fetchCourses } from "../../../redux/actions/coursesActions";
import { fetchTerms } from "../../../redux/actions/termsActions";
import { fetchAssignedSubjects } from "../../../redux/actions/assignSubjectActions";
import { clearErrors } from "../../../redux/actions/errorActions";
import { clearSuccess } from "../../../redux/actions/successActions";
import Snackbar from "../../includes/snackbar/snackbar.component";
import { ReactComponent as Times } from "../../../assets/icons/Times.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import parse from "html-react-parser";
import ReactPlayer from "react-player/lazy";

import "./css/style.css";
const btnList = [
  ["undo", "redo"],
  ["font", "fontSize", "formatBlock"],
  ["bold", "underline", "italic", "strike", "subscript", "superscript"],
  ["removeFormat"],
  "/",
  ["fontColor", "hiliteColor"],
  ["outdent", "indent"],
  ["align", "horizontalRule", "list", "table"],
  ["link", "image"],
  ["fullScreen", "showBlocks", "codeView"],
];

const ClassContentEdition = (props) => {
  const {
    success,
    error,
    terms,
    term,
    courses,
    course,
    assignedSubjects,
    subject,
    content,
    classContent,
  } = props;

  const dispatch = useDispatch();

  const [addVideoModal, setAddVideoModal] = useState(false);
  const [isUpload, setIsUpload] = useState(true);
  const [snackbar, setSnackbar] = useState({ message: "" });
  const [fetchingSubjects, setFetchingSubjects] = useState(false);
  const [classNote, setClassNote] = useState(""); //
  const [newTitle, setNewTitle] = useState(""); //
  const [subjectId, setSubjectId] = useState(""); //
  const [courseId, setCourseId] = useState(""); //
  const [termId, setTermId] = useState(""); //
  const [newVideoFile, setNewVideoFile] = useState("");
  const [newVideoTranscript, setNewVideoTranscript] = useState("");
  const [updatedVideoUrl, setUpdatedVideoUrl] = useState("");
  const [updatedTranscript, setUpdatedTranscript] = useState("");
  const [show, setShow] = useState(false);

  const mounted = useRef(false);
  const history = useHistory();
  const { id } = useParams();
  const initialized = useRef(false);

  const selectedVideo = useSelector(
    (state) => state.classContents.selectedVideo
  );
  const gotos = useSelector((state) => state.classContents.gotos);

  useEffect(() => {
    if (course._id && initialized.current) {
      setFetchingSubjects(true);
      props.fetchAssignedSubjects(course);
      props.inputChange("subject", {});
    }
  }, [course]);

  useEffect(() => {
    props.fetchClassContent(id);
    dispatch(fetchRelatedContents(id));
    props.fetchClassContent(id);
    return () => {};
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      props.fetchCourses();
      props.fetchTerms();
      mounted.current = true;
    } else {
      if (success.id) {
        const snack = {
          message:
            typeof success.msg === "object"
              ? success.msg.join("<br/>")
              : success.msg,
          type: "success",
        };

        switch (success.id) {
          case "INITIALIZE_STATE_SUCCESS":
            initialized.current = true;
            break;
          case "EDIT_CLASS_CONTENT_SUCCESS":
            setSnackbar({ ...snack });
          case "GET_ASSIGNED_SUBJECTS_SUCCESS":
            setFetchingSubjects(false);
        }
        props.clearSuccess();
      } else if (error.id) {
        const snack = {
          message:
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg,
          type: "error",
        };

        switch (error.id) {
          case "EDIT_CLASS_CONTENT_FAILURE":
            setSnackbar(snack);
            break;
        }
        props.clearErrors();
      }
    }
  });

  useEffect(() => {
    initialized.current = false;
    if (classContent._id) {
      const init = {
        term: classContent.termId,
        course: classContent.courseId,
        subject: {
          assignedId: classContent.subjectId._id,
          ...classContent.subjectId.mainSubjectId,
        },
        title: classContent.title,
        content: classContent.content,
        videoLessons: classContent.videoUrls.map((vid) => {
          return {
            transcript: vid.transcript,
            video: { url: vid.videoUrl, file: null, preview: null },
            audio: null,
          };
        }),
      };
      props.initializeState(init);
    }
  }, [classContent]);

  const handleInput = (e) => {
    props.inputChange(e.target.name, e.target.value);
  };

  const randomString = (length) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const saveTexts = () => {
    const data = {};
    if (newTitle) {
      data.title = newTitle;
    }
    if (classNote) {
      data.content = classNote;
    }
    if (subjectId) {
      data.subjectId = subjectId;
    }
    if (courseId) {
      data.courseId = courseId;
    }
    if (termId) {
      data.termId = termId;
    }
    dispatch(updateClassTextsAndThumbNAil(classContent._id, data));
  };

  return (
    <div id="content-creation" className="page-padding">
      <Snackbar
        snack={snackbar}
        reset={() => {
          setSnackbar({ message: "" });
        }}
      />
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          borderLeft: "5px solid var(--primary-color)",
          paddingLeft: "8px",
        }}
      >
        <h4 style={{ margin: "0", flexGrow: 1 }} className="title-text">
          Class Note
        </h4>
        <Button onClick={saveTexts}>Save</Button>
      </div>
      <div className="row mt-5">
        <div className="col-12 col-lg-8">
          <TextField
            placeholder="Topic"
            name="title"
            value={classContent?.title}
            onInput={(e) => {
              setNewTitle(e.target.value);
            }}
            plain
            className="mb-4"
          />
          <div className="note-markup">
            {initialized.current && (
              <SunEditor
                setOptions={{
                  height: 400,
                  buttonList: btnList,
                  toolbarContainer: "#toolbar-container",
                }}
                onChange={(text) => {
                  setClassNote(text);
                }}
                defaultValue={content}
              />
            )}
          </div>
        </div>
        <div className="col-6 col-lg-4 mt-4 mt-lg-0">
          <div
            style={{
              backgroundColor: "white",
              padding: "20px 0 60px 0",
              borderRadius: "10px",
            }}
          >
            <h4 className="pl-4">Go to</h4>
            <hr />
            <div class="px-4 mb-4">
              <select
                className="form-control styled-select"
                onChange={(e) => {
                  window.location = `/class-contents/${e.target.value}/edit`;
                }}
              >
                {gotos.map((lesson) => (
                  <option value={lesson._id} selected={lesson._id === id}>
                    {lesson.title} ({lesson.termId && lesson.termId.name})
                  </option>
                ))}
              </select>
            </div>
            <h4 className="pl-4">Categories</h4>
            <hr />
            <div className="pl-4 pr-4">
              <label htmlFor="selectTerm">Select class</label>
              <select
                id="selectTerm"
                className="form-control styled-select "
                onChange={(e) => {
                  setCourseId(e.target.value);
                }}
              >
                {courses.map((item) => (
                  <option value={item._id} selected={item._id === course._id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <label htmlFor="selectTerm">Select Subject</label>
              <select
                id="selectTerm"
                className="form-control styled-select"
                onChange={(e) => {
                  setSubjectId(e.target.value);
                }}
              >
                {assignedSubjects && assignedSubjects.length === 0 && (
                  <option value={subject._id} selected>
                    {subject.name}
                  </option>
                )}
                {assignedSubjects.map((item) => (
                  <option value={item._id} selected={item._id === subject._id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <label htmlFor="selectTerm">Select Term</label>
              <select
                id="selectTerm"
                className="form-control styled-select"
                onChange={(e) => {
                  setTermId(e.target.value);
                }}
              >
                {terms.map((item) => (
                  <option value={item._id} selected={item._id === term._id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <Button
            color="var(--primary-color)"
            className="mt-4"
            style={{ padding: ".7em 1.4em", borderRadius: "10px" }}
            onClick={() => {
              document.getElementById("video-lessons").scrollIntoView();
            }}
          >
            <Play className="mr-2" />
            Attach Video Lessons
          </Button>

          {classContent.thumbnailUrl && (
            <h4 className="title-text mt-4">Lesson Thumbnail</h4>
          )}
          <div
            className="upload-thumbail-box card-body border my-3 d-flex justify-content-center align-items-center "
            style={{ backgroundImage: `url(${classContent.thumbnailUrl})` }}
            title="Lesson Thumbnail"
          >
            {classContent.thumbnailUrl && (
              <div class="icon-back delete-thumbnail ">
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  className="cursor text-white"
                  title="Delete Thumbnail"
                  onClick={() => {
                    dispatch(removeThumbNAil(classContent._id));
                  }}
                />
              </div>
            )}
            <input
              type="file"
              id="thumbnail"
              accept="image/*"
              className="hidden-input"
              onChange={(e) => {
                const data = new FormData();
                data.append("thumbnailUrl", e.target.files[0]);
                dispatch(updateClassTextsAndThumbNAil(classContent._id, data));
              }}
            />
            <label
              className=" text-center cursor"
              htmlFor="thumbnail"
              title="Upload Thumbnail"
            >
              <div class="icon-back">
                <FontAwesomeIcon icon={faCamera} className="text-white" />
              </div>
              {!classContent.thumbnailUrl && (
                <p className="mt-2 small">Upload Thumbnail</p>
              )}
            </label>
          </div>
        </div>
      </div>

      <div className="pt-5" id="video-lessons">
        <h4
          style={{
            padding: "4px 0 4px 8px",
            borderLeft: "5px solid var(--primary-color)",
          }}
          className="title-text"
        >
          Video Lesson
        </h4>
        {classContent.videoUrls &&
          classContent.videoUrls.map((video, index) => (
            <div
              key={randomString(10)}
              style={{ borderBottom: "1px solid #B6B6B6", paddingBottom: 35 }}
              className="card-body border rounded rounded-4"
            >
              <p
                style={{
                  color: "rgba(74,80,94,1)",
                  fontSize: "110%",
                  marginTop: 16,
                  paddingLeft: 14,
                }}
                className="d-flex"
              >
                Video {index + 1}
                <button
                  className="ml-auto d-flex align-items-center justify-content-center"
                  style={{
                    border: "none",
                    backgroundColor: "var(--error-color)",
                    width: 30,
                    height: 30,
                    borderRadius: 5,
                  }}
                  onClick={() => {
                    dispatch(deleteLessonVideo(classContent._id, video._id));
                  }}
                >
                  <Times />
                </button>
              </p>
              <p style={{ color: "rgba(74,80,94,1)", paddingLeft: 14 }}>
                Transcript
              </p>
              <div className="transcript-preview px-3 py-2">
                {video.transcript && parse(video.transcript)}
              </div>

              <div className="mt-3">
                {(!video.videoUrl && (
                  <input
                    type="url"
                    id={`uploadLessonVideo${index}`}
                    // accept="video/*"
                    className="form-control w-auto"
                    placeholder="Video Link"
                    required
                    onChange={(e) => {
                      const data = new FormData();
                      data.append("videoUrl", e.target.value);
                      dispatch(
                        updateVideoLesson(classContent._id, video._id, data)
                      );
                    }}
                  />
                )) || (
                  <div style={{ maxWidth: 150, display: "inline-block" }}>
                    {video.videoUrl && (
                      <div>
                        {/* <video width="320" height="240" controls>
                          <source src={video.videoUrl} type="video/mp4" />
                          <source src={video.videoUrl} type="video/ogg" />
                          Your browser does not support the video tag.
                        </video> */}
                        {/* <iframe
                          width="320"
                          height="240"
                          src={video.videoUrl}
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe> */}
                        <ReactPlayer
                          // className="react-player"

                          url={video.videoUrl}
                          controls="true"
                          width="300px"
                          height="140px"
                          // muted={false}
                          // playing={true}
                          // ref={ref}
                        />
                      </div>
                    )}
                    {/* {video.videoUrl && (
                      <div>
                        <div className="d-flex justify-content-between">
                          <label
                            htmlFor={`uploadLessonVideo${index}`}
                            className="pointer"
                            style={{
                              color: "var(--primary-color)",
                              textDecoration: "underline",
                              fontSize: "90%",
                            }}
                          >
                            <input
                              type="file"
                              id={`uploadLessonVideo${index}`}
                              accept="video/*"
                              className="hidden-input"
                              required
                              onChange={(e) => {
                                const data = new FormData();
                                data.append("video", e.target.files[0]);
                                dispatch(
                                  updateVideoLesson(
                                    classContent._id,
                                    video._id,
                                    data
                                  )
                                );
                              }}
                            />
                            Change
                          </label>
                        </div>
                      </div>
                    )} */}
                  </div>
                )}
              </div>

              <button
                type="button"
                class="btn text-white my-2"
                // data-toggle="modal"
                // data-target="#exampleModal"
                style={{ backgroundColor: `var(--primary-color)` }}
                onClick={(e) => {
                  e.preventDefault();
                  setUpdatedTranscript("");
                  setUpdatedVideoUrl("");
                  dispatch(inputChange("selectedVideo", video));
                  setTimeout(() => {
                    setShow(true);
                  }, 1000);
                }}
              >
                Edit Video and Transcript
              </button>
            </div>
          ))}

        <button
          className="border-0 bg-transparent m-2"
          type="button"
          data-toggle="modal"
          data-target="#addNewVideo"
        >
          <Plus className="c-plus" />
          <span className="text-muted">Add Lesson</span>
        </button>
      </div>
      <Modal
        show={addVideoModal}
        onClose={() => {
          setAddVideoModal(false);
        }}
        maxWidth="80vw"
      >
        <h4>Upload or Select Media</h4>
        <div className="nav-container">
          <span
            className={`pointer ${isUpload ? "active" : ""}`}
            onClick={() => {
              setIsUpload(true);
            }}
          >
            Upload
          </span>
          <span
            className={`pointer ${!isUpload ? "active" : ""}`}
            onClick={() => {
              setIsUpload(false);
            }}
          >
            Media
          </span>
        </div>
        <FileUploader
          multiple
          onInput={(files) => {}}
          style={{ marginTop: 30 }}
          maxFiles={10}
          validTypes="video/mp4,video/mov"
        />
      </Modal>
      {/* Add new video */}
      <div
        class="modal fade"
        id="addNewVideo"
        tabindex="-1"
        role="dialog"
        aria-labelledby="addNewVideoLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Add New Lesson Video and Transcript
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const data = {};
                if (newVideoFile) {
                  data.videoUrl = newVideoFile;
                }
                if (newVideoTranscript) {
                  data.transcript = newVideoTranscript;
                }
                // data.append("videoUrl", newVideoFile);
                // data.append("transcript", newVideoTranscript);
                dispatch(addVideoLesson(classContent._id, data));
              }}
            >
              <div class="modal-body">
                <div className="row">
                  <div className="col-5">
                    <div class="mb-3">
                      <label for="newVideo" class="form-label">
                        Upload Video
                      </label>
                      <input
                        class="form-control"
                        type="url"
                        id="newVideo"
                        // accept="video/*"
                        required
                        placeholder="Video link"
                        onChange={(e) => {
                          // const data = new FormData();
                          // data.append("thumbnailUrl", e.target.value);
                          setNewVideoFile(e.target.value);
                        }}
                      />
                    </div>
                    {newVideoFile && (
                      <button
                        className="btn btn-primary my-2"
                        onClick={() => setNewVideoFile(null)}
                      >
                        Delete Video
                      </button>
                    )}
                  </div>
                  {newVideoFile && (
                    <div className="col-7">
                      {/* <video
                        width="100%"
                        height="140"
                        controls
                        className="rounded rounded-4"
                      >
                        <source src={newVideoFile} type="video/mp4" />
                        <source src={newVideoFile} type="video/ogg" />
                        Your browser does not support the video tag.
                      </video> */}
                      {/* <iframe
                        width="100%"
                        height="140"
                        src={newVideoFile}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe> */}
                      <ReactPlayer
                        // className="react-player"

                        url={newVideoFile}
                        controls="true"
                        width="100%"
                        height="140px"
                        // muted={false}
                        // playing={true}
                        // ref={ref}
                      />
                    </div>
                  )}
                </div>
                <p>Transcript</p>
                <div className="video-markup">
                  {initialized.current && (
                    <SunEditor
                      setOptions={{
                        height: 300,
                        buttonList: [
                          ["undo", "redo"],
                          ["formatBlock", "font", "fontSize"],
                          [
                            "bold",
                            "italic",
                            "underline",
                            "fontColor",
                            "hiliteColor",
                          ],
                        ],
                        stickyToolbar: false,
                      }}
                      onChange={(text) => {
                        setNewVideoTranscript(text);
                      }}
                    />
                  )}
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Add new Video
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Add new video */}
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="video-markup">
                {initialized.current && (
                  <SunEditor
                    setOptions={{
                      height: 300,
                      buttonList: [
                        ["undo", "redo"],
                        ["formatBlock", "font", "fontSize"],
                        [
                          "bold",
                          "italic",
                          "underline",
                          "fontColor",
                          "hiliteColor",
                        ],
                      ],
                      stickyToolbar: false,
                    }}
                    onChange={(text) => {
                      setUpdatedTranscript(text);
                    }}
                    defaultValue={selectedVideo.transcript}
                  />
                )}
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn text-white"
                style={{ backgroundColor: `var(--primary-color)` }}
                onClick={(e) => {
                  e.preventDefault();
                  if (updatedTranscript) {
                    dispatch(
                      updateVideoLesson(classContent._id, selectedVideo._id, {
                        transcript: updatedTranscript,
                      })
                    );
                  }
                }}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {show && (
        <div className="like-modal">
          <FontAwesomeIcon
            className="close-like-modal"
            icon={faTimes}
            onClick={() => setShow(false)}
            size="lg"
          />
          <div className="like-modal-box">
            <div class="modal-body">
              <div>
                <div class="d-flex align-items-start">
                  <div className="mr-2">
                    <label>Video Url</label>
                    <input
                      defaultValue={selectedVideo.videoUrl}
                      placeholder="Video URL"
                      onChange={(e) => setUpdatedVideoUrl(e.target.value)}
                    />
                  </div>

                  <ReactPlayer
                    url={updatedVideoUrl || selectedVideo.videoUrl}
                    controls="true"
                    width="200px"
                    height="100px"
                  />
                </div>
              </div>
              <br />
              <div className="video-markup">
                {initialized.current && (
                  <SunEditor
                    setOptions={{
                      height: 200,
                      buttonList: [
                        ["undo", "redo"],
                        ["formatBlock", "font", "fontSize"],
                        [
                          "bold",
                          "italic",
                          "underline",
                          "fontColor",
                          "hiliteColor",
                        ],
                      ],
                      stickyToolbar: false,
                    }}
                    onChange={(text) => {
                      setUpdatedTranscript(text);
                    }}
                    defaultValue={selectedVideo.transcript}
                  />
                )}
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                onClick={() => setShow(false)}
              >
                Close
              </button>
              <button
                type="button"
                class="btn text-white"
                style={{ backgroundColor: `var(--primary-color)` }}
                onClick={(e) => {
                  e.preventDefault();
                  const data = {};
                  if (updatedTranscript) {
                    data.transcript = updatedTranscript;
                  }
                  if (updatedVideoUrl) {
                    data.videoUrl = updatedVideoUrl;
                  }
                  if (updatedTranscript || updatedVideoUrl) {
                    dispatch(
                      updateVideoLesson(
                        classContent._id,
                        selectedVideo._id,
                        data
                      )
                    );
                  }
                }}
              >
                Save changes
              </button>
            </div>{" "}
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  term: state.classContents.term,
  terms: state.terms.terms,
  course: state.classContents.course,
  courses: state.courses.courses,
  subject: state.classContents.subject,
  assignedSubjects: state.assignSubject.assignedSubjects,
  content: state.classContents.content,
  videoLessons: state.classContents.videoLessons,
  title: state.classContents.title,
  classContent: state.classContents.classContent,
  error: state.error,
  success: state.success,
});
export default connect(mapStateToProps, {
  editClassContent,
  fetchAssignedSubjects,
  inputChange,
  objectPropInputChange,
  fetchCourses,
  fetchTerms,
  clearSuccess,
  clearErrors,
  initializeState,
  fetchClassContent,
})(ClassContentEdition);
