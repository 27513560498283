import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  addOrRemoveCoins,
  inputChange as payInputChange,
} from "../../../redux/actions/paymentPlansActions";
import Snackbar from "../../includes/snackbar/snackbar.component";
import { show } from "../../../assets/js/globalFunctions";
import { Redirect } from "react-router-dom";
import "./css/style.css";

const CoinActivation = (props) => {
  const { roles, role } = props;
  const [snackbar, setSnackbar] = useState({ message: "" });
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState(0);
  const addCoinStatus = useSelector(
    (state) => state.paymentPlans.addCoinStatus
  );
  const addCoinStatusMessage = useSelector(
    (state) => state.paymentPlans.addCoinStatusMessage
  );
  useEffect(() => {
    dispatch(payInputChange("addCoinStatus", "pending"));
  }, []);
  useEffect(() => {
    if (addCoinStatus === "failed") {
      if (addCoinStatusMessage) {
        setSnackbar({ message: addCoinStatusMessage });
      } else {
        setSnackbar({ message: "Error adding coins. Try again!" });
      }
    }
    if (addCoinStatus === "success") {
      setSnackbar({ message: "Added coins successfully!", type: "success" });
    }
    dispatch(payInputChange("addCoinStatus", "pending"));
  }, [addCoinStatus]);

  return (
    <div id="manual-activation" className="page-padding">
      {role && (
        <div>
          {!show(2, role, roles) ? <Redirect to="/dashboard" /> : null}
          <Snackbar
            snack={snackbar}
            reset={() => {
              setSnackbar({ message: "" });
            }}
          />
          <h3 className="title-text">Add Coins Manually</h3>
          <p className="mb-5">Manually add coins for users.</p>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              dispatch(addOrRemoveCoins({ email, amount, type: "add" }));
            }}
          >
            <label>Email</label>

            <div
              id="text-field"
              class=" d-flex align-items-center"
              style={{ maxWidth: 500 }}
            >
              <input
                placeholder="Email"
                type="email"
                style={{
                  width: "100%",
                  height: "50px",
                  backgroundColor: "white",
                  padding: "20px 62px 20px 25px",
                  height: 45,
                  borderWidth: "0",
                }}
                className="general-shadow"
                required
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <label>Amount</label>

            <div
              id="text-field"
              class=" d-flex align-items-center"
              style={{ maxWidth: 500 }}
            >
              <input
                placeholder="Amount"
                style={{
                  width: "100%",
                  height: "50px",
                  backgroundColor: "white",
                  padding: "20px 62px 20px 25px",
                  height: 45,
                  borderWidth: "0",
                }}
                className="general-shadow"
                type="number"
                required
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />
            </div>

            <div className="mt-5">
              <button
                style={{
                  backgroundColor: "rgba(48,52,61,1)",
                  color: "white",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                  borderRadius: "10px",
                  fontSize: 18,
                  padding: ".6em 1.5em",
                }}
                type="submit"
              >
                Add coin
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  roles: state.auth.roles,
  role: state.auth.user.role,
});
export default connect(mapStateToProps, {})(CoinActivation);
