import React, { useState } from "react";
import "./css/style.css";
import { ReactComponent as PasswordHide } from "../../../assets/icons/PasswordHide.svg";

const TextField = (props) => {
  const [focused, setFocused] = useState(false);
  const [type, setType] = useState(props.type || "text");
  const handleInput = (e) => {
    if (props.onInput) props.onInput(e);
  };

  const handleActionClick = () => {
    if (props.type === "password") {
      setType(type === "text" ? "password" : "text");
    }
  };

  const handleBlur = () => {
    setFocused(false);
    if (props.validator)
      props.validator.showMessageFor(props.validationData.for);
  };

  return (
    <div
      id="text-field"
      className={`${props.className || ""}`}
      style={{ ...props.style }}
    >
      <input
        type={type}
        defaultValue={props.value}
        onChange={handleInput}
        style={{
          width: props.width || "100%",
          height: props.height || "50px",
          borderColor: props.outlined ? props.color || "black" : "",
          backgroundColor: !props.outlined ? props.color || "white" : "inherit",
          padding: !props.icon
            ? "20px 25px 20px 25px"
            : props.iconLeft
            ? "20px 25px 20px 62px"
            : "20px 62px 20px 25px",
          ...props.inputStyle,
          height: props.height || 45,
        }}
        onFocus={() => setFocused(true)}
        onBlur={handleBlur}
        placeholder={props.placeholder}
        className={`${
          props.outlined ? "outlined" : !props.depressed ? "general-shadow" : ""
        } ${props.plain ? "plain" : ""}`}
        readOnly={props.readOnly}
        name={props.name}
      />
      {(props.icon && (
        <button
          style={{
            left: props.iconLeft ? "25px" : "",
            right: !props.iconLeft ? "25px" : "",
          }}
          className={`icon${focused ? " focused" : ""}`}
        >
          {props.icon}
        </button>
      )) ||
        (props.type === "password" ? (
          <div
            className={`icon pointer ${type === "password" ? " active" : ""}`}
            onClick={handleActionClick}
          >
            <PasswordHide />
          </div>
        ) : null)}

      {props.validationData && props.validator && (
        <div className="error-message">
          {props.validator.message(
            props.validationData.for,
            props.value,
            props.validationData.rules
          )}
        </div>
      )}
    </div>
  );
};

export default TextField;
