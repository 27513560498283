import React, { useState, useRef, useEffect } from "react";

import Table from "../../includes/table/table.component";
import Modal from "../../includes/modal/modal.component";
import Alert from "../../includes/alert/alert.component";
import Pagination from "../../includes/pagination/pagination.component";
import FilterSearch from "../../includes/filter-search/filterSearch.component";
import UserDetail from "./afrilearnUserDetail.component";
import ActionButton from "../../includes/button/actionButton.component";
import { ReactComponent as View } from "../../../assets/icons/Show.svg";
import { connect } from "react-redux";
import {
  fetchAfrilearnUsers,
  deleteAfrilearnUser,
  deleteAfrilearnUsers,
} from "../../../redux/actions/afrilearnUsersActions";
import { clearErrors } from "../../../redux/actions/errorActions";
import { clearSuccess } from "../../../redux/actions/successActions";
import DeleteDialog from "../../includes/confirm-dialog/confirmDialog.component";
import Snackbar from "../../includes/snackbar/snackbar.component";
import DateWrapper from "../../includes/date-wrapper/date-wrapper.component";
import "./css/style.css";
import { show } from "../../../assets/js/globalFunctions";
import { Redirect } from "react-router-dom";

const Filter = FilterSearch(Pagination(Table));

const AfrilearnUsers = (props) => {
  const headers = [
    { value: "fullName", text: "Name", align: "left" },
    { value: "email", text: "Email" },
    { value: "phoneNumber", text: "Phone" },
    { value: "channel", text: "channel" },
    { value: "createdAt", text: "Date Registered", wrapper: DateWrapper },
  ];
  const { afrilearnUsers, count, error, success, role, roles } = props;
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [currentActionUser, setCurrentActionUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(15);
  const [search, setSearch] = useState(false);
  const [filterBy, setFilterBy] = useState(null);
  const [newFilterBy, setNewFilterBy] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [usersAlert, setUsersAlert] = useState({
    show: false,
    message: "Selected User(s) deleted successfully",
  });
  const [detailModal, setDetailModal] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ message: "", type: "error" });

  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      // props.fetchAfrilearnUsers();
      mounted.current = true;
    } else {
      if (error.id) {
        const message =
          typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
        let alert_ = { type: "error", show: true, message };
        if (error.id === "DELETE_AFRILEARN_USER_FAILURE") {
          setUsersAlert({ ...alert_ });
          props.clearErrors();
        } else if (error.id === "DELETE_AFRILEARN_USERS_FAILURE") {
          const message =
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
          setUsersAlert({ ...alert_ });
          props.clearErrors();
        }
        props.clearErrors();
      } else if (success.id) {
        let alert_ = { type: "success", show: true, message: success.msg };
        if (success.id === "DELETE_AFRILEARN_USER_SUCCESS") {
          setUsersAlert({ ...alert_ });
          props.clearSuccess();
        } else if (success.id === "DELETE_AFRILEARN_USERS_SUCCESS") {
          setUsersAlert({
            ...alert_,
          });
          props.clearSuccess();
        }
        props.clearSuccess();
      }
    }
  });

  useEffect(() => {
    if (role) {
      if (!search && !props.location.state)
        props.fetchAfrilearnUsers({ skip, limit });
      else if (props.location.state)
        props.fetchAfrilearnUsers({
          filterBy: "role",
          searchValue: props.location.state.searchValue,
          skip,
          limit,
        });
      else {
        filterBy !== "role"
          ? props.fetchAfrilearnUsers({ filterBy, searchValue, skip, limit })
          : props.fetchAfrilearnUsers({
              filterBy,
              searchValue: newFilterBy,
              skip,
              limit,
            });
      }
    }
  }, [role, skip, limit, filterBy, newFilterBy, searchValue]);

  const deleteSelectedUsers = () => {
    if (selectedUsers.length < 1) {
      setSnackbar({ message: "No user was selected", type: "error" });
      return;
    } else if (selectedUsers.length == 1) {
      props.deleteAfrilearnUser(selectedUsers[0]._id);
    } else {
      props.deleteAfrilearnUsers(selectedUsers.map((user_) => user_.id));
    }
  };
  return (
    <div id="classes" className="page-padding">
      {role && (
        <div>
          {!show(1, role, roles) ? <Redirect to="/dashboard" /> : null}
          <DeleteDialog
            show={deleteDialog}
            onCancel={() => {
              setDeleteDialog(false);
            }}
            onContinue={() => {
              setDeleteDialog(false);
              deleteSelectedUsers();
            }}
            onClose={() => {
              setDeleteDialog(false);
            }}
            title="Delete User"
            message={`Are you sure you want to delete the user${
              selectedUsers.length > 1 ? "s" : ""
            }?`}
          />
          <Snackbar
            snack={snackbar}
            reset={() => {
              setSnackbar({ message: "" });
            }}
          />
          <div className="d-flex justify-content-between flex-wrap">
            <h3 className="title-text">Users: Afrilearn</h3>
            <h3 className="title-text">
              Number of
              {props.location.state || filterBy == "role"
                ? ` ${
                    filterBy == "role"
                      ? newFilterBy
                      : props.location.state.searchValue
                  }s: `
                : " Users: "}
              {count ? parseFloat(count).toLocaleString("en") : 0}
            </h3>
            <Modal
              maxWidth="550px"
              show={detailModal}
              onClose={() => {
                setDetailModal(false);
              }}
            >
              <UserDetail user={currentActionUser} />
            </Modal>
            <div>
              {show(2, role, roles) && (
                <ActionButton
                  delete
                  onClick={() => {
                    setDeleteDialog(true);
                  }}
                  className="mr-3"
                />
              )}
            </div>
          </div>
          <div className="d-flex justify-content-center">
            {usersAlert.show && (
              <Alert
                type={usersAlert.type}
                message={usersAlert.message}
                style={{ maxWidth: 500, flexGrow: 1 }}
                className="mt-3"
                onClose={() => {
                  setUsersAlert({
                    show: false,
                    message: "",
                  });
                }}
              />
            )}
          </div>

          <Filter
            filters={[
              { value: "all", text: "All" },
              { value: "fullName", text: "Name", searchValue: "fullName" },
              { value: "email", text: "Email", searchValue: "email" },
              { value: "role", text: "Role", searchValue: "role" },
            ]}
            newFilters={[
              { value: "student", text: "Student", searchValue: "student" },
              { value: "teacher", text: "Teacher", searchValue: "teacher" },
              { value: "parent", text: "Parent", searchValue: "parent" },
              { value: "school", text: "School", searchValue: "school" },
            ]}
            items={afrilearnUsers}
            count={count}
            loading={loading}
            skip={skip}
            itemsPerPage={limit}
            isDbQuery
            showNewFilter={filterBy == "role" ? true : false}
            onSearch={(filter_by, search_value) => {
              setSkip(0);
              setFilterBy(filter_by);
              setSearchValue(search_value);
              setSearch(true);
              setSkip(skip);
            }}
            onFilterChange={(filter) => {
              props.location.state = null;
              setFilterBy(filter.value);
            }}
            onNewFilterChange={(filter) => {
              setSearch(true);
              setNewFilterBy(filter.value);
            }}
            refreshSkip={() => {
              setSkip(0);
            }}
            headers={headers}
            selectable={show(2, role, roles)}
            action={show(2, role, roles) && <View style={{ width: 20 }} />}
            onItemsSelected={(val) => {
              setSelectedUsers(val);
            }}
            onActionClicked={(item) => {
              setCurrentActionUser(item);
              setDetailModal(true);
            }}
            onPageChange={(pageNumber, itemsPerPage) => {
              setSkip((pageNumber - 1) * itemsPerPage);
              setLimit(itemsPerPage);
            }}
            onLimitChange={(val) => setLimit(val)}
            className="mt-4"
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  afrilearnUsers: state.afrilearnUsers.afrilearnUsers,
  count: state.afrilearnUsers.count,
  role: state.auth.user.role,
  roles: state.auth.roles,
  error: state.error,
  success: state.success,
});
export default connect(mapStateToProps, {
  fetchAfrilearnUsers,
  deleteAfrilearnUser,
  deleteAfrilearnUsers,
  clearErrors,
  clearSuccess,
})(AfrilearnUsers);
