export const REQUESTS_INPUT_CHANGE = "REQUESTS_INPUT_CHANGE";
export const INPUT_CHANGE = "INPUT_CHANGE";
export const ARRAY_INPUT_CHANGE = "ARRAY_INPUT_CHANGE";
export const OBJECT_PROP_INPUT_CHANGE = "OBJECT_PROP_INPUT_CHANGE";
export const INITIALIZE_STATE = "INITIALIZE_STATE";
export const INITIALIZE_STATE_SUCCESS = "INITIALIZE_STATE_SUCCESS";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_SUCCESS = "GET_SUCCESS";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const CLEAR_FORM = "CLEAR_FORM";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const PASSWORD_CHANGE_SUCCESS = "PASSWORD_CHANGE_SUCCESS";
export const PASSWORD_CHANGE_FAILURE = "PASSWORD_CHANGE_FAILURE";
export const PASSWORD_CHANGE_FROM_PROFILE_SUCCESS =
  "PASSWORD_CHANGE_FROM_PROFILE_SUCCESS";
export const PASSWORD_CHANGE_FROM_PROFILE_FAILURE =
  "PASSWORD_CHANGE_FROM_PROFILE_FAILURE";
export const SOCIAL_LOGIN_UPDATE_SUCCESS = "SOCIAL_LOGIN_UPDATE_SUCCESS";
export const SOCIAL_LOGIN_UPDATE_FAILURE = "SOCIAL_LOGIN_UPDATE_FAILURE";
export const COURSE_ENROLMENT_SUCCESS = "COURSE_ENROLMENT_SUCCESS";
export const COURSE_ENROLMENT_FAILURE = "COURSE_ENROLMENT_FAILURE";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";

export const COURSE_INPUT_CHANGE = "COURSE_INPUT_CHANGE";
export const GET_ALL_EDUCATIONAL_CATEGORIES_SUCCESS =
  "GET_ALL_EDUCATIONAL_CATEGORIES_SUCCESS";
export const GET_ALL_EDUCATIONAL_CATEGORIES_FAILURE =
  "GET_ALL_EDUCATIONAL_CATEGORIES_FAILURE";
export const GET_SINGLE_EDUCATIONAL_CATEGORY_SUCCESS =
  "GET_SINGLE_EDUCATIONAL_CATEGORY_SUCCESS";
export const GET_SINGLE_EDUCATIONAL_CATEGORY_FAILURE =
  "GET_SINGLE_EDUCATIONAL_CATEGORY_FAILURE";
export const POPULATE_DASHBOARD_SUCCESS = "POPULATE_DASHBOARD_SUCCESS";
export const POPULATE_DASHBOARD_FAILURE = "POPULATE_DASHBOARD_FAILURE";

export const GET_AFRICHALLENGE_DATA_BY_DATE_SUCCESS =
  "GET_AFRICHALLENGE_DATA_BY_DATE_SUCCESS";
export const GET_AFRICHALLENGE_DATA_BY_DATE_FAILURE =
  "GET_AFRICHALLENGE_DATA_BY_DATE_FAILURE";

export const GET_AFRICHALLENGE_WINNER_THIS_WEEK_SUCCESS =
  "GET_AFRICHALLENGE_WINNER_THIS_WEEK_SUCCESS";
export const GET_AFRICHALLENGE_WINNER_THIS_WEEK_FAILURE =
  "GET_AFRICHALLENGE_WINNER_THIS_WEEK_FAILURE";

export const GET_AFRICHALLENGE_WEEKLY_DATA_SUCCESS =
  "GET_AFRICHALLENGE_WEEKLY_DATA_SUCCESS";
export const GET_AFRICHALLENGE_WEEKLY_DATA_FAILURE =
  "GET_AFRICHALLENGE_WEEKLY_DATA_FAILURE";

export const GET_ALL_CMSUSERS_SUCCESS = "GET_ALL_CMSUSERS_SUCCESS";
export const GET_ALL_CMSUSERS_FAILURE = "GET_ALL_CMSUSERS_FAILURE";

export const ADD_CMSUSER_SUCCESS = "ADD_CMSUSER_SUCCESS";
export const ADD_CMSUSER_FAILURE = "ADD_CMSUSER_FAILURE";
export const EDIT_CMSUSER_SUCCESS = "EDIT_CMSUSER_SUCCESS";
export const EDIT_CMSUSER_FAILURE = "EDIT_CMSUSER_FAILURE";
export const DELETE_CMSUSERS_SUCCESS = "DELETE_CMSUSERS_SUCCESS";
export const DELETE_CMSUSERS_FAILURE = "DELETE_CMSUSERS_FAILURE";
export const DELETE_CMSUSER_SUCCESS = "DELETE_CMSUSER_SUCCESS";
export const DELETE_CMSUSER_FAILURE = "DELETE_CMSUSER_FAILURE";

export const PAYMENT_INPUT_CHANGE = "PAYMENT_INPUT_CHANGE";
export const GET_PAYMENT_PLANS_SUCCESS = "GET_PAYMENT_PLANS_SUCCESS";
export const GET_PAYMENT_PLANS_FAILURE = "GET_PAYMENT_PLANS_FAILURE";
export const ADD_OR_REMOVE_COINS_SUCCESS = "ADD_OR_REMOVE_COINS_SUCCESS";
export const ADD_OR_REMOVE_COINS_FAILURE = "ADD_OR_REMOVE_COINS_FAILURE";
export const ADD_PAYMENT_PLAN_SUCCESS = "ADD_PAYMENT_PLAN_SUCCESS";
export const ADD_PAYMENT_PLAN_FAILURE = "ADD_PAYMENT_PLAN_FAILURE";
export const EDIT_PAYMENT_PLAN_SUCCESS = "EDIT_PAYMENT_PLAN_SUCCESS";
export const EDIT_PAYMENT_PLAN_FAILURE = "EDIT_PAYMENT_PLAN_FAILURE";
export const DELETE_PAYMENT_PLAN_SUCCESS = "DELETE_PAYMENT_PLAN_SUCCESS";
export const DELETE_PAYMENT_PLAN_FAILURE = "DELETE_PAYMENT_PLAN_FAILURE";
export const DELETE_PAYMENT_PLANS_SUCCESS = "DELETE_PAYMENT_PLANS_SUCCESS";
export const DELETE_PAYMENT_PLANS_FAILURE = "DELETE_PAYMENT_PLANS_FAILURE";
export const CREATE_PAYMENT_TRANSACTION_SUCCESS =
  "CREATE_PAYMENT_TRANSACTION_SUCCESS";
export const CREATE_PAYMENT_TRANSACTION_FAILURE =
  "CREATE_PAYMENT_TRANSACTION_FAILURE";

export const LOAD_QUESTIONS_SUCCESS = "LOAD_QUESTIONS_SUCCESS";
export const LOAD_QUESTIONS_FAILURE = "LOAD_QUESTIONS_FAILURE";
export const PAST_QUESTIONS_INPUT_CHANGE = "PAST_QUESTIONS_INPUT_CHANGE";
export const GET_PAST_QUESTION_SUBJECTS_SUCCESS =
  "GET_PAST_QUESTION_SUBJECTS_SUCCESS";
export const GET_PAST_QUESTION_SUBJECTS_FAILURE =
  "GET_PAST_QUESTION_SUBJECTS_FAILURE";
export const FLAG_QUESTION_SUCCESS = "FLAG_QUESTION_SUCCESS";
export const FLAG_QUESTION_FAILURE = "FLAG_QUESTION_FAILURE";
export const SAVE_USER_ANSWER = "SAVE_USER_ANSWER";
export const UPDATE_QUESTION_TAG = "UPDATE_QUESTION_TAG";
export const POPULATE_SUBMITTED_ANSWER = "POPULATE_SUBMITTED_ANSWER";
export const SUBMIT_RESULT_SUCCESS = "SUBMIT_RESULT_SUCCESS";
export const SUBMIT_RESULT_FAILURE = "SUBMIT_RESULT_FAILURE";

export const GET_SUBJECT_AND_RELATED_LESSONS_SUCCESS =
  "GET_SUBJECT_AND_RELATED_LESSONS_SUCCESS";
export const GET_SUBJECT_AND_RELATED_LESSONS_FAILURE =
  "GET_SUBJECT_AND_RELATED_LESSONS_FAILURE";

export const GET_SINGLE_CLASS_SUCCESS = "GET_SINGLE_CLASS_SUCCESS";
export const GET_SINGLE_CLASS_FAILURE = "GET_SINGLE_CLASS_FAILURE";

export const GET_CLASSES_SUCCESS = "GET_CLASSES_SUCCESS";
export const GET_CLASSES_FAILURE = "GET_CLASSES_FAILURE";

export const ADD_REQUEST_REPLY_SUCCESS = "ADD_REQUEST_REPLY_SUCCESS";
export const ADD_REQUEST_REPLY_FAILURE = "ADD_REQUEST_REPLY_FAILURE";
export const GET_REQUESTS_SUCCESS = "GET_REQUESTS_SUCCESS";
export const GET_REQUESTS_FAILURE = "GET_REQUESTS_FAILURE";
export const GET_SUBJECTS_SUCCESS = "GET_SUBJECTS_SUCCESS";
export const GET_SUBJECTS_FAILURE = "GET_SUBJECTS_FAILURE";
export const ADD_SUBJECT_SUCCESS = "ADD_SUBJECT_SUCCESS";
export const ADD_SUBJECT_FAILURE = "ADD_SUBJECT_FAILURE";
export const EDIT_SUBJECT_SUCCESS = "EDIT_SUBJECT_SUCCESS";
export const EDIT_SUBJECT_FAILURE = "EDIT_SUBJECT_FAILURE";
export const DELETE_SUBJECT_SUCCESS = "DELETE_SUBJECT_SUCCESS";
export const DELETE_SUBJECT_FAILURE = "DELETE_SUBJECT_FAILURE";
export const DELETE_SUBJECTS_SUCCESS = "DELETE_SUBJECTS_SUCCESS";
export const DELETE_SUBJECTS_FAILURE = "DELETE_SUBJECTS_FAILURE";

export const GET_AFRILEARN_USER_FAILURE = "GET_AFRILEARN_USER_FAILURE";
export const GET_AFRILEARN_USER_SUCCESS = "GET_AFRILEARN_USER_SUCCESS";
export const ACTIVATE_USER_SUCCESS = "ACTIVATE_USER_SUCCESS";
export const ACTIVATE_USER_FAILURE = "ACTIVATE_USER_FAILURE";
export const FETCH_USER_ENROLLED_COURSES_SUCCESS =
  "FETCH_USER_ENROLLED_COURSES_SUCCESS";

export const ACTIVATION_INPUT_CHANGE = "ACTIVATION_INPUT_CHANGE";

export const CLEAR_QUIZ_STATE = "CLEAR_QUIZ_STATE";

export const GET_PAYMENT_TRANSACTIONS_SUCCESS =
  "GET_PAYMENT_TRANSACTIONS_SUCCESS";
export const GET_PAYMENT_TRANSACTIONS_FAILURE =
  "GET_PAYMENT_TRANSACTIONS_FAILURE";

export const GET_ALL_COINS_TRANSACTIONS_SUCCESS =
  "GET_ALL_COINS_TRANSACTIONS_SUCCESS";
export const GET_ALL_COINS_TRANSACTIONS_FAILURE =
  "GET_ALL_COINS_TRANSACTIONS_FAILURE";

export const GET_COINS_TRANSACTIONS_SUCCESS = "GET_COINS_TRANSACTIONS_SUCCESS";
export const GET_COINS_TRANSACTIONS_FAILURE = "GET_COINS_TRANSACTIONS_FAILURE";

export const GET_COINS_TRANSACTIONS_BY_YEAR_SUCCESS =
  "GET_COINS_TRANSACTIONS_BY_YEAR_SUCCESS";
export const GET_COINS_TRANSACTIONS_BY_YEAR_FAILURE =
  "GET_COINS_TRANSACTIONS_BY_YEAR_FAILURE";

export const GET_SIGNUPS_BY_DATE_SUCCESS = "GET_SIGNUPS_BY_DATE_SUCCESS";
export const GET_SIGNUPS_BY_DATE_FAILURE = "GET_SIGNUPS_BY_DATE_FAILURE";

export const DELETE_PAYMENT_TRANSACTIONS_SUCCESS =
  "DELETE_PAYMENT_TRANSACTIONS_SUCCESS";
export const DELETE_PAYMENT_TRANSACTIONS_FAILURE =
  "DELETE_PAYMENT_TRANSACTIONS_FAILURE";
export const DELETE_PAYMENT_TRANSACTION_SUCCESS =
  "DELETE_PAYMENT_TRANSACTION_SUCCESS";
export const DELETE_PAYMENT_TRANSACTION_FAILURE =
  "DELETE_PAYMENT_TRANSACTION_FAILURE";

export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_START = "LOAD_USER_START";

export const GET_TERMS_SUCCESS = "GET_TERMS_SUCCESS";
export const GET_TERMS_FAILURE = "GET_TERMS_FAILURE";
export const ADD_TERM_SUCCESS = "ADD_TERM_SUCCESS";
export const ADD_TERM_FAILURE = "ADD_TERM_FAILURE";
export const EDIT_TERM_SUCCESS = "EDIT_TERM_SUCCESS";
export const EDIT_TERM_FAILURE = "EDIT_TERM_FAILURE";
export const DELETE_TERM_SUCCESS = "DELETE_TERM_SUCCESS";
export const DELETE_TERM_FAILURE = "DELETE_TERM_FAILURE";
export const DELETE_TERMS_SUCCESS = "DELETE_TERMS_SUCCESS";
export const DELETE_TERMS_FAILURE = "DELETE_TERMS_FAILURE";

export const GET_PAST_QUESTION_CATEGORIES_SUCCESS =
  "GET_PAST_QUESTION_CATEGORIES_SUCCESS";
export const GET_PAST_QUESTION_CATEGORIES_FAILURE =
  "GET_PAST_QUESTION_CATEGORIES_FAILURE";
export const ADD_PAST_QUESTION_CATEGORY_SUCCESS =
  "ADD_PAST_QUESTION_CATEGORY_SUCCESS";
export const ADD_PAST_QUESTION_CATEGORY_FAILURE =
  "ADD_PAST_QUESTION_CATEGORY_FAILURE";
export const EDIT_PAST_QUESTION_CATEGORY_SUCCESS =
  "EDIT_PAST_QUESTION_CATEGORY_SUCCESS";
export const EDIT_PAST_QUESTION_CATEGORY_FAILURE =
  "EDIT_PAST_QUESTION_CATEGORY_FAILURE";
export const DELETE_PAST_QUESTION_CATEGORY_SUCCESS =
  "DELETE_PAST_QUESTION_CATEGORY_SUCCESS";
export const DELETE_PAST_QUESTION_CATEGORY_FAILURE =
  "DELETE_PAST_QUESTION_CATEGORY_FAILURE";
export const DELETE_PAST_QUESTION_CATEGORIES_SUCCESS =
  "DELETE_PAST_QUESTION_CATEGORIES_SUCCESS";
export const DELETE_PAST_QUESTION_CATEGORIES_FAILURE =
  "DELETE_PAST_QUESTION_CATEGORIES_FAILURE";

export const GET_EDUCATIONAL_CATEGORIES_SUCCESS =
  "GET_EDUCATIONAL_CATEGORIES_SUCCESS";
export const GET_EDUCATIONAL_CATEGORIES_FAILURE =
  "GET_EDUCATIONAL_CATEGORIES_FAILURE";
export const ADD_EDUCATIONAL_CATEGORY_SUCCESS =
  "ADD_EDUCATIONAL_CATEGORY_SUCCESS";
export const ADD_EDUCATIONAL_CATEGORY_FAILURE =
  "ADD_EDUCATIONAL_CATEGORY_FAILURE";
export const EDIT_EDUCATIONAL_CATEGORY_SUCCESS =
  "EDIT_EDUCATIONAL_CATEGORY_SUCCESS";
export const EDIT_EDUCATIONAL_CATEGORY_FAILURE =
  "EDIT_EDUCATIONAL_CATEGORY_FAILURE";
export const DELETE_EDUCATIONAL_CATEGORY_SUCCESS =
  "DELETE_EDUCATIONAL_CATEGORY_SUCCESS";
export const DELETE_EDUCATIONAL_CATEGORY_FAILURE =
  "DELETE_EDUCATIONAL_CATEGORY_FAILURE";
export const DELETE_EDUCATIONAL_CATEGORIES_SUCCESS =
  "DELETE_EDUCATIONAL_CATEGORIES_SUCCESS";
export const DELETE_EDUCATIONAL_CATEGORIES_FAILURE =
  "DELETE_EDUCATIONAL_CATEGORIES_FAILURE";

export const GET_COUNTS_SUCCESS = "GET_COUNTS_SUCCESS";
export const GET_COUNTS_FAILURE = "GET_COUNTS_FAILURE";

export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_FAILURE = "GET_COURSES_FAILURE";
export const ADD_COURSE_SUCCESS = "ADD_COURSE_SUCCESS";
export const ADD_COURSE_FAILURE = "ADD_COURSE_FAILURE";
export const EDIT_COURSE_SUCCESS = "EDIT_COURSE_SUCCESS";
export const EDIT_COURSE_FAILURE = "EDIT_COURSE_FAILURE";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_FAILURE = "DELETE_COURSE_FAILURE";
export const DELETE_COURSES_SUCCESS = "DELETE_COURSES_SUCCESS";
export const DELETE_COURSES_FAILURE = "DELETE_COURSES_FAILURE";

export const GET_CLASS_CONTENTS_SUCCESS = "GET_CLASS_CONTENTS_SUCCESS";
export const GET_CLASS_CONTENTS_FAILURE = "GET_CLASS_CONTENTS_FAILURE";

export const GET_CONTENT_RELATED_CONTENTS_SUCCESS =
  "GET_CONTENT_RELATED_CONTENTS_SUCCESS";
export const GET_CONTENT_RELATED_CONTENTS_FAILURE =
  "GET_CONTENT_RELATED_CONTENTS_FAILURE";
export const GET_CLASS_CONTENT_SUCCESS = "GET_CLASS_CONTENT_SUCCESS";
export const GET_CLASS_CONTENT_FAILURE = "GET_CLASS_CONTENT_FAILURE";
export const ADD_CLASS_CONTENT_SUCCESS = "ADD_CLASS_CONTENT_SUCCESS";
export const ADD_CLASS_CONTENT_FAILURE = "ADD_CLASS_CONTENT_FAILURE";
export const EDIT_CLASS_CONTENT_SUCCESS = "EDIT_CLASS_CONTENT_SUCCESS";
export const EDIT_CLASS_CONTENT_FAILURE = "EDIT_CLASS_CONTENT_FAILURE";
export const DELETE_CLASS_CONTENT_SUCCESS = "DELETE_CLASS_CONTENT_SUCCESS";
export const DELETE_CLASS_CONTENT_FAILURE = "DELETE_CLASS_CONTENT_FAILURE";
export const DELETE_CLASS_CONTENTS_SUCCESS = "DELETE_CLASS_CONTENTS_SUCCESS";
export const DELETE_CLASS_CONTENTS_FAILURE = "DELETE_CLASS_CONTENTS_FAILURE";

export const GET_QUIZZES_SUCCESS = "GET_QUIZZES_SUCCESS";
export const GET_QUIZZES_FAILURE = "GET_QUIZZES_FAILURE";
export const GET_QUIZ_SUCCESS = "GET_QUIZ_SUCCESS";
export const GET_QUIZ_FAILURE = "GET_QUIZ_FAILURE";
export const ADD_QUIZ_SUCCESS = "ADD_QUIZ_SUCCESS";
export const ADD_QUIZ_FAILURE = "ADD_QUIZ_FAILURE";
export const EDIT_QUIZ_SUCCESS = "EDIT_QUIZ_SUCCESS";
export const EDIT_QUIZ_FAILURE = "EDIT_QUIZ_FAILURE";
export const DELETE_QUIZ_SUCCESS = "DELETE_QUIZ_SUCCESS";
export const DELETE_QUIZ_FAILURE = "DELETE_QUIZ_FAILURE";
export const DELETE_QUIZZES_SUCCESS = "DELETE_QUIZZES_SUCCESS";
export const DELETE_QUIZZES_FAILURE = "DELETE_QUIZZES_FAILURE";

export const CREATE_COMMENT_TO_ANNOUNCEMENT_SUCCESS =
  "CREATE_COMMENT_TO_ANNOUNCEMENT_SUCCESS";
export const CREATE_COMMENT_TO_ANNOUNCEMENT_FAILURE =
  "CREATE_COMMENT_TO_ANNOUNCEMENT_FAILURE";

export const ADD_ANNOUNCEMENT_SUCCESS = "ADD_ANNOUNCEMENT_SUCCESS";
export const ADD_ANNOUNCEMENT_FAILURE = "ADD_ANNOUNCEMENT_FAILURE";

export const CREATE_COMMENT_TO_CONTENT_SUCCESS =
  "CREATE_COMMENT_TO_CONTENT_SUCCESS";
export const CREATE_COMMENT_TO_CONTENT_FAILURE =
  "CREATE_COMMENT_TO_CONTENT_FAILURE";

export const ASSIGN_CONTENT_TO_STUDENT_FAILURE =
  "ASSIGN_CONTENT_TO_STUDENT_FAILURE";
export const ASSIGN_CONTENT_TO_STUDENT_SUCCESS =
  "ASSIGN_CONTENT_TO_STUDENT_SUCCESS";

export const SEND_CLASS_REQUEST_SUCCESS = "SEND_CLASS_REQUEST_SUCCESS";
export const SEND_CLASS_REQUEST_FAILURE = "SEND_CLASS_REQUEST_FAILURE";

export const SEND_CLASS_INVITE_SUCCESS = "SEND_CLASS_INVITE_SUCCESS";
export const SEND_CLASS_INVITE_FAILURE = "SEND_CLASS_INVITE_FAILURE";

export const CHECK_USER_AND_JOIN_CLASS_SUCCESS =
  "CHECK_USER_AND_JOIN_CLASS_SUCCESS";
export const CHECK_USER_AND_JOIN_CLASS_FAILURE =
  "CHECK_USER_AND_JOIN_CLASS_FAILURE";

export const USER_JOIN_THROUGH_INVITE_SUCCESS =
  "USER_JOIN_THROUGH_INVITE_SUCCESS";
export const USER_JOIN_THROUGH_INVITE_FAILURE =
  "USER_JOIN_THROUGH_INVITE_FAILURE";

export const GET_ALL_SEARCH_RESULTS_SUCCESS = "GET_ALL_SEARCH_RESULTS_SUCCESS";
export const GET_ALL_SEARCH_RESULTS_FAILURE = "GET_ALL_SEARCH_RESULTS_FAILURE";

export const GET_ALL_AFRILEARN_USERS_SUCCESS =
  "GET_ALL_AFRILEARN_USERS_SUCCESS";
export const GET_ALL_AFRILEARN_USERS_FAILURE =
  "GET_ALL_AFRILEARN_USERS_FAILURE";
export const DELETE_AFRILEARN_USER_SUCCESS = "DELETE_AFRILEARN_USER_SUCCESS";
export const DELETE_AFRILEARN_USER_FAILURE = "DELETE_AFRILEARN_USER_FAILURE";
export const DELETE_AFRILEARN_USERS_SUCCESS = "DELETE_AFRILEARN_USERS_SUCCESS";
export const DELETE_AFRILEARN_USERS_FAILURE = "DELETE_AFRILEARN_USERS_FAILURE";

export const UPDATE_AFRILEARN_USER_SUCCESS = "UPDATE_AFRILEARN_USER_SUCCESS";
export const UPDATE_AFRILEARN_USER_FAILURE = "UPDATE_AFRILEARN_USER_FAILURE";

export const REMOVE_USER_FEEDBACK_SUCCESS = "REMOVE_USER_FEEDBACK_SUCCESS";
export const REMOVE_USER_FEEDBACK_FAILURE = "REMOVE_USER_FEEDBACK_FAILURE";

export const INITIALIZE_BLOG_ARTICLE_STATE = "INITIALIZE_BLOG_ARTICLE_STATE";

export const GET_BLOG_ARTICLE_CATEGORIES_FAILURE =
  "GET_BLOG_ARTICLE_CATEGORIES_FAILURE";
export const GET_BLOG_ARTICLE_CATEGORIES_SUCCESS =
  "GET_BLOG_ARTICLE_CATEGORIES_SUCCESS";
export const DELETE_BLOG_ARTICLE_CATEGORIES_FAILURE =
  "DELETE_BLOG_ARTICLE_CATEGORIES_FAILURE";
export const DELETE_BLOG_ARTICLE_CATEGORIES_SUCCESS =
  "DELETE_BLOG_ARTICLE_CATEGORIES_SUCCESS";
export const DELETE_BLOG_ARTICLE_CATEGORY_FAILURE =
  "DELETE_BLOG_ARTICLE_CATEGORY_FAILURE";
export const DELETE_BLOG_ARTICLE_CATEGORY_SUCCESS =
  "DELETE_BLOG_ARTICLE_CATEGORY_SUCCESS";
export const ADD_BLOG_ARTICLE_CATEGORY_SUCCESS =
  "ADD_BLOG_ARTICLE_CATEGORY_SUCCESS";
export const ADD_BLOG_ARTICLE_CATEGORY_FAILURE =
  "ADD_BLOG_ARTICLE_CATEGORY_FAILURE";
export const EDIT_BLOG_ARTICLE_CATEGORY_SUCCESS =
  "EDIT_BLOG_ARTICLE_CATEGORY_SUCCESS";
export const EDIT_BLOG_ARTICLE_CATEGORY_FAILURE =
  "EDIT_BLOG_ARTICLE_CATEGORY_FAILURE";

export const GET_BLOG_ARTICLES_FAILURE = "GET_BLOG_ARTICLES_FAILURE";
export const GET_BLOG_ARTICLES_SUCCESS = "GET_BLOG_ARTICLES_SUCCESS";
export const GET_BLOG_ARTICLE_FAILURE = "GET_BLOG_ARTICLE_FAILURE";
export const GET_BLOG_ARTICLE_SUCCESS = "GET_BLOG_ARTICLE_SUCCESS";
export const DELETE_BLOG_ARTICLES_FAILURE = "DELETE_BLOG_ARTICLES_FAILURE";
export const DELETE_BLOG_ARTICLES_SUCCESS = "DELETE_BLOG_ARTICLES_SUCCESS";
export const DELETE_BLOG_ARTICLE_FAILURE = "DELETE_BLOG_ARTICLE_FAILURE";
export const DELETE_BLOG_ARTICLE_SUCCESS = "DELETE_BLOG_ARTICLE_SUCCESS";
export const ADD_BLOG_ARTICLE_SUCCESS = "ADD_BLOG_ARTICLE_SUCCESS";
export const ADD_BLOG_ARTICLE_FAILURE = "ADD_BLOG_ARTICLE_FAILURE";
export const EDIT_BLOG_ARTICLE_SUCCESS = "EDIT_BLOG_ARTICLE_SUCCESS";
export const EDIT_BLOG_ARTICLE_FAILURE = "EDIT_BLOG_ARTICLE_FAILURE";
export const BLOG_ARTICLES_INPUT_CHANGE = "BLOG_ARTICLES_INPUT_CHANGE";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILURE = "SEND_EMAIL_FAILURE";

export const SEARCH_INPUT_CHANGE = "SEARCH_INPUT_CHANGE";
export const QUIZ_INPUT_CHANGE = "QUIZ_INPUT_CHANGE";
export const ASSIGN_SUBJECTS_INPUT_CHANGE = "ASSIGN_SUBJECTS_INPUT_CHANGE";
export const CLASS_CONTENTS_INPUT_CHANGE = "CLASS_CONTENTS_INPUT_CHANGE";

export const GET_PERFORMANCE_SUCCESS = "GET_PERFORMANCE_SUCCESS";
export const GET_PERFORMANCE_FAILURE = "GET_PERFORMANCE_FAILURE";

export const GET_PERFORMANCE_IN_CLASS_SUCCESS =
  "GET_PERFORMANCE_IN_CLASS_SUCCESS";
export const GET_PERFORMANCE_IN_CLASS_FAILURE =
  "GET_PERFORMANCE_IN_CLASS_FAILURE";

export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

export const UPDATE_PROFILE_PIC_SUCCESS = "UPDATE_PROFILE_PIC_SUCCESS";
export const UPDATE_PROFILE__PIC_FAILURE = "UPDATE_PROFILE__PIC_FAILURE";

export const ADD_RECENT_ACTIVITIES_SUCCESS = "ADD_RECENT_ACTIVITIES_SUCCESS";
export const ADD_RECENT_ACTIVITIES_FAILURE = "ADD_RECENT_ACTIVITIES_FAILURE";

export const GET_ALL_SUBJECT_PROGRESSES_SUCCESS =
  "GET_ALL_SUBJECT_PROGRESSES_SUCCESS";
export const GET_ALL_SUBJECT_PROGRESSES_FAILURE =
  "GET_ALL_SUBJECT_PROGRESSES_FAILURE";

export const ADD_SUBJECT_PROGRESS_SUCCESS = "ADD_SUBJECT_PROGRESS_SUCCESS";
export const ADD_SUBJECT_PROGRESS_FAILURE = "ADD_SUBJECT_PROGRESS_FAILURE";

export const ACCEPT_REJECT_CLASSMEMBER_SUCCESS =
  "ACCEPT_REJECT_CLASSMEMBER_SUCCESS";
export const ACCEPT_REJECT_CLASSMEMBER_FAILURE =
  "ACCEPT_REJECT_CLASSMEMBER_FAILURE";

export const ADD_CLASS_SUCCESS = "ADD_CLASS_SUCCESS";
export const ADD_CLASS_FAILURE = "ADD_CLASS_FAILURE";

export const ADD_ASSIGNED_SUBJECT_SUCCESS = "ADD_ASSIGNED_SUBJECT_SUCCESS";
export const ADD_ASSIGNED_SUBJECT_FAILURE = "ADD_ASSIGNED_SUBJECT_FAILURE";
export const GET_ASSIGNED_SUBJECTS_SUCCESS = "GET_ASSIGNED_SUBJECTS_SUCCESS";
export const GET_ASSIGNED_SUBJECTS_FAILURE = "GET_ASSIGNED_SUBJECTS_FAILURE";
export const DELETE_ASSIGNED_SUBJECT_SUCCESS =
  "DELETE_ASSIGNED_SUBJECT_SUCCESS";
export const DELETE_ASSIGNED_SUBJECT_FAILURE =
  "DELETE_ASSIGNED_SUBJECT_FAILURE";

export const ADD_ASSIGNED_PAST_QUESTION_SUCCESS =
  "ADD_ASSIGNED_PAST_QUESTION_SUCCESS";
export const ADD_ASSIGNED_PAST_QUESTION_FAILURE =
  "ADD_ASSIGNED_PAST_QUESTION_FAILURE";
export const GET_ASSIGNED_PAST_QUESTIONS_SUCCESS =
  "GET_ASSIGNED_PAST_QUESTIONS_SUCCESS";
export const GET_ASSIGNED_PAST_QUESTIONS_FAILURE =
  "GET_ASSIGNED_PAST_QUESTIONS_FAILURE";
export const DELETE_ASSIGNED_PAST_QUESTION_SUCCESS =
  "DELETE_ASSIGNED_PAST_QUESTION_SUCCESS";
export const DELETE_ASSIGNED_PAST_QUESTION_FAILURE =
  "DELETE_ASSIGNED_PAST_QUESTION_FAILURE";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const UPDATE_CLASS_TEXTS_AND_THUMBNAIL_SUCCESS =
  "UPDATE_CLASS_TEXTS_AND_THUMBNAIL_SUCCESS";
export const UPDATE_CLASS_TEXTS_AND_THUMBNAIL_FAILURE =
  "UPDATE_CLASS_TEXTS_AND_THUMBNAIL_FAILURE";

export const DELETE_VIDEO_LESSON_SUCCESS = "DELETE_VIDEO_LESSON_SUCCESS";
export const DELETE_VIDEO_LESSON_FAILURE = "DELETE_VIDEO_LESSON_FAILURE";

export const UPDATE_VIDEO_LESSON_SUCCESS = "UPDATE_VIDEO_LESSON_SUCCESS";
export const UPDATE_VIDEO_LESSON_FAILURE = "UPDATE_VIDEO_LESSON_FAILURE";

export const ADD_VIDEO_LESSON_SUCCESS = "ADD_VIDEO_LESSON_SUCCESS";
export const ADD_VIDEO_LESSON_FAILURE = "ADD_VIDEO_LESSON_FAILURE";

export const ADD_IMPACT_STORY_SUCCESS = "ADD_IMPACT_STORY_SUCCESS";
export const ADD_IMPACT_STORY_FAILURE = "ADD_IMPACT_STORY_FAILURE";

export const UPDATE_IMPACT_STORY_SUCCESS = "UPDATE_IMPACT_STORY_SUCCESS";
export const UPDATE_IMPACT_STORY_FAILURE = "UPDATE_IMPACT_STORY_FAILURE";

export const GET_IMPACT_STORIES_SUCCESS = "GET_IMPACT_STORIES_SUCCESS";
export const GET_IMPACT_STORIES_FAILURE = "GET_IMPACT_STORIES_FAILURE";

export const GET_IMPACT_STORY_SUCCESS = "GET_IMPACT_STORY_SUCCESS";
export const GET_IMPACT_STORY_FAILURE = "GET_IMPACT_STORY_FAILURE";

export const GET_IMPACT_STORIES_CATEGORIES_SUCCESS =
  "GET_IMPACT_STORIES_CATEGORIES_SUCCESS";
export const GET_IMPACT_STORIES_CATEGORIES_FAILURE =
  "GET_IMPACT_STORIES_CATEGORIES_FAILURE";

export const DELETE_IMPACT_STORIES_SUCCESS = "DELETE_IMPACT_STORIES_SUCCESS";
export const DELETE_IMPACT_STORIES_FAILURE = "DELETE_IMPACT_STORIES_FAILURE";

export const DELETE_IMPACT_STORY_SUCCESS = "DELETE_IMPACT_STORY_SUCCESS";
export const DELETE_IMPACT_STORY_FAILURE = "DELETE_IMPACT_STORY_FAILURE";

export const IMPACT_STORIES_INPUT_CHANGE = "IMPACT_STORIES_INPUT_CHANGE";
