import React, { useEffect, useState, useRef, forwardRef } from "react";
import SelectField from "../../includes/select-field/select.component";
import { ReactComponent as Plus } from "../../../assets/icons/PlusDark.svg";
import Button from "../../includes/button/button.component";
import { connect } from "react-redux";
import {
  inputChange,
  assignPastQuestionType,
  fetchAssignedPastQuestionTypes,
  deleteAssignedPastQuestionType,
  initializeState,
} from "../../../redux/actions/assignPastQuestionTypesActions";
import { fetchCourses } from "../../../redux/actions/coursesActions";
import { fetchPastQuestionCategories } from "../../../redux/actions/pastQuestionTypesActions";
import { clearErrors } from "../../../redux/actions/errorActions";
import { clearSuccess } from "../../../redux/actions/successActions";

// import FileUploader from "../../includes/file-uploader/fileUploader.component";
import Snackbar from "../../includes/snackbar/snackbar.component";

import AssignedPastQuestionTypes from "./assignedPastQuestion";
import { Redirect } from "react-router-dom";
import { show } from "../../../assets/js/globalFunctions";

// import "./css/style.css";

const AssignPastQuestionTypes = (props) => {
  const {
    courses,
    pastQuestionTypes,
    pastQuestionType,
    role,
    roles,
    success,
    error,
    assignedPastQuestionTypes,
  } = props;
  const [fetchingAssigned, setFetchingAssigned] = useState(false);
  const [snack, setSnack] = useState({ message: "", type: "error" });
  const mounted = useRef(false);
  const [course, setCourse] = useState({});
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (success.id) {
        let id = success.id;
        const snack = {
          message:
            typeof success.msg === "object"
              ? success.msg.join("<br/>")
              : success.msg,
          type: "success",
        };
        switch (id) {
          case "ADD_ASSIGNED_PAST_QUESTION_SUCCESS":
            setSnack(snack);
            props.clearSuccess();
            break;
          case "DELETE_ASSIGNED_PAST_QUESTION_SUCCESS":
            setSnack(snack);
            props.clearSuccess();
            break;
        }
        props.clearSuccess();
      } else if (error.id) {
        let id = error.id;
        const snack = {
          message:
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg,
          type: "error",
        };
        if (id === "ADD_ASSIGNED_PAST_QUESTION_FAILURE") {
          setSnack(snack);
        }
        props.clearErrors();
      }
    }
  });

  useEffect(() => {
    if (role) {
      props.fetchCourses();
      props.fetchPastQuestionCategories();
      props.initializeState();
    }
  }, [role]);

  useEffect(() => {
    if (course._id) {
      fetchAssigned();
    }
  }, [course]);

  const fetchAssigned = async () => {
    setFetchingAssigned(true);
    await props.fetchAssignedPastQuestionTypes(course);
    setFetchingAssigned(false);
  };

  const assign = () => {
    let message;
    if (!course) message = "Please select a class";
    if (!pastQuestionType) message = "Please select a past question type";
    if (assigned(pastQuestionType))
      message = "Cannot override existing past question type";
    // if (!media) message = "Please add an image";

    if (message) {
      setSnack({ message, type: "error" });
      return;
    }

    props.assignPastQuestionType(course, pastQuestionType);
  };

  const assigned = (pqType) => {
    return assignedPastQuestionTypes.find(
      (assigned) => assigned.name == pqType
    );
  };

  const selectPastQuestionType = (e) => {
    handleInput(e);
    if (assigned(e.target.value)) {
      setSnack({ message: "Past Question Type already assigned" });
    }
  };

  const deleteAssigned = (pastQuestionType) => {
    props.deleteAssignedPastQuestionType(course, pastQuestionType);
  };

  const handleInput = (e) => {
    props.inputChange(e.target.name, e.target.value);
  };

  return (
    <div id="assign-subject" className="page-padding">
      {role && (
        <div>
          {!show(1, role, roles) ? <Redirect to="/" /> : null}
          <Snackbar
            snack={snack}
            reset={() => {
              setSnack({ message: "" });
            }}
          />
          <h3 className="mb-md-5 mb-3 title-text">
            Assign Past Question Category to Class
          </h3>
          <div className="d-flex align-items-center ">
            <span className="mr-4">Classes</span>
            <SelectField
              items={courses}
              style={{ maxWidth: 250 }}
              depressed
              value={course}
              textField="name"
              valueField="_id"
              name="course"
              onSelect={(e) => {
                setCourse(e.target.value);
              }}
            />
          </div>

          <div
            style={{ borderBottom: "4px solid var(--primary-color)" }}
            className="mt-md-5 mt-3 mb-md-4 mb-2"
          >
            <h4 className="">{course.name || "Please Select A Class"}</h4>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <button style={{ backgroundColor: "inherit", border: "none" }}>
                <div className="d-flex align-items-center">
                  <Plus style={{ width: 18 }} />
                  <span className="ml-2" style={{ fontSize: "110%" }}>
                    Assign New
                  </span>
                </div>
              </button>

              <SelectField
                className="mt-4 mb-5"
                placeholder="Select Past Question Category"
                value={pastQuestionType}
                valueField="_id"
                textField="name"
                name="pastQuestionType"
                items={pastQuestionTypes}
                onSelect={selectPastQuestionType}
              />
              <div className="center my-md-5 my-3">
                <Button onClick={assign}>Assign</Button>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="assigned-display">
                <div
                  style={{
                    padding: "20px 30px 10px 30px",
                    borderBottom: "1px solid rgba(0,0,0,0.3)",
                  }}
                >
                  <h5 className="font2">Assigned Past Question Categories</h5>
                </div>
                <div style={{ padding: "20px 30px 0px 30px" }}>
                  {!fetchingAssigned && (
                    <div style={{ marginTop: "-20px" }}>
                      {assignedPastQuestionTypes.length > 0 &&
                        assignedPastQuestionTypes.map(
                          (pastQuestionType, index) => (
                            <AssignedPastQuestionTypes
                              pastQuestionType={pastQuestionType}
                              key={index}
                              onDeleteAssigned={deleteAssigned}
                            />
                          )
                        )}
                      {!course._id && (
                        <div className="center pt-3 pb-3">Select a class</div>
                      )}
                      {course._id && assignedPastQuestionTypes.length < 1 && (
                        <div className="center pt-3 pb-3">
                          No past question category has been assigned to this
                          class
                        </div>
                      )}
                    </div>
                  )}
                  {fetchingAssigned && (
                    <div className="center pt-3 pb-3">
                      <div className="loader-medium" style={{}}></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  pastQuestionTypes: state.pastQuestionCategories.pastQuestionCategories,
  pastQuestionType: state.assignPastQuestionType.pastQuestionType,
  courses: state.courses.courses,
  assignedPastQuestionTypes:
    state.assignPastQuestionType.assignedPastQuestionTypes,
  role: state.auth.user.role,
  roles: state.auth.roles,
  error: state.error,
  success: state.success,
});

export default connect(mapStateToProps, {
  inputChange,
  fetchPastQuestionCategories,
  fetchCourses,
  assignPastQuestionType,
  clearErrors,
  clearSuccess,
  initializeState,
  fetchAssignedPastQuestionTypes,
  deleteAssignedPastQuestionType,
})(AssignPastQuestionTypes);
