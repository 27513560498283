import React, { useState, useEffect, useRef } from 'react'
import SunEditor from 'suneditor-react'
import { useHistory, Redirect } from 'react-router-dom'
import 'suneditor/dist/css/suneditor.min.css'
import Button from '../../includes/button/button.component'
import SelectField from '../../includes/select-field/select.component'
import TextField from '../../includes/text-field/textField.component'
import { ReactComponent as Play } from '../../../assets/icons/Play.svg'
import FileUploader from '../../includes/file-uploader/fileUploader.component'
import { ReactComponent as Plus } from '../../../assets/icons/Plus.svg'
import {
  inputChange,
  addBlogArticle,
  initializeState
} from '../../../redux/actions/blogArticlesActions'
import {
  fetchBlogArticleCategories,
  addBlogArticleCategory
} from '../../../redux/actions/blogArticleCategoriesActions'
import { connect } from 'react-redux'
import { clearErrors } from '../../../redux/actions/errorActions'
import { clearSuccess } from '../../../redux/actions/successActions'
import Snackbar from '../../includes/snackbar/snackbar.component'
import { ReactComponent as Times } from '../../../assets/icons/Times.svg'
import { deleteTags, show } from '../../../assets/js/globalFunctions'
import Checkbox from '../../includes/checkbox/checkbox.component'

import './css/style.css'

const btnList = [
  ['undo', 'redo'],
  ['font', 'fontSize', 'formatBlock'],
  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
  ['removeFormat'],
  '/',
  ['fontColor', 'hiliteColor'],
  ['outdent', 'indent'],
  ['align', 'horizontalRule', 'list', 'table'],
  ['link', 'image'],
  ['fullScreen', 'showBlocks', 'codeView']
]

const BlogArticleCreation = props => {
  const { articleCategories, role, roles, success, error } = props
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [media, setMedia] = useState(null)
  const [category, setCategory] = useState({})
  const [newCategory, setNewCategory] = useState('')
  const [snackbar, setSnackbar] = useState({ message: '' })
  const [addingCategory, setAddingCategory] = useState(false)
  const [isHeadline, setIsHeadline] = useState(false)

  const contentImagesRef = useRef([])
  const fileUploaderRef = useRef()
  const mounted = useRef(false)
  const history = useHistory()

  useEffect(() => {
    if (!mounted.current) {
      props.fetchBlogArticleCategories()
      mounted.current = true
    } else {
      if (success.id) {
        const snack = {
          message:
            typeof success.msg === 'object'
              ? success.msg.join('<br/>')
              : success.msg,
          type: 'success'
        }

        switch (success.id) {
          case 'ADD_BLOG_ARTICLE_SUCCESS':
            setSnackbar({ ...snack, timeout: 1500 })
            setTimeout(() => {
              history.push('/blog/articles')
            }, 2000)
            break
          case 'ADD_BLOG_ARTICLE_CATEGORY_SUCCESS':
            setSnackbar({ ...snack })
            setAddingCategory(false)
            setNewCategory('')
            break
        }
        props.clearSuccess()
      } else if (error.id) {
        const snack = {
          message:
            typeof error.msg === 'object' ? error.msg.join('<br/>') : error.msg,
          type: 'error'
        }

        switch (error.id) {
          case 'ADD_BLOG_ARTICLE_FAILURE':
            setSnackbar(snack)
            break
          case 'ADD_BLOG_ARTICLE_CATEGORY_FAILURE':
            setSnackbar({ ...snack })
            setAddingCategory(false)
            break
        }
        props.clearErrors()
      }
    }
  })

  const handleImageUpload = (targetImgElement, index, state, imageInfo) => {
    if (state === 'create') {
      if (
        imageInfo.src.includes('http://') ||
        imageInfo.src.includes('https://')
      )
        return
      var blobBin = atob(imageInfo.src.split(',')[1])
      var array = []
      for (var i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i))
      }
      var file = new Blob([new Uint8Array(array)], { type: 'image/jpg' })
      contentImagesRef.current = [
        ...contentImagesRef.current,
        { file: file, src: imageInfo.src }
      ]
    }
  }

  const submit = () => {
    let message = ''
    if (!title) message = 'Title cannot be empty'
    else if (!deleteTags(content)) message = 'Content cannot be empty'
    else if (!media) message = 'You have to select a media'
    else if (!category) message = 'You have to select a category'

    if (message) {
      setSnackbar({ message })
      return
    }

    // const contImages = getAllContentMediaUrl(content)
    // contentImagesRef.current = contentImagesRef.current.filter(
    //   img => !contImages.includes(img.src)
    // )

    props.addBlogArticle({
      title,
      content,
      categoryId: category._id,
      media,
      isHeadline,
      contentImages: contentImagesRef.current.map(cnt => cnt.file),
      contentUrls: contentImagesRef.current.map(cnt => cnt.src)
    })
  }
  const setTitle_ = e => {
    setTitle(e.target.value)
  }

  const setMedia_ = e => {
    setMedia(e.target.value)
  }

  const setCategory_ = e => {
    setCategory(e.target.value)
  }

  const setNewCategory_ = e => {
    setNewCategory(e.target.value)
  }

  const addNewCategory = async () => {
    if (!newCategory) {
      setSnackbar({ message: 'Please enter new category' })
      return
    }
    setAddingCategory(true)
    await props.addBlogArticleCategory({ name: newCategory }, false)
  }

  return (
    <div id='content-creation' className='page-padding'>
      {role && (
        <>
          {!show(1, role, roles) ? <Redirect to='/dashboard' /> : null}
          <Snackbar
            snack={snackbar}
            reset={() => {
              setSnackbar({ message: '' })
            }}
          />
          <div
            className='d-flex justify-content-between align-items-center'
            style={{
              borderLeft: '5px solid var(--primary-color)',
              paddingLeft: '8px'
            }}
          >
            <h4 style={{ margin: '0', flexGrow: 1 }} className='title-text'>
              Blog Post
            </h4>
            <Button onClick={submit}>Save</Button>
          </div>
          <div className='row mt-5'>
            <div className='col-12 col-lg-8'>
              <TextField
                placeholder='Title'
                name='title'
                value={title}
                onInput={setTitle_}
                plain
                className='mb-4'
              />
              <div className='note-markup'>
                {/* {initialized.current && ( */}
                <SunEditor
                  setOptions={{
                    height: 515,
                    buttonList: btnList,
                    toolbarContainer: '#toolbar-container'
                  }}
                  onChange={cont => {
                    setContent(cont)
                  }}
                  onImageUpload={handleImageUpload}
                  setContents={content}
                />
                {/* )} */}
              </div>
            </div>
            <div className='col-12 col-lg-4 mt-4 mt-lg-0'>
              <div
                style={{
                  backgroundColor: 'white',
                  padding: '20px 0 60px 0',
                  borderRadius: '10px'
                }}
              >
                <h4 className='pl-4'>Category</h4>
                <hr />
                <div className='pl-4 pr-4'>
                  <div className='mb-2'>Select Category</div>
                  <SelectField
                    value={category}
                    onSelect={setCategory_}
                    name='course'
                    items={articleCategories}
                    textField='name'
                    valueField='_id'
                    outlined
                    placeholder='Select Category'
                    color='#B6B6B6'
                    inputStyle={{ borderRadius: 5, paddingLeft: 15 }}
                    className='mb-4'
                  ></SelectField>
                  <div className='mb-2'>Create new category</div>
                  <TextField
                    value={newCategory}
                    onInput={setNewCategory_}
                    name='new category'
                    outlined
                    placeholder='Create Category'
                    color='#B6B6B6'
                    inputStyle={{ borderRadius: 5, paddingLeft: 15 }}
                    className='mb-2'
                  ></TextField>
                  <div className='right'>
                    {!addingCategory && (
                      <span
                        className='hover-style pointer'
                        style={{ color: 'var(--primary-color)' }}
                        onClick={addNewCategory}
                      >
                        Add
                      </span>
                    )}
                    {addingCategory && <div className='loader-small'></div>}
                  </div>
                </div>
              </div>

              <FileUploader
                className='mt-4'
                ref={fileUploaderRef}
                onInput={setMedia_}
                validTypes='image/jpg, image/png, image/gif, video/mp4'
              />

              <div
                style={{
                  backgroundColor: 'white',
                  padding: '20px 0 20px 0',
                  borderRadius: '10px'
                }}
                className='mt-4 pl-4 pr-4 d-flex align-items-center'
              >
                <span className='mr-2'>Make Headline:</span>
                <Checkbox
                  checked={isHeadline}
                  onChange={val => {
                    setIsHeadline(val)
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
const mapStateToProps = state => ({
  articleCategories: state.blogArticleCategories.blogArticleCategories,
  role: state.auth.user.role,
  roles: state.auth.roles,
  error: state.error,
  success: state.success
})
export default connect(mapStateToProps, {
  inputChange,
  addBlogArticle,
  initializeState,
  fetchBlogArticleCategories,
  addBlogArticleCategory,
  clearSuccess,
  clearErrors
})(BlogArticleCreation)
