import React, { useEffect, useRef } from "react";
import {
  inputChange,
  addCourse,
  editCourse,
} from "../../../redux/actions/coursesActions";
import {
  fetchEducationalCategories
} from "../../../redux/actions/educationalCategoriesActions";
import { connect } from "react-redux";
import TextField from "../../includes/text-field/textField.component";
import SelectField from "../../includes/select-field/select.component";
import Button from "../../includes/button/button.component";
import Modal from "../../includes/modal/modal.component";

const AddUserForm = (props) => {

  const { name, alias, category, educationalCategories } = props;

  useEffect(() => {
    if (props.type === "Edit" && props.class_.name !== name) {
      props.inputChange("name", props.class_.name);
      props.inputChange("alias", props.class_.alias);
      props.inputChange("category", props.class_.categoryId);
    } else if (props.type !== "Edit") {
      props.inputChange("name", "");
      props.inputChange("alias", "");
      props.inputChange("category", "");
    }
  }, [props.type, props.class_]);

  const mounted = useRef(false);

  useEffect(() => {
    if(!mounted.current){
      props.fetchEducationalCategories(false);
      mounted.current = true;
    }
  })

  const resetForm = () => {
    if (props.type === "Edit") {
      props.inputChange("name", props.class_.name);
      props.inputChange("alias", props.class_.alias);
      props.inputChange("category", props.class_.categoryId);
      return;
    }
    props.inputChange("name", "");
    props.inputChange("alias", "");
    props.inputChange("category", "");
  };

  const addClass = async () => {
    let message;
    if (!category) message = "Class category is required";
    else if (!name) message = "Class name is required";
    else if (!alias) message = "Class alias is required";

    if (message) {
      props.onSetSnackbar({ message, type: "error" });
      return;
    }

    await props.addCourse({ name, alias, categoryId: category._id });
    resetForm();
    props.onComplete({ name });
  };

  const editClass = async () => {
    let message;
    if (
      name === props.class_.name &&
      props.class_.categoryId && category._id === props.class_.categoryId._id &&
      alias === props.class_.alias
    )
      message = "No changes made";
    if (!name || !category || !alias) message = "Field cannot be empty";

    if (message) {
      props.onSetSnackbar({ message, type: "error" });
      return;
    }

    await props.editCourse(
      props.class_.name,
      { name, alias, categoryId: category._id },
      props.class_._id
    );
    resetForm();
    props.onComplete({ name });
  };

  const handleSubmit = () => {
    if (props.type === "Edit") {
      editClass();
    } else {
      addClass();
    }
  };

  const handleInput = (e) => {
    props.inputChange(e.target.name, e.target.value);
  };

  return (
    <Modal
      maxWidth="550px"
      show={props.show}
      onClose={() => {
        resetForm();
        props.onClose();
      }}
    >
      <div
        style={{
          padding: "0 30px",
        }}
      >
        <h3 className="mb-4">{props.type} Class Category</h3>
        <h6>Educational Category</h6>
        <SelectField
          onSelect={handleInput}
          outlined
          value={category}
          items={educationalCategories}
          name="category"
          textField="name"
          valueField="_id"
          style={{ marginBottom: 25 }}
          placeholder="Select"
        />
        <h6>Class Name</h6>
        <TextField
          onInput={handleInput}
          value={name}
          outlined
          name="name"
          style={{ marginBottom: 25 }}
        ></TextField>

        <h6>Name Abbreviation</h6>
        <TextField
          onInput={handleInput}
          name="alias"
          value={alias}
          outlined
          style={{ marginBottom: 0 }}
        ></TextField>
        <div className="center mt-4">
          <Button
            onClick={handleSubmit}
            style={{ fontSize: "120%" }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  name: state.courses.name,
  alias: state.courses.alias,
  category: state.courses.category,
  educationalCategories: state.educationalCategories.educationalCategories
});
export default connect(mapStateToProps, { inputChange, addCourse, editCourse, fetchEducationalCategories })(
  AddUserForm
);
