import React, { useState, useEffect, useRef } from 'react'
import SunEditor from 'suneditor-react'
import { useHistory, Redirect, useParams } from 'react-router-dom'
import 'suneditor/dist/css/suneditor.min.css'
import Button from '../../includes/button/button.component'
import SelectField from '../../includes/select-field/select.component'
import TextField from '../../includes/text-field/textField.component'
import FileUploader from '../../includes/file-uploader/fileUploader.component'
import {
  inputChange,
  fetchBlogArticle,
  editBlogArticle,
  initializeState
} from '../../../redux/actions/blogArticlesActions'
import {
  fetchBlogArticleCategories,
  addBlogArticleCategory
} from '../../../redux/actions/blogArticleCategoriesActions'
import { connect } from 'react-redux'
import { clearErrors } from '../../../redux/actions/errorActions'
import { clearSuccess } from '../../../redux/actions/successActions'
import Snackbar from '../../includes/snackbar/snackbar.component'
import {
  deleteTags,
  show,
  getAllContentMediaUrl
} from '../../../assets/js/globalFunctions'
import Checkbox from '../../includes/checkbox/checkbox.component'

import './css/style.css'

const btnList = [
  ['undo', 'redo'],
  ['font', 'fontSize', 'formatBlock'],
  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
  ['removeFormat'],
  '/',
  ['fontColor', 'hiliteColor'],
  ['outdent', 'indent'],
  ['align', 'horizontalRule', 'list', 'table'],
  ['link', 'image'],
  ['fullScreen', 'showBlocks', 'codeView']
]

const isVideo = mediaString => {
  if (!mediaString) return false
  return mediaString.slice(mediaString.lastIndexOf('.') + 1) === 'mp4'
}

const BlogArticleEdition = props => {
  const { articleCategories, role, roles, blogArticle, success, error } = props
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [category, setCategory] = useState({})
  const [newCategory, setNewCategory] = useState('')
  const [mediaUrl, setMediaUrl] = useState({})
  const [snackbar, setSnackbar] = useState({ message: '' })
  const [media, setMedia] = useState(null)
  const [useNewImage, setUseNewImage] = useState(false)
  const [addingCategory, setAddingCategory] = useState(false)
  const [isHeadline, setIsHeadline] = useState(false)

  const contentImagesRef = useRef([])
  const fileUploaderRef = useRef()
  const mounted = useRef(false)
  const initialized = useRef(false)
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    if (!mounted.current) {
      props.fetchBlogArticle(id)
      props.fetchBlogArticleCategories()
      mounted.current = true
    } else {
      if (success.id) {
        const snack = {
          message:
            typeof success.msg === 'object'
              ? success.msg.join('<br/>')
              : success.msg,
          type: 'success'
        }

        switch (success.id) {
          case 'EDIT_BLOG_ARTICLE_SUCCESS':
            setSnackbar({ ...snack, timeout: 1500 })
            setTimeout(() => {
              history.push('/blog/articles')
            }, 2000)
            break
          case 'ADD_BLOG_ARTICLE_CATEGORY_SUCCESS':
            setSnackbar({ ...snack })
            setAddingCategory(false)
            setNewCategory('')
            break
        }
        props.clearSuccess()
      } else if (error.id) {
        const snack = {
          message:
            typeof error.msg === 'object' ? error.msg.join('<br/>') : error.msg,
          type: 'error'
        }

        switch (error.id) {
          case 'EDIT_BLOG_ARTICLE_FAILURE':
            setSnackbar(snack)
            break
          case 'ADD_BLOG_ARTICLE_CATEGORY_FAILURE':
            setSnackbar({ ...snack })
            setAddingCategory(false)
            break
        }
        props.clearErrors()
      }
    }
  })

  useEffect(() => {
    const article = props.blogArticle
    if (article._id) {
      setTitle(article.title)
      setCategory(article.categoryId)
      setContent(article.content)
      setIsHeadline(article.isHeadline)

      initialized.current = true
    }
  }, [props.blogArticle])

  const handleImageUpload = (targetImgElement, index, state, imageInfo) => {
    if (
      !imageInfo ||
      imageInfo.src.includes('http://') ||
      imageInfo.src.includes('https://')
    )
      return
    var blobBin = atob(imageInfo.src.split(',')[1])
    var array = []
    for (var i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i))
    }
    var file = new Blob([new Uint8Array(array)], { type: 'image/jpg' })
    contentImagesRef.current = [
      ...contentImagesRef.current,
      { file: file, src: imageInfo.src }
    ]
  }

  const submit = () => {
    let message = ''
    if (!title) message = 'Title cannot be empty'
    else if (!deleteTags(content)) message = 'Content cannot be empty'
    else if (useNewImage && !media) message = 'You have to select a media'
    else if (!category) message = 'You have to select a category'

    if (message) {
      setSnackbar({ message })
      return
    }

    const getDeleteMedia = () => {
      let urls = []
      if (useNewImage) urls.push(blogArticle.mediaUrl)
      let currentSrcs = getAllContentMediaUrl(content).filter(
        src => src.includes('http://') || src.includes('https://')
      )
      let originalSrcs = getAllContentMediaUrl(blogArticle.content).filter(
        src => src.includes('http://') || src.includes('https://')
      )
      originalSrcs.forEach(src => {
        if (!currentSrcs.includes(src)) urls.push(src)
      })
      return urls
    }

    let others = {}
    if (useNewImage) others = { media }
    else others = { mediaUrl }

    props.editBlogArticle(
      {
        title,
        content,
        categoryId: category._id,
        contentImages: contentImagesRef.current.map(cnt => cnt.file),
        contentUrls: contentImagesRef.current.map(cnt => cnt.src),
        deleteMedia: getDeleteMedia(),
        isHeadline,
        ...others
      },
      blogArticle._id
    )
  }
  const setTitle_ = e => {
    setTitle(e.target.value)
  }

  const setNewImage_ = e => {
    setMedia(e.target.value)
  }

  const setCategory_ = e => {
    setCategory(e.target.value)
  }

  const setNewCategory_ = e => {
    setNewCategory(e.target.value)
  }

  const addNewCategory = async () => {
    if (!newCategory) {
      setSnackbar({ message: 'Please enter new category' })
      return
    }
    setAddingCategory(true)
    await props.addBlogArticleCategory({ name: newCategory }, false)
  }

  return (
    <div id='content-creation' className='page-padding'>
      {role && (
        <>
          {!show(1, role, roles) ? <Redirect to='/dashboard' /> : null}
          <Snackbar
            snack={snackbar}
            reset={() => {
              setSnackbar({ message: '' })
            }}
          />
          <div
            className='d-flex justify-content-between align-items-center'
            style={{
              borderLeft: '5px solid var(--primary-color)',
              paddingLeft: '8px'
            }}
          >
            <h4 style={{ margin: '0', flexGrow: 1 }} className='title-text'>
              Blog Post
            </h4>
            <Button onClick={submit}>Save</Button>
          </div>
          <div className='row mt-5'>
            <div className='col-12 col-lg-8'>
              <TextField
                placeholder='Title'
                name='title'
                value={title}
                onInput={setTitle_}
                plain
                className='mb-4'
              />
              <div className='note-markup'>
                {initialized.current && (
                  <SunEditor
                    setOptions={{
                      height: 515,
                      buttonList: btnList,
                      toolbarContainer: '#toolbar-container'
                    }}
                    onChange={cont => {
                      setContent(cont)
                    }}
                    onImageUpload={handleImageUpload}
                    autoFocus={false}
                    defaultValue={content}
                  />
                )}
              </div>
            </div>
            <div className='col-12 col-lg-4 mt-4 mt-lg-0'>
              <div
                style={{
                  backgroundColor: 'white',
                  padding: '20px 0 60px 0',
                  borderRadius: '10px'
                }}
              >
                <h4 className='pl-4'>Category</h4>
                <hr />
                <div className='pl-4 pr-4'>
                  <div className='mb-2'>Select Category</div>
                  <SelectField
                    value={category}
                    onSelect={setCategory_}
                    name='course'
                    items={articleCategories}
                    textField='name'
                    valueField='_id'
                    outlined
                    placeholder='Select Category'
                    color='#B6B6B6'
                    inputStyle={{ borderRadius: 5, paddingLeft: 15 }}
                    className='mb-4'
                  ></SelectField>
                  <div className='mb-2'>Create new category</div>
                  <TextField
                    value={newCategory}
                    onInput={setNewCategory_}
                    name='new category'
                    outlined
                    placeholder='Create Category'
                    color='#B6B6B6'
                    inputStyle={{ borderRadius: 5, paddingLeft: 15 }}
                    className='mb-2'
                  ></TextField>
                  <div className='right'>
                    {!addingCategory && (
                      <span
                        className='hover-style pointer'
                        style={{ color: 'var(--primary-color)' }}
                        onClick={addNewCategory}
                      >
                        Add
                      </span>
                    )}
                    {addingCategory && <div className='loader-small'></div>}
                  </div>
                </div>
              </div>
              <div className='mt-4'>
                {useNewImage && (
                  <>
                    <FileUploader
                      ref={fileUploaderRef}
                      onInput={setNewImage_}
                      validTypes='image/jpg, image/png, image/gif, video/mp4'
                    />
                    <div className='right mt-1'>
                      <span
                        className='hover-style pointer'
                        style={{ color: 'var(--primary-color)' }}
                        onClick={() => {
                          setUseNewImage(false)
                        }}
                      >
                        Use original
                      </span>
                    </div>
                  </>
                )}
                {!useNewImage && (
                  <>
                    {!isVideo(blogArticle.mediaUrl) && (
                      <>
                        <img
                          src={blogArticle.mediaUrl}
                          style={{ maxWidth: '100%', borderRadius: 10 }}
                        />
                      </>
                    )}
                    {isVideo(blogArticle.mediaUrl) && (
                      <>
                        <video width='100%' style={{ borderRadius: 10 }}>
                          <source
                            src={blogArticle.mediaUrl + '#t=0.1'}
                            type='video/mp4'
                          />
                        </video>
                      </>
                    )}
                    <div className='right'>
                      <span
                        className='hover-style pointer'
                        style={{ color: 'var(--primary-color)' }}
                        onClick={() => {
                          setUseNewImage(true)
                        }}
                      >
                        Select new
                      </span>
                    </div>
                  </>
                )}
              </div>

              <div
                style={{
                  backgroundColor: 'white',
                  padding: '20px 0 20px 0',
                  borderRadius: '10px'
                }}
                className='mt-4 pl-4 pr-4 d-flex align-items-center'
              >
                <span className='mr-2'>Make Headline:</span>
                <Checkbox
                  checked={isHeadline}
                  onChange={val => {
                    setIsHeadline(val)
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
const mapStateToProps = state => ({
  articleCategories: state.blogArticleCategories.blogArticleCategories,
  blogArticle: state.blogArticles.blogArticle,
  role: state.auth.user.role,
  roles: state.auth.roles,
  error: state.error,
  success: state.success
})
export default connect(mapStateToProps, {
  inputChange,
  fetchBlogArticle,
  initializeState,
  editBlogArticle,
  addBlogArticleCategory,
  fetchBlogArticleCategories,
  clearSuccess,
  clearErrors
})(BlogArticleEdition)
