import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchRequests,
  requestInputChange,
} from "../../../redux/actions/studentsRequestsActions";
import moment from "moment";
import {
  faDownload,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function StudentRequests() {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState("");
  const requestsCount = useSelector(
    (state) => state.studentRequests.requestsCount
  );
  const requests = useSelector((state) => state.studentRequests.requests);
  const loadingRequests = useSelector(
    (state) => state.studentRequests.loadingRequests
  );
  const loadingRequestsStatus = useSelector(
    (state) => state.studentRequests.loadingRequestsStatus
  );
  const [loading, setLoading] = useState(false);

  let searchResults = requests;
  if (searchQuery) {
    searchResults = requests.filter(
      (i) =>
        i.subjectId.toLowerCase().includes(searchQuery.toLowerCase()) ||
        i.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (i.userId &&
          i.userId.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (i.userId &&
          i.userId.fullName.toLowerCase().includes(searchQuery.toLowerCase()))
    );
  }

  useEffect(() => {
    dispatch(fetchRequests(requests.length, 10));
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-lg-8">
          <div className="p-4">
            <div className="d-flex " style={{ alignItems: "baseline" }}>
              <h1>Student's Homework </h1>{" "}
              <small>
                {" "}
                ({requests.length} of {requestsCount})
              </small>
            </div>
            <input
              type="text"
              className="form-control form-control-lg bg-light my-3"
              placeholder="Search Homework"
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div class="list-group border-0">
              {searchResults.length === 0 && (
                <div
                  style={{
                    height: 500,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="d-flex flex-column"
                >
                  <FontAwesomeIcon icon={faExclamationCircle} size="10x" />
                  <p>No Replies yet</p>
                </div>
              )}

              {searchResults.map((request, index) => (
                <NavLink
                  to={`/student-requests/${index}`}
                  key={index}
                  class="list-group-item list-group-item-action  cursor border-0"
                  aria-current="true"
                  onClick={() => {
                    dispatch(requestInputChange("request", request));
                  }}
                >
                  <div class="d-flex w-100 justify-content-between">
                    <p class="mb-1">{request.subjectId}</p>
                    <small>{moment(request.createdAt).fromNow()}</small>
                  </div>
                  <h5 class="mb-1">{request.question}</h5>
                  <small>{request?.userId?.fullName}</small>
                  <small className="badge bg-secondary">
                    {" "}
                    {request?.replies?.length}{" "}
                    {request?.replies?.length > 1 ? "replies" : "reply"}{" "}
                  </small>
                </NavLink>
              ))}
            </div>
            <div className="w-100 d-flex justify-content-center my-4">
              {requests.length < requestsCount && (
                <button
                  className="btn btn-secondary btn-lg "
                  disabled={loadingRequests}
                  onClick={() => {
                    dispatch(fetchRequests(Number(requests.length), 10));
                    setLoading(true);
                  }}
                >
                  {loadingRequests ? "Loading..." : "Load More"}
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4"></div>
      </div>
    </div>
  );
}
