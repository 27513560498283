import React, { useState, useRef, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import "./css/style.css";
import image from "../../../assets/images/LoginPage.png";
import TextField from "../../includes/text-field/textField.component";
import { ReactComponent as MessageIcon } from "../../../assets/icons/Message.svg";
import Logo from "../../../assets/images/LogoGreen.png";
import Button from "../../includes/button/button.component";
import Snackbar from "../../includes/snackbar/snackbar.component";
import { inputChange, loginUser } from "../../../redux/actions/authActions";
import { clearErrors } from "../../../redux/actions/errorActions";
import { connect } from "react-redux";

const Login = (props) => {
  const { email, password, redirect, location, error, isAuthenticated } = props;
  const [snack, setSnack] = useState({ message: "", type: "error" });

  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      props.inputChange("redirect", false);
      mounted.current = true;
    } else {
      if (error.id === "LOGIN_FAILURE") {
        const message =
          typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
        setSnack({ message });
        props.clearErrors();
      }
    }
  });

  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    props.inputChange(name, value);
  };

  const login = () => {
    let message;
    if (!email) message = "Email address is required";
    else if (!password) message = "Password is required";

    if (message) {
      setSnack({ type: "error", message });
      return;
    } else {
      props.loginUser({ email, password });
    }
  };

  return (
    <div id="login" className="d-flex">
      {redirect ? <Redirect to={location} /> : null}
      <Snackbar
        snack={snack}
        reset={() => {
          setSnack({ ...snack, message: "" });
        }}
      />
      <div className="form-container col d-flex align-items-start justify-content-center">
        <div className="mt-3">
          <img src={Logo} className="mb-5" />
          <p style={{ fontSize: "90%" }}>Welcome back</p>
          <h4 className="mb-4">Login to Your Account</h4>
          <TextField
            value={email}
            onInput={handleChange}
            icon={<MessageIcon />}
            placeholder="Email"
            style={{ marginTop: 30 }}
            name="email"
          />
          <TextField
            value={password}
            onInput={handleChange}
            placeholder="Password"
            type="password"
            style={{ marginTop: 40 }}
            name="password"
          />
          <div
            style={{ marginTop: 40 }}
            className="d-flex justify-content-between align-items-center"
          >
            <Button onClick={login}>Login</Button>
            {/* <Link className="forgot-password" to="/forgot-password">
              Forgot password?
            </Link> */}
          </div>
        </div>
      </div>
      <div className="image-container col align-items-center justify-content-center">
        <div className="center">
          <h2 className="font2">Content Management System</h2>
          <img src={image} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  redirect: state.auth.redirect,
  email: state.auth.email,
  password: state.auth.password,
  roles: state.auth.roles,
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
  location: state.auth.location,
});

export default connect(mapStateToProps, {
  inputChange,
  loginUser,
  clearErrors,
})(Login);
