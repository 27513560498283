import React, { useEffect, useState, useRef } from "react";

import Table from "../../includes/table/table.component";
import Alert from "../../includes/alert/alert.component";
import Modal from "../../includes/modal/modal.component";
import { ReactComponent as Edit } from "../../../assets/icons/EditSquare.svg";
import { connect } from "react-redux";
import {
  fetchCourses,
  deleteCourse,
  deleteCourses,
} from "../../../redux/actions/coursesActions";
import { clearErrors } from "../../../redux/actions/errorActions";
import { clearSuccess } from "../../../redux/actions/successActions";
import "./css/style.css";
import AddClassForm from "./addClassForm.component";
import DeleteDialog from "../../includes/confirm-dialog/confirmDialog.component";
import ActionButton from "../../includes/button/actionButton.component";
import Snackbar from "../../includes/snackbar/snackbar.component";
import DateWrapper from "../../includes/date-wrapper/date-wrapper.component";
import { show } from "../../../assets/js/globalFunctions";

const Classes = (props) => {
  const headers = [
    { value: "name", text: "Class", align: "left" },
    { value: "alias", text: "Alias" },
    { value: "creatorId.firstName", text: "Created By" },
    { value: "createdAt", text: "Date created", wrapper: DateWrapper },
  ];

  const { courses, error, success, role, roles } = props;
  const [items, setItems] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [currentActionClass, setCurrentActionClass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [classesAlert, setClassesAlert] = useState({
    show: false,
    type: "success",
    message: "Selected class(es) deleted successfully",
  });
  const [classForm, setClassForm] = useState({
    show: false,
    type: "edit",
    class_: null,
  });
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({ message: "", type: "error" });

  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (error.id) {
        const message =
          typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
        let alert_ = { type: "error", show: true, message };
        if (error.id === "DELETE_COURSE_FAILURE") {
          setClassesAlert({ ...alert_ });
          props.clearErrors();
        } else if (error.id === "DELETE_COURSES_FAILURE") {
          const message =
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
          setClassesAlert({ ...alert_ });
          props.clearErrors();
        } else if (error.id === "ADD_COURSE_FAILURE") {
          const message =
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
          setClassesAlert({ ...alert_ });
          props.clearErrors();
        } else if (error.id === "EDIT_COURSE_FAILURE") {
          const message =
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg;
          setClassesAlert({ ...alert_ });
          props.clearErrors();
        }
        props.clearErrors();
      } else if (success.id) {
        let alert_ = { type: "success", show: true, message: success.msg };
        if (success.id === "DELETE_COURSE_SUCCESS") {
          setClassesAlert({ ...alert_ });
          props.clearSuccess();
        } else if (success.id === "DELETE_COURSES_SUCCESS") {
          setClassesAlert({
            ...alert_,
          });
          props.clearSuccess();
        } else if (success.id === "ADD_COURSE_SUCCESS") {
          setClassesAlert({
            ...alert_,
          });
          props.clearSuccess();
        } else if (success.id === "EDIT_COURSE_SUCCESS") {
          setClassesAlert({
            ...alert_,
          });
          props.clearSuccess();
        }
        props.clearSuccess();
      }
    }
  });

  useEffect(() => {
    if (role) {
      props.fetchCourses();
    }
  }, [role]);
  // useEffect(() => {
  //   if (currentActionClass) setCreationModal(true);
  // }, [currentActionClass]);

  const deleteSelectedClasses = () => {
    if (selectedClasses.length < 1) {
      setSnackbar({ message: "No class was selected", type: "error" });
      return;
    } else if (selectedClasses.length == 1) {
      props.deleteCourse(selectedClasses[0]._id);
    } else {
      props.deleteCourses(selectedClasses.map((class_) => class_._id));
    }
    // setLoading(true);
    // setTimeout(() => {
    //   let deleted = [];
    //   let arr = items.filter((item) => {
    //     let isSelected = selectedClasses.includes(item);
    //     if (isSelected) deleted.push(item.name);
    //     return !isSelected;
    //   });
    //   setItems(arr);
    //   setLoading(false);
    //   setSelectedClasses([]);
    //   setClassesAlert({
    //     ...classesAlert,
    //     show: true,
    //     message:
    //       deleted.length == 1
    //         ? `Class "${deleted[0]}" was successfully deleted`
    //         : deleted.length < 6
    //         ? `Classes "${deleted.join(", ")}" deleted successfully`
    //         : `All selected classes were deleted successfully`,
    //   });
    // }, 5000);
  };

  return (
    <div id="classes" className="page-padding">
      {role && (
        <div>
          <DeleteDialog
            show={deleteDialog}
            onCancel={() => {
              setDeleteDialog(false);
            }}
            onContinue={() => {
              setDeleteDialog(false);
              deleteSelectedClasses();
            }}
            onClose={() => {
              setDeleteDialog(false);
            }}
            title="Delete Class"
            message={`Are you sure you want to delete the class${
              selectedClasses.length > 1 ? "es" : ""
            }?`}
          />
          <Snackbar
            snack={snackbar}
            reset={() => {
              setSnackbar({ message: "" });
            }}
          />
          <div className="d-flex justify-content-between">
            <h3 className="title-text">Classes</h3>
            <div>
              <AddClassForm
                show={classForm.show}
                onClose={() => {
                  setClassForm({ ...classForm, show: false });
                }}
                onComplete={(val) => {
                  setClassForm({ ...classForm, show: false });
                  // setClassesAlert({
                  //   message: `New class "${val.name}" added successfully`,
                  //   show: true,
                  // });
                  let arr = items.slice();
                  arr.push({
                    ...val,
                    createdAt: "25/02/2020",
                    creator: { name: "Victor" },
                  });
                  setItems(arr);
                }}
                onSetSnackbar={(val) => {
                  setSnackbar(val);
                }}
                class_={classForm.class_}
                type={classForm.type}
              />

              {show(1, role, roles) && (
                <ActionButton
                  onClick={() => {
                    setClassForm({ type: "Create", show: true });
                  }}
                  className="mr-4"
                />
              )}
              {show(1, role, roles) && (
                <ActionButton
                  delete
                  onClick={() => {
                    setDeleteDialog(true);
                  }}
                  className="mr-3"
                />
              )}
            </div>
          </div>
          <div className="d-flex justify-content-center">
            {classesAlert.show && (
              <Alert
                type={classesAlert.type}
                message={classesAlert.message}
                style={{ maxWidth: 500, flexGrow: 1 }}
                className="mt-3"
                onClose={() => {
                  setClassesAlert({
                    show: false,
                    message: "",
                  });
                }}
              />
            )}
          </div>
          <Table
            headers={headers}
            items={courses}
            selectable={show(1, role, roles)}
            action={show(1, role, roles) && <Edit style={{ width: 20 }} />}
            onItemsSelected={(val) => {
              setSelectedClasses(val);
            }}
            onActionClicked={(item) => {
              setCurrentActionClass(item);
              setClassForm({ type: "Edit", show: true, class_: item });
            }}
            loading={loading}
            className="mt-4"
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  courses: state.courses.courses,
  role: state.auth.user.role,
  roles: state.auth.roles,
  error: state.error,
  success: state.success,
});
export default connect(mapStateToProps, {
  fetchCourses,
  deleteCourse,
  deleteCourses,
  clearErrors,
  clearSuccess,
})(Classes);
