const DateWrapper = (props) => {
  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    let day = d.getDate();
    let month = d.getMonth();
    let year = d.getFullYear();
    if (isNaN(day) || isNaN(month) || isNaN(year)) return "Invalid date";
    return [pad(day), pad(month + 1), year].join("/");
  }

  return <span>{convertDate(props.value)}</span>;
};

export default DateWrapper;
