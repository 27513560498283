const RoleWrapper = (props) => {
    const roles = [
        {id: "605b11ff2bb1d23ddc58f1d4", name: "Admin"},
        {id: "605b11c92bb1d23ddc58f1d2", name: "Staff"},
        {id: "605b11e72bb1d23ddc58f1d3", name: "Moderator"},
    ]
    function getRoleName(roleId) {
      let role = roles.find(role => role.id === roleId);
      if(role) return role.name;
    }
  
    return <span>{getRoleName(props.value)}</span>;
  };
  
  export default RoleWrapper;
  