import API from "../../assets/js/api";
import { returnErrors } from "./errorActions";
import { returnSuccess } from "./successActions";

import {
  GET_ALL_CMSUSERS_FAILURE,
  GET_ALL_CMSUSERS_SUCCESS,
  DELETE_CMSUSERS_FAILURE,
  DELETE_CMSUSERS_SUCCESS,
  DELETE_CMSUSER_FAILURE,
  DELETE_CMSUSER_SUCCESS,
  ADD_CMSUSER_SUCCESS,
  ADD_CMSUSER_FAILURE,
  EDIT_CMSUSER_SUCCESS,
  EDIT_CMSUSER_FAILURE,
  INPUT_CHANGE
} from "./types";

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  );
};
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id));
};

export const inputChange = (name, value) => (dispatch) => {
  dispatch({type: INPUT_CHANGE, payload: {name,value}})
}

export const fetchCmsUsers = () => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.getCmsUsers();

    dispatch({ type: GET_ALL_CMSUSERS_SUCCESS, payload: result.data.data });
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_ALL_CMSUSERS_FAILURE);
  }
};

export const addCmsUser = (data) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.addCmsUser(data);

    dispatch({type: ADD_CMSUSER_SUCCESS, payload: result.data.data});
    dispatchSuccess(
      dispatch,
      `User "${data.firstName} ${data.lastName}" successfully created`,
      ADD_CMSUSER_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, ADD_CMSUSER_FAILURE);
  }
};

export const deleteCmsUser = (userId) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deleteCmsUser(userId);

    dispatch({ type: DELETE_CMSUSER_SUCCESS, payload: userId });
    dispatchSuccess(dispatch, result.data.data.message, DELETE_CMSUSER_SUCCESS);

    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_CMSUSER_FAILURE);
  }
};

export const deleteCmsUsers = (userIds) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deleteCmsUsers({ userIds });

    dispatch({ type: DELETE_CMSUSERS_SUCCESS, payload: { userIds } });
    dispatchSuccess(dispatch, result.data.data.message, DELETE_CMSUSERS_SUCCESS);
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_CMSUSERS_FAILURE);
  }
};

export const editCmsUser = (firstName, lastName, update, userId) => async (
  dispatch,
  getState
) => {
  try {
    document.body.classList.add("loading-indicator");
    const user = getState().auth.user;
    const result = await API.editCmsUser(update, userId);

    dispatch({
      type: EDIT_CMSUSER_SUCCESS,
      payload: { user, user: result.data.data.user },
    });
    dispatchSuccess(
      dispatch,
      `User successfully edited`,
      EDIT_CMSUSER_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, EDIT_CMSUSER_FAILURE);
  }
};
