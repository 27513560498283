import React, { useEffect, useState } from "react";
import {
  inputChange,
  addEducationalCategory,
  editEducationalCategory,
} from "../../../redux/actions/educationalCategoriesActions";
import { connect } from "react-redux";
import TextField from "../../includes/text-field/textField.component";
import SelectField from "../../includes/select-field/select.component";
import Button from "../../includes/button/button.component";
import Modal from "../../includes/modal/modal.component";

const AddUserForm = (props) => {

  const { name, alias, category } = props;

  useEffect(() => {
    if (props.type === "Edit" && props.category.name !== name) {
      props.inputChange("name", props.category.name);
    } else if (props.type !== "Edit") {
      props.inputChange("name", "");
    }
  }, [props.type, props.category]);

  const resetForm = () => {
    if (props.type === "Edit") {
      props.inputChange("name", props.category.name);
      return;
    }
    props.inputChange("name", "");
  };

  const addEducationalCategory = async () => {
    if (!name) {
      props.onSetSnackbar({ message: "EducationalCategory name is required", type: "error" });
      return;
    }

    await props.addEducationalCategory({ name });
    resetForm();
    props.onComplete({ name });

    // setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    //   props.onComplete({ name, alias, categoryId });
    // }, 3000);
  };

  const editEducationalCategory = async () => {
    let message;
    if (
      name === props.category.name
    )
      message = "No changes made";
    else if (!name) message = "Field cannot be empty";

    if (message) {
      props.onSetSnackbar({ message, type: "error" });
      return;
    }

    await props.editEducationalCategory(
      props.category.name,
      { name },
      props.category._id
    );
    resetForm();
    props.onComplete({ name });
  };

  const handleSubmit = () => {
    if (props.type === "Edit") {
      editEducationalCategory();
    } else {
      addEducationalCategory();
    }
  };

  const handleInput = (e) => {
    props.inputChange(e.target.name, e.target.value);
  };

  return (
    <Modal
      maxWidth="550px"
      show={props.show}
      onClose={() => {
        resetForm();
        props.onClose();
      }}
    >
      <div
        style={{
          padding: "0 30px",
        }}
      >
        <h3 className="mb-4">{props.type} Educational Category</h3>
        <h6>Category Name</h6>
        <TextField
          onInput={handleInput}
          value={name}
          name="name"
          style={{ marginBottom: 25 }}
        ></TextField>
        <div className="center mt-4">
          <Button
            onClick={handleSubmit}
            style={{ fontSize: "120%" }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  name: state.educationalCategories.name,
});
export default connect(mapStateToProps, { inputChange, addEducationalCategory, editEducationalCategory })(
  AddUserForm
);
