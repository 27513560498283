import React, { useEffect, useState } from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
} from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

import StudentTransactions from "./studentTransactions.component";
import TeacherTransactions from "./teacherTransactions.component";
import "./css/style.css";
import { show } from "../../../assets/js/globalFunctions";
import { ResponsiveLine } from "@nivo/line";
import Workbook from "react-excel-workbook";
import {
  fetchCoinTransactions,
  fetchCoinTransactionsByYear,
} from "../../../redux/actions/transactionsActions";
import Button from "../../includes/button/button.component";

const months = [
  {
    value: 0,
    name: "January",
    noOfDays: 31,
  },
  {
    value: 1,
    name: "February",
    noOfDays: 28,
  },
  {
    value: 2,
    name: "March",
    noOfDays: 31,
  },
  {
    value: 3,
    name: "April",
    noOfDays: 30,
  },
  {
    value: 4,
    name: "May",
    noOfDays: 31,
  },
  {
    value: 5,
    name: "June",
    noOfDays: 30,
  },
  {
    value: 6,
    name: "July",
    noOfDays: 31,
  },
  {
    value: 7,
    name: "August",
    noOfDays: 31,
  },
  {
    value: 8,
    name: "Sepetember",
    noOfDays: 30,
  },
  {
    value: 9,
    name: "October",
    noOfDays: 31,
  },
  {
    value: 10,
    name: "November",
    noOfDays: 31,
  },
  {
    value: 11,
    name: "December",
    noOfDays: 31,
  },
];

const getMonthName = (number) => {
  const n = months.find((i) => i.value === number - 1);
  return n && n.name;
};
const TransactionsNav = (props) => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const { role, roles } = props;
  //COin section
  const numWithComma = (num) => {
    return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  };
  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
  const getValues = (transactions, { value, noOfDays }, year, interval = 2) => {
    if (!transactions.length) return;
    let vals = [];
    let current = 1;
    for (let i = 1; i <= noOfDays; i += interval) {
      let start = new Date(year, value, i);
      let a;
      if (i + interval > noOfDays) a = i + interval - noOfDays;
      else a = interval;
      let end = start.addDays(a);
      let revenue;
      let arr = transactions
        .filter((t) => {
          if (!t.createdAt || !t.amount) return false;
          let tDate = new Date(t.createdAt);

          return tDate >= start && tDate < end;
        })
        .map((t) => t.amount);

      if (arr.length)
        revenue = arr.reduce((a, b) => {
          if (typeof b === "number") return a + b;
          return a + 0;
        });
      else revenue = 0;

      vals.push({
        x:
          interval === 1 || i === noOfDays
            ? `${i}`
            : `${i} - ${i + interval > noOfDays ? noOfDays : i + interval - 1}`,
        y: revenue,
      });
    }
    if (interval !== 1) vals.unshift({ x: "", y: 0 });
    return vals;
  };
  const sortYearlyData = (data, year, typeOf) => {
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    let output = [
      {
        id: "japan",
        color: "rgba(38, 170, 118, 1)",
        data: [],
      },
    ];
    if (typeOf === "all") {
      months.forEach((month) => {
        let monthData = data;

        monthData = data.find(
          (i) => i._id.month === month && i._id.year === year
        );
        const monthDataAdded = data.find(
          (i) =>
            i._id.month === month && i._id.year === year && i._id.type === "add"
        );
        const monthDataRemoved = data.find(
          (i) =>
            i._id.month === month &&
            i._id.year === year &&
            i._id.type === "remove"
        );
        output[0].data.push({
          x: getMonthName(month),
          y:
            (monthData &&
              monthDataAdded.totalAmount + monthDataRemoved.totalAmount) ||
            0,
        });
      });
    } else {
      months.forEach((month) => {
        let monthData = data;

        monthData = data.find(
          (i) =>
            i._id.month === month &&
            i._id.year === year &&
            i._id.type === typeOf
        );
        output[0].data.push({
          x: getMonthName(month),
          y: (monthData && monthData.totalAmount) || 0,
        });
      });
    }
    return output;
  };
  const coinTransactions = useSelector(
    (state) => state.transactions.coinTransactions
  );
  const coinTransactionsByYear = useSelector(
    (state) => state.transactions.coinTransactionsByYear
  );

  var monthx = new Date().toISOString().split("-")[1]; //months from 1-12
  var yearx = new Date().toISOString().split("-")[0];
  const [coinsSpent, setCoinsSpent] = useState([]);
  const [dataType, setDataType] = useState("monthly");
  const [coinsType, setCoinsType] = useState("coinsSpent");
  const [sortDate, setSortDate] = useState(`${yearx}-${monthx}`);
  const currentMonth = months.find(
    (m) => m.value === new Date(sortDate).getMonth()
  );
  const currentYear = new Date(sortDate).getFullYear();

  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [interval, setInterval] = useState(7);
  const [curentRevenue, setCurentRevenue] = useState(0);

  var m = new Date(
    new Date(sortDate).setMonth(new Date(sortDate).getMonth() + 1)
  )
    .toISOString()
    .split("-")[1];
  var y = new Date(
    new Date(sortDate).setMonth(new Date(sortDate).getMonth() + 1)
  )
    .toISOString()
    .split("-")[0];
  const nextMonth = `${y}-${m}`;
  useEffect(() => {
    if (dataType === "monthly") {
      if (coinTransactions.added.length) {
        let coinsDisplayed = coinTransactions.removed;
        if (coinsType === "coinsBought") {
          coinsDisplayed = coinTransactions.added;
        }
        if (coinsType === "coinsProccessed") {
          coinsDisplayed = [
            ...coinTransactions.removed,
            ...coinTransactions.added,
          ];
        }
        let d = getValues(coinsDisplayed, month, year, interval);
        let result = d.map((a) => a.y);

        var total = 0;
        for (var i in result) {
          total += result[i];
        }
        setCurentRevenue(total);
        setCoinsSpent([
          {
            id: "japan",
            color: "rgba(38, 170, 118, 1)",
            data: d,
          },
        ]);
      }
    } else {
      let typp = "remove";
      if (coinsType === "coinsBought") {
        typp = "add";
      }
      if (coinsType === "coinsProccessed") {
        typp = "all";
      }
      let c = sortYearlyData(coinTransactionsByYear, year, typp);
      let result = c[0] && c[0].data && c[0].data.map((a) => a.y);

      var total = 0;
      for (var i in result) {
        total += result[i];
      }
      setCurentRevenue(total);
      setCoinsSpent(c);
    }
  }, [month, year, interval, coinsType, dataType, coinTransactions]);

  useEffect(() => {
    if (sortDate) {
      dispatch(fetchCoinTransactions(sortDate, nextMonth));
    }
  }, [sortDate]);
  useEffect(() => {
    dispatch(fetchCoinTransactionsByYear());
  }, []);
  //Coin section
  return (
    <div id="payment-transaction" className="page-padding">
      {role && (
        <div>
          {!show(1, role, roles) ? <Redirect to="/dashboard" /> : null}
          {/* <div className="mb-5">
          <NavLink className="t-nav-link" exact to={`${url}`}>Payment Transactions</NavLink>
          <NavLink className="t-nav-link" to={`${url}/teachers`}>Teachers</NavLink>
        </div> */}
          <div className="col-12 p-0  ">
            <div
              className="mt-4 p-md-4"
              style={{
                borderRadius: 20,
                height: 502,
              }}
            >
              <div className="d-flex align-items-center justify-content-between flex-wrap mt-5 mt-md-0">
                <h3 className="title-text mr-auto">
                  Revenue Growth Rate (Coin)
                </h3>

                <div className="ml-2">
                  <span className="mr-2">
                    Total Coins{" "}
                    {coinsType === "coinsSpent"
                      ? "Used"
                      : coinsType === "coinsBought"
                      ? "Bought"
                      : "Proccessed"}
                  </span>
                  <Button
                    color="var(--primary-color)"
                    style={{
                      borderRadius: 10,
                      padding: ".8em 1.2em",
                      fontSize: "120%",
                    }}
                  >
                    ₦ {numWithComma(curentRevenue)}
                  </Button>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between py-3 flex-wrap">
                <span className="border-rod bg-secondary mb-2"></span>
                <span className="text-secondary mb-2">Provisions Month</span>

                <input
                  type="month"
                  name="month"
                  id="month"
                  className="short-date-btn form-control form-control-lg w-auto bold-text mb-2"
                  onChange={(e) => {
                    setSortDate(e.target.value);
                    const n = months.find(
                      (m) => m.value === new Date(e.target.value).getMonth()
                    );
                    setYear(new Date(e.target.value).getFullYear());
                    setMonth(n);
                  }}
                  defaultValue={`${yearx}-${monthx}`}
                />
                <div class="btn-group dropup mb-2">
                  <button
                    type="button"
                    class="btn btn-outline-secondary rounded"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa fa-ellipsis-h text-success"></i>
                  </button>
                  <ul class="dropdown-menu p-0">
                    <div className="list-group borderless">
                      <li
                        className={`py-2 px-3 list-group-item list-group-item-action ${
                          dataType === "monthly" && `active`
                        } bold-text cursor`}
                        onClick={() => setDataType("monthly")}
                      >
                        Monthly
                      </li>
                      <li
                        className={`py-2 px-3 list-group-item list-group-item-action ${
                          dataType === "yearly" && `active`
                        } bold-text cursor`}
                        onClick={() => setDataType("yearly")}
                      >
                        Yearly
                      </li>
                    </div>
                  </ul>
                </div>

                <Workbook
                  filename="example.xlsx"
                  element={
                    <button
                      type="button"
                      class="btn btn-outline-secondary mb-2"
                    >
                      Export Report
                    </button>
                  }
                >
                  <Workbook.Sheet data={coinTransactions.added} name="Monthly">
                    <Workbook.Column label="description" value="description" />
                    <Workbook.Column label="type" value="type" />
                    <Workbook.Column label="amount" value="amount" />
                    <Workbook.Column
                      label="date"
                      value={(row) =>
                        new Date(row.createdAt).toLocaleDateString()
                      }
                    />
                  </Workbook.Sheet>
                  <Workbook.Sheet data={coinTransactionsByYear} name="Yearly">
                    <Workbook.Column
                      label="Year"
                      value={(row) => row._id.year}
                    />
                    <Workbook.Column
                      label="Month"
                      value={(row) => row._id.month}
                    />
                    <Workbook.Column
                      label="Type"
                      value={(row) => row._id.type}
                    />
                    <Workbook.Column label="amount" value="totalAmount" />
                  </Workbook.Sheet>
                </Workbook>
              </div>

              <ul class="nav nav-tabs d-flex w-100 justify-content-around">
                <label
                  for="coinsSpent"
                  className={`bold-text ${
                    coinsType === "coinsSpent" && `active`
                  }`}
                >
                  <input
                    type="radio"
                    id="coinsSpent"
                    name="fav_language"
                    value="coinsSpent"
                    className="hidden-input"
                    onChange={(e) => setCoinsType(e.target.value)}
                  />
                  Total Coins Used
                </label>
                <label
                  for="coinsBought"
                  className={`bold-text ${
                    coinsType === "coinsBought" && `active`
                  }`}
                >
                  <input
                    type="radio"
                    id="coinsBought"
                    name="fav_language"
                    value="coinsBought"
                    className="hidden-input"
                    onChange={(e) => setCoinsType(e.target.value)}
                  />
                  Total coins bought
                </label>
                <label
                  for="coinsProccessed"
                  className={`bold-text ${
                    coinsType === "coinsProccessed" && `active`
                  }`}
                >
                  <input
                    type="radio"
                    id="coinsProccessed"
                    name="fav_language"
                    value="coinsProccessed"
                    className="hidden-input"
                    onChange={(e) => setCoinsType(e.target.value)}
                  />
                  Total Coins Processed
                </label>
              </ul>

              <div style={{ height: 250 }}>
                <ResponsiveLine
                  data={coinsSpent}
                  margin={{
                    top: 50,
                    right: 10,
                    bottom: 50,
                    left: 40,
                  }}
                  xScale={{ type: "point" }}
                  yScale={{
                    type: "linear",
                    min: "auto",
                    max: "auto",
                    stacked: true,
                    reverse: false,
                  }}
                  colors={["var(--primary-color)"]}
                  yFormat=" >-.2f"
                  curve="natural"
                  enableArea={true}
                  enableGridX={false}
                  enableSlices="x"
                  areaOpacity={0.02}
                  axisLeft={null}
                  lineWidth={3}
                  pointSize={15}
                  pointColor={["var(--primary-color)"]}
                  pointBorderWidth={4}
                  pointBorderColor={["white"]}
                  pointLabelYOffset={-12}
                  useMesh={true}
                  sliceTooltip={({ slice }) => {
                    return (
                      <div
                        style={{
                          backgroundColor: "white",
                          padding: "15px 20px",
                          borderRadius: 10,
                        }}
                        className="general-shadow"
                      >
                        <div style={{ fontSize: "1.2em" }}>
                          ₦{slice.points[0].data.yFormatted}
                        </div>
                        {dataType === "monthly" && (
                          <div
                            style={{
                              fontSize: "0.8em",
                              color: "rgba(0,0,0,0.5)",
                            }}
                          >{`${slice.points[0].data.xFormatted} ${month.name} ${year}`}</div>
                        )}
                        {dataType === "yearly" && (
                          <div
                            style={{
                              fontSize: "0.8em",
                              color: "rgba(0,0,0,0.5)",
                            }}
                          >{`${slice.points[0].data.xFormatted} ${year}`}</div>
                        )}
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <hr />
          <Switch>
            <Route exact path={path}>
              <StudentTransactions />
            </Route>
            <Route path={`${path}/teachers`}>
              <TeacherTransactions />
            </Route>
          </Switch>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  role: state.auth.user.role,
  roles: state.auth.roles,
});
export default connect(mapStateToProps)(TransactionsNav);
