import React, { Component } from "react";
import "./css/style.css";

import { ReactComponent as Times } from "../../../assets/icons/Times.svg";

class Modal extends Component {
  updateModal = (val) => {
    this.props.modal(val);
  };

  render() {
    const { activator, children, show } = this.props;
    return (
      <div id="modal">
        <div className={`overlay ${!show ? "m-hidden" : ""}`}>
          <div
            style={{ maxWidth: this.props.maxWidth || "600px" }}
            className={`content`}
          >
            <div style={{ position: "sticky", top: "0px", textAlign: "right" }}>
              <button
                type="button"
                id="close-button"
                onClick={() => {
                  this.props.onClose();
                }}
              >
                <Times />
              </button>
            </div>
            <div style={{marginTop: "-32px"}}>{children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
