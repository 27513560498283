import API from "../../assets/js/api";
import { returnErrors } from "./errorActions";
import { returnSuccess } from "./successActions";

import {
  GET_SUBJECTS_FAILURE,
  GET_SUBJECTS_SUCCESS,
  DELETE_SUBJECTS_FAILURE,
  DELETE_SUBJECTS_SUCCESS,
  DELETE_SUBJECT_FAILURE,
  DELETE_SUBJECT_SUCCESS,
  ADD_SUBJECT_FAILURE,
  ADD_SUBJECT_SUCCESS,
  EDIT_SUBJECT_FAILURE,
  EDIT_SUBJECT_SUCCESS,
  OBJECT_PROP_INPUT_CHANGE,
  INITIALIZE_STATE,
  INITIALIZE_STATE_SUCCESS,
  INPUT_CHANGE,
} from "./types";

const dispatchError = (dispatch, err, id) => {
  dispatch(
    returnErrors(
      err.response.data.errors
        ? err.response.data.errors
        : err.response.data.error,
      err.response.data.status,
      id
    )
  );
};
const dispatchSuccess = (dispatch, message, id) => {
  dispatch(returnSuccess(message, id));
};

export const inputChange = (name, value) => (dispatch) => {
  dispatch({ type: INPUT_CHANGE, payload: { name, value } });
};

export const objectPropInputChange = (name, propName, value) => (dispatch) => {
  dispatch({
    type: OBJECT_PROP_INPUT_CHANGE,
    payload: { name, propName, value },
  });
};

export const initializeState = (value) => (dispatch) => {
  dispatch({ type: INITIALIZE_STATE, payload: value });
  dispatchSuccess(dispatch, "", INITIALIZE_STATE_SUCCESS);
};

export const fetchSubjects = () => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.getMainSubjects();

    dispatch({ type: GET_SUBJECTS_SUCCESS, payload: result.data.data });
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, GET_SUBJECTS_FAILURE);
  }
};

export const addSubject = (data) => async (dispatch, getState) => {
  try {
    document.body.classList.add("loading-indicator");
    const user = getState().auth.user;
    const formData = new FormData();
    formData.set("name", data.name);
    formData.set("introText", data.introText);
    formData.set("media", data.media);
    formData.set("creatorId", user._id);
    const result = await API.addMainSubject(formData);

    dispatch({
      type: ADD_SUBJECT_SUCCESS,
      payload: { user, subject: result.data.data.subject },
    });
    dispatchSuccess(
      dispatch,
      `Subject "${data.name}" successfully created`,
      ADD_SUBJECT_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, ADD_SUBJECT_FAILURE);
  }
};
export const editSubject = (name, update, subjectId) => async (
  dispatch,
  getState
) => {
  try {
    document.body.classList.add("loading-indicator");
    const formData = new FormData();
    formData.set("name", update.name);
    formData.set("introText", update.introText);
    formData.set("media", update.media);
    const user = getState().auth.user;
    const result = await API.editMainSubject(formData, subjectId);

    dispatch({
      type: EDIT_SUBJECT_SUCCESS,
      payload: { user, subject: result.data.data.subject },
    });
    dispatchSuccess(
      dispatch,
      `Subject "${name}" successfully edited`,
      EDIT_SUBJECT_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, EDIT_SUBJECT_FAILURE);
  }
};

export const deleteSubject = (data) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deleteMainSubject(data);

    dispatch({ type: DELETE_SUBJECT_SUCCESS, payload: data._id });
    dispatchSuccess(dispatch, result.data.data.message, DELETE_SUBJECT_SUCCESS);
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_SUBJECT_FAILURE);
  }
};

export const deleteSubjects = (data) => async (dispatch) => {
  try {
    document.body.classList.add("loading-indicator");
    const result = await API.deleteMainSubjects(data);

    dispatch({ type: DELETE_SUBJECTS_SUCCESS, payload: { subjectIds: data.subjectIds } });
    dispatchSuccess(
      dispatch,
      result.data.data.message,
      DELETE_SUBJECTS_SUCCESS
    );
    document.body.classList.remove("loading-indicator");
  } catch (err) {
    document.body.classList.remove("loading-indicator");
    dispatchError(dispatch, err, DELETE_SUBJECTS_FAILURE);
  }
};
