import React, { useEffect, useState } from "react";
import {
  inputChange,
  addPastQuestionCategory,
  editPastQuestionCategory,
} from "../../../redux/actions/pastQuestionTypesActions";
import { connect } from "react-redux";
import TextField from "../../includes/text-field/textField.component";
import Button from "../../includes/button/button.component";
import Modal from "../../includes/modal/modal.component";

const PastQuestionForm = (props) => {

  const { name, categoryId } = props;

  useEffect(() => {
    if (props.type === "Edit" && props.category.name !== name) {
      props.inputChange("name", props.category.name);
      props.inputChange("categoryId", props.category.categoryId);
    } else if (props.type !== "Edit") {
      props.inputChange("name", "");
      props.inputChange("categoryId", "");
    }
  }, [props.type, props.category]);

  const resetForm = () => {
    if (props.type === "Edit") {
      props.inputChange("name", props.category.name);
      props.inputChange("categoryId", props.category.categoryId);
      return;
    }
    props.inputChange("name", "");
    props.inputChange("categoryId", "");
  };

  const addPastQuestionCategory = async () => {
    if (!name) {
      props.onSetSnackbar({ message: "Name is required", type: "error" });
      return;
    }

    if (!categoryId) {
      props.onSetSnackbar({ message: "Category Id is required", type: "error" });
      return;
    }

    await props.addPastQuestionCategory({ name, categoryId });
    resetForm();
    props.onComplete({ name });
  };

  const editPastQuestionCategory = async () => {
    let message;
    if (
      name === props.category.name &&
      categoryId === props.category.categoryId
    )
      message = "No changes made";
    else if (!name || !categoryId) message = "Field cannot be empty";

    if (message) {
      props.onSetSnackbar({ message, type: "error" });
      return;
    }

    await props.editPastQuestionCategory(
      props.category.name,
      { name, categoryId },
      props.category._id
    );
    resetForm();
    props.onComplete({ name });
  };

  const handleSubmit = () => {
    if (props.type === "Edit") {
      editPastQuestionCategory();
    } else {
      addPastQuestionCategory();
    }
  };

  const handleInput = (e) => {
    props.inputChange(e.target.name, e.target.value);
  };

  return (
    <Modal
      maxWidth="550px"
      show={props.show}
      onClose={() => {
        resetForm();
        props.onClose();
      }}
    >
      <div
        style={{
          padding: "0 30px",
        }}
      >
        <h3 className="mb-4">{props.type} Past Question Category</h3>
        <h6>Category Name</h6>
        <TextField
          onInput={handleInput}
          value={name}
          name="name"
          style={{ marginBottom: 25 }}
        ></TextField>
        <h6>Category Id</h6>
        <TextField
          onInput={handleInput}
          value={categoryId}
          name="categoryId"
          style={{ marginBottom: 25 }}
        ></TextField>
        <div className="center mt-4">
          <Button
            onClick={handleSubmit}
            style={{ fontSize: "120%" }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  name: state.pastQuestionCategories.name,
  categoryId: state.pastQuestionCategories.categoryId
});
export default connect(mapStateToProps, { inputChange, addPastQuestionCategory, editPastQuestionCategory })(
  PastQuestionForm
);
