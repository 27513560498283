import React, { useState, useEffect, useRef } from "react";
import SunEditor from "suneditor-react";
import { useHistory, Redirect, useParams } from "react-router-dom";
import "suneditor/dist/css/suneditor.min.css";
import Button from "../../includes/button/button.component";
import SelectField from "../../includes/select-field/select.component";
import TextField from "../../includes/text-field/textField.component";
import {
  inputChange,
  fetchBlogArticle,
  editBlogArticle,
  initializeState,
} from "../../../redux/actions/blogArticlesActions";
import {
  fetchBlogArticleCategories,
  addBlogArticleCategory,
} from "../../../redux/actions/blogArticleCategoriesActions";
import { connect, useDispatch, useSelector } from "react-redux";
import { clearErrors } from "../../../redux/actions/errorActions";
import { clearSuccess } from "../../../redux/actions/successActions";
import Snackbar from "../../includes/snackbar/snackbar.component";
import { show } from "../../../assets/js/globalFunctions";

import "./css/style.css";
import {
  getImpactStory,
  getImpactStoryCategories,
  updateUserStory,
} from "../../../redux/actions/impactStoryActions";

const btnList = [
  ["undo", "redo"],
  ["font", "fontSize", "formatBlock"],
  ["bold", "underline", "italic", "strike", "subscript", "superscript"],
  ["removeFormat"],
  "/",
  ["fontColor", "hiliteColor"],
  ["outdent", "indent"],
  ["align", "horizontalRule", "list", "table"],
  ["link", "image"],
  ["fullScreen", "showBlocks", "codeView"],
];

const UserStoryEdition = (props) => {
  const customerStory = useSelector(
    (state) => state.customerStories.customerStory
  );
  const { role, roles, success, error } = props;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState({});
  const [newCategory, setNewCategory] = useState("");
  const [snackbar, setSnackbar] = useState({ message: "" });
  const [addingCategory, setAddingCategory] = useState(false);
  const [fileURL, setfileURL] = useState("");

  const setfileURL_ = (e) => {
    setfileURL(e.target.value);
  };

  const contentImagesRef = useRef([]);
  const mounted = useRef(false);
  const initialized = useRef(false);
  const { id } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const customerStoryCategories = useSelector(
    (state) => state.customerStories.customerStoryCategories
  );
  useEffect(() => {
    if (!mounted.current) {
      dispatch(getImpactStoryCategories());
      dispatch(getImpactStory(id));
      mounted.current = true;
    } else {
      if (success.id) {
        const snack = {
          message:
            typeof success.msg === "object"
              ? success.msg.join("<br/>")
              : success.msg,
          type: "success",
        };

        switch (success.id) {
          case "UPDATE_IMPACT_STORY_SUCCESS":
            setSnackbar({ ...snack, timeout: 1500 });
            setTimeout(() => {
              history.push("/impact-stories");
            }, 2000);
            break;
        }
        props.clearSuccess();
      } else if (error.id) {
        const snack = {
          message:
            typeof error.msg === "object" ? error.msg.join("<br/>") : error.msg,
          type: "error",
        };

        switch (error.id) {
          case "UPDATE_IMPACT_STORY_FAILURE":
            setSnackbar(snack);
            break;
        }
        props.clearErrors();
      }
    }
  });

  useEffect(() => {
    if (customerStory._id) {
      setTitle(customerStory.title);
      setCategory(customerStory.categoryId);
      setDescription(customerStory.description);
      setfileURL(customerStory.fileURL);

      initialized.current = true;
    }
  }, [customerStory]);

  const handleImageUpload = (targetImgElement, index, state, imageInfo) => {
    if (
      !imageInfo ||
      imageInfo.src.includes("http://") ||
      imageInfo.src.includes("https://")
    )
      return;
    var blobBin = atob(imageInfo.src.split(",")[1]);
    var array = [];
    for (var i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }
    var file = new Blob([new Uint8Array(array)], { type: "image/jpg" });
    contentImagesRef.current = [
      ...contentImagesRef.current,
      { file: file, src: imageInfo.src },
    ];
  };

  const submit = () => {
    const data = {};
    if (title !== customerStory.title) {
      data.title = title;
    }
    if (description !== customerStory.description) {
      data.description = description;
      data.contentImages = contentImagesRef.current.map((cnt) => cnt.file);
      data.contentUrls = contentImagesRef.current.map((cnt) => cnt.src);
    }
    if (category !== customerStory.categoryId) {
      data.category = category;
    }
    if (fileURL !== customerStory.fileURL) {
      data.fileURL = fileURL;
    }

    const itIsNew =
      title !== customerStory.title ||
      description !== customerStory.description ||
      category !== customerStory.categoryId ||
      fileURL !== customerStory.fileURL;

    if (itIsNew) {
      dispatch(updateUserStory({ ...data }, customerStory._id));
    }
  };
  const setTitle_ = (e) => {
    setTitle(e.target.value);
  };

  const setCategory_ = (e) => {
    setCategory(e.target.value);
  };

  return (
    <div id="content-creation" className="page-padding">
      {role && (
        <>
          {!show(1, role, roles) ? <Redirect to="/dashboard" /> : null}
          <Snackbar
            snack={snackbar}
            reset={() => {
              setSnackbar({ message: "" });
            }}
          />
          <div
            className="d-flex justify-content-between align-items-center"
            style={{
              borderLeft: "5px solid var(--primary-color)",
              paddingLeft: "8px",
            }}
          >
            <h4 style={{ margin: "0", flexGrow: 1 }} className="title-text">
              User Story
            </h4>
            <Button onClick={submit}>Save</Button>
          </div>
          <div className="row mt-5">
            <div className="col-12 col-lg-8">
              <TextField
                placeholder="Title"
                name="title"
                value={title}
                onInput={setTitle_}
                plain
                className="mb-4"
              />
              <div className="note-markup">
                {initialized.current && (
                  <SunEditor
                    setOptions={{
                      height: 515,
                      buttonList: btnList,
                      toolbarContainer: "#toolbar-container",
                    }}
                    onChange={(cont) => {
                      setDescription(cont);
                    }}
                    onImageUpload={handleImageUpload}
                    autoFocus={false}
                    defaultValue={description}
                  />
                )}
              </div>
            </div>
            <div className="col-12 col-lg-4 mt-4 mt-lg-0">
              <div
                style={{
                  backgroundColor: "white",
                  padding: "20px 0 60px 0",
                  borderRadius: "10px",
                }}
              >
                <h4 className="pl-4">Category</h4>
                <hr />
                <div className="pl-4 pr-4">
                  <div className="mb-2">Select Category</div>
                  <SelectField
                    value={category}
                    onSelect={setCategory_}
                    name="course"
                    items={customerStoryCategories}
                    textField="name"
                    valueField="_id"
                    outlined
                    placeholder="Select Category"
                    color="#B6B6B6"
                    inputStyle={{ borderRadius: 5, paddingLeft: 15 }}
                    className="mb-4"
                  ></SelectField>
                </div>
                <div className="pl-4 pr-4">
                  <div className="mb-2">Upload image/video url</div>
                  <TextField
                    value={fileURL}
                    onInput={setfileURL_}
                    name="new category"
                    outlined
                    placeholder="File URL"
                    color="#B6B6B6"
                    inputStyle={{ borderRadius: 5, paddingLeft: 15 }}
                    className="mb-2"
                  ></TextField>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  articleCategories: state.blogArticleCategories.blogArticleCategories,
  blogArticle: state.blogArticles.blogArticle,
  role: state.auth.user.role,
  roles: state.auth.roles,
  error: state.error,
  success: state.success,
});
export default connect(mapStateToProps, {
  inputChange,
  fetchBlogArticle,
  initializeState,
  editBlogArticle,
  addBlogArticleCategory,
  fetchBlogArticleCategories,
  clearSuccess,
  clearErrors,
})(UserStoryEdition);
