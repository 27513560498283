import {
    SEND_EMAIL_SUCCESS, 
    INPUT_CHANGE
  } from "../actions/types";
  
  const initialState = {
    category: "",
    subject: "",
    message: "",
    emailTo: "",
    mainCategory: ""
  };
  
  const sendEmailReducer = (state = initialState, action) => {
    let arr;
    switch (action.type) {
      case INPUT_CHANGE:
        return {
          ...state,
          [action.payload.name]: action.payload.value,
        };
      case SEND_EMAIL_SUCCESS:
        return {
          ...state,
        };
      default:
        return state;
    }
  };
  
  export default sendEmailReducer;
  