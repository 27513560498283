import React from "react";

const AssignedPastQuestionType = (props) => {
  return (
    props.pastQuestionType.name ?
    <div
      style={{ width: "100%" }}
      className="d-flex justify-content-between assigned-subject"
    >
      <div className="d-flex flex-column justify-content-between">
        <span>{props.pastQuestionType.name}</span>
        <p>CategoryId: {props.pastQuestionType.categoryId}</p>
      </div>
      <button
        className="remove-button"
        onClick={() => {
          props.onDeleteAssigned(props.pastQuestionType);
        }}
      >
        Remove
      </button>
    </div> : ""
  );
};
export default AssignedPastQuestionType;
