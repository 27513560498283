import {
  GET_COURSES_SUCCESS,
  DELETE_COURSE_SUCCESS,
  DELETE_COURSES_SUCCESS,
  ADD_COURSE_SUCCESS,
  EDIT_COURSE_SUCCESS,
  INPUT_CHANGE,
} from "../actions/types";

const initialState = {
  courses: [],
  name: "",
  alias: "",
  category: "",
};

const coursesReducer = (state = initialState, action) => {
  let arr;
  switch (action.type) {
    case INPUT_CHANGE:
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case GET_COURSES_SUCCESS:
      return {
        ...state,
        courses: action.payload.courses,
      };
    case DELETE_COURSE_SUCCESS:
      arr = state.courses.slice();
      arr = arr.filter((course) => course._id !== action.payload);
      return {
        ...state,
        courses: arr,
      };
    case DELETE_COURSES_SUCCESS:
      return {
        ...state,
        courses: state.courses.filter(
          (course) => !action.payload.courseIds.includes(course._id)
        ),
      };
    case ADD_COURSE_SUCCESS:
      return {
        ...state,
        courses: [
          ...state.courses,
          {
            ...action.payload.course,
            creatorId: {
              firstName: action.payload.user.firstName,
              _id: action.payload.user._id,
            },
          },
        ],
      };
    case EDIT_COURSE_SUCCESS:
      arr = state.courses.slice();
      let ind = arr.findIndex(
        (course) => course._id === action.payload.course._id
      );
      arr[ind] = {
        ...action.payload.course,
        creatorId: {
          _id: action.payload.course.creatorId,
          firstName: action.payload.user.firstName,
        },
      };
      return {
        ...state,
        courses: arr,
      };
    default:
      return state;
  }
};

export default coursesReducer;
