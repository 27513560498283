import React, { useEffect, useState } from 'react'
import { inputChange, addCmsUser, editCmsUser } from "../../../redux/actions/cmsUserActions";
import { connect } from "react-redux";
import TextField from '../../includes/text-field/textField.component'
import SelectField from "../../includes/select-field/select.component";
import Button from '../../includes/button/button.component'
import './css/detail-modal.css'
import RoleWrapper from '../../includes/role-wrapper/role-wrapper.component';

const UserForm = (props) => {
    const [loading, setLoading] = useState(false);
    const [showCopied, setshowCopied] = useState(false);
    const
        { user, currentActionUser, firstName,
            lastName, email, password, roles,
            role} = props;

    useEffect(() => {
        if (props.type === "Edit" && props.user.firstName !== firstName) {
            props.inputChange("firstName", props.user.firstName);
            props.inputChange("lastName", props.user.lastName);
            props.inputChange("email", props.user.email);
            props.inputChange("role", roles.find(role => role._id === props.user.role));
        } else if (props.type == "Create") {
            props.inputChange("firstName", "");
            props.inputChange("lastName", "");
            props.inputChange("email", "");
            props.inputChange("role", "");
            props.inputChange("password", "")
        }
    }, [props.type, props.user]);

    const resetForm = () => {
        if (props.type == "Edit") {
            props.inputChange("firstName", props.user.firstName);
            props.inputChange("lastName", props.user.lastName);
            props.inputChange("email", props.user.email);
            props.inputChange("role", props.user.role);
            return;
        }
        if (props.type == "Detail New") {
            props.inputChange("firstName", currentActionUser.firstName);
            props.inputChange("lastName", currentActionUser.lastName);
            props.inputChange("email", currentActionUser.email);
            props.inputChange("role", currentActionUser.role);
            props.inputChange("password", currentActionUser.password);
            return;
        }

        if (props.type == "Create" || "Edit New") {
            props.inputChange("firstName", "");
            props.inputChange("lastName", "");
            props.inputChange("email", "");
            props.inputChange("role", "");
            // props.inputChange("password", "");
        }
    };

    const editNewReset = () => {
        props.inputChange("firstName", currentActionUser.firstName);
        props.inputChange("lastName", currentActionUser.lastName);
        props.inputChange("email", currentActionUser.email);
        props.inputChange("role", currentActionUser.role);
        props.inputChange("password", currentActionUser.password);
        return;
    }

    const addUser = async () => {
        let message;
        let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/;
        if (!firstName) message = "First Name is required";
        else if (!lastName) message = "Last Name is required";
        else if (!email) message = "Email is required";
        else if (!email.match(emailRegex))
            message = "Please enter a valid Email Address";
        else if (!role) message = "Role is required";
        else if (!password) message = "Password is required";
        else if (password.length < 8)
            message = "Password requires a minimum of 8 characters";
        if (message) {
            props.onSetSnackbar({ message, type: "error" });
            return;
        }

        setshowCopied(false);

        await props.addCmsUser({ firstName, lastName, email, password, role: role._id });
        resetForm();
    };

    const editUser = async () => {
        let message;
        if (
            firstName === props.user.firstName &&
            lastName === props.user.lastName &&
            role === props.user.role &&
            email === props.user.email
        )
            message = "No changes made";
        if (!firstName
            || !lastName
            || !email
            || !role) message = "Field cannot be empty";

        if (message) {
            props.onSetSnackbar({ message, type: "error" });
            return;
        }

        if (props.type == "Edit") {
            await props.editCmsUser(
                props.user.firstName, props.user.lastName,
                { firstName, lastName, email, role: role._id },
                props.user._id
            );
        }

        if (props.type == "Edit New") {
            await props.editCmsUser(
                currentActionUser.firstName, currentActionUser.lastName,
                { firstName, lastName, email, role: role._id },
                currentActionUser._id
            );
        }
        resetForm();
    };

    const handleInput = (e) => {
        props.inputChange(e.target.name, e.target.value);
    };

    const handleSubmit = () => {
        if (props.type === "Edit" || props.type === "Edit New") {
            editUser();
        } else {
            addUser();
        }
    };

    const userForm = () => {
        return (
            <div className="add-user-form">
                <h3>{props.type} User</h3>
                <p className="mb-3">
                    {props.type == "Create" ? "Create" : "Edit"} account for
                    {props.type == "Edit" ? "" : " new"} user
                </p>
                <label htmlFor="firstname">First Name</label>
                <TextField
                    onInput={handleInput}
                    name="firstName"
                    value={firstName}
                />
                <label htmlFor="lastname">Last Name</label>
                <TextField
                    onInput={handleInput}
                    name="lastName"
                    value={lastName}
                />
                <label htmlFor="email">Email</label>
                <TextField
                    onInput={handleInput}
                    name="email"
                    value={email}
                />
                <label htmlFor="role">Role</label>
                <SelectField
                    onSelect={handleInput}
                    value={role}
                    items={roles}
                    name="role"
                    textField="name"
                    valueField="_id"
                    placeholder="Select"
                />
                {(props.type == "Create") ?
                    <div>
                        <label htmlFor="password" style={{ marginTop: 20 }}>
                            Password
                    </label>
                        <TextField
                            onInput={handleInput}
                            name="password"
                            value={password}
                            type={"password"}
                        />
                    </div>
                    : " "
                }
                <Button onClick={handleSubmit} loading={loading} className="add-btn">
                    {props.type == "Create" ? "Add" : "Edit"}
                </Button>
            </div>
        )
    }

    const UserDetail = () => {
        let roleId = user.role ? user.role : currentActionUser.role;
        let roleText = <RoleWrapper value={roleId} />

        const copyText = () => {
            navigator.clipboard.writeText(password)
            setshowCopied(!showCopied);
            setTimeout(() => { setshowCopied(showCopied) }, 700);

        }

        return (
            <div className="detail">
                <h3 style={{ marginBottom: 30 }}>
                    {roleText} Details
                </h3>
                <p><label>First Name:</label>
                    {user.firstName ? user.firstName : currentActionUser.firstName}
                </p>
                <p><label>Last Name:</label>
                    {user.lastName ? user.lastName : currentActionUser.lastName}
                </p>
                <p><label>Email:</label>
                    {user.email ? user.email : currentActionUser.email}
                </p>
                <p><label>Role:</label>
                    {roleText}
                </p>
                {props.type == "Detail New" ?
                    <div>
                        <p><label>Password:</label>
                            {user.password ? user.password : password}
                            <span style={{ position: 'relative', marginLeft: 40 }}>
                                <span
                                    style={{
                                        display: showCopied ? 'block' : 'none',
                                        position: 'absolute',
                                        top: '-37px',
                                        left: '-8px',
                                        padding: '4px 8px',
                                        backgroundCcolor: 'white',
                                        borderRadius: 8,
                                        border: '1px solid #F2994A'
                                    }}>
                                    Copied!
                                </span>
                                <a id="copy-link" onClick={copyText}>
                                    copy
                                </a>
                            </span>
                        </p>
                        <a id="edit-link"
                            onClick={() => {
                                editNewReset()
                                props.onEdit()
                            }} >
                            Edit
                        </a>
                    </div> :
                    <p></p>
                }
            </div>
        )
    }

    return (
        props.type == "Detail" || props.type == "Detail New"
            ? UserDetail()
            : userForm()
    )
}

const mapStateToProps = (state) => ({
    currentActionUser: state.cmsUsers.currentActionUser,
    firstName: state.cmsUsers.firstName,
    lastName: state.cmsUsers.lastName,
    email: state.cmsUsers.email,
    role: state.cmsUsers.role,
    roles: state.auth.roles,
    password: state.cmsUsers.password
});
export default connect(
    mapStateToProps,
    {
        inputChange,
        addCmsUser,
        editCmsUser
    })(UserForm);